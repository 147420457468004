<div class="row justify-content-center title-modal" style="margin-left: -24px;margin-right: -24px;">
  <div class="col-11 text-uppercase text-center p-3">
    <h4>Gracias por tu pedido</h4>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row">
      <mat-label class="col-12 mb-2"> <strong>Numero de pedido:</strong> {{object.main.numberOrder}}</mat-label> <br>
      <p>Muchas gracias, Estamos preparando tu orden. Nos comunicaremos contigo a la brevedad.</p>
    </div>
    <div class="row justify-content-center mt-4">
      <div class="col-11">
        <button type="button" class="btn btn-danger btn-block" (click)="close(true);">Aceptar</button>
      </div>
    </div>
  </div>
</div>
