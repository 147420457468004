<mat-drawer-container class="drawerConteiner" autosize>
    <mat-drawer #drawer class="mainDrawer" mode="side">
      <app-menu></app-menu>
    </mat-drawer>
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center pl-0 pr-0">
        <div class="col-10">
          <div class="row mt-2 mb-2">
            <div class="col-9">
              <h4 class="text-uppercase pt-2 pb-2">Meseros</h4>
            </div>
    
            <div class="col-3">
              <button style="width:100%" mat-flat-button color="accent"  matTooltip="Agregar un nuevo mesero" (click)="update('new')"> <i class="material-icons">add_circle_outline</i>Meseros</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-10">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col-12">
                  <table class="table table-general">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Estatus</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let object of waiterList">
                        <td>{{ object.main.code}}</td>
                        <td>{{object.system.physicalPerson.main.name}}  {{object.system.physicalPerson.main.lastname}} {{object.system.physicalPerson.main.surname}}</td>
                        <td>{{object.system.status == 'false'? 'Inactivo' : 'Activo'}}</td>
                        <td>
                          <button mat-icon-button color="primary" matTooltip="Editar mesero" (click)="update(object.main.uuid)">
                            <i class="material-icons">create</i>
                          </button>
                          <button mat-icon-button color="warn" matTooltip="Asignar sucursal" (click)="access(object.main.uuid)">
                            <i class="material-icons">key</i>
                          </button>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <!-- aqui el paginado o footer -->
              <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
                <div class="col-6 ">
                  <mat-paginator [length]=paginatorModel.total [pageSize]=waiterMin.max [pageSizeOptions]=paginatorModel.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)">
                  </mat-paginator>
                </div>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>



</mat-drawer-container>
