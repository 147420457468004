<mat-drawer-container class="drawerConteiner font-kelson img-home-seafood" autosize>
  <mat-drawer #drawer class="mainDrawwerSeafood" mode="push" position="end">
    <app-restaurant-seafood-menu-collapsed></app-restaurant-seafood-menu-collapsed>
  </mat-drawer>
  <div class="row img-home-seafood m-0">
    <div class="col-12">
      <div class="row justify-content-center font-kelson">
        <div class="col-3" routerLink="/restaurant-seafood/home">
          <button mat-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
            <mat-icon class="pt-2 text-orange" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center" id="ribbon" >
          <h2 class="text-white align-middle mb-0 font-kelson" id="content-ribbon" style="line-height: 1.4rem;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-seafood/order">
            <mat-icon class="text-orange">receipt</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-orange" style="font-size: 2rem;">dehaze</mat-icon>
          </button>
        </div>
      </div>
      <div class="row justify-content-center mt-2">
        <!-- <mat-form-field appearance="legacy" class="col-11 font-kelson">
          <mat-label>Buscar</mat-label>
          <input matInput placeholder="Buscar">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field> -->
        <div class="col-11 text-center mt-2">
          <h1 class="font-billy" style="font-size: 4rem;">{{metadata.foodCategory.main.name}}</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-11">
          <div class="row">
            <div class="col-6 text-center mt-1" *ngFor="let foodSaucer of foodSaucerList" routerLink="/restaurant-seafood/product-detail/{{foodSaucer.main.uuid}}">
              <span class="font-kelson"><strong>{{foodSaucer.main.name}}</strong></span>
              <div *ngIf="foodSaucer.main.image" class="box_seaFood">
                <img mat-card-image [src]="foodSaucer.image" class="img-menu rounded" *ngIf="foodSaucer.image">
                <div class="sk-circle" *ngIf="foodSaucer.image == undefined">
                  <div class="sk-circle1 sk-child"></div>
                  <div class="sk-circle2 sk-child"></div>
                  <div class="sk-circle3 sk-child"></div>
                  <div class="sk-circle4 sk-child"></div>
                  <div class="sk-circle5 sk-child"></div>
                  <div class="sk-circle6 sk-child"></div>
                  <div class="sk-circle7 sk-child"></div>
                  <div class="sk-circle8 sk-child"></div>
                  <div class="sk-circle9 sk-child"></div>
                  <div class="sk-circle10 sk-child"></div>
                  <div class="sk-circle11 sk-child"></div>
                  <div class="sk-circle12 sk-child"></div>
                </div>
              </div>
              <div class="box_seaFood" *ngIf="!foodSaucer.main.image">
              </div>
              <span class="price text-white pb-4 pl-3 pr-3 pt-4">{{foodSaucer.main.price | currency:'CAD':'symbol-narrow'}}</span>
            </div>

          </div>
        </div>
      </div>
      <footer class="text-center text-secondary p-1">
      © 2019 Keysoft. Reservados todos los derechos.
      </footer>
    </div>
  </div>
</mat-drawer-container>
