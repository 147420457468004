<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0">
    <div class="col-10">
      <div class="row">
        <h4 class="text-uppercase pt-2 pb-2 col-6">Configuración Áreas del Restaurante</h4>
      </div>
      <mat-tab-group (selectedTabChange)="setBranch($event)">
        <mat-tab *ngFor="let branch of branchList">
          <ng-template mat-tab-label>{{branch.main.name}}</ng-template>
          <div class="row">
            <mat-card class="col-3 mt-1 ml-2" *ngFor="let restaurantArea of restaurantAreaList">
              <button mat-icon-button class="float-right" style="cursor: pointer" (click)="updateDialog(restaurantArea.main.uuid);" color="primary" aria-label="Editar área">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button class="float-right" style="cursor: pointer" (click)="downloadQr(restaurantArea.main.uuid);" color="primary" aria-label="Descargar códigos QR">
                <mat-icon>qr_code</mat-icon>
              </button><br />
              <mat-card-header>
                <mat-card-title >
                  <mat-icon style="font-size:22px;" color="warn">room</mat-icon>&nbsp;{{restaurantArea.main.name}}
                </mat-card-title>
                <mat-card-subtitle>
                  <mat-icon color="accent">deck</mat-icon>&nbsp; Mesas &nbsp; ({{restaurantArea.system.foodTables.length}})
                </mat-card-subtitle>
              </mat-card-header>
            </mat-card>
            <div class="col-2 text-center pt-4" (click)="updateDialog('new');">
              <button mat-mini-fab color="accent" aria-label="Nueva área">
                <mat-icon>add_location_alt</mat-icon>
              </button>
              <h3 class="mt-2">Nueva Área</h3>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

</mat-drawer-container>
