import { Component, OnInit, Output, EventEmitter, ViewChild  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from '../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-restaurant-hotel-menu-sn',
  templateUrl: './restaurant-hotel-menu-sn.component.html',
  styleUrls: ['./restaurant-hotel-menu-sn.component.css']
})
export class RestaurantHotelMenuSnComponent implements OnInit {
  @Output() menuToogleRestaurant: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  metadata = {
    name:'',
    branch:{
      main:{
        uuid:''
      }
    },
    foodCategory:{
      main:{
        uuid:'',
        name:''
      }
    },
    description:'',
    quantity:1,
    displayModal:false
  }
  foodSaucerBranchMin ={
    system:{
      branch:{
        main:{
          uuid:''
        }
      },
      foodCategory:{
        main:{
          uuid:''
        }
      }
    },
    "max":10,
    "offset":0,
    "filter":{
      "name":"searchElement",
      "value":""
    }
  }
  foodSaucerBranchList = [];
  foodSaucerList = [];
  foodOrderModel = {
    main:{
      uuid: '',
      carryOut:  false, // true para llevar, false para en sitio
      dateFoodOrder: '', // Fecha y hora del medido
      status: 'Abierta', // Abierta, Preparacion, En entrega, Concluida
      subTotal: 0.0, // Subtotal a pagar
      tax: 0.0, // Impuestos a pagar
      total: 0.0, // //Total a pagar
      numberOrder:'',
      paymentMethod:'Pago en Efectivo'
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      foodTable:{
        main:{
          uuid:''
        }
      },
      roomService:{
        main:{
          uuid:''
        }
      }
    }
  };
  foodOrderDetailModel = {
    main:{
      uuid:'',
      priceUnitary: 0.0,
      quantity: 0.0,
      observation:''
    },
    system:{
      foodSaucer:{
        main:{
          uuid:''
        }
      },
      foodOrder:{
        main:{
          uuid:''
        }
      }
    }
  }
  foodOrderDetailList = [];

  foodOrder = JSON.parse(JSON.stringify(this.foodOrderModel));
  objectToOrder:any = {
    main:{
      name:''
    }
  };

  constructor(private session: SessionService, public loadingService:LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar) {
   }

  ngOnInit(): void {
    let branch = this.session.getAnonymousSessionBranch();
    this.metadata.name = branch.system.company.main.tradename;
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] != '') {
        //bscamos los platillos de una categoria
        this.metadata.foodCategory.main.uuid = params['uuid'];
        this.metadata.branch = this.session.getAnonymousSessionBranch();
        this.foodSaucerList = [];
        this.getFoodSaucer();
      } else {
        this.router.navigate(['/restaurant-hotel/home']);
      }
    });
  }

  /**función para obtener el listado de platillo por categoria y branch
  */
  getFoodSaucer(){
    this.foodSaucerBranchMin.system.branch.main.uuid = this.metadata.branch.main.uuid;
    this.foodSaucerBranchMin.system.foodCategory.main.uuid = this.metadata.foodCategory.main.uuid;
    this.session.getRequestAnonymous("foodSaucerBranch:findAllByBranchAndFoodCategory",this.foodSaucerBranchMin).subscribe((data:any)=>{
      this.foodSaucerBranchList = data.object.instanceList;
      for(let i=0; i< this.foodSaucerBranchList.length; i++){
        this.foodSaucerList.push(this.foodSaucerBranchList[i].system.foodSaucer);
        this.metadata.foodCategory = this.foodSaucerBranchList[i].system.foodCategory;
      }
      // buscamos la imagenes
      for(let i=0; i < this.foodSaucerList.length; i++){
        if(this.foodSaucerList[i].main.image){
          this.getImage(this.foodSaucerList[i],i);
        }
      }
      // console.log("foodSaucerList::::::::::",this.foodSaucerList);
    },error=>{
      console.log("error:foodSaucerBranch:findAllByBranchAndFoodCategory",error);
    })
  }

  /**función para obtener la imagen de un producto
  */
  async getImage(object,indexX){
    let auxFile ={
      file:{
        uuid:object.main.uuid,
        name:object.main.name,
        file:'',
        index:0,
        total:0,
        chunk:10,
        chunkSize:0,
        position:0,
        type:''
      },
      main:{
        uuid:object.main.uuid//uuid del archivo
      }
    }
    let base64 = '';
    try{
      let chunkFirstTime = true;
      for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
        // console.log("index--->",auxFile.file.index);
        let resF = await this.getRequestBaseFile(auxFile);
        if(resF.transaction == 'ok'){
          base64 = base64.concat(resF.object.file.file);
          auxFile.file.name = resF.object.file.name;
          if(chunkFirstTime){
            auxFile.file.chunk = resF.object.file.chunk;
            chunkFirstTime = false;
          }
        }else{
          // this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
        }
      }
      if( base64 != ''){
        // console.log(base64);
        base64 = base64.replace(/-/g, "+");
        object.image = base64;
      }

    }catch(e){
      console.log("error",e);
      object.mode = 'determinate';
      // this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
    }
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous('foodSaucer:download',object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }
  /*
  * funcion para abrir o cerrar le menu
  */
  toogleMenuRestaurant(ev){
    this.drawerCtrl.opened = ev;
  }

  /**función para obtener el foodOrder
  */
  getFoodOrder(){
    return new Promise((resolve,reject)=>{
      //verificamos si existe foodOrder si no lo pedimos de localstorage
      let aux =  localStorage.getItem("foodOrder");
      // console.log(aux);
      if(aux){
        // console.log("Encontramos un foodOrder");
        let aux2 = JSON.parse(localStorage.getItem("foodOrder"));
        if(aux2.main.status == 'Concluida'){
          aux2.main.status = 'Abierta';
          aux2.main.uuid = '';
          let objectAndObjectUuid = this.session.getAnonymusObjectAndObjectUuid();
          if(objectAndObjectUuid.object == "foodTable"){
            this.foodOrder.system.foodTable.main.uuid = objectAndObjectUuid.objectUuid;
            this.foodOrder.main.carryOut = false;
          }else{
            if(objectAndObjectUuid.object == "roomService"){
              this.foodOrder.system.roomService.main.uuid = objectAndObjectUuid.objectUuid;
            }else{
              this.foodOrder.main.carryOut = true;
            }
          }
          this.foodOrder.main.dateFoodOrder = moment().format('DD/MM/YYYY HH:mm:ss');
          this.session.getRequestAnonymous("foodOrder:update",this.foodOrder).subscribe((data:any)=>{
            this.foodOrder.main.uuid = data.object.main.uuid;
            localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
            resolve(this.foodOrder);
          },error=>{
            reject(error);
          })
        }else{
          resolve(aux2);
        }
      }else{
        let branch:any = this.session.getAnonymousSessionBranch();
        this.foodOrder.system.company.main = branch.system.company.main;
        this.foodOrder.system.branch.main = branch.main;
        let objectAndObjectUuid = this.session.getAnonymusObjectAndObjectUuid();
        // console.log("getAnonymusObjectAndObjectUuid",objectAndObjectUuid);
        if(objectAndObjectUuid.object == "foodTable"){
          this.foodOrder.system.foodTable.main.uuid = objectAndObjectUuid.objectUuid;
          this.foodOrder.main.carryOut = false;
        }else{
          if(objectAndObjectUuid.object == "roomService"){
            this.foodOrder.system.roomService.main.uuid = objectAndObjectUuid.objectUuid;
            this.foodOrder.main.carryOut = false;
          }else{
            this.foodOrder.main.carryOut = true;
          }
        }
        this.foodOrder.main.dateFoodOrder = moment().format('DD/MM/YYYY HH:mm:ss');
        // console.log("No hay un foodOrder, lo creamos",this.foodOrder);
        this.session.getRequestAnonymous("foodOrder:update",this.foodOrder).subscribe((data:any)=>{
          this.foodOrder.main.uuid = data.object.main.uuid;
          localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
          resolve(this.foodOrder);
        },error=>{
          reject(error);
        })
      }
    });
  }

  /**funcionalidad para actualizar foodOrderDetail
  */
  updateFoodOrderDetails(object){
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous("foodOrdenDetail:update",object).subscribe((data:any)=>{
        object.main.uuid = data.object.main.uuid;
        //actualizamos los valores de la orden
        resolve(object);
      },error=>{
        reject(error);
      })
    });
  }

  /**funcionalidad para actualziar un foodOrder
  */
  getFoodOrderUpdate(){
    this.session.getRequestAnonymous("foodOrder:get",this.foodOrder).subscribe((data:any)=>{
      this.foodOrder = data.object;
      localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
    },error=>{
    })
  }

  /**función par agregar un producto
  */
  addProduct(){
    this.loadingService.wait();
    this.getFoodOrder().then((data)=>{
      this.foodOrder = data;
      let foodOrderDetailAux = JSON.parse(JSON.stringify(this.foodOrderDetailModel));
      foodOrderDetailAux.main.priceUnitary = this.objectToOrder.main.price;
      foodOrderDetailAux.main.observation = this.metadata.description;
      foodOrderDetailAux.main.quantity = this.metadata.quantity;
      foodOrderDetailAux.system.foodSaucer.main = this.objectToOrder.main;
      foodOrderDetailAux.system.foodOrder.main = this.foodOrder.main;
      this.foodOrder.main.dateFoodOrder = moment().format('DD-MM-YYYY HH:mm');
      this.foodOrder.main.quantity = this.foodOrder.main.quantity + foodOrderDetailAux.main.quantity;
      this.foodOrder.main.subTotal = this.foodOrder.main.subTotal + (foodOrderDetailAux.main.quantity*foodOrderDetailAux.main.priceUnitary);
      this.updateFoodOrderDetails(foodOrderDetailAux).then((data)=>{
        if(localStorage.getItem("foodOrderDetailList")){
          this.foodOrderDetailList = JSON.parse(localStorage.getItem("foodOrderDetailList"));
        }
        this.foodOrderDetailList.push(data);
        localStorage.setItem("foodOrderDetailList", JSON.stringify(this.foodOrderDetailList));
        // console.log("producto agregado");
        this.getFoodOrderUpdate();
        this.closeModal();
        this.loadingService.hide()
        this.snackBar.open("Gracias tu platillo fue agregado", 'Aceptar', {duration: 5000});
      }).catch((e)=>{
        console.log("e:updateFoodOrderDetails",e);
        this.loadingService.hide()
      });

    }).catch((e)=>{
      console.log("error:getFoodOrder",e);
      this.loadingService.hide()
    })
  }

  addQuantity(){
    this.metadata.quantity+=1;
  }

  minQuantity(){
    this.metadata.quantity-=1;
    if(this.metadata.quantity < 1){
      this.metadata.quantity = 1;
    }
  }

  /**función par abrir el modal */
  openModal(object){
    this.metadata.displayModal = true;
    this.objectToOrder = JSON.parse(JSON.stringify(object));
  }

  /**función para cerrar el modal */
  closeModal(){
    this.metadata.displayModal = false;
  }
}
