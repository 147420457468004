<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0">
    <div class="col-10">
      <div class="row mt-2 mb-2">
        <div class="col-9">
          <h4 class="text-uppercase pt-2 pb-2">Promociones</h4>
        </div>
        <!-- <button style="width:100%" mat-flat-button color="accent"  matTooltip="Crear nuevo producto" (click)="edit('81327129879213')"> <i class="material-icons">add_circle_outline</i>Cro</button> -->

        <div class="col-3">
          <button style="width:100%" mat-flat-button color="accent"  matTooltip="Crear nueva promoción" (click)="updatePromotion('new');"> <i class="material-icons">add_circle_outline</i>Promoción</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-10">
      <mat-card>
        <mat-card-content>
          <div class="row">
            <div class="col-12">
              <table class="table table-general">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">IMAGEN</th>
                    <th scope="col">SUCURSAL</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let object of promotionList">
                    <td>
                      <img [src]="object.image" class="img-product" alt="promo">
                    </td>
                    <td>{{object.system.branch.main.name}}</td>
                    <td>
                      <button mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="deleteAPromotion(object);">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <!-- aqui el paginado o footer -->
          <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
            <div class="col-6 ">
              <mat-paginator [length]=paginatorModel.total [pageSize]="10" [pageSizeOptions]=paginatorModel.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)">
              </mat-paginator>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

</mat-drawer-container>
