<mat-drawer-container class="drawerConteiner font-Titilliumr" autosize style="background: #efebde;">
  <mat-drawer #drawer class="mainDrawwerRetro" mode="push" position="end">
    <app-restaurant-retro-menu-collapsed></app-restaurant-retro-menu-collapsed>
  </mat-drawer>
  <div class="row img-home-retro mr-0">
    <div class="col-12">

      <div class="row justify-content-center font-Titilliumb">
        <div class="col-3 pr-0" routerLink="/restaurant-retro/home">
          <button mat-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
            <mat-icon class="pt-2 text-verde" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center pt-3" >
          <h2 class="text-verde align-middle mb-0 font-Titilliumb" style="line-height: 1.2rem;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2 pl-0 pr-0">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-retro/order">
            <mat-icon class="text-verde">receipt</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-verde" style="font-size: 2rem;">dehaze</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="row justify-content-center mt-2">
    <!-- <mat-form-field appearance="legacy" class="col-11 font-kelson">
      <mat-label>Buscar</mat-label>
      <input matInput placeholder="Buscar">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field> -->
    <div class="col-11 text-center mt-2">
      <h1 class="font-Titilliumb text-verde" style="font-size: 2rem;">{{metadata.foodCategory.main.name}}</h1>
    </div>
  </div>


  <div class="row justify-content-center m-0">
    <div class="col-11">
      <div class="row">
        <div class="col-6 text-star mt-3" *ngFor="let foodSaucer of foodSaucerList" routerLink="/restaurant-retro/product-detail/{{foodSaucer.main.uuid}}">
          <div class="col-12" style="padding-right: 0px;">
            <span class="font-Titilliumb pb-1 price">{{foodSaucer.main.price | currency:'CAD':'symbol-narrow'}}</span>
          </div>
            <div class="bandera-verde p-1">
              <p class="font-Titilliumr" style="line-height: 0.9rem; color: #fff;">{{foodSaucer.main.name}}</p>
            </div>
          <div *ngIf="foodSaucer.main.image" class="box_retro">
            <img mat-card-image [src]="foodSaucer.image" class="img-menu rounded" *ngIf="foodSaucer.image">
            <div class="sk-circle" *ngIf="foodSaucer.image == undefined">
              <div class="sk-circle1 sk-child"></div>
              <div class="sk-circle2 sk-child"></div>
              <div class="sk-circle3 sk-child"></div>
              <div class="sk-circle4 sk-child"></div>
              <div class="sk-circle5 sk-child"></div>
              <div class="sk-circle6 sk-child"></div>
              <div class="sk-circle7 sk-child"></div>
              <div class="sk-circle8 sk-child"></div>
              <div class="sk-circle9 sk-child"></div>
              <div class="sk-circle10 sk-child"></div>
              <div class="sk-circle11 sk-child"></div>
              <div class="sk-circle12 sk-child"></div>
            </div>
          </div>
          <div class="box_retro" *ngIf="!foodSaucer.main.image">
          </div>

        </div>

      </div>
    </div>
  </div>
  <footer class="text-center text-secondary p-1 mt-3" >
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>


</mat-drawer-container>
