<mat-drawer-container class="drawerConteiner font-kelson img-home-seafood" autosize>
  <mat-drawer #drawer class="mainDrawwerSeafood" mode="push" position="end">
    <app-restaurant-seafood-menu-collapsed></app-restaurant-seafood-menu-collapsed>
  </mat-drawer>
  <div class="row img-home-seafood m-0">
    <div class="col-12">
      <div class="row justify-content-center font-kelson">
        <div class="col-3 pt-2">
          <button mat-icon-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;" (click)="back();">
            <mat-icon class="text-orange" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center" id="ribbon">
          <h2 class="text-white align-middle mb-0 font-kelson" id="content-ribbon" style="line-height: 1.4rem;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-seafood/order">
            <mat-icon class="text-orange">receipt</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-orange" style="font-size: 2rem;">dehaze</mat-icon>
          </button>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-11 text-center">
          <h1 style="font-size: 1.4rem;margin-bottom:0px;">{{object.main.name}}</h1>
        </div>
        <!-- <div class="col-5 text-right ">
          <h1 class="font-weight-bold pt-1" id="price-details" style="color:#31210b;">
            {{object.main.price | currency:'CAD':'symbol-narrow'}}
          </h1>
        </div> -->
      </div>
      <div class="row justify-content-center">
        <div class=" col-11 box_seaFood-product">
          <ngb-carousel >
            <ng-template ngbSlide>
              <div *ngIf="!object.main.image" style="margin-top: 100%;"></div>
              <div  *ngIf="object.main.image">
                <img [src]="object.image" alt="promo" width="100%" *ngIf="object.image">
                <div class="sk-circle" *ngIf="object.image == undefined">
                  <div class="sk-circle1 sk-child"></div>
                  <div class="sk-circle2 sk-child"></div>
                  <div class="sk-circle3 sk-child"></div>
                  <div class="sk-circle4 sk-child"></div>
                  <div class="sk-circle5 sk-child"></div>
                  <div class="sk-circle6 sk-child"></div>
                  <div class="sk-circle7 sk-child"></div>
                  <div class="sk-circle8 sk-child"></div>
                  <div class="sk-circle9 sk-child"></div>
                  <div class="sk-circle10 sk-child"></div>
                  <div class="sk-circle11 sk-child"></div>
                  <div class="sk-circle12 sk-child"></div>
                </div>
              </div>
            </ng-template>

          </ngb-carousel>
          <span class="price text-white pb-4 pl-3 pr-3 pt-4" *ngIf="!object.main.foodSaucerSize">{{object.main.price | currency:'CAD':'symbol-narrow'}}</span>
        </div>
        <div class="col-11">
          <p class=" text-justify box_description" style="font-size:1.5rem;">
            {{object.main.description}}
          </p>
        </div>
        <div class="col-12" *ngIf="object.main.foodSaucerSize">
          <label id="example-radio-group-label">Escoge tu tamaño favorito</label>
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              [(ngModel)]="metadata.foodSaucerSize.main.uuid" (change)="selectedFoodSaucerSize($event);">
              <mat-radio-button class="example-radio-button" *ngFor="let object of foodSaucerSizeList" [value]="object.main.uuid">
                <span>{{object.main.size}} </span>
                <span style="float:right;">{{object.main.price | currency:'CAD':'symbol-narrow'}}</span>
              </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-12 text-center">
          <button mat-raised-button aria-label="Regresar" matTooltip="Agregar" class="pb-2 pt-2" style="background-color:#ff5426; border-radius:2rem; font-size:1.3rem; color:#fff;" (click)="openModal();">
            AGREGAR
          </button>
        </div>
      </div>
      <footer class="text-center text-secondary p-1">
      © 2019 Keysoft. Reservados todos los derechos.
      </footer>
    </div>
  </div>
</mat-drawer-container>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{display:metadata.displayModal ? 'block':'none'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <p class="col-12">Agregar la cantidad de platillos/bebidas.</p>
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-2">
                <button mat-icon-button (click)="minQuantity();"> <mat-icon>remove_circle_outline</mat-icon> </button>
              </div>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>Cantidad</mat-label>
                <input matInput name="quantity" [(ngModel)]="metadata.quantity" >
              </mat-form-field>
              <div class="col-2">
                <button mat-icon-button (click)="addQuantity();"> <mat-icon>add_circle_outline</mat-icon> </button>
              </div>
            </div>
          </div>
          <p class="col-12">Si quieres agregar complementos ó observaciones agregalas aqui.</p>
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Complementos</mat-label>
            <textarea matInput name="description" [(ngModel)]="metadata.description"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width:100%;">
          <div class="col-6">
            <button mat-flat-button  color="warn" style="width:100%;" (click)="closeModal();">Cerrar</button>
          </div>
          <div class="col-6">
            <button mat-flat-button color="accent"  style="width:100%;" (click)="addProduct();">Agregar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
