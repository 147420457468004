import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-restaurant-seafood-welcome',
  templateUrl: './restaurant-seafood-welcome.component.html',
  styleUrls: ['./restaurant-seafood-welcome.component.css']
})
export class RestaurantSeafoodWelcomeComponent implements OnInit {

  configurationQRModel = {
    main:{
      uuid:'',
      theme: '',
      restaurant: false,
      hotel: false,
      mexpago: false,
      PayPal: false
    },
    system:{
      status:true
    }
  }
  object = JSON.parse(JSON.stringify(this.configurationQRModel));
  logo:any = '';
  metadata ={
    name:''
  }
  constructor(private session: SessionService, public snackBar: MatSnackBar, private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    //obtenemos la configuración de la company
    this.object = this.session.getConfigurationQr();
    let branch = this.session.getAnonymousSessionBranch();
    this.metadata.name = branch.system.company.main.tradename;
    //cargamos img de fondo y  tipo de letra
    console.log("configuración",this.object);
    this.getLog();
  }

  /**función para obtener el logo de la compañia
  */
  async getLog(){
    let branch = this.session.getAnonymousSessionBranch();
    let auxFile ={
      file:{
        uuid:branch.system.company.main.uuid,
        name:"logo",
        file:'',
        index:0,
        total:0,
        chunk:10,
        chunkSize:0,
        position:0,
        type:''
      },
      main:{
        uuid:branch.system.company.main.uuid//uuid del archivo
      }
    }
    let base64 = '';
    try{
      let chunkFirstTime = true;
      for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
        // console.log("index--->",auxFile.file.index);
        let resF = await this.getRequestBaseFile(auxFile);
        if(resF.transaction == 'ok'){
          base64 = base64.concat(resF.object.file.file);
          auxFile.file.name = resF.object.file.name;
          if(chunkFirstTime){
            auxFile.file.chunk = resF.object.file.chunk;
            chunkFirstTime = false;
          }
        }else{
          // this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
        }
      }
      if( base64 != ''){
        // console.log(base64);
        base64 = base64.replace(/-/g, "+");
        this.logo = this.sanitizer.bypassSecurityTrustUrl(base64);
      }

    }catch(e){
      console.log("error",e);
      // this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
    }
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous('configurationQr:downloadLogo',object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }


}
