import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { SessionService } from '../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';
import { MatGridListModule } from '@angular/material/grid-list';
// import { WaiterOrderComponent } from '../../waiter/waiter-order/waiter-order.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  //standalone : true,
  selector: 'app-waiter-dashboard',
  templateUrl: './waiter-dashboard.component.html',
  styleUrls: ['./waiter-dashboard.component.css']


  //imports: [MatGridListModule],
})
export class WaiterDashboardComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  branchList = [];

  restaurantAreaList = [];

  restaurantAreaModel = {
    main: {
      uuid: '',
      name: '',
      typeArea: 'Restaurante'
    },
    system: {
      branch: {
        main: {
          uuid: ''
        }
      },
      foodTables: [],
      status: true
    }
  };

  metadata = {
    branch: {
      main: {
        uuid: '',
        name: '',
      }
    },
    foodTable: {
      main: {
        number: 0
      }
    },
  }

  branchMin={
     system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      }
    }
  }


  restaurantAreaMin={
      main: {
        typeArea: 'Restaurante'
      },
       system: {
        branch: {
          main:{
            uuid: ''
          }
        }}
 }


 object = JSON.parse(JSON.stringify(this.restaurantAreaModel));
  contentTabIdex=0;
  constructor(public dialog: MatDialog, private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) { }

  ngOnInit() {
    this.getRestaurantAreaList();
  }

  /**
   * Obtiene la lista de sucursales
   */
  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.branchMin.system.company.main.uuid = this.session.getSessionObject().system.company.main.uuid;
      this.session.getRequest('branch:findAllByCompanyAndStatus', this.branchMin ).subscribe(
        (data: any) => {
          this.branchList = data.object.instanceList;
          // Obtenemos las áreas del lugar de acuerdo a la sucursal
          if(this.branchList.length > 0){
            this.metadata.branch = this.branchList[0];
          }
          this.loadingService.hide();
        }, error => {
          console.log("Error: branch:findAllByCompanyAndStatus", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
      });
  }

    /**
   * Obtiene las areas del restaurant
   */
    getRestaurantAreaList() {
      this.loadingService.wait();
      this.restaurantAreaMin.system.branch.main.uuid = this.session.getSessionObject().main.uuid;
      this.session.getRequest('restaurantArea:findAllByBranch', this.restaurantAreaMin ).subscribe(
        (data: any) => {
          this.restaurantAreaList = data.object.instanceList;
          this.loadingService.hide();
        }, error => {
          console.log("Error: restaurantArea:findAllByBranch", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        })
      }


  /**
   * Obtiene las mesas
   */
  getFoodTable(object){
    this.object.main.uuid = object.tab.ariaLabelledby;
    this.session.getRequest('restaurantArea:get', this.object).subscribe((data: any) => {
      this.object = JSON.parse(JSON.stringify(data.object));
      this.metadata.foodTable.main.number = 0;
      console.log(this.metadata.foodTable.main.number);
      this.loadingService.hide();
    }, (error) => {
        this.loadingService.hide();
        console.log('Error: restaurantArea:get', error)
    });
  }



  /**funcaionalidad para agregar una comanda
  */
/*    order(uuid){
    const dialogRef = this.dialog.open(WaiterOrderComponent, {
      width: '98%',
      height:'80%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getRestaurantAreaList();
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  } */

    /**
   * Obtiene la lista de mesa de alimentos
   */
    //  getFoodTableList() {
    //     this.loadingService.wait();
    //     this.session.getRequest('foodTable:findAllByRestaurantArea', this.restaurantAreaMin).subscribe(
    //       (data: any) => {
    //         this.loadingService.hide();
    //       instanceList: data.object.instanceList
    //       }, error => {
    //         console.log("Error: foodTable:findAllByRestaurantArea", error);
    //         this.snackBar.open(error.message, 'Error', { duration: 5000 });
    //         this.loadingService.hide();
    //       });
    // }



  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
