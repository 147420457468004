import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { RhpaymentDialogComponent } from './rrpayment-dialog/rrpayment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-restaurant-hotel-payment',
  templateUrl: './restaurant-hotel-payment.component.html',
  styleUrls: ['./restaurant-hotel-payment.component.css']
})
export class RestaurantHotelPaymentComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  address = {
    main: {
      uuid: '',
      settingName: '',
      numberOutdoor: '',
      numberInterior: '',
      zipCode: '',
      fullAddress:''
    },
    complement: {
      betweenRoads: '',
      locationDescription: ''
    },
    system: {
      addressType: 'Fiscal',
      setting: {
        main: {
          uuid: '',
          mName: ''
        },
        system: {
          locality: ''
        }
      },
      locality: {
        main: {
          uuid: '',
          code: '',
          name: '',
          typeLocality: ''
        },
        system: {
          town: {
            main: {
              uuid: '',
              name: ''
            },
            system: {
              state: {
                main:{
                  uuid:'',
                  name:''
                },
              }
            }
          }
        }
      },
      state:{
        main:{
          uuid:''
        }
      },
      status: true
    }
  }
  foodOrder:any = {
    main:{
      uuid: '',
      carryOut:  false, // true para llevar, false para en sitio
      dateFoodOrder: '', // Fecha y hora del medido
      status: 'Abierta', // Abierta, Preparacion, En entrega, Concluida
      subTotal: 0.0, // Subtotal a pagar
      tax: 0.0, // Impuestos a pagar
      total: 0.0, // //Total a pagar
      paymentMethod:''
    },
    system:{
      foodOrderDetails:[],
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      foodTable:{
        main:{
          uuid:''
        }
      }
    }
  }
  foodOrderCarryOut = {
    main:{
      uuid: '',
      fullname: '',
      paymentMethod:'contra entrega',// Método de pago: contra entrega, tarjeta de crédito, tarjeta de debito, Paypal
      latitude: '17.0665',
      longitude: '-96.7226'
    },
    system:{
      address:this.address,
      company:{
        main:{
          uuid:''
        }
      },
      foodOrder:{
        main:{
          uuid:''
        }
      },
      contacts:[]
    }
  }

  metadata={
    name:'',
    map:{
      lat:0,
      log:0,
      enabled:false
    }
  };
  contacts = [{
    main: {
      uuid: '',
      data: '',
      type: 'Celular'
    },
    system: {
      status: true
    }
  }];
  object:any = JSON.parse(JSON.stringify(this.foodOrderCarryOut));
  constructor(public fb: FormBuilder, private session: SessionService, private router: Router, private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private _location: Location, public dialog: MatDialog, public loadingService: LoadingService) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'phone': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    })
   }

  ngOnInit(): void {
    let branch = this.session.getAnonymousSessionBranch();
    this.metadata.name = branch.system.company.main.tradename;
    this.object.system.company.main =branch.system.company.main;
    if(localStorage.getItem("foodOrder")){
      this.foodOrder = JSON.parse(localStorage.getItem("foodOrder"));
      this.session.getRequestAnonymous("foodOrder:get",this.foodOrder).subscribe((data:any)=>{
        this.foodOrder = data.object;
        this.object.system.foodOrder.main.uuid = this.foodOrder.main.uuid;
        this.object.system.address.system.state.main.uuid = branch.complement.address.system.state.main.uuid;
        localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
        // console.log(this.object);
      },error=>{
        console.log(error);
      })
    }else{
      console.log("No hay que mostrar")
    }
    this.getCurrentLocation();
  }

  send(){
    if(this.object.system.address.main.fullAddress != '' ){
      this.object.system.contacts = this.contacts;
      this.loadingService.show(true,"Generando su pedido.");
      this.session.getRequestAnonymous("foodOrderCarryOut:update",this.object).subscribe((data:any)=>{
        this.foodOrder.main.status = 'Preparacion';
        this.foodOrder.main.carryOut = true;
        this.foodOrder.main.dateFoodOrder = moment().format('DD/MM/YYYY HH:mm:ss');
        this.session.getRequestAnonymous("foodOrder:update",this.foodOrder).subscribe((data:any)=>{
          localStorage.setItem("foodOrder", '');
          this.openDetailsOfORder();
          this.loadingService.hide();
          this.router.navigate(['/restaurant-hotel/home']);
          this.snackBar.open("Gracias tu pedido, estamos preparando tu orden.", 'Aceptar', {duration: 5000});
        },error=>{
          console.log(error);
          this.loadingService.hide();
        })
      },error=>{
        console.log(error);
        this.loadingService.hide();
      })
    }else{
      this.snackBar.open("Ingresa una dirección de envio.", 'Aceptar', {duration: 8000});
    }
  }

  /**función para regrsar a la pantalla anterior
  */
  back(){
    this._location.back();
  }

  /*
  * funcion para abrir o cerrar le menu
  */
  toogleMenuRestaurant(ev){
    this.drawerCtrl.opened = ev;
  }

  /**funcionalidad para enviar los datos del pedido del pedido
  */
  openDetailsOfORder() {
    this.dialog.open(RhpaymentDialogComponent, {
      width: '90%',
      data: this.foodOrder
    });
  }
  /**función para obtener la locolización actual
  */
  getCurrentLocation(){
    return new Promise((resolve,reject)=>{
      navigator.geolocation.getCurrentPosition(resp => {
        console.log(resp);
        this.metadata.map.lat = resp.coords.latitude;
        this.metadata.map.log = resp.coords.longitude;
        this.metadata.map.enabled = true;
        this.object.main.latitude = resp.coords.latitude;
        this.object.main.longitude = resp.coords.longitude;
        resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
      },
      err => {
        reject(err);
      });
    });
  }

  /**función a ejecutar cuando cambio de marker
  */
  changeLocation(ev){
    this.object.main.latitude = ev.coords.lat;
    this.object.main.longitude = ev.coords.lng;
  }
}
