<div class="panel-group container" role="tablist" >
  <!-- seccion de estado -->
  <!-- <div class="row">
    <mat-form-field class="col-7" appearance="outline">
      <mat-label>Estado</mat-label>
      <input matInput placeholder="Estado" [(ngModel)] = "metadata.searchState" name="stateName" (ngModelChange) = "saveStateName(metadata.searchState)" autocomplete="off" required>
    </mat-form-field>
    <div *ngIf= "metadata.searchState != ''" class="col-7">
      <mat-list>
        <mat-list-item *ngFor = "let object of stateListFilter" class="list-item" (click)= "stateSelected(object)">  {{object.main.name}} </mat-list-item>
      </mat-list>
    </div>
  </div> -->
  <div class="row">
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>Dirección</mat-label>
      <input matInput required placeholder="Dirección" [(ngModel)] = "stiAddress.main.fullAddress" name="addressSettingName" autocomplete="nope" required>
    </mat-form-field>
    <!-- seccion de calle  -->
    <mat-form-field class="col-6" appearance="outline">
      <mat-label>CP</mat-label>
      <input matInput required placeholder="Código Postal" [(ngModel)] = "stiAddress.main.zipCode" name="addressZipCode" autocomplete="off">
    </mat-form-field>
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>Entre Calles</mat-label>
      <textarea matInput placeholder="Entre Calles" autocomplete="off" [(ngModel)] = "stiAddress.complement.betweenRoads" name="addressBetweenRoads"></textarea>
    </mat-form-field>
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>Referencias</mat-label>
      <textarea matInput placeholder="Casa azul con porton blanco." autocomplete="off" [(ngModel)] = "stiAddress.complement.locationDescription" name="addressLocationDescription"></textarea>
    </mat-form-field>
  </div>

</div>
