<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0">
    <div class="col-10 text-right">
      <div class="row mt-2 mb-2 justify-content-end">
        <div class="col-3">
        </div>
      </div>
    </div>
    <div class="col-10">
    </div>
  </div>

</mat-drawer-container>
