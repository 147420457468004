<mat-drawer-container class="drawerConteiner" autosize style="background: #fff;">
  <mat-drawer #drawer class="mainDrawwerHotel" mode="push" position="end">
    <app-restaurant-hotel-menu-collapsed></app-restaurant-hotel-menu-collapsed>
  </mat-drawer>
  <div class="row img-home-hotel mr-0">
    <div class="col-12">



      <div class="row justify-content-center" style="background: #f1f1f1;">
        <div class="col-3 pr-0" routerLink="/restaurant-hotel">
          <button mat-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
            <mat-icon class="pt-2 text-gris" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center p-0 " >
          <h2 class="text-gris align-middle mb-0 font-IBMPlexR" style="line-height: 1.2rem; font-size: 1rem; padding-top: 4px;" >{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2 pl-0 pr-0">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-hotel/order">
            <mat-icon class="text-gris">receipt_long</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-gris" style="font-size: 2rem; margin-right: 5px;">dehaze</mat-icon>
          </button>
        </div>
      </div>


      <div class="row home-restaurant mt-1 ">
        <div class="col-12 pr-0">
          <div class="row triangulo-bottom" style="">
            <img src="/assets/img/restaurant-hotel/pico-slide.png" alt="" style="width: 100%; height: 100%;">
          </div>
          <ngb-carousel *ngIf="object.main.promotionImage">
            <ng-template ngbSlide *ngFor="let object of promotionList">
              <div class="picsum-img-wrapper">
                <img [src]="object.image" alt="promo" width="100%" *ngIf="object.image">
                <div class="sk-circle" *ngIf="object.image == undefined">
                  <div class="sk-circle1 sk-child"></div>
                  <div class="sk-circle2 sk-child"></div>
                  <div class="sk-circle3 sk-child"></div>
                  <div class="sk-circle4 sk-child"></div>
                  <div class="sk-circle5 sk-child"></div>
                  <div class="sk-circle6 sk-child"></div>
                  <div class="sk-circle7 sk-child"></div>
                  <div class="sk-circle8 sk-child"></div>
                  <div class="sk-circle9 sk-child"></div>
                  <div class="sk-circle10 sk-child"></div>
                  <div class="sk-circle11 sk-child"></div>
                  <div class="sk-circle12 sk-child"></div>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>


      <div class="row justify-content-center  p-0 mt-5" style="color: #656f6b;">
         <div class="col-5 text-center p-0" *ngFor="let foodCat of foodCategoryList" routerLink="/restaurant-hotel/menu/sn/{{foodCat.main.uuid}}">  <!--routerLink="/restaurant-hotel/menu/{{foodCat.main.uuid}}" -->
          <img src="/assets/img/{{object.main.theme}}/{{foodCat.complement.icon}}" class="" style="width: 5.4rem; border-style: solid; border-color: #aeaeae; border-width: 2px; background: #616D71;">
          <h4 class="font-IBMPlexR" style="font-size: .6rem; letter-spacing: 2px;">{{foodCat.main.name}}</h4>
        </div>
      </div>


      <footer class="text-center text-secondary p-1">
      © 2019 Keysoft. Reservados todos los derechos.
      </footer>
    </div>
  </div>
</mat-drawer-container>
