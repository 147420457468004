import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { SessionService } from '../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { interval, Subject } from 'rxjs';

@Component({
  selector: 'app-kitchen-bar',
  templateUrl: './kitchen-bar.component.html',
  styleUrls: ['./kitchen-bar.component.css']
})
export class KitchenBarComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  branchList = [];

  qrConfiguration = {
    main: {
      uuid: '',
      theme: '',
      restaurant: true,
      hotel: false,
      carryOut: false,
      mexPago: false,
      payPal: false,
      promotionImage:false,
    },
    complement: {
      SbClientId: ''
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      },
      tax:{
        main:{
          uuid:''
        }
      }
    }
  }

  branchModel={
    main:{
      uuid:'',
      name:'',
      code:'',
    },
    system:{
      company:{
        main:{
          uuid:'',
          tradename:'',
        }
      }
    }
  }

  metadata = {
    dateFoodOrder: moment().format('DD/MM/YYYY'),
    hourFoodOrder: moment().format('HH:mm:ss'),
    status: 0,
    branch:{
      main:{
        uuid:'',
        name:''
      }
    },
    bar:{
      main:{
        uuid:'',
        name:'',
      }
    }
  }

  
  foodSaucerBranchMin={
    system:{
      foodSaucer:{
        main:{
          uuid:'',
        }
      },
      bar:{
        main:{
          uuid:'',
        }
      }
    }
  }

  foodOrderList = [];
  foodOrderList2 = [];
  foodOrderDetail = [];
  foodOrderDetailList = [];
  barList =[];

  object = JSON.parse(JSON.stringify(this.branchModel));

  constructor(public dialog: MatDialog, private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) { 
    this.branchModel.main.uuid = this.session.getSessionObject().main.uuid;
  }
  
  ngOnInit(): void {
    this.getBranch();   
  }

   /**función para obtener los datos de configuración de qr
  */
   getConfigurationQr(){
    this.session.getRequest("configurationQr:get",{system:{company:{main:{uuid: this.branchModel.system.company.main.uuid}}}}).subscribe(
      (data:any) => {
        //console.log(data);
        this.qrConfiguration = data.object;
        this.getFoodOrder();
      }, error => {
        console.log("Error: configurationQr:get",error);
      });
  }

  /**función para obtener los datos de configuración de qr
  */
   getBranch(){
    this.session.getRequest("branch:get", {main:{uuid: this.session.getSessionObject().main.uuid}} ).subscribe(
      (data:any) => {
        //console.log(data);
        this.branchModel = data.object;
        this.getConfigurationQr();
        this.getBarList();
      }, error => {
        console.log("Error: configurationQr:get",error);
      });
  }

  getFoodOrder() {
      this.session.getRequest('foodOrder:findAllByBranchAndCarryOutAndStatusAndDateFoodOrderBetween', { main: { carryOut: false, status: 'Preparacion', dateFoodOrderStart: moment().format('DD/MM/YYYY HH:mm:ss'), dateFoodOrderEnd: moment().format('DD/MM/YYYY HH:mm:ss') }, system: { branch: this.branchModel } }).subscribe(
        (data:any) => {
         this.foodOrderList2 = data.object.instanceList;
         for(let x = 0; x < this.foodOrderList2.length; x++){
          this.foodOrderDetail = this.foodOrderList2[x].system.foodOrderDetails;
          console.log('36', this.foodOrderDetail)
            for(let y= 0; y < this.foodOrderDetail.length; y++){
              this.foodSaucerBranchMin.system.foodSaucer.main.uuid = this.foodOrderDetail[y].system.foodSaucer.main.uuid;
            }
            this.getFoodSaucer();
           }
        
        }, error => {
          console.log("Error: foodOrder:findAllByBranchAndCarryOutAndStatusAndDateFoodOrderBetween", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
        });
    };
  
  /**función para obtener los datos de configuración de qr
  */
      getFoodSaucer(){
        this.session.getRequest("foodSaucerBranch:findAllByFoodSaucerAndBar", this.foodSaucerBranchMin ).subscribe(
          (data:any) => {
            //console.log(data);
            this.foodOrderList = data.object.instanceList;
         
          }, error => {
            console.log("Error: configurationQr:get",error);
          });
      }

  /**
  * Barra de Restaurante
  */
  getBarList() {
      this.loadingService.wait();
      this.session.getRequest('bar:findAllByBranch', {system: { branch: { main: { uuid:this.branchModel.main.uuid} } }}).subscribe(
        (data: any) => {
          this.barList = data.object.instanceList;
          this.loadingService.hide();
        }, error => {
          console.log("Error: bar:findAllByBranch", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        })

  }


  /**función para obtener los datos de configuración de qr
  */
  getFoodSaucerByBar(bar){
    this.session.getRequest("foodSaucerBranch:findAllByBarAndIsMake", {system:{bar:{main:{uuid: bar.main.uuid}}}, complement:{isMake: false}} ).subscribe(
    (data:any) => {
      //console.log(data);
      this.foodOrderDetailList = data.object.instanceList;     
    }, error => {
      console.log("Error: configurationQr:get",error);
    });
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  delete(foodOrder){
    this.loadingService.show(true,'Guardando registro...');
    foodOrder.complement.isMake = true;
    this.session.getRequest("foodOrderDetail:updateIsMake",foodOrder).subscribe(
      (data:any) => {
        for (let i = 0; i < this.foodOrderDetailList.length; i++) {
          if (foodOrder.main.uuid == this.foodOrderDetailList[i].main.uuid) {
            this.foodOrderDetailList.splice(i, 1);
          }
        }
        this.loadingService.hide();
      },
      error => {
        console.log("Error: foodOrderDetail:updateIsMake",error);
        this.loadingService.hide();
      }
    );
  
    

  }


}







