import { Component, Input, Output, Inject , ViewChild} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WaiterOrderProductComponent } from './waiter-order-product/waiter-order-product.component';
import { WaiterOrderBarComponent } from './waiter-order-bar/waiter-order-bar.component';

@Component({
  selector: 'app-waiter-order',
  templateUrl: './waiter-order.component.html',
  styleUrls: ['./waiter-order.component.css']
})
export class WaiterOrderComponent{
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  complexForm: FormGroup;

  foodOrderModel={
    main:{
      uuid:'',
      carryOut:  false, // true para llevar, false para en sitio
      dateFoodOrder: '', // Fecha y hora del medido
      status: 'Abierta', // Abierta, Preparacion, En entrega, Concluida
      subTotal: 0.0, // Subtotal a pagar
      tax: 0.0, // Impuestos a pagar
      total: 0.0, // //Total a pagar
      numberOrder: '',
   /*    trip:0.0, */
    },
    complement:{
      numberPerson: 0,
      numberChildren: 0,
    /*   typeTrip:'porcentaje', */
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      waiter:{
        main:{
          uuid:''
        }
      },
      foodTable:{
        main:{
          uuid:'',
          status:'Ocupada'
        }
      },
/*       roomOrder:{
        main:{
          uuid:''
        }
      }, */
      objectType:'foodTable',
      objectUuid:'',
      //foodOrderCarryOut:[],
      foodOrderDetails:[]
    }
  }

  metadata = {
    dateFoodOrder: moment().format('DD-MM-YYYY'),
    hourFoodOrder: moment().format('HH:mm:ss'),
    numberPerson: 0, //numero de personas en la mesa
    numberChildren: 0, //numero de niños en la mesa
    peopleNumber: 0, //#persona que hace el pedido
    totalFoodSaucers:0, //total de platillos de la comanda
    clientUuid:'',
    bar:{
      main:{
        uuid:'',
      }
    }
  }
  ordenes=[];
  details=[];
  foodCategoryModel ={
    main:{
      uuid: '',
      name:'',
      icon:'',
    },
    system:{
      status:'',
    }
  }

  foodCategoryMin={
    system:{
      branch:{
        main:{
          uuid:'',
        }
      }
    }
  }

  foodCategoryList=[];
  foodSaucerList=[];

  waiterMin={
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    }
  }

  waiterList=[];

  foodSaucerBranchMin={
    system:{
      branch:{
        main:{
          uuid:'',
        }
      },
      foodCategory:{
        main:{
          uuid:'',
        }
      }
    }
  }

  foodSaucerBranchMin2={
    system:{
      foodSaucer:{
        main:{
          uuid:'',
        }
      },
      bar:{
        main:{
          uuid:'',
        }
      }
    }
  }

  foodTableModel={
    main:{
      uuid:'',
      number:0,
      status:'',
    },
    system:{
      restaurantArea:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      }
    }
  }

  foodOrderMin={
    main:{
      uuid:'',
      status:'Entregada' ,
    },
    system:{
      foodTable:{
        main:{
          uuid:'',
        }
      }
    }
  }

  saleModel={
    main:{
      uuid:'',
      saleNumber:'',
      total: 0,
      subTotal: 0,
      taxTotal: 0,
      tripAmount:0,
      status:'Por cobrar',
      tripType: false,
    },
    system:{
      typeSale:'',
      saleUuid:'',
      client:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      waiter:{
        main:{
          uuid:''
        }
      },

    },
  }

  clientMin={
    max: 10,
    offset: 0,
    sort: "mCode",
    order: "asc",
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  }

  clientList= [];

  branchModel={
    main:{
      uuid:'',
      name:'',
      code:'',
    },
    system:{
      company:{
        main:{
          uuid:'',
          tradename:'',
        }
      }
    }
  }

  foodOrderDetailModel ={
    main:{
      uuid: '',
      quantity: 1,
      observation:'',
      priceUnitary:'',
      subTotal:0,
      tax:0,
    },
    complement:{
      peopleNumber:'',
    },
    system:{
      foodSaucer:{
        main:{
          uuid:''
        }
      },
      foodSaucerSize:{
        main:{
          uuid:'',
          order:0,
          price:0.0,
          size:'',    
        }
      }
    }
  }


  object = JSON.parse(JSON.stringify(this.foodOrderModel));
  foodTable = JSON.parse(JSON.stringify(this.foodTableModel));
  sale =  JSON.parse(JSON.stringify(this.saleModel));
  objectBranch = JSON.parse(JSON.stringify(this.branchModel));
  objectFoodOrderDetail = JSON.parse(JSON.stringify(this.foodOrderDetailModel));

  barList =[];
  branchList = [];
  foodOrderList2=[];
  foodOrderDetail=[];
  foodOrderList=[];
  foodOrderDetailList=[];
  foodSaucerAndBranchList=[];

  public selectedIndex = 0;




  constructor(private activatedRoute: ActivatedRoute, private router: Router, public fb: FormBuilder, public dialog: MatDialog, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {}


  ngOnInit(){
    this.getFoodCategoryList();
    this.getWaiterList();
    this.findAllByCompanyAndStatus();
    this.getBranch();

    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){
        // no hacemos nada por el momento
      }else{
        this.foodTable.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.session.getRequest('foodTable:get',this.foodTable).subscribe((data:any)=>{
          this.foodTable = JSON.parse(JSON.stringify(data.object));
          //verificamos si existe ya una orden abierta para la mesa seleccionada
          this.foodOrderMin.system.foodTable.main.uuid = this.foodTable.main.uuid;
          this.session.getRequest('foodOrder:findByFoodTableAndNotStatus', this.foodOrderMin).subscribe(
            (data: any) => {
             // console.log('aqui', data);
              //console.log('aqui', data.object.complement.numberPerson);
              for(let x=0; x< data.object.complement.numberPerson; x++){
                //console.log('aqui', x);
                this.loadPeople(x+1);
              }

              for(let i=0; i<data.object.system.foodOrderDetails.length; i++){
                this.metadata.totalFoodSaucers= this.metadata.totalFoodSaucers + data.object.system.foodOrderDetails[i].main.quantity;
              }

              this.object = data.object;
              this.metadata.numberPerson = data.object.complement.numberPerson;
              this.metadata.numberChildren = data.object.complement.numberChildren;

              this.loadingService.hide();
            }, error => {
              console.log("Error: foodSaucerBranch:findByFoodTableAndStatus", error);
              // this.snackBar.open(error.message, 'Error', { duration: 5000 });
              this.loadingService.hide();
            })

        },
        (error)=>{
          console.log('Error:foodTable:get',error)
        })
      }
    });


  }

  loadPeople(cant){
  this.ordenes = [];
    for(let i = 0; i< cant; i++){
      let detail= JSON.parse(JSON.stringify(this.foodOrderDetailModel));
      this.ordenes.push(detail);
      this.ordenes.length;
    }
    //console.log(this.ordenes);
  }



  getFoodCategoryList() {
    this.loadingService.wait();
    this.foodCategoryMin.system.branch.main.uuid = this.session.getSessionObject().main.uuid;
    //console.log(this.foodCategoryMin.system.branch.main.uuid);
    this.session.getRequest('foodCategory:findAllByBranch', this.foodCategoryMin).subscribe(
      (data: any) => {
        this.foodCategoryList = data.object.instanceList;
       // console.log('categorias', this.foodCategoryList);
        this.loadingService.hide();
      }, error => {
        console.log("Error: foodCategory:findAllByBranch", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
}


getFoodSaucer(object) {
  // this.loadingService.wait();
  this.foodSaucerBranchMin.system.branch.main.uuid = this.session.getSessionObject().main.uuid;
  this.foodSaucerBranchMin.system.foodCategory.main.uuid = object.main.uuid;
  //console.log(this.foodSaucerBranchMin.system.foodCategory.main.uuid);
  this.session.getRequest('foodSaucerBranch:findAllByBranchAndFoodCategory', this.foodSaucerBranchMin).subscribe(
    (data: any) => {
      //console.log('aqui', data);
      this.foodSaucerList = data.object.instanceList;
      this.loadingService.hide();
    }, error => {
      console.log("Error: foodSaucerBranch:findAllByBranchAndFoodCategory", error);
      this.snackBar.open(error.message, 'Error', { duration: 5000 });
      this.loadingService.hide();
    })
}

 /**función para obtener los datos de la barra
  */
 getfoodOrderList(){
  this.session.getRequest("foodSaucerBranch:findAllByFoodSaucerAndBar", this.foodSaucerBranchMin ).subscribe(
    (data:any) => {
      console.log(data);
      this.foodOrderList = data.object.instanceList;
   
    }, error => {
      console.log("Error: configurationQr:get",error);
    });
}

  /**función para las platillos(barras)
  */
  findAllfoodSaucerBranch() {
    //if (this.object.main.uuid != '') {
      this.session.getRequest("foodSaucerBranch:findAllByFoodSaucerAndBranch", { system: { foodSaucer:{main:{uuid:this.foodSaucerBranchMin2.system.foodSaucer.main.uuid }} , branch:{main:{uuid: this.session.getSessionObject().main.uuid }}  } }).subscribe(
        (data: any) => {
          this.foodSaucerAndBranchList = data.object.instanceList;
          for (let i = 0; i < this.foodSaucerAndBranchList.length; i++) {
            console.log('77', this.foodSaucerAndBranchList[i].system.bar.main.uuid)
            //para obtener los platilos por barra
            this.session.getRequest("foodSaucerBranch:findAllByBarAndIsMake", {system:{bar:{main:{uuid: this.foodSaucerAndBranchList[i].system.bar.main.uuid}}}, complement:{isMake: false}} ).subscribe(
              (data:any) => {
                console.log('555',data);
                this.foodOrderDetailList = data.object.instanceList;     
              }, error => {
                console.log("Error: configurationQr:get",error);
              });


/*           for (let x = 0; x < this.foodSaucerAndBranchList[i].system.bars.length; x++) {
               console.log('44', this.branchList[i].system.bars)
            }   */
          }
      }, error => {
        console.log(error);
      })

   // }
  }

  findAllByBarAndIsMake(){
    this.session.getRequest("foodSaucerBranch:findAllByBarAndIsMake", {system:{bar:{main:{uuid: this.session.getSessionObject().main.uuid}}}, complement:{isMake: false}} ).subscribe(
    (data:any) => {
      console.log(data);
      this.foodOrderDetailList = data.object.instanceList;     
    }, error => {
      console.log("Error: configurationQr:get",error);
    });
  }

   /**función para obtener los datos de configuración de qr
  */
   getBranch(){
    this.session.getRequest("branch:get", {main:{uuid: this.session.getSessionObject().main.uuid}} ).subscribe(
      (data:any) => {
        console.log(data);
        this.objectBranch = data.object;
      }, error => {
        console.log("Error: configurationQr:get",error);
      });
  }


  getWaiterList() {
    this.loadingService.wait();
    this.waiterMin.system.company.main.uuid = this.session.getSessionObject().system.company.main.uuid;
    this.session.getRequest('waiter:findAllByCompany', this.waiterMin).subscribe(
      (data: any) => {
        this.waiterList = data.object.instanceList;
        this.loadingService.hide();
      }, error => {
        console.log("Error: waiter:findAllByCompany", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

  // /**funcionalidad para agregar una productos por categoria
  // */
  //   product(object){
  //     const dialogRef = this.dialog.open(WaiterOrderProductComponent, {
  //       width: '40%',
  //       height:'60%',
  //       data: object,
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //       if(result != undefined ){
  //         if(result.transaction == 'ok'){
  //           // El modal se cerro con objeto
  //           //if para determinar el precio del producto
  //             if(result.object.system.foodSaucer.main.foodSaucerSize == true){
  //               result.object.main.priceUnitary = result.object.system.foodSaucerSize.main.price
  //               //console.log('$$$', result.object.system.foodSaucerSize)
  //             }else{
  //               result.object.main.priceUnitary = result.object.system.foodSaucer.main.price
  //             }
  //             result.object.main.subTotal = result.object.main.quantity * result.object.main.priceUnitary
  //              //console.log(result.object.main.priceUnitary)
  //         if(result.object.system.foodSaucer.main.tax == true){
  //            result.object.main.tax = (result.object.main.priceUnitary * result.object.main.quantity)* .16
  //         }else{
  //            result.object.main.tax = 0
  //         }
  //          this.object.system.foodOrderDetails.push(result.object);
  //          //console.log('++++',result.object);

  //          this.calculateTotals();
  //          this.snackBar.open('El platillo se agrego correctamente', 'Información');
  //         }else{
  //           this.snackBar.open('No fue agregado el platillo', 'Advertencia');
  //         }
  //       }else{
  //         // El modal se cerro sin seleccionar algo, dandole click fuera
  //       }
  //     });
  //   }

    toogleMenu(ev){
      this.drawerCtrl.opened = ev;
    }


  tabActive(event) {
    // obtenemos el index del tab
    console.log(event.index);
    // actualizamos el index seleccionado
    //this.selectedIndex = event.index;
    this.metadata.peopleNumber = event.index;
    localStorage.setItem('peopleNumber', JSON.stringify(this.metadata.peopleNumber));
    let peopleNumber = localStorage.getItem('peopleNumber');
  }

  
  addFoodOrderDetails(object){
     console.log('listadoo',object)

    // console.log('listadoo',object.system.foodSaucer)
    this.objectFoodOrderDetail.system.foodSaucer = object.system.foodSaucer;
    let peopleNumber = localStorage.getItem('peopleNumber');
    this.objectFoodOrderDetail.complement.peopleNumber = peopleNumber;
    this.objectFoodOrderDetail.main.priceUnitary = object.system.foodSaucer.main.price;
    this.objectFoodOrderDetail.main.subTotal = this.objectFoodOrderDetail.main.quantity * this.objectFoodOrderDetail.main.priceUnitary;
    
  //  this.details=[]
        let detail = JSON.parse(JSON.stringify(this.objectFoodOrderDetail));
        this.details.push(detail);
        this.details.length;

        this.object.system.foodOrderDetails.push(detail);
        console.log('11111111111',this.object.system.foodOrderDetails);
        this.calculateTotals()

      console.log(this.details);
     

     // this.foodSaucerSizeModel.system.foodSaucer.main.uuid = this.object.main.uuid;
      //this.foodSaucerComplementList.push(JSON.parse(JSON.stringify(this.foodSaucerComplementModel)));
    }

    
    deleteFoodSaucer(foodOrderDetail, x) {
      this.object.system.foodOrderDetails.splice(x, 1);
      this.calculateTotals();
    }
    // log(object){
    //   console.log(object)
    // }



  /**función para calcular el total
  */
  calculateTotals() {
    this.object.main.total = 0;
    this.object.main.subTotal = 0;
    this.object.main.tax = 0;
    this.metadata.totalFoodSaucers= 0;
    

    for (let item of this.object.system.foodOrderDetails) {
      //console.log('item',item);
      this.metadata.totalFoodSaucers = this.metadata.totalFoodSaucers + item.main.quantity;
      this.object.main.subTotal = this.object.main.subTotal + item.main.subTotal;
      //this.object.main.tax = this.object.main.tax + item.main.tax;
      this.object.main.tax = 0;
    }
    //console.log(this.object.main.subTotal);
    //this.object.main.total = this.object.main.subTotal + this.object.main.tax;
    this.object.main.total = this.object.main.subTotal;
    console.log(this.object.main.total);
    localStorage.setItem('totalFoodSaucers', JSON.stringify(this.metadata.totalFoodSaucers));
    let totalFoodSaucers = localStorage.getItem('totalFoodSaucers');
  }

 

//funcion para guardar una orden y poner la mesa como ocupada
  send(object){
    this.loadingService.show(true, 'Guardando orden espere por favor ....');
    object.system.company.main.uuid = this.session.getSessionObject().system.company.main.uuid;
    object.system.branch.main.uuid = this.session.getSessionObject().main.uuid;
    object.system.objectUuid = this.foodTable.system.restaurantArea.main.uuid;
    object.system.foodTable.main.uuid = this.foodTable.main.uuid;
    object.complement.numberPerson = this.metadata.numberPerson;
    object.complement.numberChildren = this.metadata.numberChildren;
    object.main.dateFoodOrder = moment().format('DD-MM-YYYY hh:mm:ss');
    object.main.status = 'Preparacion'
    object.system.foodOrderDetails = this.object.system.foodOrderDetails
    
    this.session.getRequest('foodOrder:update', object).subscribe(
      (data: any) => {
      //se actualiza el estatus de la mesa a "ocupada"
      this.foodTable.main.status = 'Ocupada';
    this.session.getRequest("foodTable:update", this.foodTable).subscribe(
      (data: any) => {
        this.snackBar.open('Se actualiza '+ this.foodTable.main.status + ' correctamente', '', {
          duration: 1000
        });
        //funcion para listar las barras
        //this.getBarList();
        this.loadingService.hide();
      }, error => {
        console.log("Error: foodTable:update", error);
        this.loadingService.hide();
      });

    this.object = JSON.parse(JSON.stringify(data.object));
    this.snackBar.open('Se agrega orden correctamente', 'Información', {
      duration: 8000
    });

     //this.router.navigate(['/waiter/dashboard']);
      this.loadingService.hide();
    },
      (error) => {
        console.log('Error:foodOrder:update', error)
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      });
  }

  commandReport(bar){
    return new Promise<void>((resolve, reject) => {
      this.loadingService.show(true, "obteniendo comananda de la barra " + bar.main.name);
      //console.log('foodSaucer?',this.object)
      this.session.getRequest('sale:comanda', {system:{bar:{main:{uuid:bar.main.uuid}}, foodOrder:{main:{uuid:this.object.main.uuid}}}}).subscribe(
        (data: any) => {
          if(data.transaction=='ok'){
            var elem = document.createElement('a');
            elem.href = "data:application/pdf;base64,"+ data.object;
            elem.setAttribute('download', bar.main.name+'.pdf');
            document.body.appendChild(elem);
            elem.click();
          }
          this.loadingService.hide();
          resolve();
        },
        error => {
          console.log("sale:comanda", error);
          this.loadingService.hide();
          this.snackBar.open('Error en la descarga', 'Error', { duration: 5000 });
        })
    })
  }

  /**funcionalidad para agregar una productos por categoria
  */
      charge(object){
        const dialogRef = this.dialog.open(WaiterOrderProductComponent, {
          width: '40rem',
          height:'30rem',
          data: object,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              //this.getWaiter();
            }else{
              // El modal se cerro sin objeto
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
          }
        });
      }

//   chargeanterior(object){
//     const dialogRef = this.dialog.open(WaiterOrderProductComponent, {
//       width: '40rem',
//       height:'30rem',
//       data: object,
//     });
//     dialogRef.afterClosed().subscribe(result => {
//       if (result.transaction == 'ok') {
//         // El modal se cerro con objeto

//       } else {
//         // El modal se cerro sin objeto
//       }
//     });
//     /***this.loadingService.show(true, 'Generando su ticket espere por favor ....');
//      object.main.dateFoodOrder = moment().format('DD-MM-YYYY hh:mm:ss');
//      object.main.status = 'Por pagar'
//      this.session.getRequest('foodOrder:update', object).subscribe((data: any) => {
//       this.foodTable.main.status = 'Disponible';
//      this.session.getRequest("foodTable:update", this.foodTable).subscribe(
//       (data: any) => {
//         this.snackBar.open('Se actualiza '+ this.foodTable.main.status + ' correctamente', '', {
//           duration: 1000
//         });
//         this.loadingService.hide();
//       }, error => {
//         console.log("Error: foodTable:update", error);
//         this.loadingService.hide();
//       });
//     this.object = JSON.parse(JSON.stringify(data.object));
//    // console.log('38',this.object)
//      this.sale.system.client.main.uuid = this.metadata.clientUuid
//      this.sale.system.typeSale = 'foodOrder';
//      this.sale.system.saleUuid = this.object.main.uuid;
//      this.sale.system.branch.main.uuid = this.session.getSessionObject().main.uuid;
//      this.sale.system.waiter.main.uuid = this.object.system.waiter.main.uuid;
//      //console.log('34', this.object.main.uuid)
//      this.session.getRequest("sale:update", this.sale).subscribe(
//        (data: any) => {
//          this.sale = data.object;
//          console.log('45',this.sale)
//          this.snackBar.open('Se actualiza '+ this.sale.main.status + ' correctamente', '', {
//            duration: 1000
//          });
// // --
//          this.loadingService.show(true, 'Descargando ticket, espere un momento...');
//          this.session.getRequest('sale:ticket', this.sale).subscribe(
//            (data: any) => {
//              var elem = document.createElement('a');
//               elem.href = "data:application/pdf;base64,"+ data.object;
//              elem.setAttribute('download', 'ticket.pdf');
//              document.body.appendChild(elem);
//              elem.click();
//              this.loadingService.hide();
//            },
//            error => {
//              console.log("sale:ticket", error);
//              this.loadingService.hide();
//              this.snackBar.open('Error en la descarga', 'Error', { duration: 5000 });
//            })
// // --
//          this.loadingService.hide();
//        }, error => {
//          console.log("Error: sale:update", error);
//          this.loadingService.hide();
//        });

//     this.snackBar.open('La orden esta lista para cobrar', 'Información', {
//       duration: 8000
//     });
//      this.router.navigate(['/waiter/dashboard']);
//       this.loadingService.hide();
//     },
//      (error) => {
//         console.log('Error:foodOrder:update', error)
//         this.snackBar.open(error.message, 'Error', { duration: 5000 });
//         this.loadingService.hide();
//       });***/

//   }

  findAllByCompanyAndStatus(){
    this.loadingService.wait();
    this.clientMin.system.company.main.uuid = this.session.getSessionObject().system.company.main.uuid;
    this.session.getRequest('client:findAllByCompanyAndStatus', this.clientMin).subscribe(
      (data: any) => {
        this.clientList = data.object.instanceList;
         for(let c=0; c<this.clientList.length; c++){
       this.metadata.clientUuid = this.clientList[c].main.uuid;
     }
        this.loadingService.hide();
      }, error => {
        console.log("Error: client:findAllByCompanyAndStatus", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })

  }

  getBarList() {
    this.loadingService.wait();
    this.session.getRequest('bar:findAllByBranch', {system:{branch:{main:{uuid: this.session.getSessionObject().main.uuid }}}}).subscribe(
      (data: any) => {
        this.barList = data.object.instanceList;
        for(let i=0; i<data.object.instanceList.length; i++){
          this.commandReport(data.object.instanceList[i] ).then((data:any)=>{
          });

        }
        this.loadingService.hide();
      }, error => {
        console.log("Error: bar:findAllByBranch", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

    /**funcionalidad para agregar una productos por categoria
  */
    commandBar(object){
      const dialogRef = this.dialog.open(WaiterOrderBarComponent, {
        width: '60rem',
        height:'30rem',
        data: object,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            // El modal se cerro con objeto
            //this.getWaiter();
          }else{
            // El modal se cerro sin objeto
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

}
