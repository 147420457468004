import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rcpayment-dialog',
  templateUrl: './rcpayment-dialog.component.html',
  styleUrls: ['./rcpayment-dialog.component.css']
})
export class RcpaymentDialogComponent implements OnInit {

  object = {
    main:{
      numberOrder:''
    }
  };
  constructor(public dialogRef: MatDialogRef<RcpaymentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.object = this.data;
   }

  ngOnInit(): void {
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    this.dialogRef.close(object);
  }

}
