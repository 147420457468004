import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
export interface isLoading {
    visible: boolean
    message: string
}
export interface isLoadingWhite{
  visible: boolean
}
@Injectable()
export class LoadingService {

  loadingChange: Subject<isLoading> = new Subject<isLoading>();
  loadingWhite: Subject<isLoadingWhite> = new Subject<isLoadingWhite>();
  constructor()  {
    this.loadingChange.next({
      visible:false,
      message:''
    });
    this.loadingWhite.next({
      visible:false
    });
    }
    /** funcion para mostrar el cargador con blur
    * @param status Variable para hacer visible el loading : boolean :| true | false.
    * @param msg Mensaje a mostrar en el loading : string : .
    * @returns return void
    */
  show(status:boolean,msg:string){
    this.loadingChange.next({
      visible:status,
      message:msg
    });
  }
  /** función para mostrar el loading con en una barra superior
  */
  wait(){
    this.loadingWhite.next({
      visible:true
    });
  }
  /*
  * Funcion para desactivar el loader
  */
  hide(){
    this.loadingChange.next({
      visible:false,
      message:''
    });
    this.loadingWhite.next({
      visible:false
    });
  }
}
