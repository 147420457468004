<mat-drawer-container class="drawerConteiner" autosize>
    <mat-drawer #drawer class="mainDrawer" mode="side">
      <app-menu></app-menu>
    </mat-drawer>
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    
    <div class="row justify-content-center pl-0 pr-0 mt-3">
      <div class="col-10 text-right">
        <div class="row justify-content-center">
          <h2>Sucursal {{branchModel.main.name}}</h2>
        </div>

        <div class="row justify-content-start">
              <mat-form-field class="col-4" appearance="outline">
                <mat-label>Seleccione una barra</mat-label>
                <mat-select placeholder="Barra" name="roleType"  [(ngModel)]="metadata.bar.main.uuid"  (ngModelChange)="getFoodSaucerByBar(metadata.bar.main.uuid)"required>
                  <mat-option *ngFor="let object of barList" [value]="object">
                    {{object.main.name}}
                  </mat-option>
                  <mat-option [value]="''" *ngIf="object.length > 0">
                    --- Seleccione un elemento ---
                  </mat-option>
                  <mat-option [value]="''" *ngIf="object.length == 0" >
                    --- No hay elementos ---
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
     
        
      </div>
    
        <div class="col-11">
            <mat-card>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Preparado</th>
                <th scope="col">Platillo</th>
                <th scope="col">Observacion</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let foodOrder of foodOrderDetailList" (click)="delete(foodOrder);">
                <th scope="row"><mat-checkbox class="example-margin" [(ngModel)]="foodOrder.complement.isMake"></mat-checkbox></th>
                <th>{{foodOrder.system.foodSaucer.main.name}}</th>
                <th>{{foodOrder.main.observation}}</th>
              </tr>
            </tbody>
          </table>
        </mat-card>

        </div>
    
  
    </div>


</mat-drawer-container>

