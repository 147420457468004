import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, 	HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Session } from './session.interface';
import { AlertModel } from './alert.interface';
import server_url from './server_url';
import endpoint from './request_url';
import { Router } from '@angular/router';
import { interval, Subject } from 'rxjs';

const timerToCheckValidSession = interval(1000); //

const uri = server_url;
const endpoints = endpoint;


@Injectable()
export class SessionService {
  private sessionModel = {
    user: {
      main:{
        uuid:'',
        username:'',
        fullName:'',
        name:'',
        surname:'',
        lastname:''
      },
      system:{
        objectType:''
      },
      imagen:{
        file:'',
        name:'',
        type:''
      }
    },
    object: {
      main:{
        uuid:'',
        name:''
      }
    },
    objects:[],
    warehouse:{
      main:{
        uuid:''
      }
    },
    employee:{
      main:{
        uuid:''
      }
    }
  }
  private anonymosSessionModel = {
    system: {
      company:{
        main:{
          uuid:''
        }
      },
      // walking: a entregar en sitio o lugar
      // foodTable: mesa del lugar
      // roomOccupation: habitación de un hotel
      object:'',
      objectUuid:''
    },
    configurationQr:{
      main:{
        uuid:'',
        theme: '',
        restaurant: false,
        hotel: false,
        mexpago: false,
        PayPal: false
      }
    },
    object:{
      main:{
        uuid:''
      }
    },
    branch:{
      main:{
        uuid:''
      }
    }
  }

  private isUserLoggedIn;
  private roles;
  private token;
  private tokenType;
  private refreshToken;
  private expiresIn;
  private session:Session = JSON.parse(JSON.stringify(this.sessionModel));
  private anonymousSession = JSON.parse(JSON.stringify(this.anonymosSessionModel));
  private alert:AlertModel = {
    message:'',
    alert:false,
    showAlertSession:false,
    showlightboxAlert:false,
  }
  metadata = {
    enabledInitDogBylogin:false
  }
  showAlert: Subject<AlertModel> = new Subject<AlertModel>();
  constructor(protected http: HttpClient, private ngZone: NgZone, private router: Router) {
    this.isUserLoggedIn = false;
    this.token = '';
    this.tokenType = '';
    this.refreshToken = '';
    this.expiresIn = 0;
    this.roles = [];
    this.showAlert.next(this.alert);
  }
  /**Instanciamos el backend para obtener los datos de sesión e inicializamos el objeto publico
  */
  private setLoggedIn(object) {
    this.isUserLoggedIn = true;
    this.token = object.access_token;
    this.tokenType = object.token_type;
    this.refreshToken = object.refresh_token;
    this.expiresIn =(parseInt(object.expires_in) * 1000) + new Date().getTime();
    this.roles = object.roles;
    sessionStorage.setItem('isUserLoggedIn', this.isUserLoggedIn);
    sessionStorage.setItem('token', this.token);
    sessionStorage.setItem('tokenType', this.tokenType);
    sessionStorage.setItem('expiresIn', this.expiresIn);
    sessionStorage.setItem('roles', JSON.stringify(this.roles));
  }
  /**función para limpiar todos los objetos de sesión
  */
  private setLoggoutIn() {
    this.isUserLoggedIn = false;
    this.token = '';
    this.tokenType = '';
    this.refreshToken = '';
    this.roles = [];
    this.expiresIn = 0;
    sessionStorage.setItem('isUserLoggedIn','false');
    sessionStorage.setItem('token','');
    sessionStorage.setItem('tokenType','');
    sessionStorage.setItem('expiresIn','');
    sessionStorage.setItem('roles','');
    sessionStorage.setItem('session','');
    sessionStorage.setItem('validate','');
    sessionStorage.setItem('menuType','');
    sessionStorage.setItem('objectSession','');
  }

  /**función para obtener los roles de usuario en sesión
  */
  public getRoles() {
    return this.roles;
  }
  /** función para setear los objetos de sesión
  */
  private setSession(object:any) {
    this.session.user = object.user;
    this.session.objects = object.objects;
    this.session.object = object.object;
    sessionStorage.setItem('session',JSON.stringify(this.session));
  }

  /** función para guadar el objeto donde el usuario inicio sesión
  */
  setObjectSession(object:any) {
    this.session.object = JSON.parse(JSON.stringify(object));
    sessionStorage.setItem('objectSession',JSON.stringify(object));
  }

  setWarehouse(object:any) {
    this.session.warehouse = JSON.parse(JSON.stringify(object));
    sessionStorage.setItem('session',JSON.stringify(this.session));
  }

  setEmployee(object:any) {
    this.session.employee = JSON.parse(JSON.stringify(object));
    sessionStorage.setItem('session',JSON.stringify(this.session));
  }

  /** Función para obtener si hay un usuario en sesión
  */
  getLoggedIn() {
    return this.isUserLoggedIn;
  }

  /** función para obtener el objeto de sesión
  */
  getSession() {
    return this.session;
  }

  /** función para obtener el nombre de un usuario.
  */
  getfullName() {
    return this.session.user.main.fullName;
  }

  /**función para obtener los objetos que tiene acceso un usuario.
  */
  getObjects() {
    return  JSON.parse(JSON.stringify(this.session.objects));
  }

  /**función para obtener el objeto donde inicio sesión el usuario
  */
  getSessionObject() {
    return this.session.object;
  }
  /** función para obtener los datos de usuario
  */
  getUser(){
    return this.session.user;
  }

  /** función para obtener el usuario del usuario.
  */

  getUsername(){
    return this.session.user.main.username;
  }

  getWarehouse(){
    return this.session.warehouse;
  }
  getEmployee(){
    return this.session.employee;
  }

  /**==== Métodos para sesion de restaurant ====**/
   setAnonymousSessionSettings(object:any) {
    this.anonymousSession.configurationQr = object;
    localStorage.setItem('anonymousSession',JSON.stringify(this.anonymousSession));
  }

   setAnonymousSessionSystem(object:any) {
    this.anonymousSession.system = object;
    localStorage.setItem('anonymousSession',JSON.stringify(this.anonymousSession));
  }

  /**Método para guardar el object de la sesión
  */
  setAnonymousSessionObject(object:any) {
     this.anonymousSession.object = object;
     localStorage.setItem('anonymousSession',JSON.stringify(this.anonymousSession));
   }

   /**Método para guardar la sucursal
   */
   setAnonymousSessionBranch(object:any) {
      this.anonymousSession.branch = object;
      localStorage.setItem('anonymousSession',JSON.stringify(this.anonymousSession));
    }


  /**Método para obtener la configuración del qr
  */
  getConfigurationQr(){
    return this.anonymousSession.configurationQr;
  }

  /**Método para obtener la company
  */
  getAnonymusCompany(){
    return this.anonymousSession.system.company;
  }
  /**Método para obtener el object y el objectUuid a la que pertenece el qr
  */
  getAnonymusObjectAndObjectUuid(){
    return this.anonymousSession.system;
  }

  getAnonymousSessionBranch() {
     return this.anonymousSession.branch;
   }

    /**==== FIN Métodos para sesion de restaurant ====**/

  /**
   * Función que codifica los datos que se envian al backend
   */
  private encodeFormData(obj, encode) {
    if (!encode) {
      var stringyFy = JSON.stringify(obj);
      stringyFy = stringyFy.replace(/&/g, '#amp;');
      stringyFy = stringyFy.replace(/%/g, '#37;');
      return stringyFy;
    }
    let query = '';
    let name, value, fullSubName, subName, subValue, innerObj, i;
    for (let name in obj) {
      value = obj[name];
      if (value instanceof Array) {
        for (i = 0; i < value.length; ++i) {
          subValue = value[i];
          fullSubName = name + '[' + i + ']';
          innerObj = {};
          innerObj[fullSubName] = subValue;
          query += this.encodeFormData(innerObj, undefined) + '&';
        }
      }
      else if (value instanceof Object) {
        for (subName in value) {
          subValue = value[subName];
          fullSubName = name + '[' + subName + ']';
          innerObj = {};
          innerObj[fullSubName] = subValue;
          query += this.encodeFormData(innerObj, undefined) + '&';
        }
      }
      else if (value !== undefined && value !== null) {
        query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
      }
    }
    return query.length ? query.substr(0, query.length - 1) : query;
  };

  /**
   * Función para obtener el recurso del listado de enpoints para consulta al backend
   */
  getEnpoint(code: string) {
    //buscamos el code en el compendio de urls
    let resourse = endpoints.find(x => x.code == code);

    //variable que contendra el path a llamar por el http metodo get
    if (resourse === undefined) {
      console.log('Error: La url que desea accesar no esta definida');
    } else {
      return resourse.endpoint;
    }
  }

  /**función para realizar login
  *
  */
  login(object) {
    let parameters = {
      user: object.main.username,
      password: object.main.password
    };
    let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let httpOptions = {
      headers: headers
    };
    return new Promise((resolve,reject) => {
      //console.log(this.encodeFormData(parameters, true));
      this.http.post(server_url + this.getEnpoint('api:login'), this.encodeFormData(parameters, true), httpOptions)
        .subscribe((data:any) => {
          this.setLoggedIn(data);
          headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded' ,
            'Authorization': this.tokenType + " " + this.token
          });
          httpOptions = {
            headers: headers
          };
          this.http.post(server_url + this.getEnpoint('session:create'), this.encodeFormData({username: object.username}, true), httpOptions)
            .subscribe((data:any) => {
              this.setSession(data.object);
              this.setValidate(parameters.password);
              if(this.metadata.enabledInitDogBylogin){
                this.initDog();
              }
              resolve({transaction: 'ok', object: this.getSession()});
            }, error => {
              console.log("Error:login:001 ", error);
              reject(error);
            });
        }, error => {
          console.log("Error:login:002 ", error);
          reject(error);
        });
    });
  }

  /**función para realizar consultas al backend
  * @param code código para consultar al backend
  * @param object objeto a enviar al backEnd
  */
  getRequest(code: string, object): Observable<Response> {
    let path = server_url + this.getEnpoint(code);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': this.tokenType + " " + this.token
   });
    const httpOptions = {
      headers: headers
    };
    return Observable.create(observer => {
      this.http.post(path, 'object' + '=' + this.encodeFormData(object, false) + '&', httpOptions)
        .subscribe((response) => {
          let jsonAUX = response;
          let answerString = JSON.stringify(jsonAUX);
           answerString = answerString.replace(/#37;/g, '%');
           answerString = answerString.replace(/#amp;/g, '&');
          let answer = JSON.parse(answerString);
          if (answer.transaction === 'ok') {
            observer.next(answer);
            observer.complete();
          } else {
            observer.error(answer);
          }
        }, error => {
          console.log(error);
          observer.error(error);
        });
    });
  }

  /**función para realizar consultas al backend en modo anonymous
  * @param code código para consultar al backend
  * @param object objeto a enviar al backEnd
  */
  getRequestAnonymous(code: string, object): Observable<Response> {
    let path = server_url + this.getEnpoint(code);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
   });
    const httpOptions = {
      headers: headers
    };
    return Observable.create(observer => {
      this.http.post(path, 'object' + '=' + this.encodeFormData(object, false) + '&', httpOptions)
        .subscribe((response) => {
          let jsonAUX = response;
          let answerString = JSON.stringify(jsonAUX);
           answerString = answerString.replace(/#37;/g, '%');
           answerString = answerString.replace(/#amp;/g, '&');
          let answer = JSON.parse(answerString);
          if (answer.transaction === 'ok') {
            observer.next(answer);
            observer.complete();
          } else {
            observer.error(answer);
          }
        }, error => {
          console.log(error);
          observer.error(error);
        });
    });
  }

  /*
  funcion para cerrar session
  */
  logout() {
    return new Promise((resolve)=>{
      this.session = JSON.parse(JSON.stringify(this.sessionModel));
      this.setLoggoutIn();
      sessionStorage.clear();
      resolve(true);
    });
  }

  /*
  funcion para cargar variables de session despues de un reload.
  */
  /*
  funcion para cargar variables de session despues de un reload.
  */
  realodSession(){
    return new Promise((resolve,reject)=>{
      this.isUserLoggedIn = sessionStorage.getItem('isUserLoggedIn');
      this.token = sessionStorage.getItem('token');
      this.tokenType = sessionStorage.getItem('tokenType');
      this.expiresIn = sessionStorage.getItem('expiresIn');
      this.roles = JSON.parse(sessionStorage.getItem('roles'));
      this.session = JSON.parse(sessionStorage.getItem('session'));
      this.session.object = JSON.parse(sessionStorage.getItem('objectSession'));
      this.anonymousSession = JSON.parse(localStorage.getItem('anonymousSession'));
      this.expiresIn = parseInt(this.expiresIn);
      if( this.isUserLoggedIn != null && this.token != null && this.tokenType != null && this.expiresIn != null && this.roles != null && this.session != null){
        this.endSession().then(data=>{
          if(data){
            // se ejecuto correctamente el procedimiento de alerta
            this.initDog();
            this.metadata.enabledInitDogBylogin=true;
            resolve(true);
          }else{
            // la session se ha terminado
            resolve(false);
          }
        })

      }else{
        resolve(false);
      }
    });
  }

  /*funcion para ejecutar servicio de alerta de final de session.
  */
  endSession(){
    return new Promise ((resolve) =>{
      let dateTimeToNotify = this.expiresIn;
      if(new Date().getTime() < dateTimeToNotify){
        // console.log("Sesion Activa");
        resolve(true);
      }else{
        // console.log("la session se ha terminado.")
        resolve(false);
      }
    })
  }

  /**Observador para alertar al usuario de que se esta terminado su sesión
  * @param emitAlert cantidad en segundos a mostrar la alerta para fin de sesión 0 - 3600
  */

  watchDogSession(emitAlert:number): Observable<Response>{
    let response = {
      transaction:'', // ok | bad
      message:'', // posibles mensajes del observador
      code:'' // codigo identificador de la respuesta del observador
    }
    let enabledAlerTime = true;
    let enableEndSession = true;
    return Observable.create((observer)=>{
      //verificamos si ya se termino la sesión
      this.endSession().then(data=>{
        if(data){
          // El usuartio tiene una sesión activa.
          //verificamos si su sesion sigue activida cada segundo
          // timerToCheckValidSession.pipe(takeUntil())
          timerToCheckValidSession.subscribe((n)=>{
            // console.log("Segundos trascurridos",n);
            const alertTime = new Date(this.expiresIn - (emitAlert*1000));
            const expirationTime = new Date(this.expiresIn);

            let time = new Date();
            // console.log("AlertTime",expirationTime);
            // console.log("Now",new Date());
            let subs = expirationTime.getTime() - time.getTime();
            // console.log("substract",subs/1000);
            // Si el tiempo de expiración es menor que el tiempo actuales el enviamos respondemos que la session a terminado

            if((alertTime.getTime() - time.getTime()) < 0 && enabledAlerTime){
              enabledAlerTime = false;
              response.code = "watchDogSession:002";
              response.transaction = "ok";
              response.message = "Su sesión esta próxima a vencer, reanude su sesión para continuar."
              observer.next(response);
              observer.complete();
            }
            if((expirationTime.getTime() - time.getTime()) < 0 && enableEndSession){
              enableEndSession = false;
              response.code = "watchDogSession:003";
              response.transaction = "ok";
              response.message = "Su sesión se ha terminado, reanude su sesión para continuar."
              observer.next(response);
              observer.complete();
            }

          });
        }else{
          // la session se ha terminado
          response.code = "watchDogSession:001";
          response.transaction = "bad";
          response.message = "Su sesión ya ha terminado."
          observer.next(response);
        }
      })
    });
  }
/*
  Funcion para renovar una sesion
  */
  refreshSession(object){
    let parameters = {
      user: object.main.username,
      password: object.main.password
    };
    let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let httpOptions = {
      headers: headers
    };
    return new Promise((resolve,reject) => {
      this.http.post(server_url + this.getEnpoint('api:login'), this.encodeFormData(parameters, true), httpOptions)
        .subscribe((data:any) => {
          this.setLoggedIn(data);
          headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded' ,
            'Authorization': this.tokenType + " " + this.token
          });
          httpOptions = {
            headers: headers
          };
          this.http.post(server_url + this.getEnpoint('session:create'), this.encodeFormData({username: object.username}, true), httpOptions)
            .subscribe((data:any) => {
              this.setSession(data.object);
              this.setValidate(parameters.password);
              this.initDog();
              this.realodSession();
              resolve({transaction: 'ok', object: this.getSession()});
            }, error => {
              console.log("Error:login:001 ", error);
              reject(error);
            });
        }, error => {
          console.log("Error:login:002 ", error);
          reject(error);
        });
    });
  }

  /** función para iniciar el senseo de la alerta
  */
  initDog(){
    // vigilante notificara alerta de fin de sesión a los 300 segundos antes de terminar la sesión
    this.watchDogSession(300).subscribe((data:any)=>{
      // console.log(data);
      this.alert.message = data.message
      if(data.transaction == 'ok'){
        if(data.code == 'watchDogSession:003'){
          // la session termino.
          this.alert.showAlertSession = true;
          this.alert.showlightboxAlert = true;
        }else{
          // sesión proxima a vencer
          this.alert.showAlertSession = true;
          this.alert.showlightboxAlert = true;
        }
      }else{
        this.alert.alert = true;
        this.alert.showlightboxAlert = true;
      }
      this.showAlert.next(this.alert);
    },error=>{
      console.log("hubo un error, no se puede iniciar el vigilante",error);
    })
  }

  /*
  funcion para enviar al objeto de sessionStorage validate
  */
  setValidate(object){
    let v = this.utoa(object);
    sessionStorage.setItem('validate', v);
  }
  getValidate(){
    let v = sessionStorage.getItem('validate');
    return this.atou(v);
  }

  // ucs-2 string to base64 encoded ascii
 utoa(str) {
    return btoa(encodeURIComponent(str));
  }
// base64 encoded ascii to ucs-2 string
 atou(str) {
    return decodeURIComponent(atob(str));
  }


  /*
  funcion para cargar variables de session despues de un reload.
  */
  realodSessionAnonymus(){
    return new Promise((resolve,reject)=>{
      this.anonymousSession = JSON.parse(localStorage.getItem('anonymousSession'));
      resolve(true);
    });
  }

}
