<div class="row bg-dark">
  <div class="col-2">
    <button mat-icon-button (click)="close(true);" color="warn">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="col-10 text-center pt-3 pb-3">
    <h3 class="text-white text-uppercase">Detalle de Pedido</h3>
  </div>
</div>
<div class="row mt-2 justify-content-center">
  <div class="col-11">
    <div class="row">
      <div class="col-6">
        <mat-card *ngIf="object.system.objectType == 'foodTable'">
          <div class="row">
            <div class="col-6">
              <mat-icon mat-list-icon>deck</mat-icon>
              <div mat-line>Mesa: {{object.system.foodTable.main.number}}</div>
            </div>
          </div>
        </mat-card>
        <mat-card *ngIf="object.system.objectType == 'roomService'">
          <div class="row">
            <div class="col-6">
              <mat-icon mat-list-icon>home</mat-icon>
              <!-- <div mat-line>Habitación: {{object.system.object.main.number}}</div> -->
            </div>
          </div>
        </mat-card>
<!--         <mat-card *ngIf="object.main.carryOut == true && object.system.foodOrderCarryOut.length > 0">
          <div class="row">
            <div class="col-12">
              <div mat-line>Cliente: {{object.system.foodOrderCarryOut[0].main.fullname}}</div>
              <div mat-line>Dirección: {{object.system.foodOrderCarryOut[0].system.address.main.fullAddress}}</div>
              <div mat-line>Contactos: <span *ngFor="let contact of object.system.foodOrderCarryOut[0].system.contacts"> {{contact.main.data}} </span></div>
            </div>
          </div>
        </mat-card> -->
      </div>
      <div class="col-6">
        <mat-card class="bg-warning text-center">
          <h1 class="pt-2 pb-2 font-weight-bold">
            {{object.main.numberOrden}}
          </h1>
        </mat-card>
      </div>
    <!--   <div class="col-12" *ngIf="object.system.foodOrderCarryOut.length > 0 && object.main.status != 'Preparacion'">
        <p>Localización para compartir con el repartidor.</p>
        <a href="https://www.google.com/maps/search/?api=1&query={{object.system.foodOrderCarryOut[0].main.latitude}},{{object.system.foodOrderCarryOut[0].main.longitude}}" target="_blank">
          https://www.google.com/maps/search/?api=1&query={{object.system.foodOrderCarryOut[0].main.latitude}},{{object.system.foodOrderCarryOut[0].main.longitude}}
        </a> -->
        <!-- <agm-map [latitude]="object.system.foodOrderCarryOut[0].main.latitude" [longitude]="object.system.foodOrderCarryOut[0].main.longitude" [zoom]="18" style="width: 100%; height: 40vh;">
         <agm-marker [latitude]="object.system.foodOrderCarryOut[0].main.latitude" [longitude]="object.system.foodOrderCarryOut[0].main.longitude" [label]="'Ubicación del cliente'" [markerDraggable]="false">
         </agm-marker>
        </agm-map> -->
      <!-- </div> -->
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <h3 class="font-weight-bold mt-2">Platillos</h3>
    <table class="table">
      <thead class="bg-info">
        <tr>
          <th scope="col"></th>
          <th scope="col">Nombre</th>
          <th scope="col">Observaciones</th>
          <th scope="col">Cantidad</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let object of object.system.foodOrderDetails">
          <th scope="row">
            <img [src]="object.system.foodSaucer.image" *ngIf="object.system.foodSaucer.main.image" class="img-product" alt="promo">
            <img *ngIf="!object.system.foodSaucer.main.image" alt="Sin imagen" src="assets/img/not-image.jpg" class="img-product" />
          </th>
          <td class="align-middle">
            <span>{{object.system.foodSaucer.main.name}}</span><br>
            <span *ngIf="object.main.foodSaucerSize"> <small>Tamaño: {{object.main.foodSaucerSize}}</small> </span>
          </td>
          <td class="align-middle">{{object.main.observation}}</td>
          <td class="align-middle text-center">{{object.main.quantity}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row">
      <div class="col-6" *ngIf="object.main.status == 'Preparacion'">
        <button style="width:100%" mat-flat-button color="warn"  matTooltip="Salida de orden" (click)="sendFoodOrden();" [disabled]="object.main.uuid == ''">Salida de orden</button>
      </div>
      <div class="col-6">
        <!-- <button style="width:100%" mat-flat-button color="accent"  matTooltip="Imprimir orden" >Imprimir orden</button> -->
      </div>
    </div>
  </div>
</div>
