
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code: "api:login",
    endpoint: "api/login"
  }, {
    code: "session:create",
    endpoint: "validate/validate"
  },  {
    code: "state:list",
    endpoint: "state/list"
  },  {
    code: "companyClient:list",
    endpoint: "companyClient/list"
  },  {
    code: "companyClient:get",
    endpoint: "companyClient/get"
  },  {
    code: "companyClient:update",
    endpoint: "companyClient/update"
  },  {
    code: "state:findAllByStatus",
    endpoint: "state/findAllByStatus"
  },  {
    code: "settingType:findAllByStatus",
    endpoint: "settingType/findAllByStatus"
  },  {
    code: "town:findAllByStateAndStatus",
    endpoint: "town/findAllByStateAndStatus"
  },  {
    code: "locality:findAllByTownAndStatus",
    endpoint: "locality/findAllByTownAndStatus"
  },  {
    code: "setting:findAllByLocalityAndStatus",
    endpoint: "setting/findAllByLocalityAndStatus"
  },  {
    code: "state:updatet",
    endpoint: "state/update"
  },  {
    code: "town:update",
    endpoint: "town/update"
  },  {
    code: "locality:update",
    endpoint:"locality/update"
  },  {
    code: "setting:update",
    endpoint: "setting/update"
  },  {
    code: "taxRegime:findAllByUse",
    endpoint: "taxRegime/findAllByUse"
  },  {
    code: "setting:get",
    endpoint: "setting/get "
  },  {
    code: "dependentMethods:deleteCompanyClient",
    endpoint: "dependentMethods/deleteCompanyClient"
  },  {
    code: "tax:findAllByCompanyAndType",
    endpoint: "tax/findAllByCompanyAndType"
  },  {
    code: "customer:list",
    endpoint: "customer/list"
  },  {
    code: "customer:get",
    endpoint: "customer/get"
  }, {
    code: "user:list",
    endpoint: "user/list"
  }, {
    code: "user:findAllByTypeAndObjectUuidAndObjectTypeAndStatus",
    endpoint: "user/findAllByTypeAndObjectUuidAndObjectTypeAndStatus"
  }, {
    code: "user:findAllByTypeAndObjectUuidAndObjectTypeAndRoleAndStatus",
    endpoint: "user/findAllByTypeAndObjectUuidAndObjectTypeAndRoleAndStatus"
  }, {
    code: "dependentMethods:deleteUser",
    endpoint: "dependentMethods/deleteUser"
  }, {
    code: "validate:userObjects",
    endpoint: "validate/userObjects"
  },{
    code: "user:get",
    endpoint: "user/get"
  }, {
    code: "user:update",
    endpoint: "user/update"
  }, {
    code: "branch:findAllByCompanyAndStatus",
    endpoint: "branch/findAllByCompanyAndStatus"
  }, {
    code: "user:findAllRolByStatus",
    endpoint: "user/findAllRolByStatus"
  }, {
    code: "validate:userObjects",
    endpoint: "validate/userObjects"
  }, {
    code: "customer:findAllByStatus",
    endpoint: "customer/findAllByStatus"
  }, {
    code: "user:deleteObject",
    endpoint: "user/deleteObject"
  }, {
    code: "user:deleteByRolAndObjects",
    endpoint: "user/deleteByRolAndObjects"
  }, {
    code: "user:updateUserObject",
    endpoint: "user/updateUserObject"
  },{
    code: "companyClient:findAllByCompanyAndStatus",
    endpoint: "companyClient/findAllByCompanyAndStatus"
  },{
    code: "branch:list",
    endpoint: "branch/list"
  },{
    code: "restaurantArea:findAllByBranch",
    endpoint: "restaurantArea/findAllByBranch"
  },{
    code: "restaurantArea:update",
    endpoint: "restaurantArea/update"
  },{
    code: "foodTable:update",
    endpoint: "foodTable/update"
  },{
    code: "roomService:update",
    endpoint: "roomService/update"
  },{
    code: "restaurantArea:get",
    endpoint: "restaurantArea/get"
  },{
    code: "branch:update",
    endpoint: "branch/update"
  },{
    code: "foodTable:findAllByRestaurantArea",
    endpoint: "foodTable/findAllByRestaurantArea"
  },{
    code: "roomService:findAllByHotel",
    endpoint: "roomService/findAllByHotel"
  },{
    code: "foodCategory:get",
    endpoint: "foodCategory/get"
  },{
    code: "foodCategory:findAllByBranch",
    endpoint: "foodCategory/findAllByBranch"
  },{
    code: "foodCategory:update",
    endpoint: "foodCategory/update"
  },{
    code: "foodSaucer:update",
    endpoint: "foodSaucer/update"
  },{
    code: "branch:get",
    endpoint: "branch/get"
  },{
    code: "configurationQr:load",
    endpoint: "configurationQr/load"
  },{
    code: "configurationQr:update",
    endpoint: "configurationQr/update"
  },{
    code: "configurationQr:updateLogo",
    endpoint: "configurationQr/updateLogo"
  },{
    code: "configurationQr:downloadLogo",
    endpoint: "configurationQr/downloadLogo"
  },{
    code: "configurationQr:deleteLogo",
    endpoint: "configurationQr/deleteLogo"
  },{
    code: "configurationQr:getNameLogo",
    endpoint: "configurationQr/getNameLogo"
  },{
    code: "configurationQr:get",
    endpoint: "configurationQr/get"
  },{
    code: "foodTable:get",
    endpoint: "foodTable/get"
  },{
    code: "roomService:get",
    endpoint: "roomService/get"
  },{
    code: "foodTable:qrByRestaurantArea",
    endpoint: "foodTable/qrByRestaurantArea"
  },{
    code: "roomService:qrByHotel",
    endpoint: "roomService/qrByHotel"
  },{
    code: "foodSaucer:upload",
    endpoint: "foodSaucer/upload"
  },{
    code: "foodSaucer:findAllByCompany",
    endpoint: "foodSaucer/findAllByCompany"
  },{
    code: "foodSaucer:get",
    endpoint: "foodSaucer/get"
  },{
    code: "foodSaucer:download",
    endpoint: "foodSaucer/download"
  },{
    code: "foodSaucer:getImage",
    endpoint: "foodSaucer/getImage"
  },{
    code: "foodSaucerBranch:update",
    endpoint: "foodSaucerBranch/update"
  },{
    code: "foodSaucerBranch:qrByBranch",
    endpoint: "foodSaucerBranch/qrByBranch"
  },{
    code: "foodSaucerBranch:delete",
    endpoint: "foodSaucerBranch/delete"
  },{
    code: "foodSaucerBranch:findAllByFoodSaucer",
    endpoint: "foodSaucerBranch/findAllByFoodSaucer"
  },{
    code: "foodSaucerBranch:findAllByBranchAndFoodCategory",
    endpoint: "foodSaucerBranch/findAllByBranchAndFoodCategory"
  },{
    code: "promotionImage:update",
    endpoint: "promotionImage/update"
  },{
    code: "promotionImage:upload",
    endpoint: "promotionImage/upload"
  },{
    code: "promotionImage:download",
    endpoint: "promotionImage/download"
  },{
    code: "foodSaucer:deleteImage",
    endpoint: "foodSaucer/deleteImage"
  },{
    code: "foodSaucerSize:update",
    endpoint: "foodSaucerSize/update"
  },{
    code: "foodSaucerSize:findAllByFoodSaucer",
    endpoint: "foodSaucerSize/findAllByFoodSaucer"
  },{
    code: "foodSaucerSize:findAllByFoodSaucerAndStatus",
    endpoint: "foodSaucerSize/findAllByFoodSaucerAndStatus"
  },{
    code: "promotionImage:findAllByBranch",
    endpoint: "promotionImage/findAllByBranch"
  },{
    code: "promotionImage:deleteImage",
    endpoint: "promotionImage/deleteImage"
  },{
    code: "foodOrder:update",
    endpoint: "foodOrder/update"
  },{
    code: "foodOrdenDetail:update",
    endpoint: "foodOrdenDetail/update"
  },{
    code: "foodOrder:get",
    endpoint: "foodOrder/get"
  },{
    code: "foodOrder:findAllByBranchAndCarryOutAndStatusAndDateFoodOrderBetween",
    endpoint: "foodOrder/findAllByBranchAndCarryOutAndStatusAndDateFoodOrderBetween"
  },{
    code: "foodOrderCarryOut:update",
    endpoint: "foodOrderCarryOut/update"
  },{
    code: "role:findAllByObjectTypeAndObjectId",
    endpoint: "role/findAllByObjectTypeAndObjectId"
  },{
    code: "action:list",
    endpoint: "action/list"
  },{
    code: "role:findAllByAction",
    endpoint: "role/findAllByAction"
  },{
    code: "role:deleteRoleAction",
    endpoint: "role/deleteRoleAction"
  },{
    code: "action:update",
    endpoint: "action/update"
  },{
    code: "role:refreshRoles",
    endpoint: "role/refreshRoles"
  },{
    code: "role:createRoleAction",
    endpoint: "role/createRoleAction"
  },{
    code: "action:update",
    endpoint: "action/update"
  },{
    code: "role:refreshRoles",
    endpoint: "role/refreshRoles"
  },{
    code: "foodSaucerComplement:update",
    endpoint: "foodSaucerComplement/update"
  },{
    code: "role:update",
    endpoint: "role/update"
  },{
    code: "company:get",
    endpoint: "company/get"
  },{
    code: "company:list",
    endpoint: "company/list"
  },{
    code: "company:update",
    endpoint: "company/update"
  },{
    code: "company:findAllByStatus",
    endpoint: "company/findAllByStatus"
  },{
    code:"taxRegime:findAllByUseAndStatus",
    endpoint:"taxRegime/findAllByUseAndStatus"
  },{
    code:"taxRegime:list",
    endpoint:"taxRegime/list"
  },{
    code:"taxRegime:findAllByUse",
    endpoint:"taxRegime/findAllByUse"
  },{
    code:"dependentMethods:deleteCompany",
    endpoint:"dependentMethods/deleteCompany"
  },{
    code: "company:delete",
    endpoint: "company/delete"
  },{
    code: "company:findAllByStatus",
    endpoint: "company/findAllByStatus"
  },{
    code: "user:findAll",
    endpoint: "user/findAll"
  },{
    code: "user:findAllByStatus",
    endpoint: "user/findAllByStatus"
  },{
    code: "waiter:list",
    endpoint: "waiter/list"
  },{
    code: "waiter:update",
    endpoint: "waiter/update"
  },{
    code: "waiter:get",
    endpoint: "waiter/get"
  },{
    code: "waiter:findAllByCompany",
    endpoint: "waiter/findAllByCompany"
  },{
    code: "foodSaucerBranch:findAllByBranchAndFoodCategory",
    endpoint: "foodSaucerBranch/findAllByBranchAndFoodCategory"
  },{
    code: "foodOrderDetail:update",
    endpoint: "foodOrderDetail/update"
  },{
    code: "foodOrder:findByFoodTableAndStatus",
    endpoint:"foodOrder/findByFoodTableAndStatus"
  },{
    code: "foodOrder:findByFoodTableAndNotStatus",
    endpoint: "foodOrder/findByFoodTableAndNotStatus"
  },{
    code: "bar:findAllByBranch",
    endpoint: "bar/findAllByBranch"
  },{
    code: "bar:update",
    endpoint: "bar/update"
  },{
    code: "bar:get",
    endpoint: "bar/get"
  },{
    code: "foodSaucerBranch:findAllByFoodSaucerAndBar",
    endpoint: "foodSaucerBranch/findAllByFoodSaucerAndBar"
  },{
    code: "foodSaucerBranch:findAllByBarAndIsMake",
    endpoint: "foodSaucerBranch/findAllByBarAndIsMake"
  },{
    code: "foodOrderDetail:updateIsMake",
    endpoint: "foodOrderDetail/updateIsMake"
  },{
    code: "client:findAllByCompanyAndStatus",
    endpoint: "client/findAllByCompanyAndStatus"
  },{
    code: "sale:update",
    endpoint: "sale/update"
  },{
    code:"sale:downloadReport",
    endpoint:"sale/downloadReport"
  },{
    code:"sale:ticket",
    endpoint: "sale/ticket"
  },{
    code:"chef:update",
    endpoint:"chef/update"
  },{
    code:"chef:list",
    endpoint:"chef/list"
  },{
    code:"chef:get",
    endpoint:"chef/get"
  },{
    code: "user:findByPhysicalPerson",
    endpoint:"user/findByPhysicalPerson"
  },{
    code:"user:updateUserRole",
    endpoint:"user/updateUserRole"
  },{
    code:"user:findByObjectTypeAndObjectUuid",
    endpoint:"user/findByObjectTypeAndObjectUuid"
  },{
    code: "user:deleteUserObject",
    endpoint: "user/deleteUserObject"
  },{
    code:"sale:comanda",
    endpoint:"sale/comanda"
  },{
    code: "foodSaucerBranch:findAllByFoodSaucerAndBranch",
    endpoint: "foodSaucerBranch/findAllByFoodSaucerAndBranch"
  },{
    code:"bar:findAllByBranch",
    endpoint: "bar/findAllByBranch"
  },{
    code:"foodSaucerBranch:findAllByFoodOrderAndBar",
    endpoint:"foodSaucerBranch/findAllByFoodOrderAndBar"
  },{
    code:"user:findByObjectTypeAndObjectUuidAndUser",
    endpoint:"user/findByObjectTypeAndObjectUuidAndUser"
  },{
    code:"user:deleteByUserObjectAndUserUuidAndUser",
    endpoint:"user/deleteByUserObjectAndUserUuidAndUser"
  },


  


  


  
];

export default urls;
