<div class="row justify-content-center title-modal" style="margin-left: -24px;margin-right: -24px;">
  <div class="col-4 text-uppercase text-center p-3">
    <h4>Nueva Categoria de Trabajo</h4>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row">
      <mat-form-field class="col-8" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.main.name" [formControl]="complexForm.controls['name']" >
        <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <section class="col-4 text-center" style="display:initial">
        <mat-slide-toggle [(ngModel)]="object.system.status" name="sStatus">Activo</mat-slide-toggle>
      </section>
    </div>
    <div class="row justify-content-center">
      <h3 class="col-12" id="example-radio-group-label">Iconos</h3>
      <mat-radio-group aria-labelledby="example-radio-group-label" [(ngModel)]="object.complement.icon">
        <mat-radio-button class="col-2" *ngFor="let icon of iconList" [value]="icon.value">
          <img style="background: #000000;" src="/assets/img/{{metadata.configurationQr.main.theme}}/{{icon.value}}" width="100%">
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button type="button" class="btn btn-danger btn-block" color="warn" [disabled]="complexForm.invalid || object.complement.icon == ''"  (click)="send(object);">Aceptar</button>
      </div>
    </div>
  </div>
</div>
