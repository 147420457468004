<mat-drawer-container class="drawerConteiner" autosize>
    <mat-drawer #drawer class="mainDrawer" mode="side">
      <app-menu></app-menu>
    </mat-drawer>
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-md-10 col-12 mt-2">
        <div class="row ">
          <div class="col-9">
            <h4 class="pl-2 pt-2"> Para iniciar orden, selecciona el área de atención
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-tab-group (selectedTabChange)="getFoodTable($event)" [contentTabIndex]="contentTabIdex">
              <mat-tab *ngFor="let object of restaurantAreaList" [aria-labelledby]="object.main.uuid" >
                <ng-template mat-tab-label >{{object.main.name}}</ng-template>
                <div class="row" *ngIf="object.main.uuid != ''">
                  <mat-card class="col-3 mt-1 ml-2" *ngFor="let foodTable of object.system.foodTables; let i=index;" routerLink="/waiter/order/{{foodTable.main.uuid}}" [ngClass]="foodTable.main.status == 'Disponible' ? 'freeTable' : ''">
                    <mat-card-header>
                      <mat-card-title >
                        <mat-icon style="font-size:22px;" color="warn" >room</mat-icon>&nbsp;{{foodTable.main.number}}
                      </mat-card-title>
                      <mat-card-subtitle>
                        <mat-icon color="accent">deck</mat-icon>&nbsp; <p class="letter">Mesa {{foodTable.main.status}}</p>
                      </mat-card-subtitle>
                    </mat-card-header>
                  </mat-card>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer-container>
