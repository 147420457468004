import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { SessionService } from '../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { interval, Subject } from 'rxjs';

@Component({
  selector: 'app-cashier-sale',
  templateUrl: './cashier-sale.component.html',
  styleUrls: ['./cashier-sale.component.css']
})
export class CashierSaleComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  constructor(public dialog: MatDialog, private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) { }

  ngOnInit(): void {
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
