import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { CompanyClientRestaurantAreaUpdateComponent } from './company-client-restaurant-area-update/company-client-restaurant-area-update.component';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-company-client-restaurant-area',
  templateUrl: './company-client-restaurant-area.component.html',
  styleUrls: ['./company-client-restaurant-area.component.css']
})
export class CompanyClientRestaurantAreaComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  branchList = [];

  restaurantAreaList = [];

  restaurantAreaModel = {
    main: {
      uuid: '',
      name: '',
      typeArea: 'Restaurante'
    },
    system: {
      branch: {
        main: {
          uuid: ''
        }
      },
      foodTables: [],
      status: true
    }
  };

  metadata = {
    branch: {
      main: {
        uuid: '',
        name: '',
      }
    }
  }

  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {

  }

  ngOnInit(): void {
    this.getBrachList().then((data: any) => {
      this.getRestaurantAreaList(this.branchList[0]).then((data: any) => {
        this.restaurantAreaList = [];
        for (let x=0; x<data.instanceList.length; x++) {
          let restaurantArea = JSON.parse(JSON.stringify(this.restaurantAreaModel));
          restaurantArea.main = data.instanceList[x].main;
          restaurantArea.system.branch = data.instanceList[x].system.branch;
          restaurantArea.system.status = data.instanceList[x].system.status;
          // obtenemos las mesas del área
          this.getFoodTableList(restaurantArea).then((data:any)=> {
            restaurantArea.system.foodTables = data.instanceList;
            this.restaurantAreaList.push(restaurantArea);
          });
        }
      });
    });
  }

  /**
   * Area de Restaurante
   */
  getRestaurantAreaList(branch) {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('restaurantArea:findAllByBranch', {main: {typeArea: 'Restaurante'}, system: { branch: branch } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: restaurantArea:findAllByBranch", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        })
    });
  }

  /**
   * Inicializa los datos de la sucursal seleccionada
   */
  setBranch(event) {
    // Cargas las áreas del lugar de acuerdo a sucursal seleccionada
    this.getRestaurantAreaList(this.branchList[event.index]).then((data: any) => {
      this.metadata.branch = this.branchList[event.index];
      this.restaurantAreaList = [];
      for (let x=0; x<data.instanceList.length; x++) {
        let restaurantArea = JSON.parse(JSON.stringify(this.restaurantAreaModel));
        restaurantArea.main = data.instanceList[x].main;
        restaurantArea.system.branch = data.instanceList[x].system.branch;
        restaurantArea.system.status = data.instanceList[x].system.status;
        // obtenemos las mesas del área
        this.getFoodTableList(restaurantArea).then((data:any)=> {
          restaurantArea.system.foodTables = data.instanceList;
          this.restaurantAreaList.push(restaurantArea);
        });
      }
    });
  }

  /**
   * Obtiene la lista de sucursales
   */
  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('branch:findAllByCompanyAndStatus', { system: {status: true,  company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
        (data: any) => {
          this.branchList = data.object.instanceList;
          // Obtenemos las áreas del lugar de acuerdo a la sucursal
          this.metadata.branch = this.branchList[0];
        }, error => {
          console.log("Error: branch:findAllByCompanyAndStatus", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
      });
  }

  /**
   * Obtiene la lista de mesa de alimentos
   */
  getFoodTableList(restaurantArea) {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('foodTable:findAllByRestaurantArea', {system:{restaurantArea: restaurantArea}}).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: foodTable:findAllByRestaurantArea", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  /**
   * funcionalidad para cambiar el menú
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  /**
   * Funcionalidad para dar de alta un area de restaurante
   */
  updateDialog(uuid) {
    this.dialog.open(CompanyClientRestaurantAreaUpdateComponent, {
      width: '60%',
      data: {
        main: {
          uuid: uuid
        },
        system: {
          branch: this.metadata.branch
        }
      }
    }).afterClosed().subscribe(result => {
      console.log('***',result)
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.getBrachList().then((data: any) => {
            this.getRestaurantAreaList(this.branchList[0]).then((data: any) => {
              this.restaurantAreaList = [];
              for (let x=0; x<data.instanceList.length; x++) {
                let restaurantArea = JSON.parse(JSON.stringify(this.restaurantAreaModel));
                restaurantArea.main = data.instanceList[x].main;
                restaurantArea.system.branch = data.instanceList[x].system.branch;
                restaurantArea.system.status = data.instanceList[x].system.status;
                // obtenemos las mesas del área
                this.getFoodTableList(restaurantArea).then((data:any)=> {
                  restaurantArea.system.foodTables = data.instanceList;
                  this.restaurantAreaList.push(restaurantArea);
                });
              }
            });
          });
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  downloadQr(uuid){
    this.loadingService.wait();
    // cargamos los datos de la BD
    this.session.getRequest('foodTable:qrByRestaurantArea', {system: {restaurantArea: {main: {uuid: uuid} } } }).subscribe((data:any)=>{
      let elem = document.createElement('a');
      elem.href = data.object.image.file;
      elem.setAttribute('download', data.object.image.name);
      document.body.appendChild(elem);
      elem.click();
      this.loadingService.hide();
    }, (error)=>{
      this.loadingService.hide();
      console.log('Error: foodTable:qrByRestaurantArea',error)
    });
  }
}
