<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer2" mode="push" position="end">
    <app-menu-client></app-menu-client>
  </mat-drawer>
  <div class="row justify-content-center home-restaurant">
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="Regresar" matTooltip="Regresar" color="warn" (click)="back();">
        <mat-icon >chevron_left</mat-icon>
      </button>
    </div>
    <div class="col-8 text-center">
      <!-- <img class="mt-1 mb-2 img-toolbar" [src]="metadata.logo" width="30%"> -->
    </div>
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" color="warn">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
  <div class="row justify-content-center" style=" min-height: 80vh;">
    <div class="col-11 pt-3">
      <p class="pb-4" style=" font-size: 15pt;"> <strong>Formulario de pedido</strong> </p>
      <!-- <p class="pb-1" style=" font-size: 12pt;"> <strong>Método de pago: Efectivo</strong> </p>
      <p class="pb-1" style=" font-size: 12pt;"> <strong>Forma de pago: {{object.main.paymentMethod}}</strong> </p> -->

      <div class="row">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.main.fullname" [formControl]="complexForm.controls['name']" >
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Teléfono</mat-label>
          <input matInput placeholder="Teléfono" name="phone" [(ngModel)]="contacts[0].main.data" [formControl]="complexForm.controls['phone']" >
          <mat-error *ngIf="complexForm.controls['phone'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <!-- <app-sti-contact [contacts] = "contacts"></app-sti-contact> -->
        <div class="col-12 mb-2" *ngIf="metadata.map.enabled">
          <p>Agrega tu ubicación.</p>
          <agm-map [latitude]="metadata.map.lat" [longitude]="metadata.map.log" [zoom]="18" style="width: 100%; height: 40vh;">
           <agm-marker [latitude]="metadata.map.lat" [longitude]="metadata.map.log" [label]="'Tu ubicación'" [markerDraggable]="true" (dragEnd)="changeLocation($event);">
           </agm-marker>
          </agm-map>
        </div>
        <div *ngIf="!metadata.map.enabled" class="col-12">
          <p>Tu localziación no esta activa. Agrega los datos de tu dirección.</p>
        </div>
        <mat-label class="col-12 mb-2"> <strong>Ingresa tu dirección</strong> </mat-label>
        <sti-address-anonymus [stiAddress]="object.system.address" class="col-12"></sti-address-anonymus>
      </div>

    </div>
    <div class="col-10">
      <button mat-flat-button  [disabled]="complexForm.invalid || contacts.length == 0" style="width:100%; background:#FFC107;" (click)="send();">Pedir</button>
    </div>

  </div>


  <footer class="text-center text-secondary p-1">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</mat-drawer-container>
