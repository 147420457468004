import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
// import { CompanyClientProductUpdateComponent } from './company-client-product-update/company-client-product-update.component';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-company-client-product',
  templateUrl: './company-client-product.component.html',
  styleUrls: ['./company-client-product.component.css']
})
export class CompanyClientProductComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  @ViewChild('sidenav', { static: false }) sidenav: MatDrawer;

  foodSaucerMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    },
    max:10,
    offset:0,
    filter:{
      name:"searchElement",
      value:""
    }
  }
  metadata = {
    object: {
      main: {
        uuid:'new'
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  list = [];
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {

  }

  ngOnInit(): void {
    this.foodListByCompany();
  }

  /**funcionalidad para obtener el listado de prodcutos
  */
  foodListByCompany(){
    this.loadingService.wait();
    this.foodSaucerMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest('foodSaucer:findAllByCompany',this.foodSaucerMin).subscribe(
      (data:any)=>{
        this.loadingService.hide();
        this.list = data.object.instanceList;
        for(let i=0; i < this.list.length; i++){
          if (this.list[i].main.image) {
            this.getImage(this.list[i],i);
          }
        }
        this.paginatorModel.total = data.object.total;
      },
      error => {
        this.loadingService.hide();
        console.log("foodSaucer:findAllByCompany",error);
      })
  }

  onPaginateChange(event){
    this.foodSaucerMin.offset = (event.pageIndex * event.pageSize);
    this.foodSaucerMin.max = event.pageSize;
    this.foodListByCompany();
  }

  edit(uuid){
    console.log(uuid);
    this.metadata.object.main.uuid = uuid;
    this.sidenav.toggle();
  }

  /**función para obtener la imagen de un producto
  */
  async getImage(object,indexX){
    let auxFile ={
      file:{
        uuid:object.main.uuid,
        name:object.main.name,
        file:'',
        index:0,
        total:0,
        chunk:10,
        chunkSize:0,
        position:0,
        type:''
      },
      main:{
        uuid:object.main.uuid//uuid del archivo
      }
    }
    let base64 = '';
    try{
      let chunkFirstTime = true;
      for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
        // console.log("index--->",auxFile.file.index);
        let resF = await this.getRequestBaseFile(auxFile);
        if(resF.transaction == 'ok'){
          base64 = base64.concat(resF.object.file.file);
          auxFile.file.name = resF.object.file.name;
          if(chunkFirstTime){
            auxFile.file.chunk = resF.object.file.chunk;
            chunkFirstTime = false;
          }
        }else{
          this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
        }
      }
      if( base64 != ''){
        // console.log(base64);
        base64 = base64.replace(/-/g, "+");
        this.list[indexX].image = base64;
      }

    }catch(e){
      console.log("error",e);
      object.mode = 'determinate';
      this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
    }
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequest('foodSaucer:download',object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }


  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  closeSide(ev){
    this.sidenav.close();
    if (ev) {
      this.foodListByCompany();
    }
  }


}
