<div class="row bg-dark">
    <div class="col-12 text-center pt-3 pb-3">
        <h3 class="text-white text-uppercase">Asignar sucursal</h3>
    </div>
</div>

<div class="row justify-content-center mt-4">
    <h2 class="col-12">Cocinero: {{object.system.physicalPerson.main.name}} {{object.system.physicalPerson.main.lastname}}
        {{object.system.physicalPerson.main.surname}}</h2>
</div>

<div class="row justify-content-center mt-4">
    <!-- [checked]="object.system.selected"  (ngModelChange)="addBranch(object);" -{{object.system.selected}}-->
    <mat-checkbox *ngFor="let object of branchList" [(ngModel)]="object.system.selected" class="col-2"
        (change)="addBranch(object);">
        {{object.system.object.main.name}}&nbsp;&nbsp;
    </mat-checkbox>&nbsp;&nbsp;
</div>

<!-- 
<div class="row justify-content-center mt-4">
    <mat-checkbox *ngFor="let object of branchList" [(ngModel)]="object.system.selected" class="col-2">
    {{object.system.object.main.name}}&nbsp;&nbsp;
</mat-checkbox>&nbsp;&nbsp;

</div> -->


<div class="row justify-content-end mt-4">
    <div class="col-4">
        <button type="button" class="btn btn-danger btn-block" (click)="close(false);">Cerrar</button>
    </div>
</div>
