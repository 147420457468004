<mat-drawer-container class="drawerConteiner font-kelson" autosize>
  <mat-drawer #drawer class="mainDrawwerCountry" mode="push" position="end">
    <app-restaurant-country-menu-collapsed></app-restaurant-country-menu-collapsed>
  </mat-drawer>
  <div class="row justify-content-center barra-menu-country font-kelson">
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="Menu" matTooltip="Regresar" routerLink="/restaurant-country/home" style="padding-left:0px;">
        <mat-icon class="text-white">keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div class="col-6 pt-2 text-center">
      <h2 class="text-white">{{metadata.name}}</h2>
    </div>
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-country/order">
        <mat-icon class="text-white">receipt</mat-icon>
      </button>
    </div>
    <div class="col-2 pt-2 text-center">
      <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
        <mat-icon class="text-white">dehaze</mat-icon>
      </button>
    </div>
  </div>
  <div class="row home-restaurant justify-content-center font-kelson">
    <div class="col-11 mt-2 text-center">
      <!-- <mat-form-field appearance="outline" style="width:100%;">
        <mat-label>Buscar</mat-label>
        <input matInput placeholder="Buscar">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field><br/> -->
      <h1 style="color:#7bb81d">{{metadata.foodCategory.main.name}}</h1>
    </div>
    <div class="col-11">
      <div class="row">
        <div class="col-6" *ngFor="let foodSaucer of foodSaucerList">
          <mat-card class="example-card mt-1" routerLink="/restaurant-country/product-detail/{{foodSaucer.main.uuid}}" style="background:#f0f1f0;">
            <!---Url de imagen de prueba. Aqui va la ruta de productos--->
            <!-- <div *ngIf="!foodSaucer.main.image" style="margin-top: 100%;"></div> -->
            <div *ngIf="foodSaucer.main.image">
              <img mat-card-image [src]="foodSaucer.image" class="img-menu rounded" *ngIf="foodSaucer.image">
              <div class="sk-circle" *ngIf="foodSaucer.image == undefined">
                <div class="sk-circle1 sk-child"></div>
                <div class="sk-circle2 sk-child"></div>
                <div class="sk-circle3 sk-child"></div>
                <div class="sk-circle4 sk-child"></div>
                <div class="sk-circle5 sk-child"></div>
                <div class="sk-circle6 sk-child"></div>
                <div class="sk-circle7 sk-child"></div>
                <div class="sk-circle8 sk-child"></div>
                <div class="sk-circle9 sk-child"></div>
                <div class="sk-circle10 sk-child"></div>
                <div class="sk-circle11 sk-child"></div>
                <div class="sk-circle12 sk-child"></div>
              </div>
            </div>
            <mat-card-content>
              <div class="row">
                <div class="col-7 pr-0 pl-1">
                  <p style="line-height: 10pt;">{{foodSaucer.main.name}}</p>
                </div>
                <div class="col-5 pl-0 pr-1 text-right">
                  <h4 class="font-weight-bold">{{foodSaucer.main.price | currency:'CAD':'symbol-narrow'}}</h4>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <footer class="text-center text-secondary p-1">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</mat-drawer-container>
