import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-user-update',
  templateUrl: './admin-user-update.component.html',
  styleUrls: ['./admin-user-update.component.css']
})
export class AdminUserUpdateComponent implements OnInit {
  complexForm: FormGroup;

  physicalPerson = {
    main:{
      uuid:"",
      name:"",
      lastname:"",
      surname:""
    },
    complement:{
      dateBirth:"",
      curp:"",
      rfc:"",
      gender:""
    },
    system :{
      status:true,
      address:''
    }
  };

  user = {
    main:{
      uuid:"",
      username:"",
      password:"",
      passwordConfirm:"",
    },
    complement:{
      enabled:"true",
      accountExpired:"false",
      accountLocked:"false",
      passwordExpired:"false"
    },
    system:{
      status:true,
      type: "CUSTOMER",
      objectUuid: "",
      physicalPerson: this.physicalPerson
    }
  };

  customerList = [];
  metadata = {
    curp: '[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[A-Za-z0-9]{2}',
    reggexRfcPhysical: '[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}',
    company:{
      main:{
        uuid:"",
      }
    }
  };

  object = JSON.parse(JSON.stringify(this.user));
  companyList = [];

  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminUserUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'username': [null, Validators.compose([Validators.required])],
      'password': [null, Validators.compose([Validators.required])],
      'passwordConfirm': [null, Validators.compose([Validators.required])],
      'name': [null, Validators.compose([Validators.required])],
      'surname': [null, Validators.compose([Validators.required])],
      'lastname': [null, Validators.compose([Validators.required])],
      'curp': [null, Validators.compose([Validators.pattern(this.metadata.curp)])],
      'rfc': [null, Validators.compose([Validators.pattern(this.metadata.reggexRfcPhysical)])],
    });
   }

  ngOnInit(): void {
    // this.getCustomer();
    // this.object.system.objectUuid = this.session.getSessionObject().main.uuid;
    this.metadata.company.main.uuid = this.session.getSessionObject().main.uuid;
    console.log(this.metadata.company.main.uuid);
/*     let customerUuid = this.session.getSessionObject().main.uuid
    this.changueClient(customerUuid); */
    if(this.data != 'new') {
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.session.getRequest('user:get',this.object).subscribe((data:any)=>{
        console.log(data);
        this.object = JSON.parse(JSON.stringify(data.object));
        console.log(this.object);
        // if(this.object.system.physicalPerson.complement.dateBirth){
        //     this.metadata.dateBirth = moment(this.object.system.physicalPerson.complement.dateBirth).add(6, 'hours').utc().toISOString();
        // }
      },
      (error)=>{
        console.log('Error:user:get',error)
      })
    }
  }

  // getCustomer(){
  //     this.session.getRequest('customer:findAllByStatus', {status:true}).subscribe(
  //       (data:any)=>{
  //         this.customerList = data.object.instanceList;
  //       },
  //       error => {
  //         console.log("customer:findAllByStatus",error);
  //       })
  // }

/*   changueClient(objectIdTemp){
    // this.object.objectsList = [];
    // this.object.userObject.system.listObjects = [];
    // this.object.userObject.system.objectId = '';

    // this.session.getRequest('company:findAllByCompanyAndStatus', {system:{company:{main:{uuid: objectIdTemp}}, status:true}}).subscribe(
    this.session.getRequest('company:findAllByStatus', {system:{status:true}}).subscribe(
     (data:any)=>{
       this.companyList = data.object.instanceList;
     },
     error => {
       console.log("company:findAllByStatus",error);
     })
  } */


  send(object) {
    // cargamos los datos de la BD
    console.log('user.----',object);
    this.loadingService.show(true,'Guardando usuario');
    console.log(object.system.objectUuid);
    this.object.system.physicalPerson.complement.dateBirth = moment().format('DD-MM-YYYY');
    this.session.getRequest('user:update', object).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      this.snackBar.open('Registro guardado exitosamente', 'Información', {duration: 5000});
      this.close(true);
      this.loadingService.hide();
    },
    (error)=>{
      console.log('Error:user:update',error)
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      this.loadingService.hide();
    })
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'user:001';
    }
    this.dialogRef.close(object);
  }

}
