<div class="menu-title text-center pt-4 pb-4">
  <img src="assets/img/logo.png" alt="Logo" width="100">
</div>
<div class="row justify-content-center global-menu">
  <div class="col-12 main-menu">
    <div class="list-group">
      <a class="p-2" [ngClass]="{'item-menu-active': object.selected}" style="width: 104%;" *ngFor="let object of menuOptions" (click)="menuSelection(object);" style="border-bottom:solid #fff 1px!important;">
        <p class="mb-0 p-3 p-menu row text-uppercase">
          <span class="material-icons">
            {{object.icon}}
          </span>
          &nbsp;&nbsp;{{object.title}}
        </p>
        <div class="list-group" *ngIf="object.options.length>0" style="border-bottom:solid #fafafa 1px!important;">
          <div *ngIf="object.open" class="p-2">
            <a class="list-group-item pl-3 text-uppercase " [ngClass]="{'item-menu-active': object.selected}" style="width: 104%; border-bottom:solid #fff 1px!important;" *ngFor="let option of object.options" (click)="menuSelection(option);">
              {{option.title}}
            </a>
          </div>
        </div>
      </a>
    </div>

    <!-- <mat-list role="list" class="sti-dark">
      <mat-list-item role="listitem" class="item-menu" [ngClass]="{'item-menu-active': object.selected}" style="width: 104%;color:#fff;" *ngFor="let object of menuOptions" (click)="menuSelection(object);">
        <mat-icon mat-list-icon>
          {{object.icon}}
        </mat-icon>
        {{object.title}}
        </mat-list-item>
        <div class="row" *ngIf="object.options.length>0">
          <mat-list-item role="listitem" class="item-menu" [ngClass]="{'item-menu-active': object.selected}" style="width: 104%;color:#fff;" *ngFor="let option of object.options" (click)="menuSelection(option);">
            <mat-icon mat-list-icon *ngIf="object.options.length==0">
              {{option.icon}}
            </mat-icon>
              {{option.title}}
          </mat-list-item>
        </div>
    </mat-list> -->
  </div>
</div>
