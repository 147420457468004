<mat-drawer-container class="drawerConteiner font-kelson" autosize>
  <mat-drawer #drawer class="mainDrawwerCountry" mode="push" position="end">
    <app-restaurant-country-menu-collapsed></app-restaurant-country-menu-collapsed>
  </mat-drawer>
  <div class="row justify-content-center barra-menu-country font-kelson">
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;" (click)="back();">
        <mat-icon class="text-white">keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div class="col-6 text-center pt-2">
      <h2 class="text-white">{{metadata.name}}</h2>
    </div>
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-country/order">
        <mat-icon class="text-white">receipt</mat-icon>
      </button>
    </div>
    <div class="col-2 pt-2 text-center">
      <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
        <mat-icon class="text-white">dehaze</mat-icon>
      </button>
    </div>
  </div>
  <div class="row justify-content-center" style="min-height: 80vh;">
    <div class="col-11">
      <ol class="pt-3" style="list-style-type:none;">
        <li style="float: right;" *ngIf="foodOrder.system.foodOrderDetails.length > 0"> <span class="badge" style="background: #FFC107;padding: 5px;">{{foodOrder.main.status}}</span> </li>

        <li style=" font-size: 20pt;"> <strong>Tu Orden</strong> </li>
        <li class="pb-4" style=" font-size: small;">{{foodOrder.main.numberOrder}}</li>

        <li class="pb-3" style="font-size: 13pt;" *ngIf="foodOrder.system.foodOrderDetails.length > 0"> <strong>PLATILLOS</strong> </li>
        <li *ngFor="let object of foodOrder.system.foodOrderDetails" class="pb-1">
          <div class="">
            <span class="pr-3">{{object.main.quantity}}</span>
            <span>{{object.system.foodSaucer.main.name}}</span>
            <span style="float: right;">{{(object.main.priceUnitary * object.main.quantity)  | currency:'CAD':'symbol-narrow'}}</span>
          </div>
        </li>
        <li class="pt-3" style=" font-size: 12pt;" *ngIf="foodOrder.system.foodOrderDetails.length > 0">
          <span>Total</span>
          <span style="float: right;">{{(foodOrder.main.total)  | currency:'CAD':'symbol-narrow'}}</span>
        </li>
        <li *ngIf="foodOrder.system.foodOrderDetails.length == 0" style="margin-top: 50%;color: #9E9E9E;">
          <span>No se han agregado platillos a tu orden.</span>
        </li>
      </ol>
      <mat-form-field appearance="fill" style="width:100%;" *ngIf="foodOrder.system.foodOrderDetails.length > 0">
        <mat-label>Forma de pago</mat-label>
        <mat-select [(ngModel)]="foodOrder.main.paymentMethod"  name="mPaymenMethod" >
          <mat-option  [value]="'Cargo a la habitación'" *ngIf="qrConfiguration.main.hotel">Cargo a la habitación</mat-option>
          <mat-option  [value]="'Pago en Efectivo'">Pago en Efectivo</mat-option>
          <mat-option  [value]="'Tarjeta de Debito'" *ngIf="qrConfiguration.main.hotel">Tarjeta de Debito</mat-option>
          <mat-option  [value]="'Tarjeta de Credito'" *ngIf="qrConfiguration.main.hotel">Tarjeta de Credito</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-10" *ngIf="foodOrder.system.foodOrderDetails.length > 0 && !foodOrder.main.carryOut">
      <button mat-flat-button  (click)="sendToFoodOrderToCook();" style="width:100%; background:#95c751;">Ordenar</button>
    </div>
    <div class="col-10" *ngIf="foodOrder.system.foodOrderDetails.length > 0 && foodOrder.main.carryOut && qrConfiguration.main.carryOut">
      <button mat-flat-button  (click)="sendToPayment();" style="width:100%; background:#95c751;">Siguiente</button>
    </div>
    <div class="col-10" *ngIf="foodOrder.main.carryOut && !qrConfiguration.main.carryOut">
      Por el momento no contamos con servicio para llevar, disculpa las molestias.
    </div>
  </div>
  <footer class="text-center text-secondary p-1">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</mat-drawer-container>
