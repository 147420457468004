<div class="row justify-content-center title-modal" style="margin-left: -24px;margin-right: -24px;">
    <div class="col-4 text-uppercase text-center p-3">
      <h4>{{object.main.uuid == '' ? 'Nueva' : 'Editar'}} Barra</h4>
    </div>
  </div>
  <hr />
  <div class="row justify-content-center">
    <div class="col-11">
      <div class="row">
        <mat-form-field class="col-8" appearance="outline">
          <mat-label>Nombre de la barra</mat-label>
          <input matInput placeholder="Alimentos, bebidas,  etc." name="mName" [(ngModel)]="object.main.name" [formControl]="complexForm.controls['name']" >
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row justify-content-end mt-4">
        <div class="col-6">
          <button type="button" class="btn btn-danger btn-block" color="warn" [disabled]="complexForm.invalid"  (click)="send(object);">Guardar</button>
        </div>
        <div class="col-6">
            <button type="button" class="btn btn-block" style="width:100%" (click)="close(false);">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  
