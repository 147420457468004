import { Component, Input, Output, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { rejects } from 'assert';

@Component({
  selector: 'app-company-client-waiter-access',
  templateUrl: './company-client-waiter-access.component.html',
  styleUrls: ['./company-client-waiter-access.component.css']
})
export class CompanyClientWaiterAccessComponent {
  complexForm: FormGroup;

  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status: true,
    }
  };

  waiterModel = {
    main: {
      uuid: '',
      code: '',
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      },
      physicalPerson: this.physicalPerson
    }
  };


  userModel = {
    main:{
      uuid:"",
      username:"",
      password:"",
      passwordConfirm:"",
    },
    complement:{
      enabled:"true",
      accountExpired:"false",
      accountLocked:"false",
      passwordExpired:"false"
    },
    system:{
      status:true,
      type: "Company",
      objectUuid: "",
      physicalPerson: this.physicalPerson
    }
  }

  branchList = [];
  objectsList = [];
  userObjectList =[];

  userObjectModel = {
    main: {
      uuid: ''
    },
    system:{
      selected: false,
      user: {
        main: {
          uuid: '',
          username: ''
        }
      },
      object: 'Branch',
      objectUuid: '',
      objectType: 'Branch',
    },
  };

  branchModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      address: {
        main: {
          uuid: '',
          settingName: '',
          numberOutdoor: '',
          numberInterior: '',
          zipCode: '',
          fullAddress: ''
        },
        complement: {
          betweenRoads: '',
          locationDescription: ''
        },
        system: {
          addressType: 'Fiscal',
          setting: {
            main: {
              uuid: '',
              mName: ''
            },
            system: {
              locality: ''
            }
          },
          locality: {
            main: {
              uuid: '',
              code: '',
              name: '',
              typeLocality: ''
            },
            system: {
              town: {
                main: {
                  uuid: '',
                  name: ''
                },
                system: {
                  state: {
                    main: {
                      uuid: '',
                      name: ''
                    },
                  }
                }
              }
            }
          },
          state: {
            main: {
              uuid: ''
            }
          },
          status: true
        }
      }
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      status: true,

    }
  };

  
  metadata = {
    dateBirth: null,
    reggexCurp: '^[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[A-Za-z0-9]{2}',
    reggexRfc: '[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}',
  };

  object = JSON.parse(JSON.stringify(this.waiterModel));
  user = JSON.parse(JSON.stringify(this.userModel));



  constructor(fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyClientWaiterAccessComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.data != 'new') {
      this.loadingService.wait();
       this.object.main.uuid = this.data;
       console.log('**',this.data)
       // cargamos los datos de la BD
       this.session.getRequest('waiter:get',this.object).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));

         this.session.getRequest('user:findByPhysicalPerson',{system:{ physicalPerson:{main:{uuid: this.object.system.physicalPerson.main.uuid}}}}).subscribe((data:any)=>{
          this.user = JSON.parse(JSON.stringify(data.object));

          this.getBrachList().then((data:any)=> {
  
          });

          this.loadingService.hide();
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error: waiter:get',error)
        })

         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: waiter:get',error)
       })
    }
  }

  /**
  * funcion para obtener las sucursales y llenar el userObject
  */
  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('branch:list', { max: 1000, offset: 0, system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
        (data: any) => {
          for(let x=0; x<data.object.instanceList.length; x++){
            let userObject = JSON.parse(JSON.stringify(this.userObjectModel));
            userObject.system.objectType = 'Branch';
            userObject.system.object = data.object.instanceList[x];
            userObject.system.objectUuid = data.object.instanceList[x].main.uuid;
            this.findByObjectTypeAndObjectUuid(userObject)
          }
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: [] });
        }, error => {
          console.log("Error: branch:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  findByObjectTypeAndObjectUuid(userObject) {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('user:findByObjectTypeAndObjectUuidAndUser', { system:{ objectType:'Branch', objectUuid: userObject.system.objectUuid, user:{main:{uuid:this.user.main.uuid}}} } ).subscribe(
        (data: any) => {
          if(data.object.system){
            userObject.system.selected= true;
          }
         this.branchList.push(userObject)
         resolve(true)
        }, error => {
          console.log("Error: user:findByObjectTypeAndObjectUuid", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

 /**
  * funcion para asignar las sucursales el mesero
  */
   addBranch(object){
    var exist = 'false'
    let isSelected = false;
    console.log('**',object);
    this.loadingService.show(true,'Guardando accesos de usuario');
    if (object.system.selected){
      isSelected = true;
      object.system.user.main.uuid = this.user.main.uuid;
      object.system.user.main.username = this.user.main.username;
      this.session.getRequest('user:updateUserObject', object).subscribe(
        (data:any)=>{
          console.log('++',data)
          console.log("¡Guardado Exitoso!");
          this.snackBar.open('Registro guardado exitosamente', 'Informacion', {duration: 5000});
          this.loadingService.hide();
        },
        error => {
          this.loadingService.hide();
          console.log("user:updateUserObject",error);
          this.snackBar.open(error.message, 'Error', {duration: 5000});
          this.loadingService.hide();
        })
    }else{
      this.loadingService.hide();
         //eliminamos el objeto de la bd
         this.session.getRequest("user:deleteByUserObjectAndUserUuidAndUser", {system:{objectType:'Branch', objectUuid: object.system.objectUuid, user:{main:{uuid:this.user.main.uuid}} }}).subscribe((data) => {
        }, error => {
          object.system.selected = true;
          this.snackBar.open("Ocurrio un error, no se pudo desasociar la categoria", 'Error', { duration: 5000 });
        }); 
  
    }
    
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'waiter:001';
    }
    this.dialogRef.close(object);
  }

}
