<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer2" mode="push" position="end">
    <app-menu-client></app-menu-client>
  </mat-drawer>
  <div class="row justify-content-center home-restaurant">
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="Regresar" matTooltip="Regresar" routerLink="/restaurant/home" color="warn">
        <mat-icon >chevron_left</mat-icon>
      </button>
    </div>
    <div class="col-6 text-center">
      <!-- <img class="mt-1 mb-2 img-toolbar" src="/assets/img/restaurant-home/letrero.png" > -->
    </div>
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  color="warn" routerLink="/restaurant/order">
        <mat-icon >receipt</mat-icon>
      </button>
    </div>
    <div class="col-2 pt-2" >
      <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" color="warn">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
  <div class="row justify-content-between home-restaurant p-1">
    <div class="col-6 text-center">
      <div class="row">
        <div class="col-12 text-center p-1">
          <h1 class="text-danger font-weight-bold mt-4 mb-4" style="font-size:2rem;">{{metadata.foodCategory.main.name}}</h1>
        </div>
        <div class="col-12 text-center pr-0" routerLink="/restaurant/details/{{foodSaucer.main.uuid}}" *ngFor="let foodSaucer of foodSaucerList">
          <div *ngIf="!foodSaucer.main.image" style="margin-top: 100%;"></div>
          <div *ngIf="foodSaucer.main.image">
            <img [src]="foodSaucer.image" class="img-menu rounded" *ngIf="foodSaucer.image">
            <div class="sk-circle" *ngIf="foodSaucer.image == undefined">
              <div class="sk-circle1 sk-child"></div>
              <div class="sk-circle2 sk-child"></div>
              <div class="sk-circle3 sk-child"></div>
              <div class="sk-circle4 sk-child"></div>
              <div class="sk-circle5 sk-child"></div>
              <div class="sk-circle6 sk-child"></div>
              <div class="sk-circle7 sk-child"></div>
              <div class="sk-circle8 sk-child"></div>
              <div class="sk-circle9 sk-child"></div>
              <div class="sk-circle10 sk-child"></div>
              <div class="sk-circle11 sk-child"></div>
              <div class="sk-circle12 sk-child"></div>
            </div>
          </div>
          <div class="carousel-caption">
            <p class="text-center font-weight-bold" id="price">
              {{foodSaucer.main.name}}<br/>
              {{foodSaucer.main.price | currency:'CAD':'symbol-narrow'}}
            </p>
          </div>
        </div>

      </div>
    </div>
    <div class="col-6 text-center">
      <div class="row">
        <div class="col-12 text-center pl-0" routerLink="/restaurant/details/{{foodSaucer.main.uuid}}" *ngFor="let foodSaucer of foodSaucerListSecond">
          <img [src]="foodSaucer.image" class="img-menu rounded" *ngIf="foodSaucer.image">
          <div *ngIf="!foodSaucer.main.image" style="margin-top: 100%;"></div>
          <div *ngIf="foodSaucer.main.image">
            <div class="sk-circle" *ngIf="foodSaucer.image == undefined">
              <div class="sk-circle1 sk-child"></div>
              <div class="sk-circle2 sk-child"></div>
              <div class="sk-circle3 sk-child"></div>
              <div class="sk-circle4 sk-child"></div>
              <div class="sk-circle5 sk-child"></div>
              <div class="sk-circle6 sk-child"></div>
              <div class="sk-circle7 sk-child"></div>
              <div class="sk-circle8 sk-child"></div>
              <div class="sk-circle9 sk-child"></div>
              <div class="sk-circle10 sk-child"></div>
              <div class="sk-circle11 sk-child"></div>
              <div class="sk-circle12 sk-child"></div>
            </div>
          </div>
          <div class="carousel-caption">
            <p class="text-center font-weight-bold" id="price">
              {{foodSaucer.main.name}}<br/>
              {{foodSaucer.main.price | currency:'CAD':'symbol-narrow'}}
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
  <footer class="text-center text-secondary p-1">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</mat-drawer-container>
