import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-company-client-dashboard',
  templateUrl: './company-client-dashboard.component.html',
  styleUrls: ['./company-client-dashboard.component.css']
})
export class CompanyClientDashboardComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartLabels: Label[] = ['Día 1', 'Día 2', 'Día 3', 'Día 4', 'Día 5', 'Día 6', 'Día 7'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Pedido en sitio', backgroundColor: 'rgba(23,162,184,1)'},
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Pedidos para llevar',backgroundColor: 'rgba(242,193,93,1)'}
  ];
  // public barChartColors = [{
  //   backgroundColor: [
  //     'rgba(23,162,184,1)', 'rgba(23,162,184,1)','rgba(23,162,184,1)','rgba(23,162,184,1)','rgba(23,162,184,1)','rgba(23,162,184,1)','rgba(23,162,184,1)','rgba(242,193,93,1)','rgba(242,193,93,1)','rgba(242,193,93,1)','rgba(242,193,93,1)','rgba(242,193,93,1)','rgba(242,193,93,1)','rgba(242,193,93,1)',
  //   ]
  // }];
  constructor() { }

  ngOnInit(): void {
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
