<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer [mode]="'side'" class="mainDrawer">
    <app-menu></app-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center pl-0 pr-0">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-3">
              <button type="button" class="btn btn-danger btn-block" (click)="openDialogToCreateRoles()">Alta de roles</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card>
          <h5 class="text-uppercase pt-2 pb-2">Permisos</h5>
          <mat-card-content>
            <div class="row justify-content-center">
              <div class="col-11">
                <mat-tab-group (selectedTabChange)="selectedRole($event)">
                  <mat-tab *ngFor="let object of roleList" label="{{object.main.authority}}">
                    <div class="row justify-content-center">
                      <div class="col-6">
                        <p>ACCIONES DISPONIBLES</p>
                        <mat-form-field style="text-aling: right; width: 100%;">
                          <input matInput placeholder="Buscar acciones" [(ngModel)]="actionMin.filter.value" (ngModelChange)="search();" >
                        </mat-form-field>
                        <div class="row" *ngFor="let object of actionList">
                          <div class="col-8">
                            <span>{{object.main.description}}</span>
                          </div>
                          <button mat-button color="accent" class="col-4" (click)="actionSeleted(object);">Agregar</button>
                        </div>
                        <br>
                        <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
                          <div class="col-6 ">
                            <mat-paginator [length]=paginatorAction.total [pageSize]="10" [pageSizeOptions]=paginatorAction.pageSizeOptions (page)="pageEventActions = $event; onPaginateChangeAction($event)">
                            </mat-paginator>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <p>ACCIONES ACTUALES</p>
                        <div class="row" *ngFor="let object of roleActionList">
                          <div class="col-8">
                            <span>{{object.main.description}}</span>
                          </div>
                          <button mat-button color="accent" class="col-4" (click)="deleteAnAction(object);">Quitar</button>
                        </div>
                        <br>
                        <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
                          <div class="col-6 ">
                            <mat-paginator [length]=paginatorRoleAction.total [pageSize]="10" [pageSizeOptions]=paginatorRoleAction.pageSizeOptions (page)="pageEventRoleActions = $event; onPaginateChange($event)">
                            </mat-paginator>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
