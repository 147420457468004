<mat-drawer-container class="drawerConteiner font-kelson" autosize>
  <mat-drawer #drawer class="mainDrawwerCountry" mode="push" position="end">
    <app-restaurant-country-menu-collapsed></app-restaurant-country-menu-collapsed>
  </mat-drawer>
  <div class="row justify-content-center barra-menu-country font-kelson">
    <div class="col-2 pt-2" routerLink="/restaurant-country">
      <button mat-icon-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
        <mat-icon class="text-white">keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div class="col-6 text-center pt-2">
      <h2 class="text-white">{{metadata.name}}</h2>
    </div>
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-country/order">
        <mat-icon class="text-white">receipt</mat-icon>
      </button>
    </div>
    <div class="col-2 text-center pt-2">
      <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
        <mat-icon class="text-white">dehaze</mat-icon>
      </button>
    </div>
  </div>
  <div class="row home-restaurant font-kelson">
    <div class="col-12">
      <ngb-carousel *ngIf="object.main.promotionImage">
        <ng-template ngbSlide *ngFor="let object of promotionList">
          <div class="picsum-img-wrapper">
            <img [src]="object.image" alt="promo" width="100%" *ngIf="object.image">
            <div class="sk-circle" *ngIf="object.image == undefined">
              <div class="sk-circle1 sk-child"></div>
              <div class="sk-circle2 sk-child"></div>
              <div class="sk-circle3 sk-child"></div>
              <div class="sk-circle4 sk-child"></div>
              <div class="sk-circle5 sk-child"></div>
              <div class="sk-circle6 sk-child"></div>
              <div class="sk-circle7 sk-child"></div>
              <div class="sk-circle8 sk-child"></div>
              <div class="sk-circle9 sk-child"></div>
              <div class="sk-circle10 sk-child"></div>
              <div class="sk-circle11 sk-child"></div>
              <div class="sk-circle12 sk-child"></div>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
  <div class="row home-restaurant pb-5 font-kelson" style="padding: 15px;">
    <div class="col-6 text-center" *ngFor="let foodCat of foodCategoryList" routerLink="/restaurant-country/menu/{{foodCat.main.uuid}}">
      <div style="box-shadow: 0px 0px 5px #cac4c4;border-radius: 15px;">
        <img src="/assets/img/{{object.main.theme}}/{{foodCat.complement.icon}}" style="width:7rem;">
        <h3 class="text-dark">{{foodCat.main.name}}</h3>
      </div>
    </div>
  </div>
  <footer class="text-center text-secondary p-1">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</mat-drawer-container>
