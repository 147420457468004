import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-branch-order-details',
  templateUrl: './branch-order-details.component.html',
  styleUrls: ['./branch-order-details.component.css'],
  changeDetection:ChangeDetectionStrategy.Default
})
export class BranchOrderDetailsComponent {
  @Output() toggleSide: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('data') data:string;
  foodOrderModel = {
    main:{
      uuid: '',
      carryOut:  false, // true para llevar, false para en sitio
      dateFoodOrder: '', // Fecha y hora del medido
      status: '', // Abierta, Preparacion, En entrega, Concluida
      subTotal: 0.0, // Subtotal a pagar
      tax: 0.0, // Impuestos a pagar
      total: 0.0, // //Total a pagar
      numberOrder:''
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      objectType:'',
      objectUuid:'',
      // foodOrderCarryOut:[],
      foodOrderDetails:[]
    }
  };
  object = JSON.parse(JSON.stringify(this.foodOrderModel));
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: ChangeDetectionStrategy){
    if(this.data != ''){
      this.object.main.uuid = this.data;
      console.log(this.object.main.uuid);
      this.session.getRequest("foodOrder:get",this.object).subscribe((data:any)=>{
        this.object = data.object;
        for( let i = 0; i < this.object.system.foodOrderDetails.length; i++){
          if(this.object.system.foodOrderDetails[i].system.foodSaucer.main.image){
            this.getImage(this.object.system.foodOrderDetails[i].system.foodSaucer);
          }
        }
        //se comenenta mientras no se tenga el modelo de carryOut
 /*        if(this.object.system.foodOrderCarryOut.length > 0){
          if(this.object.system.foodOrderCarryOut[0].main.longitude){
            this.object.system.foodOrderCarryOut[0].main.longitude = parseFloat(this.object.system.foodOrderCarryOut[0].main.longitude);
            this.object.system.foodOrderCarryOut[0].main.latitude = parseFloat(this.object.system.foodOrderCarryOut[0].main.latitude);
          }
        }
        console.log("foodOrder",this.object); */
      },error=>{
        console.log("error:foodOrder:get",error);
      })
    }
  }

  /**función para obtener la imagen de un producto
  */
  async getImage(object){
    let auxFile ={
      file:{
        uuid:object.main.uuid,
        name:object.main.name,
        file:'',
        index:0,
        total:0,
        chunk:10,
        chunkSize:0,
        position:0,
        type:''
      },
      main:{
        uuid:object.main.uuid//uuid del archivo
      }
    }
    let base64 = '';
    try{
      let chunkFirstTime = true;
      for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
        // console.log("index--->",auxFile.file.index);
        let resF = await this.getRequestBaseFile(auxFile);
        if(resF.transaction == 'ok'){
          base64 = base64.concat(resF.object.file.file);
          auxFile.file.name = resF.object.file.name;
          if(chunkFirstTime){
            auxFile.file.chunk = resF.object.file.chunk;
            chunkFirstTime = false;
          }
        }else{
          this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
        }
      }
      if( base64 != ''){
        // console.log(base64);
        base64 = base64.replace(/-/g, "+");
        object.image = base64;
      }

    }catch(e){
      console.log("error",e);
      object.mode = 'determinate';
      this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
    }
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequest('foodSaucer:download',object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }

  /**funcionalidad para enviar una orden a entrega
  */
  sendFoodOrden(){
/*     if(this.object.main.carryOut){
      this.object.main.status = 'En entrega';
    }else{
      this.object.main.status = 'Concluida';
    } */
    this.object.main.status = 'En entrega';
    this.loadingService.show(true, 'Cambiamos orden de estatus');
    this.object.main.dateFoodOrder = moment().format('DD-MM-YYYY hh:mm:ss');
    this.session.getRequest("foodOrder:update",this.object).subscribe((data)=>{
      this.snackBar.open("La orden cambio de estatus a "+this.object.main.status, 'Aceptar', {duration: 5000});
      this.loadingService.hide();
      this.close(true);
    },error=>{
      console.log("error:foodOrder:update",error);
      this.loadingService.hide();
    })
  }
  /*
  * funcion para abrir o cerrar el sidenav
  */
  close(status){
    this.toggleSide.emit(status);
  }

}
