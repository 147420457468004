import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-user-add-access',
  templateUrl: './admin-user-add-access.component.html',
  styleUrls: ['./admin-user-add-access.component.css']
})
export class AdminUserAddAccessComponent implements OnInit {
  userObject = {
    main: {
      uuid: ''
    },
    user: {
      main: {
        uuid: '',
        username: ''
      }
    },
    role: {
      main: {
        uuid: ''
      }
    },
    system: {
      objectIdTemp: '',
      objectUuid: '',
      objectType: '',
      listObjects:[]
    }
  };

  rolesList = [];
  objectsAssingList = [];
  customerList = [];
  objectsList = [];
  object:any = {};
  companyList = [];
  constructor( private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminUserAddAccessComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loadingService.show(true,"Estamos cargando los recursos, espere por favor...")
    this.getRoles().then(data=>{
      this.getObjectsAssing(this.data.main.uuid).then(data=>{
        this.getCustomer().then(data=>{
          console.log('aquii',data);
          this.loadingService.hide();
          this.userObject.user.main.username = this.data.main.username;
          this.userObject.user.main.uuid = this.data.main.uuid;
          this.userObject.system.objectIdTemp = '';
          this.userObject.system.objectUuid = '';
          this.userObject.role.main.uuid = '';
          this.userObject.system.objectType = '';
          if(this.data.system.roles.length > 0){
             this.userObject.role.main.uuid = this.data.system.roles[0].uuid;
             for (var x = 0; x < this.rolesList.length; x++) {
               if(this.rolesList[x].main.uuid == this.userObject.role.main.uuid){
                 this.userObject.system.objectType = this.rolesList[x].system.objectType
                 break;
               }
             }
          }
          this.objectsList = [];
          this.userObject.system.listObjects = [];
          this.object = {
            rolesList: this.rolesList,
            objectsList: this.objectsList,
            objectsAssingList: this.objectsAssingList,
            customerList: this.customerList,
            userObject: this.userObject
          };

          //cargamos las compañias cliente de la customer
          this.object.userObject.system.objectIdTemp = this.session.getSessionObject().main.uuid;
          //console.log(this.object.userObject.system.objectIdTemp);
         // this.changueClient(this.object.userObject.system.objectIdTemp);
        }).catch(()=>{
          this.loadingService.hide();
          //this.close(false);
        });
      }).catch(()=>{
        this.loadingService.hide();
        //this.close(false);
      });
    }).catch(()=>{
      this.loadingService.hide();
      //this.close(false);
    });
  }

  ngOnInit(): void {
  }

  //obtenemos los roles registrados en el sistema
  getRoles(){
   return new Promise(resolve=>{
    //this.session.getRequest('user:findAllRolByStatus', {system: {status: true}}).subscribe(
    this.session.getRequest('user:findAll',{}).subscribe(
      (data:any)=>{
        if(data.object.instanceList.length > 0){
          this.rolesList = data.object.instanceList;
          console.log('---roles----',this.rolesList)
          resolve(this.rolesList);
        }
        else{
          console.log("No hay roles registrados");
        }
      },
      error => {
        console.log("user:findAll",error);
      })
    })
  }

  //obtenemos los objetos asignados al usuario
  getObjectsAssing(uuid){
   return new Promise(resolve=>{
      this.objectsAssingList = [];
      this.session.getRequest('validate:userObjects', {main: {uuid: uuid}}).subscribe(
        (data:any)=>{
          this.objectsAssingList = data.objectList;
          resolve(this.objectsAssingList);
        },
        error => {
          console.log("validate:userObjects",error);
        })
    })
  }

  //obtenemos customer
   getCustomer(){
   return new Promise(resolve=>{
      // this.session.getRequest('company:findAllByStatus', {system:{status:true}}).subscribe(
      this.session.getRequest('user:findAllByStatus', {system:{status:true}}).subscribe(
        (data:any)=>{
          this.customerList = data.object.instanceList;
          resolve(this.customerList);
        },
        error => {
          console.log("user:findAllByStatus",error);
        })
    })
  } 

  /** Permite asignar el rol al usuario **/
   addRol(){
      this.object.objectsList = [];
      this.object.userObject.system.listObjects = [];
      this.object.userObject.system.objectIdTemp = '';
      this.object.userObject.system.objectId = '';
      for (var x = 0; x < this.object.rolesList.length; x++) {
        if(this.object.rolesList[x].main.uuid == this.object.userObject.role.main.uuid){
          this.object.userObject.system.objectType = this.object.rolesList[x].system.objectType
          break;
        }
      }
   }

   /** Permite modifcar el rol asignado al usuario **/
   changeRole(uuid){
     this.loadingService.wait();
     this.object.userObject.system.objectIdTemp = '';
     this.object.userObject.system.objectId = '';
     this.object.userObject.role.main.uuid = '';
     this.object.userObject.system.objectType = '';
     this.object.objectsList = [];
     this.object.objectsAssingList = [];
     this.object.userObject.system.listObjects = [];

     this.session.getRequest('user:deleteByRolAndObjects', {user:{main:{uuid:uuid}}}).subscribe(
      (data:any)=>{
        console.log("¡Listo para cambio de rol!");
        this.snackBar.open('Listo para cambio de rol', 'Información', {duration: 5000});
        this.loadingService.hide();
      },
      error => {
        console.log("user:deleteByRolAndObjects",error);
        this.snackBar.open('Error al modificar rol', 'Error', {duration: 5000});
        this.loadingService.hide();
      })
   }

   // Obtenemos las compañias
   changueClient(objectIdTemp){
     this.object.objectsList = [];
     this.object.userObject.system.listObjects = [];
     this.object.userObject.system.objectId = '';

     this.session.getRequest('company:findAllByStatus', {system:{status:true}}).subscribe(
      (data:any)=>{
        this.companyList = data.object.instanceList;
      },
      error => {
        console.log("company:findAllByStatus",error);
      })
   }

   // Agrega a la lista las compañias de acceso
   addCompany(){
     var exist = 'false'
     for (var x = 0; x < this.companyList.length; x++) {
          if(this.companyList[x].main.uuid == this.object.userObject.system.objectId){
            if(this.object.objectsList.length > 0){
              for (var y = 0; y < this.object.objectsList.length; y++){
                if(this.object.objectsList[y].main.uuid == this.object.userObject.system.objectId){
                  exist = 'true';
                  break;
                 }
              }
              if(exist == 'false'){
                this.object.objectsList.push(this.companyList[x]); }
            }
            else{
              this.object.objectsList.push(this.companyList[x]);
            }
          }
        }
        this.object.userObject.system.listObjects = this.object.objectsList;
   }

   // Remover objecto agregado
   removeObject(index){
     this.object.objectsList.splice(index,1);
     this.object.userObject.system.listObjects = this.object.objectsList;
   }

   // Elimina objectos asignados al trabajador
   deleteObject(id, index){
     this.session.getRequest('user:deleteObject', {id: id}).subscribe(
      (data:any)=>{
        this.object.objectsAssingList.splice(index,1);
        console.log("¡Eliminado exitoso!");
        this.snackBar.open('Eliminado exitoso', 'Información', {duration: 5000});
      },
      error => {
        console.log("user:deleteObject",error);
        this.snackBar.open(error.message, 'Error', {duration: 5000});
      })
   }
   /** función para aplicar los cambios
   */
   //Funcion para guardar accesos
   send() {
     this.loadingService.show(true,'Guardando accesos de usuario');
     this.object.userObject.system.listObjects = [];
     if(this.object.userObject.system.objectType == 'Customer'){
       this.object.userObject.system.objectId = this.object.userObject.system.objectIdTemp
     }
     else{
       this.object.userObject.system.listObjects = this.object.objectsList;
     }

     this.session.getRequest('user:updateUserObject', this.object.userObject).subscribe(
       (data:any)=>{
         console.log("¡Guardado Exitoso!");
         this.snackBar.open('Registro guardado exitosamente', 'Error', {duration: 5000});
         this.close(true);
         this.loadingService.hide();
       },
       error => {
         console.log("user:updateUserObject",error);
         this.snackBar.open(error.message, 'Error', {duration: 5000});
         this.loadingService.hide();
       })
   }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      // object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'user:access:001';
    }
    this.dialogRef.close(object);
  }

}
