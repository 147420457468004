<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer [mode]="'side'" class="mainDrawer">
    <app-menu></app-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center pl-0 pr-0">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-3">
            <button style="width:100%" mat-flat-button color="accent"  matTooltip="Nuevo usuario" (click)="updateDialog('new');"> <i class="material-icons">add_circle_outline</i>Usuario</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card>
          <h4 class="text-uppercase pt-2 pb-2">Listado de usuarios</h4>
          <mat-card-content>
            <mat-tab-group (selectedTabChange)="tabChange($event);">
        <!--       <mat-tab label="Administradores"></mat-tab> -->
              <mat-tab label="Clientes">
                <div class="row pb-1">
             <!--      <mat-form-field class="col-6" appearance="outline">
                    <mat-label>Cliente</mat-label>
                    <mat-select placeholder="Cliente" [(ngModel)]="userMin.objectUuid" name="sObjectUuid" (ngModelChange)="userList();">
                      <mat-option *ngFor="let object of companyList" [value]="object.main.uuid">
                        {{ object.main.tradename }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                </div>
              </mat-tab>
            </mat-tab-group>
            <div class="row">
              <div class="col-12">
                <table class="table table-general">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">USUARIO</th>
                      <th scope="col">NOMBRE</th>
                      <th scope="col">RFC</th>
                      <th scope="col">ESTATUS</th>
                      <th scope="col">ACCIONES</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let object of list">
                      <td>{{ object.main.username }}</td>
                      <td>{{ object.system.physicalPerson.main.name }} {{object.system.physicalPerson.main.lastname}} {{object.system.physicalPerson.main.surname}}</td>
                      <td>{{ object.system.physicalPerson.complement.rfc }}</td>
                      <td>{{ object.system.status == true ? 'Activo' : 'Inactivo' }}</td>
                      <!-- <td>
                        <span *ngIf="object.system.roles.length == 0">SIN ASIGNAR</span>
                        <span *ngIf="object.system.roles.length > 0">
                          {{object.system.roles[0].authority}}
                        </span>
                      </td> -->
                      <td>
                        <button class="mr-2" color="primary" mat-icon-button matTooltip="Editar" >
                          <i class="material-icons" (click)="updateDialog(object.main.uuid);">mode_edit</i>
                        </button>
                        <button class="mr-2" color="primary" mat-icon-button matTooltip="Dar acceso" >
                          <i class="material-icons" (click)="addAccess(object);">fact_check</i>
                        </button>
                        <button mat-icon-button color="primary" matTooltip="Eliminar" (click)="delete(object.main.uuid)">
                          <i class="material-icons">delete_forever</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <!-- aqui el paginado o footer -->
            <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
              <div class="col-6 ">
                <mat-paginator [length]=paginatorModel.total [pageSize]="10" [pageSizeOptions]=paginatorModel.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)">
                </mat-paginator>
              </div>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
