import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StiFileToBlobComponent } from './sti-file.directive';
 import { MatToolbarModule } from '@angular/material/toolbar';
 import { MatIconModule } from '@angular/material/icon';
 import { MatCardModule } from '@angular/material/card';
 import { MatButtonModule } from '@angular/material/button';
 import { MatProgressBarModule } from '@angular/material/progress-bar';
 import { MatSnackBarModule } from '@angular/material/snack-bar';
 import { MatFormFieldModule } from '@angular/material/form-field';
 import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// import {
//   MatProgressBarModule,
//   MatProgressSpinnerModule
// } from '@angular/material';

@NgModule({
  providers:[
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatProgressSpinnerModule
    // MatProgressSpinnerModule
  ],
  declarations: [
    StiFileToBlobComponent
  ],
  entryComponents: [],
  exports: [
    StiFileToBlobComponent,
  ]
})
export class StiAutoUploadModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: StiAutoUploadModule,
    };
  }
 }
