<mat-drawer-container class="drawerConteiner" autosize>
    <mat-drawer #drawer class="mainDrawer" mode="side">
      <app-menu></app-menu>
    </mat-drawer>
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    
    <div class="row justify-content-center pl-0 pr-0 mt-3">
      <div class="col-11">
        <mat-card>
            <h3>Listado de cobros Sucursal "{{branchModel.main.name | titlecase}}"</h3>
            <mat-tab-group (selectedTabChange)="tabActive($event)">
                <mat-tab label="Cuentas por pagar"> 
                    <table class="table">
                        <thead class="thead-dark">
                          <tr>
                            <th># Orden</th>
                            <th>Fecha</th>
                            <th>Mesero</th>
                            <th>Total</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr  *ngFor="let foodOrder of foodOrderList2">
                            <th>{{foodOrder.main.numberOrden}}</th>
                            <th>{{foodOrder.main.dateFoodOrder | date:'dd/MM/yyyy'}}</th>
                            <td>{{foodOrder.system.waiter.system.physicalPerson.main.name}} {{foodOrder.system.waiter.system.physicalPerson.main.lastname}} {{foodOrder.system.waiter.system.physicalPerson.main.surname}}</td>
                            <th>{{foodOrder.main.total | currency}}</th>
                            <th>
                                 <button mat-icon-button color="primary" matTooltip="Editar" routerLink="/cashier/sale/{{foodOrder.main.uuid}}">
                                    <i class="material-icons">monetization_on</i>
                                </button>
                            </th>
                          </tr>
                        </tbody>
                      </table>

                </mat-tab>
                <!-- cuentas pagadas -->
                <mat-tab label="Cuentas pagadas">
                    <table class="table">
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col"># Orden</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Mesero</th>
                            <th scope="col">Total</th>
                            <th scope="col">Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr  *ngFor="let foodOrder of foodOrderList2">
                            <th>{{foodOrder.main.numberOrden}}</th>
                            <th>{{foodOrder.main.dateFoodOrder | date:'dd/MM/yyyy'}}</th>
                            <td>{{foodOrder.system.waiter.system.physicalPerson.main.name}} {{foodOrder.system.waiter.system.physicalPerson.main.lastname}} {{foodOrder.system.waiter.system.physicalPerson.main.surname}}</td>
                            <th>{{foodOrder.main.total | currency}}</th>
                            <th>

                            </th>
                          </tr>
                        </tbody>
                      </table>  

                </mat-tab>
              </mat-tab-group>
        </mat-card>
      </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-11">

        </div>
    </div>

</mat-drawer-container>

