import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { CompanyClientBranchUpdateComponent } from './company-client-branch-update/company-client-branch-update.component';
import { CompanyClientFoodCategoryUpdateComponent } from './company-client-food-category-update/company-client-food-category-update.component';
import { CompanyClientQrViewComponent } from './company-client-qr-view/company-client-qr-view.component';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-company-client-branch',
  templateUrl: './company-client-branch.component.html',
  styleUrls: ['./company-client-branch.component.css']
})
export class CompanyClientBranchComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  branchList = [];
  foodCategoryList = [];
  foodCategoryModel = {
    main: {
      uuid: '',
      name: ''
    },
    complement: {
      icon: ''
    },
    image: {
      file: '',
      fileType: '',
      fileName: ''
    },
    system: {
      status: true,
      branch: {
        main: {
          uuid: ''
        }
      }
    }
  }

  metadata = {
    branch: {
      main: {
        uuid: ''
      }
    },
    configurationQr: {
      main: {
        uuid: '',
        theme: '',
        restaurant: true,
        hotel: false,
        mexPago: true,
        payPal:true
      }
    },
  }

  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
  }

  ngOnInit() {
    this.getConfiguration();
    this.getBrachList().then((data: any) => {
      this.branchList = data.instanceList;
      if(this.branchList.length > 0){
        this.metadata.branch = this.branchList[0];
        this.getFoodCategoryList(this.metadata.branch).then((data: any) => {
          this.foodCategoryList = data.instanceList;
        });
      }
    });
  }

  getConfiguration() {
    this.loadingService.wait();
    this.session.getRequest('configurationQr:load', { system: {company: {main: {uuid: this.session.getSessionObject().main.uuid}}}}).subscribe(
      (data: any) => {
        this.metadata.configurationQr = data.object;
        this.loadingService.hide();
      }, error => {
        console.log("Error: configurationQr:load", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      });
  }

  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('branch:list', {max: 1000, offset: 0, system: {company: {main: {uuid: this.session.getSessionObject().main.uuid}}}}).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: branch:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  getFoodCategoryList(branch) {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('foodCategory:findAllByBranch', { max: 1000, offset: 0, sort: "mName",
      order: "asc",system: { branch: branch } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: foodCategory:findAllByBranch", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        })
    });
  }

  /**funcionalidad para dar de alta un restaurante
  */
  updateDialogFoodCategory(uuid) {
    this.dialog.open(CompanyClientFoodCategoryUpdateComponent, {
      width: '60%',
      data: {
        main: {
          uuid: uuid
        },
        system: {
          branch: this.metadata.branch
        }
      }
    }).afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // Validamos si el objecto existe
          let exist = true;
          for (let x=0; x<this.foodCategoryList.length; x++) {
            if (this.foodCategoryList[x].main.uuid == result.object.main.uuid ) {
              exist = false;
              this.foodCategoryList[x] = JSON.parse(JSON.stringify(result.object));
            }
          }
          if (exist) {
            this.foodCategoryList.push( JSON.parse(JSON.stringify(result.object)));
          }
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**funcionalidad para dar de alta un restaurante
  */
  updateDialog(uuid) {
    this.dialog.open(CompanyClientBranchUpdateComponent, {
      width: '60%',
      height: '90%',
      data: {
        main: {
          uuid: uuid
        }
      }
    }).afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          // Validamos si la sucursal no se encuentra ya en el arreglo
          let exist = true;
          for (let x=0; x<this.branchList.length; x++) {
            if (this.branchList[x].main.uuid == result.object.main.uuid) {
              this.branchList[x].main = result.object.main;
              exist = false;
            }
          }
          if (exist) {
            this.branchList.push(result.object);
          }
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**funcionalidad para dar de alta un restaurante
  */
  generateQrDialog(uuid) {
    this.dialog.open(CompanyClientQrViewComponent, {
      width: '40%',
      data: {
        main: {
          uuid: uuid
        }
      }
    }).afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.getBrachList();
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  setBranch(event) {
    // Cargas las áreas del lugar de acuerdo a sucursal seleccionada
    this.getFoodCategoryList(this.branchList[event.index]).then((data: any) => {
      this.metadata.branch = this.branchList[event.index];
      this.foodCategoryList = data.instanceList;
    });
  }

}
