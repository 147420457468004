<div class="row justify-content-center title-modal" style="margin-left: -24px;margin-right: -24px;">
  <div class="col-4 text-uppercase text-center p-3">
    <h4>Nuevo Restaurante</h4>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row">
      <mat-form-field class="col-4" appearance="outline">
        <mat-label>&nbsp;Código</mat-label>
        <input matInput placeholder="Código" name="mCode" [(ngModel)]="object.main.code" [formControl]="complexForm.controls['code']" >
        <mat-error *ngIf="complexForm.controls['code'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-8" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.main.name" [formControl]="complexForm.controls['name']" >
        <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-label class="col-12 mb-2"> <strong>Ingrese la direción de la sucursal</strong> </mat-label>
      <sti-address-component [stiAddress]="object.complement.address" class="col-12"></sti-address-component>
    </div>

    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <!-- {{complexForm.invalid}}  {{object.complement.address.system.locality.system.town.system.state.main.uuid == ''}} -->
        <button type="button" class="btn btn-danger btn-block" [disabled]=" complexForm.invalid || object.complement.address.system.state.main.uuid == '' || object.complement.address.main.fullAddress == '' "  (click)="send(object);">Aceptar</button>
      </div>
    </div>
  </div>
</div>
