<mat-drawer-container class="drawerConteiner" autosize>
    <mat-drawer #drawer class="mainDrawer" mode="side">
      <app-menu></app-menu>
    </mat-drawer>
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    
    <div class="row justify-content-center pl-0 pr-0 mt-3">
      <div class="col-10 text-right">
        <div class="row justify-content-end">
            <mat-form-field appearance="fill" class="col-4">
              <mat-label>Sucursal</mat-label>
              <mat-select [(ngModel)]="metadata.branch.main.uuid" (ngModelChange)="changeBranch();" disabled>
                <mat-option *ngFor="let branch of branchList" [value]="branch.main.uuid">
                  {{branch.main.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

    
        
      </div>
  
    </div>
    <div class="row justify-content-center">
        <div class="col-11">
          <mat-tab-group (selectedTabChange)="setTab($event)">
            <mat-tab>
              <ng-template label="Preparacion" mat-tab-label>
                Preparación
              </ng-template>
              <div class="row mt-2">
                <div class="pr-0" [ngClass]="qrConfiguration.main.carryOut ? 'col-6':'col-12'">
                  <div class="row">
                    <div class="col-7">
                      <h3 class="text-uppercase"><strong>Pedidos en sitio</strong></h3>
                    </div>
                    <div class="col-5 text-center">
                      <h3><strong>Fecha: </strong>{{metadata.dateFoodOrder}}</h3>
                    </div>
                  </div>
                  <mat-card>
                    <table class="table">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Orden</th>
                          <th scope="col">
                            <span *ngIf="qrConfiguration.main.hotel">Habitación</span>
                            <span *ngIf="!qrConfiguration.main.hotel">Mesa</span>
                          </th>
                          <th scope="col">Area</th>
                          <th scope="col">Platillos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let foodOrder of foodOrderList" >
                          <th scope="row">{{foodOrder.main.numberOrden}}</th>
                          <th>{{foodOrder.system.foodTable.main.number}}</th>
                          <th>{{foodOrder.system.foodTable.system.restaurantArea.main.name}}</th>
                          <td>{{foodOrder.system.foodOrderDetails.length}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </mat-card>
                </div>
                <div class="col-6" *ngIf="qrConfiguration.main.carryOut">
                  <div class="row">
                    <div class="col-7">
                      <h3 class="text-uppercase"><strong>Pedidos para llevar</strong></h3>
                    </div>
                    <div class="col-5 text-center">
                      <h3><strong>Hora: </strong>{{metadata.hourFoodOrder}}</h3>
                    </div>
                  </div>
                  <mat-card >
                    <table class="table">
                      <thead class="bg-warning">
                        <tr>
                          <th scope="col">Orden</th>
                          <th scope="col">Hora Solicitud</th>
                          <th scope="col">Platillos</th>
                          <th scope="col">Tipo de Entrega</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="cursor: pointer"  *ngFor="let foodOrder of foodOrderCarryOutList" >
                          <th scope="row">{{foodOrder.main.numberOrden}}</th>
                          <td>{{foodOrder.main.dateFoodOrder | date:"HH:mm:ss"}}</td>
                          <td>{{foodOrder.system.foodOrderDetails.length}}</td>
                          <td>Recoger en sitio</td>
                        </tr>
                      </tbody>
                    </table>
                  </mat-card>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                En entrega
              </ng-template>
              <div class="row mt-2">
                <div class="pr-0" [ngClass]="qrConfiguration.main.carryOut ? 'col-6':'col-12'">
                  <div class="row">
                    <div class="col-7">
                      <h3 class="text-uppercase">Pedidos en sitio</h3>
                    </div>
                    <div class="col-5 text-center">
                      <h3><strong>Fecha: </strong>{{metadata.dateFoodOrder}}</h3>
                    </div>
                  </div>
                  <mat-card>
                    <table class="table">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Orden</th>
                          <th scope="col">
                            <span *ngIf="qrConfiguration.main.hotel">Habitación</span>
                            <span *ngIf="!qrConfiguration.main.hotel">Mesa</span>
                          </th>
                          <th scope="col">Área</th>
                          <th scope="col">Platillos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let foodOrder of foodOrderList" >
                          <th scope="row">{{foodOrder.main.numberOrden}}</th>
                          <th>{{foodOrder.system.foodTable.main.number}}</th>
                          <th>{{foodOrder.system.foodTable.system.restaurantArea.main.name}}</th>
                          <th>{{foodOrder.system.foodOrderDetails.length}}</th>
                        </tr>
                      </tbody>
                    </table>
                  </mat-card>
                </div>
                <div class="col-6" *ngIf="qrConfiguration.main.carryOut">
                  <div class="row">
                    <div class="col-7">
                      <h3 class="text-uppercase">Pedidos para llevar</h3>
                    </div>
                    <div class="col-5 text-center">
                      <h3><strong>Fecha: </strong>{{metadata.dateFoodOrder}}</h3>
                    </div>
                  </div>
                  <mat-card >
                    <table class="table">
                      <thead class="bg-warning">
                        <tr>
                          <th scope="col">Orden</th>
                          <th scope="col">Hora Solicitud</th>
                          <th scope="col">Platillos</th>
                          <th scope="col">Tipo de Entrega</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="cursor: pointer"  *ngFor="let foodOrder of foodOrderCarryOutList" >
                          <th scope="row">{{foodOrder.main.numberOrden}}</th>
                          <td>{{foodOrder.main.dateFoodOrder | date:"HH:mm:ss"}}</td>
                          <td>{{foodOrder.system.foodOrderDetails.length}}</td>
                          <td>Recoger en sitio</td>
                        </tr>
                      </tbody>
                    </table>
                  </mat-card>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                Concluidos
              </ng-template>
              <div class="row mt-2">
                <div class="pr-0" [ngClass]="qrConfiguration.main.carryOut ? 'col-6':'col-12'">
                  <div class="row">
                    <div class="col-7">
                      <h3 class="text-uppercase">Pedidos en sitio</h3>
                    </div>
                    <div class="col-5 text-center">
                      <h3><strong>Fecha: </strong>{{metadata.dateFoodOrder}}</h3>
                    </div>
                  </div>
                  <mat-card>
                    <table class="table">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Orden</th>
                          <th scope="col">
                            <span *ngIf="qrConfiguration.main.hotel">Habitación</span>
                            <span *ngIf="!qrConfiguration.main.hotel">Mesa</span>
                          </th>
                          <th scope="col">Área</th>
                          <th scope="col">Platillos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let foodOrder of foodOrderList">
                          <th scope="row">{{foodOrder.main.numberOrden}}</th>
                          <th>{{foodOrder.system.foodTable.main.number}}</th>
                          <th>{{foodOrder.system.foodTable.system.restaurantArea.main.name}}</th>
                          <th>{{foodOrder.system.foodOrderDetails.length}}</th>
                        </tr>
                      </tbody>
                    </table>
                  </mat-card>
                </div>
                <div class="col-6" *ngIf="qrConfiguration.main.carryOut">
                  <div class="row">
                    <div class="col-7">
                      <h3 class="text-uppercase">Pedidos para llevar</h3>
                    </div>
                    <div class="col-5 text-center">
                      <h3><strong>Fecha: </strong>{{metadata.dateFoodOrder}}</h3>
                    </div>
                  </div>
                  <mat-card >
                    <table class="table">
                      <thead class="bg-warning">
                        <tr>
                          <th scope="col">Orden</th>
                          <th scope="col">Hora Solicitud</th>
                          <th scope="col">Platillos</th>
                          <th scope="col">Tipo de Entrega</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="cursor: pointer"  *ngFor="let foodOrder of foodOrderCarryOutList">
                          <th scope="row">{{foodOrder.main.numberOrden}}</th>
                          <td>{{foodOrder.main.dateFoodOrder | date:"HH:mm:ss"}}</td>
                          <td>{{foodOrder.system.foodOrderDetails.length}}</td>
                          <td>Recoger en sitio</td>
                        </tr>
                      </tbody>
                    </table>
                  </mat-card>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
  
  </mat-drawer-container>
