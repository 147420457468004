<div class="container">
  <div class="row">
    <div class="col-12 mt-4">
      <p>Click en el combo para agregar la información de contacto:</p>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-2 text-center" *ngFor = "let object of contactsType">
      <a mat-menu-item (click) = "select(object.name);" matTooltip="{{object.name}}" >
        <mat-icon style="margin-right:0px;">{{object.icon}}</mat-icon>
      </a>
    </div>
  </div> -->
  <div class="row">
    <div class="col-12">
      <button mat-button [matMenuTriggerFor]="menu" class="button-header">
        Seleccione una forma de contacto
        <i class="material-icons">
          expand_more
        </i>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor = "let object of contactsType" (click) = "select(object.name);" class="menu-items">{{object.name}}</button>
      </mat-menu>
    </div>
  </div>
  <br /><br />
  <div class="row justify-content-left">
    <div class="col-12" *ngFor = "let object of contacts; let i = index">
      <mat-form-field class="full-width md-block md-icon-left">
        <div matPrefix>
          <span *ngIf= "object.main.type == 'URL'" style="color:#A20e5e;">http://</span>
          <mat-icon *ngIf = "object.main.type =='Telefono de trabajo'" style="color:#A20e5e;">contact_phone</mat-icon>
          <mat-icon *ngIf = "object.main.type =='Telefono de casa'" style="color:#A20e5e;">phone</mat-icon>
          <mat-icon *ngIf = "object.main.type =='Celular'" style="color:#A20e5e;">phone_iphone</mat-icon>
          <mat-icon *ngIf = "object.main.type =='Email'" style="color:#A20e5e;">email</mat-icon>
          <mat-icon *ngIf = "object.main.type =='Fax'" style="color:#A20e5e;">insert_drive_file</mat-icon>
          <mat-icon *ngIf = "object.main.type =='Twitter'" class="fab fa-twitter" style="color:#A20e5e;"></mat-icon>
          <mat-icon *ngIf = "object.main.type =='Facebook'" class="fab fa-facebook" style="color:#A20e5e;"></mat-icon>
          <mat-icon *ngIf = "object.main.type =='Skype'" class="fab fa-skype" style="color:#A20e5e;"></mat-icon>
          <mat-icon *ngIf = "object.main.type =='Otro'" style="color:#A20e5e;">whatshot</mat-icon>
          &nbsp;&nbsp;
        </div>
        <input matInput placeholder="{{object.main.type}}" [(ngModel)] = "object.main.data">
        <i  matTooltip="Eliminar" [matTooltipPosition]="'above'" style="cursor: pointer; cursor: hand; color:#e34a32;" class="material-icons" matSuffix (click)= "removeContact(i, stiDisable);">delete_forever</i>
      </mat-form-field>
    </div>
  </div>
</div>
