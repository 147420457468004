import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { SessionService } from '../service/session/session.module';

export interface menu {
    route: string
  }
@Injectable()
export class MenuService {
  object = {
    route:''
  };

  metadata = {
    typeMenu:''
  }

  menuChange: Subject<menu> = new Subject<menu>();
  constructor(private session:SessionService) {
    this.menuChange.next(this.object);
   }

   selected(value){
     this.object.route = value;
     this.menuChange.next(this.object);
     sessionStorage.setItem('router', value);
   }
   getSubMenuSelected(){
     return Observable.create( observer => {
       observer.next(this.object.route)
     });
   }
   clean(){
     this.object.route = '';
     this.menuChange.next(this.object);
   }

   /** función para setear el tipo de menu a mostrar
   */
   setMenuType(object:string):void {
     sessionStorage.setItem('menuType', object);
     this.metadata.typeMenu = object;
   }

   /** funcion para obtener el tipo de menu a mostrar
   */
   getMenuType():string{
     this.metadata.typeMenu = sessionStorage.getItem('menuType');
     return this.metadata.typeMenu;
   }

   /** funcion para determinar el tipo de menu a rederizar
   */
   findMyMenu(){
     let array = [];
     switch(this.metadata.typeMenu){
       case 'admin':{
         array = [
           { route: '/admin/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
           { route: '/admin/users', title:'Usuarios', icon:'face', open:false, options:[], selected:false },
           // { route: '/admin/roles', title:'Roles', icon:'face', open:false, options:[], selected:false },
           { route: '/admin/companyClient', title:'Clientes', icon:'supervised_user_circle', open:false, options:[], selected:false },
         ];
         break;
       }
       case 'companyClient':{
         array = [
           { route: '/companyClient/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
           { route: '/branch/kitchen', title:'Cocina', icon:'fastfood', open:false, options:[], selected:false },
           { route: '/companyClient/products', title:'Productos', icon:'restaurant_menu', open:false, options:[], selected:false },
           { route: '/companyClient/branch', title:'Sucursales', icon:'add_business', open:false, options:[], selected:false },
           { route: '/companyClient/waiter', title:'Meseros', icon:'person', open:false, options:[], selected:false },
           { route: '/companyClient/chef', title:'Cocineros', icon:'person', open:false, options:[], selected:false },
           { route: '/companyClient/bar', title:'Gestion de Barras', icon:'storefront', open:false, options:[], selected:false },
           // { route: '/companyClient/settings', title:'Configuracion', icon:'settings', open:false, options:[
           //   {route:'', title:'', icon:'', open:false, options:[], selected:false},
           // ], selected:true },
         ];
         // Obtenemos la configuración
         this.session.getRequest("configurationQr:get",{system:{company:{main:{uuid: this.session.getSessionObject().main.uuid}}}}).subscribe(
           (data:any) => {
             if (data.object.main.restaurant == true) {
               array.push({ route: '/companyClient/restaurantArea', title:'Áreas del restaurant', icon:'pin_drop', open:false, options:[], selected:false });
             }
             if (data.object.main.hotel == true) {
               array.push({ route: '/companyClient/hotel', title:'Áreas del Hotel', icon:'apartment', open:false, options:[], selected:false });
             }
             if (data.object.main.promotionImage == true) {
               array.push({ route: '/companyClient/promotions', title:'Promociones', icon:'new_releases', open:false, options:[], selected:false });
             }
             array.push({ route: '/companyClient/configuration', title:'Configuración', icon:'settings', open:false, options:[], selected:false });
           }, error => {
             console.log("Error: configurationQr:get",error);
           });
         break;
       }
       case 'branch':{
         array = [
           { route: '/companyClient/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
           { route: '/branch/kitchen', title:'Cocina', icon:'fastfood', open:false, options:[], selected:true },
           // { route: '/companyClient/settings', title:'Configuracion', icon:'settings', open:false, options:[
           //   {route:'', title:'', icon:'', open:false, options:[], selected:false},
           // ], selected:true },
         ];
         break;
       }
       case 'waiter':{
        array = [
          //{ route: '/companyClient/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
          //{ route: '/waiter/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
          { route: '/waiter/dashboard', title:'Comandas', icon:'list_alt', open:false, options:[], selected:true },
        ];
        break;
      }
      case 'kitchen':{
        array = [
          { route: '/kitchen/dashboard', title:'Cocina', icon:'menu_book', open:false, options:[], selected:true },
          { route: '/kitchen/bar', title:'Barra', icon:'storefront', open:false, options:[], selected:true },

          
        ];
        break;
      }

      case 'cashier':{
        array = [
          { route: '/cashier/dashboard', title:'Caja', icon:'payments', open:false, options:[], selected:true }, 
        ];
        break;
      }
       default:{
         // no hacemos nada
         array = []
         break;
       }
     }
     return array;
   }

}
