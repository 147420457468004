import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-company-client-bar-update',
  templateUrl: './company-client-bar-update.component.html',
  styleUrls: ['./company-client-bar-update.component.css']
})
export class CompanyClientBarUpdateComponent implements OnInit {
  complexForm: FormGroup;

  barModel = {
    main: {
      uuid: '',
      name: '',
    },
    system: {
      branch: {
        main: {
          uuid: ''
        }
      },
    }
  };



  object = JSON.parse(JSON.stringify(this.barModel));


  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyClientBarUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    });
  }

  ngOnInit(): void {
    this.object.system.branch.main.uuid = this.data.system.branch.main.uuid;
    // Obtenemos las sucursales del restaurante
    if (this.data.main.uuid != 'new') {
      this.loadingService.wait();
      this.object.main.uuid = this.data.main.uuid;
      // cargamos los datos de la BD
      this.session.getRequest('bar:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.loadingService.hide();
      }, (error) => {
          this.loadingService.hide();
          console.log('Error: bar:get', error)
      });
    }
  }

  /**
   * funcion para guardar una barra
   */
  send(object) {
    console.log("barras",object),
    this.loadingService.show(true, 'Guardando registro');
    this.session.getRequest("bar:update", object).subscribe(
      (data: any) => {
        this.object.main = data.object.main
        this.snackBar.open('La barra del restaurante se guardo correctamente', '', {
          duration: 1000
        });
        this.loadingService.hide();
        this.close(true);
      }, error => {
        console.log("Error: bar:update", error);
        this.loadingService.hide();
      });
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.code = 'bar:success:001';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'bar:error:001';
    }
    this.dialogRef.close(object);
  }

}
