import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { SessionService } from '../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  
  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {

  }

  ngOnInit() {
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
