<div class="row justify-content-center title-modal" >
  <div class="col-12 text-uppercase text-center">
    <h4>Alta de promociones</h4>
  </div>
</div>
<hr/>
<div class="row justify-content-center">
  <div class="col-11">
    <sti-file-directive [stiFile]="metadata.image"></sti-file-directive>
  </div>
<div class="col-11">
  <p> <strong>Selecciona una ó varias sucursales</strong> </p>
  <mat-selection-list #matselectionlist (selectionChange)="selectedBranch($event);">
    <mat-list-option *ngFor="let object of branchList" [value]="object.main.uuid">
      {{object.main.name}}
    </mat-list-option>
  </mat-selection-list>
  <div class="pt-2">
    <button  mat-raised-button color="accent" (click)="savePromotiosWitoutImage();" style="width:50%;" [disabled]="metadata.image.name == ''">Agregar</button>
  </div>
</div>
<div class="col-12 p-2">
  <p> <strong> Listado de imagenes cargando</strong></p>
</div>
<table class="table table-general">
  <thead class="thead-dark">
    <tr>
      <th scope="col">Sucursal</th>
      <th scope="col">Nombre imagen</th>
      <th scope="col">Proceso</th>
    </tr>
    <tr *ngFor="let object of promotionList">
      <td>{{object.system.branch.main.name}}</td>
      <td>{{object.file.name}}</td>
      <td>
        <sti-auto-upload [stiFile] = "object.file" [uploadMethod]="'promotionImage:upload'" [downloadMethod]="'promotionImage:download'" ></sti-auto-upload>
      </td>
    </tr>
  </thead>

</table>


</div>
<div class="row justify-content-end">
  <div class="col-6">
    <button  mat-raised-button color="accent" (click)="close(true);" style="width:100%;">Cerrar</button>
  </div>
</div>
