<mat-drawer-container class="drawerConteiner font-Titilliumr" autosize style="background: #efebde;">
  <mat-drawer #drawer class="mainDrawwerRetro" mode="push" position="end">
    <app-restaurant-retro-menu-collapsed></app-restaurant-retro-menu-collapsed>
  </mat-drawer>
  <div class="row img-home-retro mr-0">
    <div class="col-12">

      <div class="row justify-content-center font-Titilliumb" style="background:#375b43;">
        <div class="col-3 pr-0" routerLink="/restaurant-retro">
          <button mat-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
            <mat-icon class="pt-2 text-white" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center pt-3" >
          <h2 class="text-white align-middle mb-0 font-Titilliumb" style="line-height: 1.2rem;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2 pl-0 pr-0">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-retro/order">
            <mat-icon class="text-white">receipt</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-white" style="font-size: 2rem;">dehaze</mat-icon>
          </button>
        </div>
      </div>



      <div class="row home-restaurant font-Titilliumr mt-1">
        <div class="col-12 pr-0">
          <ngb-carousel *ngIf="object.main.promotionImage">
            <ng-template ngbSlide *ngFor="let object of promotionList">
              <div class="picsum-img-wrapper">
                <img [src]="object.image" alt="promo" width="100%" *ngIf="object.image">
                <div class="sk-circle" *ngIf="object.image == undefined">
                  <div class="sk-circle1 sk-child"></div>
                  <div class="sk-circle2 sk-child"></div>
                  <div class="sk-circle3 sk-child"></div>
                  <div class="sk-circle4 sk-child"></div>
                  <div class="sk-circle5 sk-child"></div>
                  <div class="sk-circle6 sk-child"></div>
                  <div class="sk-circle7 sk-child"></div>
                  <div class="sk-circle8 sk-child"></div>
                  <div class="sk-circle9 sk-child"></div>
                  <div class="sk-circle10 sk-child"></div>
                  <div class="sk-circle11 sk-child"></div>
                  <div class="sk-circle12 sk-child"></div>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>


      <div class="row justify-content-center  p-0 mt-5 font-Titilliumb" style="color: #be7d10;"  *ngIf="object.main.whitImage">
        <div class="col-12 text-center p-0" *ngFor="let foodCat of foodCategoryList" routerLink="/restaurant-retro/menu/{{foodCat.main.uuid}}">
          <img src="/assets/img/{{object.main.theme}}/{{foodCat.complement.icon}}" class="" style="width: 4rem; background: #be7d26;">
          <h2 class="font-Titilliumb text-verde">{{foodCat.main.name}}</h2>
        </div>
      </div>
      <div class="row justify-content-center  p-0 mt-5 font-Titilliumb" style="color: #be7d10;" *ngIf="!object.main.whitImage">
        <div class="col-6 text-center p-0" *ngFor="let foodCat of foodCategoryList"  routerLink="/restaurant-retro/menu-table/{{foodCat.main.uuid}}">
          <img src="/assets/img/{{object.main.theme}}/{{foodCat.complement.icon}}" class="" style="width: 4rem; background: #be7d26;">
          <h3 class="font-Titilliumb text-verde">{{foodCat.main.name}}</h3>
        </div>
      </div>

      <footer class="text-center text-secondary p-1">
      © 2019 Keysoft. Reservados todos los derechos.
      </footer>
    </div>
  </div>
</mat-drawer-container>
