import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { MessagingService } from '../../pushNotificationService/messaging.service';

@Component({
  selector: 'app-restaurant-country-order',
  templateUrl: './restaurant-country-order.component.html',
  styleUrls: ['./restaurant-country-order.component.css']
})
export class RestaurantCountryOrderComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  foodOrder:any = {
    main:{
      uuid: '',
      carryOut:  false, // true para llevar, false para en sitio
      dateFoodOrder: '', // Fecha y hora del medido
      status: 'Abierta', // Abierta, Preparacion, En entrega, Concluida
      subTotal: 0.0, // Subtotal a pagar
      tax: 0.0, // Impuestos a pagar
      total: 0.0, // //Total a pagar,
      numberOrder:'',
      paymentMethod:'Pago en Efectivo'
    },
    system:{
      foodOrderDetails:[],
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      objectType:'',
      objectUuid:'',
    }
  }

  metadata={
    name:'',
  };
  qrConfiguration = {
    main: {
      uuid: '',
      theme: '',
      restaurant: true,
      hotel: false,
      carryOut: false,
      mexPago: false,
      payPal: false,
      promotionImage:false,
    },
    complement: {
      SbClientId: ''
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      }
    }
  }
  message;
  constructor(private session: SessionService, private router: Router, private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private _location: Location,private messagingService: MessagingService) { }

  ngOnInit(): void {
    this.qrConfiguration = this.session.getConfigurationQr();
    let branch = this.session.getAnonymousSessionBranch();
    this.metadata.name = branch.system.company.main.tradename;
    if(localStorage.getItem("foodOrder")){
      this.foodOrder = JSON.parse(localStorage.getItem("foodOrder"));
      this.session.getRequestAnonymous("foodOrder:get",this.foodOrder).subscribe((data:any)=>{
        this.foodOrder = data.object;
        localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
        // console.log(this.foodOrder);
      },error=>{
      })
    }else{
      // console.log("No hay que mostrar")
    }
  }
  /**función para activar las notificaciones
  */
  activateNot(){
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }

  /**función para regrsar a la pantalla anterior
  */
  back(){
    this._location.back();
  }
  /**funcionalidad para enviar a cocinar una orden
  */
  sendToFoodOrderToCook(){
    this.foodOrder.main.status = 'Preparacion';
    this.foodOrder.main.dateFoodOrder = moment().format('DD/MM/YYYY HH:mm:ss');
    this.session.getRequestAnonymous("foodOrder:update",this.foodOrder).subscribe((data:any)=>{
      this.foodOrder = data.object;
      localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
      // console.log(this.foodOrder);
      this.snackBar.open("Gracias por ordenar, Tu orden se envio a preparar.", 'Aceptar', {duration: 8000});
      localStorage.setItem("foodOrder", '');
      this.router.navigate(['/restaurant-country/home']);
    },error=>{
      console.log(error);
    })
  }

  /**funcionalidad para enviar al formulario de pago
  */
  sendToPayment(){
    localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
    this.router.navigate(['/restaurant-country/order/payment'])
  }
  /*
  * funcion para abrir o cerrar le menu
  */
  toogleMenuRestaurant(ev){
    this.drawerCtrl.opened = ev;
  }

}
