<mat-drawer-container class="drawerConteiner" autosize>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0 mt-2">
    <div class="col-md-11 col-12">
      <mat-card>
        <div class="row pt-2">
          <div class="col-3 text-center ">
            <h3>Fecha: <span class="text-orange">{{metadata.dateFoodOrder}}</span> - Hora: <span class="text-orange">{{metadata.hourFoodOrder}}</span></h3>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <h2 class="">
              Área: <span class="text-info">{{foodTable.system.restaurantArea.main.name}}</span>
            </h2>
          </div>

          <div class="col-2">
            <h2>Mesa: <span class="text-info">No. {{foodTable.main.number}}</span></h2>
          </div>
          <div class="col-3"></div>
          <div class="col-1">
            <button type="button" mat-button style="width:100%; color:#2b6783; text-align:right;" routerLink="/waiter/dashboard">
              Regresar
            </button>
          </div>
        </div>
      </mat-card>
      <div class="row mt-3 justify-content-end">
        <mat-form-field class="col-md-2 col-4" appearance="outline">
          <mat-label>Comensales</mat-label>
          <input matInput type="number" placeholder="Comensales" name="nPersons"
                 [(ngModel)]="metadata.numberPerson" (change)="loadPeople(metadata.numberPerson)">
        </mat-form-field>
        <mat-form-field class="col-md-4 col-8" appearance="outline">
          <mat-label>¿Quién atiende?</mat-label>
          <mat-select placeholder="Meseros del Restaurante" name="mName"
                      [(ngModel)]="object.system.waiter.main.uuid" required>
            <mat-option *ngFor="let object of waiterList" [value]="object.main.uuid">
              {{object.system.physicalPerson.main.name + ' ' + object.system.physicalPerson.main.lastname + ' ' + object.system.physicalPerson.main.surname}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-md-6 col-12 category">
          <mat-tab-group (selectedTabChange)="tabActive($event)" [selectedIndex]="selectedIndex" id="mat-tab-order">
            <mat-tab *ngFor="let object of ordenes; let index = index" [label]="object">
              <ng-template mat-tab-label >
                <mat-icon class="example-tab-icon">face</mat-icon>
                <!-- First -->
              </ng-template>
              <!--Cargamos categorias de productos-->
              <div class="row mt-2">
                <div class="col-md-4 col-6" *ngFor="let object of foodCategoryList;let i=index;">
                  <mat-card class="text-center btn-orange" (click)="getFoodSaucer(object);">
                    <h4>{{object.main.name}}</h4>
                  </mat-card>
                </div>
              </div>
              <!--Cargamos productos-->
              <div class="row mt-2">
                <div class="col-md-4 col-6" *ngFor="let object of foodSaucerList; let i=index;">
                  <!-- <mat-card (click)="product(object)"> -->
                    <mat-card (click)="addFoodOrderDetails(object)">
                    <img *ngIf="!object.system.foodSaucer.main.image" alt="Sin imagen" mat-card-image
                         src="assets/img/not-image.jpg" class="img-product">
                    <img *ngIf="object.system.foodSaucer.main.image" alt="promo" mat-card-image
                         [src]="object.system.foodSaucer.image" class="img-product">
                    <mat-card-content>
                      <p style="text-align: center;"><strong>{{object.system.foodSaucer.main.name}}</strong></p>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="col-md-6 col-12 mt-1" *ngIf="ordenes.length > 0">
          <mat-card>
            <table class="table table-general">
              <thead class="thead-dark">
              <tr>
                <th scope="col">Platillo</th>
                <th scope="col">Cant.</th>
                <th scope="col">Anotación</th>
                <th scope="col"></th>
                <!--<th scope="col">Precio</th>
                <th scope="col">Subtotal</th>
                 <th>iVA</th> -->
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let foodOrderDetail of object.system.foodOrderDetails; let x = index;">
                <td>
                  {{foodOrderDetail.system.foodSaucer.main.name}}
                </td>
                <td>
                  {{foodOrderDetail.main.quantity}}
                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Anotación</mat-label>
                    <input matInput type="text" placeholder="Antonación" name="nPersons" [(ngModel)]="foodOrderDetail.main.observation" >
                  </mat-form-field>
                </td>
                <td>
                  <button mat-icon-button color="warn" matTooltip="Eliminar producto" (click)="deleteFoodSaucer(foodOrderDetail, x);">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
                <!--<td>
                  {{foodOrderDetail.main.priceUnitary | currency:'CAD':'symbol-narrow'}}
                </td>
                <td>
                  {{foodOrderDetail.main.subTotal | currency:'CAD':'symbol-narrow'}}
                </td>-->

              </tr>
              </tbody>
              <tfoot style="background-color: #ecd044;">
              <tr>
                <td>Total de platillos:</td>
                <td>{{metadata.totalFoodSaucers}}</td>

              </tr>

              </tfoot>
            </table>
            <div class="row justify-content-end mt-2 mb-2">
              <div class="col-md-3 col-4">
                <button type="button" class="btn btn-ll btn-block" color="warn" style="width:100%"
                  (click)="charge(object);" 
                  [disabled]="object.main.uuid == '' ">Cobrar
                </button>
              </div>
              <div class="col-md-3 col-4">
                <button type="button" class="btn btn-warn btn-block" color="warn" style="width:100%"
                  (click)="commandBar(object);"
                  [disabled]="object.main.uuid == '' ">
                  Ver Comanda
                </button>
              </div>
              <div class="col-md-3 col-4">
                <button type="button" class="btn btn-danger btn-block" color="warn" style="width:100%"
                  (click)="send(object);"
                  [disabled]="object.system.waiter.main.uuid == '' || object.system.foodOrderDetails.length == 0">
                  Guardar
                </button> 
              </div>
            </div>
          </mat-card>

        </div>
      </div>

    </div>
  </div>

</mat-drawer-container>



