<div class="row justify-content-center title-modal" style="margin-left: -24px;margin-right: -24px;">
  <div class="col-4 text-uppercase text-center p-3">
    <h4>Nuevo Usuario</h4>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row">
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Correo electrónico</mat-label>
        <input matInput type="email" placeholder="Correo electrónico" name="userUsername"  [(ngModel)]="object.main.username" [formControl]="complexForm.controls['username']" required>
        <mat-error *ngIf="complexForm.controls['username'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <div *ngIf="object.main.uuid==''">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" placeholder="Contraseña" name="userPassword"  [(ngModel)]="object.main.password" [formControl]="complexForm.controls['password']" required>
          <mat-error *ngIf="complexForm.controls['password'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" placeholder="Confirmar Contraseña" name="userPasswordConfirm"  [(ngModel)]="object.main.passwordConfirm" [formControl]="complexForm.controls['passwordConfirm']" required>
          <mat-error *ngIf="complexForm.controls['passwordConfirm'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Selecciona el tipo de usuario</mat-label>
          <mat-select placeholder="Cliente" [(ngModel)]="object.system.objectUuid" name="sObjectUuid" required>
            <mat-option *ngFor="let object of companyList" [value]="object.main.uuid">
              {{ object.main.tradename }}
            </mat-option>
            <mat-option [value]="metadata.company.main.uuid">Administrador</mat-option> 
          </mat-select>
        </mat-form-field>



        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" name="mName"  [(ngModel)]="object.system.physicalPerson.main.name" [formControl]="complexForm.controls['name']" required>
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6" appearance="outline">
          <mat-label>Apellido Paterno</mat-label>
          <input matInput placeholder="Apellido Paterno" name="mSurname" [(ngModel)]="object.system.physicalPerson.main.surname" [formControl]="complexForm.controls['surname']" >
          <mat-error *ngIf="complexForm.controls['surname'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6" appearance="outline">
          <mat-label>Apellido Materno</mat-label>
          <input matInput placeholder="Apellido Materno" name="mLastname" [(ngModel)]="object.system.physicalPerson.main.lastname" [formControl]="complexForm.controls['lastname']" >
          <mat-error *ngIf="complexForm.controls['lastname'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6" appearance="outline">
          <mat-label>CURP</mat-label>
          <input matInput placeholder="CURP" name="mcurp" [(ngModel)]="object.system.physicalPerson.complement.curp" [formControl]="complexForm.controls['curp']" >
          <mat-error *ngIf="complexForm.controls['curp'].hasError('pattern')">
            Algo anda mal verifique su <strong>RFC</strong>.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6" appearance="outline">
          <mat-label>RFC</mat-label>
          <input matInput placeholder="RFC" name="mRfc" [(ngModel)]="object.system.physicalPerson.complement.rfc" [formControl]="complexForm.controls['rfc']" >
          <mat-error *ngIf="complexForm.controls['rfc'].hasError('pattern')">
            Algo anda mal verifique su <strong>RFC</strong>.
          </mat-error>
        </mat-form-field>

        <mat-radio-group name="sPhysicalPerson" [(ngModel)]="object.system.physicalPerson.complement.gender" class="p-2">
          <mat-radio-button value="Femenino"> Femenino</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="Masculino"> Masculino</mat-radio-button>
        </mat-radio-group>

    </div>


    <div class="col-12 row justify-content-end mt-4">
      <div class="col-4">
        <button type="button" style="width:100%" class="btn btn-danger btn-block" [disabled]="object.system.physicalPerson.main.name =='' || object.system.physicalPerson.main.surname =='' || object.system.physicalPerson.main.lastname =='' || object.system.physicalPerson.complement.gender ==''" (click)="send(object);">Aceptar</button>
      </div>
    </div>
  </div>
</div>
