
<div class="upload-btn-wrapper" *ngIf="!stiFile.name">
  <mat-label class="mt-1">Seleccione una imagen:</mat-label>
  <button class="btn ml-4"><i class="material-icons">cloud_upload</i>&ensp;</button>
  <input type="file" name="myfile" (change)="onFileChange($event)" #fileInput />
</div>
<div class="" *ngIf="stiFile.name">
  <mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar>
        <button mat-icon-button color="primary" matTooltip="Quitar" (click)="deleteAFile();"><i class="material-icons">clear</i></button>
      </div>
      <mat-card-title>{{stiFile.name}}</mat-card-title>
    </mat-card-header>
    <!-- <mat-card-actions>
      <button mat-button color="accent" matTooltip="Descargar" style="left: 60%;" (click)="downloadAFile();">DESCARGAR</button>
    </mat-card-actions> -->
  </mat-card>
</div>
