import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as moment from 'moment';
import { interval, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-branch-kitchen',
  templateUrl: './branch-kitchen.component.html',
  styleUrls: ['./branch-kitchen.component.css']
})
export class BranchKitchenComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  @ViewChild('panel', { static: false }) panel: MatDrawer;

  foodOrderCarryOutList = [];
  foodOrderList = [];
  branchList = [];
  metadata = {
    dateFoodOrder: moment().format('DD/MM/YYYY'),
    hourFoodOrder: moment().format('HH:mm:ss'),
    status: 0,
    branch: {
      main: {
        uuid: ''
      }
    },
    onece:true,
    object:{
      main:{
        uuid:''
      }
    }
  }
  interval:any;
  counter = interval(10000); // se emite cada 10 segundos
  counter$: Subscription
  qrConfiguration = {
    main: {
      uuid: '',
      theme: '',
      restaurant: true,
      hotel: false,
      carryOut: false,
      mexPago: false,
      payPal: false,
      promotionImage:false,
    },
    complement: {
      SbClientId: ''
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.getConfigurationQr();
    this.getBrachList().then((data: any) => {
      this.branchList = data.instanceList;
      if(this.branchList.length > 0) {
        this.metadata.branch = this.branchList[0];
        this.setTab({index:0})
        this.startTimer();
      }
    });
  }

  getFoodOrder(carryOut, status) {
    return new Promise((resolve) => {
      // this.loadingService.wait();
      this.session.getRequest('foodOrder:findAllByBranchAndCarryOutAndStatusAndDateFoodOrderBetween', { main: { carryOut: carryOut, status: status, dateFoodOrderStart: moment().format('DD/MM/YYYY HH:mm:ss'), dateFoodOrderEnd: moment().format('DD/MM/YYYY HH:mm:ss') }, system: { branch: this.metadata.branch } }).subscribe(
        (data: any) => {
          // this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: foodOrder:findAllByBranchAndCarryOutAndStatusAndDateFoodOrderBetween", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          // this.loadingService.hide();
        });
    });
  }

  getBrachList() {
    return new Promise((resolve) => {
      // this.loadingService.wait();
      this.session.getRequest('branch:list', {max: 1000, offset: 0, system: {company: {main: {uuid: this.session.getSessionObject().main.uuid}}}}).subscribe(
        (data: any) => {
          // this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: branch:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          // this.loadingService.hide();
        });
    });
  }

  setTab(selectTab) {
    switch (selectTab.index) {
      case 0:
        this.metadata.status = 0;
        this.getFoodOrder(true, 'Preparacion').then((data:any) => {
          this.foodOrderCarryOutList = data.instanceList;
        });
        this.getFoodOrder(false, 'Preparacion').then((data:any) => {
          this.foodOrderList = data.instanceList;
          // console.log('food***', this.foodOrderList);
        });
      break;
      case 1:
        this.metadata.status = 1;
        this.getFoodOrder(true, 'En entrega').then((data:any) => {
          this.foodOrderCarryOutList = data.instanceList;
        });
        this.getFoodOrder(false, 'En entrega').then((data:any) => {
          this.foodOrderList = data.instanceList;
        });
      break;
      case 2:
        this.metadata.status = 2;
        this.getFoodOrder(true, 'Concluida').then((data:any) => {
          this.foodOrderCarryOutList = data.instanceList;
        });
        this.getFoodOrder(false, 'Concluida').then((data:any) => {
          this.foodOrderList = data.instanceList;
          

        });
      break;
      default:
        break;
    }
  }

  startTimer() {
    this.counter$ = this.counter.subscribe(()=>{
      // console.log("Se emite petición");
      if(!this.session.getLoggedIn()){
        // si la session se termina nos desuscribimos del observador
        this.counter$.unsubscribe();
      }else{
        this.setTab({index:this.metadata.status});
      }
    })
  }

  /**función para ejecutar cuando cambia de sucursal
  */
  changeBranch(){
    this.setTab({index:this.metadata.status});
  }

  /**función para obtener los datos de configuración de qr
  */
  getConfigurationQr(){
    this.session.getRequest("configurationQr:get",{system:{company:{main:{uuid: this.session.getSessionObject().main.uuid}}}}).subscribe(
      (data:any) => {
        console.log(data);
        this.qrConfiguration = data.object;
      }, error => {
        console.log("Error: configurationQr:get",error);
      });
  }

  /**
   * funcionalidad para cambiar el menú
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  /**funciónalidad para abrir el panel lateral
  */
  openLateralPanel(object){
    this.metadata.object = object;
    this.panel.toggle();
  }

  closeSide(ev){
    console.log(ev);
    this.panel.close();
  }
}
