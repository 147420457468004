<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0">
    <div class="col-10">
      <div class="row">
        <h4 class="text-uppercase pt-2 pb-2 col-6">Configuración de Menú</h4>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">settings_cell</mat-icon>
                Paso 1. Seleccione un tema para su menú
              </ng-template>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let theme of themeList; let x = index" [expanded]="theme.step === x" [(ngModel)]="theme.checked"  (opened)="setStep(theme.step)" hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-checkbox class="example-margin" name="{{x}}" [(ngModel)]="theme.checked" (ngModelChange)="themeChange(theme.value);" >{{theme.name}}</mat-checkbox>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="row">
                    <div class="col-3">
                      <img src="/assets/img/{{theme.value}}/theme/01-inicio.jpg" width="100%">
                    </div>
                    <div class="col-3">
                      <img src="/assets/img/{{theme.value}}/theme/02-home.jpg" width="100%">
                    </div>
                    <div class="col-3">
                      <img src="/assets/img/{{theme.value}}/theme/03-menu.jpg" width="100%">
                    </div>
                    <div class="col-3">
                      <img src="/assets/img/{{theme.value}}/theme/04-detalle.jpg" width="100%">
                    </div>
                  </div>
                </mat-expansion-panel>

              </mat-accordion>
              <div class="row justify-content-end mt-4">
                <div class="col-3">
                  <button type="button" (click)="send(object)" class="btn btn-danger btn-block" color="warn" >Guardar</button>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">build_circle</mat-icon>
                Paso 2. Configurando el perfil
              </ng-template>
              <mat-card>
                <div class="row mt-2 justify-content-center">
                  <div class="col-12">
                    <keysoft-upload [uploadCode]="'configurationQr:updateLogo'" [downloadCode]="'configurationQr:downloadLogo'" [listFilesCode]="'configurationQr:getNameLogo'" [objectUuid]="metadata.company.main.uuid" [multiple]="false"></keysoft-upload>
                    <div class="pt-2" style="margin-right: 150px !important;">
                      <button mat-flat-button color="warn" style="float: right;" (click)="deleteAlog();">Eliminar logo</button>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row justify-content-center">
                  <div class="col-11">
                    <h3>Indique para que tipo de negocio se va a utilizar la generación que código QR</h3>
                    <mat-slide-toggle class="example-margin" (change)="chargeRestaurant($event)" [checked]="object.main.restaurant" >Restaurante</mat-slide-toggle>
                    <br/>
                    <mat-slide-toggle class="example-margin" (change)="chargeHotel($event)" [checked]="object.main.hotel" >Hotel</mat-slide-toggle>
                  </div>
                </div>
                <br/><br/>
                <div class="row justify-content-center">
                  <div class="col-11">
                    <h3>Indique si se van a utilizar promociones</h3>
                    <mat-slide-toggle class="example-margin" [(ngModel)]="object.main.promotionImage" >Promociones</mat-slide-toggle>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-11">
                    <h3>Indique si se van a utilizar imagenes en los platillos</h3>
                    <mat-slide-toggle class="example-margin" [(ngModel)]="object.main.whitImage" >Imagenes en platillos</mat-slide-toggle>
                  </div>
                </div>
                <br>
                <div class="row mt-2 justify-content-center">
                  <div class="col-11">
                    <h3>¿En el caso de Restaurante, desea integrar el servicio para llevar?</h3>
                    <mat-slide-toggle class="example-margin" (change)="chargeCarryOut($event)" [checked]="object.main.carryOut" >Activo</mat-slide-toggle>
                  </div>
                </div>
                <div class="row justify-content-end mt-4">
                  <div class="col-3">
                    <button type="button" (click)="send(object)" class="btn btn-danger btn-block" color="warn" >Guardar</button>
                  </div>
                </div>
              </mat-card>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">payment</mat-icon>
                Paso 3. Seleccione sus formas de cobro a clientes
              </ng-template>
              <mat-card>
                <div class="row mt-2 justify-content-center">
                  <div class="col-11">
                    <h3>¿Se recibirán pagos por tarjeta bancaría a través del sitio web menú código QR?</h3>
                    <mat-slide-toggle class="example-margin" (change)="chargeMexPago($event)" [checked]="object.main.mexPago" >Activo</mat-slide-toggle>
                  </div>
                </div>
                <br /><br />
                <div class="row mt-2 justify-content-center">
                  <div class="col-11">
                    <h3>¿Se recibirán pagos por PayPal a través del sitio web menú código QR?</h3>
                    <mat-slide-toggle class="example-margin" (change)="chargePayPal($event)" [checked]="object.main.payPal" >Activo</mat-slide-toggle>
                    <mat-form-field *ngIf="object.main.payPal" appearance="outline" class="col-4">
                      <mat-label>Indique su número Sb Client Id de PayPal</mat-label>
                      <input matInput [(ngModel)]="object.complement.SbClientId" placeholder="Indique su número Sb Client Id de PayPal">
                    </mat-form-field><br/>
                  </div>
                </div>
                <div class="row justify-content-end mt-4">
                  <div class="col-3">
                    <button type="button" (click)="send(object)" class="btn btn-danger btn-block" color="warn" >Guardar</button>
                  </div>
                </div>
              </mat-card>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>
