import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-company-client-food-category-update',
  templateUrl: './company-client-food-category-update.component.html',
  styleUrls: ['./company-client-food-category-update.component.css']
})
export class CompanyClientFoodCategoryUpdateComponent implements OnInit {
  complexForm: FormGroup;
  foodCategoryModel = {
    main: {
      uuid: '',
      name: '',
      icon: false,
    },
    complement: {
      icon: ''
    },
    image: {
      fileName: '',
      fileType: '',
      file: ''
    },
    system: {
      status: true,
      branch: {
        main: {
          uuid: ''
        }
      }
    }
  }
  iconList = [
    { nameIcon: 'Comida', value: 'icono-comida.png' },
    { nameIcon: 'Bebidas', value: 'icono-bebidas.png' },
    { nameIcon: 'Combos', value: 'icono-combos.png' },
    { nameIcon: 'Postre', value: 'icono-postre.png' },
    { nameIcon: 'Postre 1', value: 'icono-postre-1.png' },
    { nameIcon: 'Postre 2', value: 'icono-postre-2.png' },
    { nameIcon: 'Promociones', value: 'icono-promociones.png' },
    { nameIcon: 'Promociones 2', value: 'icono-promocion.png' },
    { nameIcon: 'Carne', value: 'icono-carne-filete.png' },
    { nameIcon: 'Pechuga', value: 'icono-carne-pechuga.png' },
    { nameIcon: 'Cerveza', value: 'icono-cerveza.png' },
    { nameIcon: 'Ensalada', value: 'icono-ensalada.png' },
    { nameIcon: 'Entradas', value: 'icono-entradas.png' },
    { nameIcon: 'Aguas', value: 'icono-agua.png' },
    { nameIcon: 'Aperitivos', value: 'icono-aperitivo.png' },
    { nameIcon: 'Cafes', value: 'icono-cafe.png' },
    { nameIcon: 'Lasagna', value: 'icono-lasana.png' },
    { nameIcon: 'Licores', value: 'icono-licores.png' },
    { nameIcon: 'Pastas', value: 'icono-pasta.png' },
    { nameIcon: 'Pizza', value: 'icono-pizza.png' },
    { nameIcon: 'Refresco', value: 'icono-refresco.png' },
    { nameIcon: 'Sopas', value: 'icono-sopa.png' },
    { nameIcon: 'Vinos', value: 'icono-vino.png' },
    { nameIcon: 'Tacos', value: 'icono-taco.png' },
    { nameIcon: 'Tlayuda', value: 'icono-tlayuda.png' },
    { nameIcon: 'Hamburguesas', value: 'icono-hamburguesa.png' },
    { nameIcon: 'Hotdogs', value: 'icono-hotdog.png' },
  ]

  metadata= {
    configurationQr: {
      main: {
        uuid: '',
        theme: '',
        restaurant: true,
        hotel: false,
        mexPago: true,
        payPal:true
      }
    },
  }

  object = JSON.parse(JSON.stringify(this.foodCategoryModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyClientFoodCategoryUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    });
  }

  ngOnInit(): void {
    this.object.system.branch.main.uuid = this.data.system.branch.main.uuid;
    this.getConfiguration().then((data: any) => {
      this.metadata.configurationQr = data.object;
      if (this.data.main.uuid != 'new') {
        this.loadingService.wait();
        this.object.main.uuid = this.data.main.uuid;
        // cargamos los datos de la BD
        this.session.getRequest('foodCategory:get', this.object).subscribe((data: any) => {
          this.object = JSON.parse(JSON.stringify(data.object));
          this.loadingService.hide();
        }, (error) => {
          this.loadingService.hide();
          console.log('Error: foodCategory:get', error)
        })
      }
    });
  }

  getConfiguration() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('configurationQr:load', { system: {company: {main: {uuid: this.session.getSessionObject().main.uuid}}}}).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({transaction: 'ok', object:data.object});
        }, error => {
          console.log("Error: configurationQr:load", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  /**
   * funcion para guardar una sucursale
   */
  send(object) {
    object.system.branch = this.object.system.branch;
    this.loadingService.show(true, 'Guardando registro');
    this.session.getRequest("foodCategory:update", object).subscribe(
      (data: any) => {
        this.object.main.uuid = data.object.main.uuid;
        this.snackBar.open('La categoria de alimento se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.close(true);
      }, error => {
        console.log("Error: foodCategory:update", error);
        this.loadingService.hide();
      });
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'foodCategory:001';
    }
    this.dialogRef.close(object);
  }

}
