<mat-progress-bar mode="query" *ngIf="metadata.saving"></mat-progress-bar>
<h1 mat-dialog-title>Agrega un nuevo rol</h1>
<div mat-dialog-content>
  <div class="row justify-content-center">
    <div class="col-11">
      <mat-form-field class="col-12" style="font-variant-caps: all-petite-caps;">
        <span matPrefix *ngIf="object.main.uuid == ''">ROLE_</span>
        <input matInput placeholder="Nombre" [(ngModel)]="object.main.authority" [formControl]="complexForm.controls['authority']" name="mauthority" (ngModelChange)="changeInputName(object.main.authority);">
        <mat-error *ngIf="complexForm.controls['authority'].hasError('required')">
          El campo es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <textarea matInput placeholder="Descripción" [(ngModel)]="object.main.description" [formControl]="complexForm.controls['description']" name="mdescription"></textarea>
        <mat-error *ngIf="complexForm.controls['description'].hasError('required')">
          El campo es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-select placeholder="Tipo de Rol" name="roleType"  [(ngModel)]="object.system.objectType" (ngModelChange)="loadObjectReosurce(object.system.objectType);" required>
          <mat-option *ngFor="let object of typeList" [value]="object">
            {{ object }}
          </mat-option>
          <mat-option [value]="''" *ngIf="typeList.length > 0">
            --- Seleccione un elemento ---
          </mat-option>
          <mat-option [value]="''" *ngIf="typeList.length == 0" >
            --- No hay elementos ---
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-select placeholder="Tipo de acceso" name="accetype"  [(ngModel)]="object.system.type" required>
          <mat-option [value]="'Company'">
            Acceso a la empresa
          </mat-option>
          <mat-option [value]="'Branch'" >
            Acceso a una sucursal
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions class="row">
  <button mat-button (click)="closeModal(false);" class="col-5" cdkFocusInitial>Cerrar</button>
  <button mat-button (click)="send(object);" class="col-5" >Guardar</button>
</div>
