<mat-drawer-container class="drawerConteiner " autosize style="background: #fff;">
  <mat-drawer #drawer class="mainDrawwerCountry" mode="push" position="end">
    <app-restaurant-hotel-menu-collapsed></app-restaurant-hotel-menu-collapsed>
  </mat-drawer>

  <!-- MENU horizontal CON REGRESAR, MENU Y NOTA -->
  <div class="row img-home-hotel mr-0 ">
    <div class="col-12">
      <div class="row justify-content-center" style="background: #f1f1f1;">
        <div class="col-3 pr-0">
          <button mat-icon-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;" (click)="back();">
            <mat-icon class="pt-2 text-gris" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center p-0">
          <h2 class="text-gris align-middle mb-0 font-IBMPlexR" style="line-height: 1.2rem; font-size: 1rem; padding-top: 4px;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2 pl-0 pr-0">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-hotel/order">
            <mat-icon class="text-gris">receipt_long</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-gris" style="font-size: 2rem; margin-right: 5px;">dehaze</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="row justify-content-center m-0 font-IBMPlexB text-gris" style="min-height: 80vh;">
    <div class="col-11">
      <ol class="pt-3 p-0" style="list-style-type:none;">
        <li style="float: right;" *ngIf="foodOrder.system.foodOrderDetails.length > 0"> <span class="badge" style="background: #FFC107;padding: 5px;">{{foodOrder.main.status}}</span> </li>

        <li style=" font-size: 20pt; font-family: IBMPlex-regular;"> <strong>Tu Orden</strong> </li>
        <li class="pb-4" style=" font-size: small; font-family: IBMPlex-regular;">{{foodOrder.main.numberOrder}}</li>

        <li class="pb-3" style="font-size: 13pt;" *ngIf="foodOrder.system.foodOrderDetails.length > 0"> <strong>PLATILLOS</strong> </li>
        <li *ngFor="let object of foodOrder.system.foodOrderDetails" class="pb-1">
          <div class="">
            <span class="pr-3">{{object.main.quantity}}</span>
            <span>{{object.system.foodSaucer.main.name}}</span>
            <span style="float: right;">{{(object.main.priceUnitary * object.main.quantity)   | currency:'CAD':'symbol-narrow'}}</span>
          </div>
        </li>
        <li class="pt-3" style=" font-size: 12pt;" *ngIf="foodOrder.system.foodOrderDetails.length > 0">
          <span>Total</span>
          <span style="float: right;">{{(foodOrder.main.total)  | currency:'CAD':'symbol-narrow'}}</span>
        </li>
        <li *ngIf="foodOrder.system.foodOrderDetails.length == 0" style="margin-top: 50%;color: #9E9E9E; font-family: IBMPlex-regular;">
          <span>No se han agregado platillos a tu orden.</span>
        </li>
      </ol>
      <mat-form-field appearance="fill" style="width:100%;" *ngIf="foodOrder.system.foodOrderDetails.length > 0">
        <mat-label>Forma de pago</mat-label>
        <mat-select [(ngModel)]="foodOrder.main.paymentMethod" name="mPaymenMethod" >
          <mat-option  [value]="'Cargo a la habitación'" *ngIf="qrConfiguration.main.hotel">Cargo a la habitación</mat-option>
          <mat-option  [value]="'Pago en Efectivo'">Pago en Efectivo</mat-option>
          <mat-option  [value]="'Tarjeta de Debito'" *ngIf="qrConfiguration.main.hotel">Tarjeta de Debito</mat-option>
          <mat-option  [value]="'Tarjeta de Credito'" *ngIf="qrConfiguration.main.hotel">Tarjeta de Credito</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-10" *ngIf="foodOrder.system.foodOrderDetails.length > 0 && !foodOrder.main.carryOut">
      <button mat-flat-button  (click)="sendToFoodOrderToCook();" style="width:90%; rgb(102 20 20); color: #fff; font-family: IBMPlex-regular !important; letter-spacing: 2px;">Ordenar</button>
    </div>
    <div class="col-10" *ngIf="foodOrder.system.foodOrderDetails.length > 0 && foodOrder.main.carryOut && qrConfiguration.main.carryOut">
      <button mat-flat-button  (click)="sendToPayment();" style="width:90%; background: rgb(102 20 20); color: #fff; font-family: IBMPlex-regular !important; letter-spacing: 2px;">Siguiente</button>
    </div>
    <div class="col-10" *ngIf="foodOrder.main.carryOut && !qrConfiguration.main.carryOut">
      Por el momento no contamos con servicio para llevar, disculpa las molestias.
    </div>

  </div>
  <footer class="text-center text-secondary p-1">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</mat-drawer-container>
