<div class="row bg-dark">
    <div class="col-12 text-center pt-3 pb-3">
      <h3 class="text-white text-uppercase">{{object.main.uuid == '' ? 'Registrar' : 'Editar'}} mesero</h3>
    </div>
</div>

<!-- <div class="row justify-content-center title-modal" style="margin-left: -24px;margin-right: -24px;">
    <div class="col-4 text-uppercase text-center p-3">
      <h4>Registrar mesero</h4>
    </div>
</div> -->

<div class="row justify-content-center mt-3">
    <div class="col-11">
      <div class="row">
        <mat-form-field class="col-4" appearance="outline">
          <mat-label>&nbsp;Código</mat-label>
          <input matInput placeholder="Código" name="mCode" [(ngModel)]="object.main.code" [formControl]="complexForm.controls['code']" >
          <mat-error *ngIf="complexForm.controls['code'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-8" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.system.physicalPerson.main.name" [formControl]="complexForm.controls['name']" >
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6" appearance="outline">
            <mat-label>Apellido Paterno</mat-label>
            <input matInput placeholder="Apellido Paterno" name="mLastname" [(ngModel)]="object.system.physicalPerson.main.lastname" [formControl]="complexForm.controls['lastname']" >
            <mat-error *ngIf="complexForm.controls['lastname'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6" appearance="outline">
            <mat-label>Apellido Materno</mat-label>
            <input matInput placeholder="Apellido Materno" name="mSurname" [(ngModel)]="object.system.physicalPerson.main.surname" [formControl]="complexForm.controls['surname']" >
            <mat-error *ngIf="complexForm.controls['surname'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-4" appearance="outline">
            <mat-label>CURP</mat-label>
            <input matInput placeholder="La CURP debe constar de 18 caracteres" name="cCurp" [(ngModel)]="object.system.physicalPerson.complement.curp" [formControl]="complexForm.controls['curp']" >
            <mat-error *ngIf="complexForm.controls['curp'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-4" appearance="outline">
            <mat-label>RFC</mat-label>
            <input matInput placeholder="El RFC debe ser de 13 caracteres" name="cRFC" [(ngModel)]="object.system.physicalPerson.complement.rfc" [formControl]="complexForm.controls['rfc']" >
            <mat-error *ngIf="complexForm.controls['rfc'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-4" appearance="outline">
          <mat-label>Fecha de Nacimiento</mat-label>
          <input matInput [matDatepicker]="picker3" (focus)="picker3.open()" placeholder="Fecha de Nacimiento" name="dateBirth" [(ngModel)]="object.system.physicalPerson.complement.dateBirth">
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3 startView="multi-year" ></mat-datepicker>
        </mat-form-field>
        <mat-radio-group class="col-4" name="sGender" [(ngModel)]="object.system.physicalPerson.complement.gender">
          <mat-radio-button value="Femenino"> Femenino&nbsp;</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="Masculino"> Masculino</mat-radio-button>
        </mat-radio-group>
        
        <mat-form-field class="col-8" appearance="outline">
          <mat-label>Correo electrónico</mat-label>
          <input matInput type="email" placeholder="Correo electrónico" name="userUsername"  [(ngModel)]="user.main.username" [formControl]="complexForm2.controls['username']" required>
          <mat-error *ngIf="complexForm2.controls['username'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <div class="row col-12" *ngIf="user.main.uuid==''">
          <mat-form-field class="col-6" appearance="outline">
            <mat-label>Contraseña</mat-label>
            <input matInput type="password" placeholder="Contraseña" name="userPassword"  [(ngModel)]="user.main.password" [formControl]="complexForm2.controls['password']" required>
            <mat-error *ngIf="complexForm2.controls['password'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-form-field>
  
          <mat-form-field class="col-6" appearance="outline">
            <mat-label>Contraseña</mat-label>
            <input matInput type="password" placeholder="Confirmar Contraseña" name="userPasswordConfirm"  [(ngModel)]="user.main.passwordConfirm" [formControl]="complexForm2.controls['passwordConfirm']" required>
            <mat-error *ngIf="complexForm2.controls['passwordConfirm'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-form-field>
          </div>
      </div>
     
      <div class="row justify-content-end mt-4">
        <div class="col-4">
          <button type="button" class="btn btn-danger btn-block" [disabled]=" complexForm.invalid || object.main.code == '' || object.system.physicalPerson.main.name == '' "  (click)="send(object, user);">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
