import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-company-client-product-update',
  templateUrl: './company-client-product-update.component.html',
  styleUrls: ['./company-client-product-update.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CompanyClientProductUpdateComponent {
  @Output() toggleSide: EventEmitter<boolean> = new EventEmitter<boolean>();

  complexForm: FormGroup;
  foodSaucerModel = {
    main: {
      uuid: '',
      name: '',
      price: 0,
      tax: true,
      isCombo: false,
      description: '',
      foodOrderInstance: false,
    },
    imagen: {
      image: '',
      imageName: '',
      imageType: '',
    },
    system: {
      status: true,
      foodCategory: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      }
    }
  };
  branchModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      address: {
        main: {
          uuid: '',
          settingName: '',
          numberOutdoor: '',
          numberInterior: '',
          zipCode: '',
          fullAddress: ''
        },
        complement: {
          betweenRoads: '',
          locationDescription: ''
        },
        system: {
          addressType: 'Fiscal',
          setting: {
            main: {
              uuid: '',
              mName: ''
            },
            system: {
              locality: ''
            }
          },
          locality: {
            main: {
              uuid: '',
              code: '',
              name: '',
              typeLocality: ''
            },
            system: {
              town: {
                main: {
                  uuid: '',
                  name: ''
                },
                system: {
                  state: {
                    main: {
                      uuid: '',
                      name: ''
                    },
                  }
                }
              }
            }
          },
          state: {
            main: {
              uuid: ''
            }
          },
          status: true
        }
      }
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      status: true,
      foodCategories: [],
      bars: [] //se agrego aqui
    }
  };
  foodSaucerSizeModel = {
    main: {
      uuid: '',
      size: '',
      price: '',
      order: 1,
    },
    system: {
      status: true,
      foodSaucer: {
        main: {
          uuid: ''
        }
      }
    }
  };
  foodSaucerComplementModel = {
    main: {
  		uuid: '',
  		name: '',
  		complement: false,
      anyFood: false
  	},
  	system: {
  		foodSaucer: {
  			main: {
  				uuid: '',
  			}
  		},
  		foodSaucerComplementDetails: []
    }
  };
  foodSaucerComplementDetailModel = {
  	main: {
  		uuid: '',
  		description: '',
  		price: 0.0,
  	},
  	system: {
      status: true,
  		foodSaucerComplement: {
  			main: {
  				uuid: '',
  			}
  		}
  	}
  };
  branchList = [];
  foodSaucerSizeList = [];
  foodSaucerComplementList = [];
  barList = [];
  
  barModel = {
    main: {
      uuid: '',
      name: '',
    },
    system: {
      branch: {
        main: {
          uuid: ''
        }
      },
    }
  };

  metadata = {
    configurationQr: {
      main: {
        uuid: '',
        theme: '',
        restaurant: true,
        hotel: false,
        mexPago: true,
        payPal: true
      }
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      status: true
    },

    foodCategory:{
      main:{
        uuid:''
      }
    },

    bar:{
      main:{
        uuid:''
      }
    }
  }
  step = 0;
  foodSaucerBranchModel = {
    main: {
      uuid: ''
    },
    system: {
      foodSaucer: {
        main: {
          uuid: ''
        }
      },
      foodCategory: {
        main: {
          uuid: ''
        }
      },
      branch: {
        main: {
          uuid: ''
        }
      },
      bar:{
        main:{
          uuid:'',
        }
      },
      status: true
    }
  }
  object = JSON.parse(JSON.stringify(this.foodSaucerModel));

  @Input('data') data: string;
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'name': [null, Validators.required, Validators.minLength(3), Validators.maxLength(100)],
      'price': [null, Validators.compose([Validators.required])],
      // 'description': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    })
  }

  ngOnInit(): void {
    // obtenemos la configuración del cliente
    this.getConfiguration();
    this.getBrachList().then((data: any) => {
      this.branchList = [];
      for (let x = 0; x < data.instanceList.length; x++) {
        let branch = JSON.parse(JSON.stringify(this.branchModel));
        branch.main = data.instanceList[x].main;
        branch.system.company = data.instanceList[x].system.company;
        branch.system.company.status = data.instanceList[x].system.status;
        this.getFoodCategoryList(data.instanceList[x]).then((data: any) => {
          branch.system.categories = data.instanceList;
          this.branchList.push(branch);
        });
        //se agrega lo de barras(checarlo)
         this.getBarList(data.instanceList[x]).then((data: any) => {
         branch.system.bars = data.instanceList;
        //   //this.branchList.push(branch);
        });
      }
    });
  }

  

  ngOnChanges(changes: ChangeDetectionStrategy) {
    console.log(this.data, "daaata")
    if (this.data != 'new') {
      // console.log("TWF!!! porqueria!!")
      this.loadingService.show(true, "Obteniendo datos del platillo");
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      for (let i = 0; i < this.branchList.length; i++) {
        for (let j = 0; j < this.branchList[i].system.categories.length; j++) {
          this.branchList[i].system.categories[j].system.selected = false;
        }
      }
      this.session.getRequest('foodSaucer:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        // validamos si exiten precio por tamaño
        if (this.object.main.foodSaucerSize == true) {
          this.findAllByFoodSaucer().then((data:any) => {
            this.foodSaucerSizeList = data.object.instanceList;
          });
        }
        this.loadingService.hide();
        this.findAllfoodSaucerBranch();
      }, (error) => {
          this.loadingService.hide();
          console.log('Error: foodSaucer:get', error)
        });
    } else {
      this.object = JSON.parse(JSON.stringify(this.foodSaucerModel));
      for (let i = 0; i < this.branchList.length; i++) {
        for (let j = 0; j < this.branchList[i].system.categories.length; j++) {
          this.branchList[i].system.categories[j].system.selected = false;
        }
      }
    }
  }

  foodCategoryUpdate(foodCategory,categoriesList) {
    if (foodCategory.system.selected) {
      let isSelected = false;
      for (let j = 0; j < categoriesList.length; j++) {
        if(foodCategory.main.uuid !=categoriesList[j].main.uuid ){
          if(categoriesList[j].system.selected ){
            isSelected = true;
           }
        }

       }
      if(isSelected){
        this.snackBar.open("Solo se puede seleccionar una categoria por sucursal", 'Error', { duration: 5000 });
        setTimeout(()=>{
          foodCategory.system.selected = false;
        },100)
      }else{
        let aux = JSON.parse(JSON.stringify(this.foodSaucerBranchModel));
        //console.log('barra', foodCategory)
        
        aux.system.foodSaucer.main.uuid = this.object.main.uuid;
        console.log("fs", aux.system.foodSaucer.main.uuid )
        aux.system.branch.main.uuid = foodCategory.system.branch.main.uuid;
        console.log("br", aux.system.branch.main.uuid )
        aux.system.foodCategory.main.uuid = foodCategory.main.uuid;
        this.metadata.foodCategory.main.uuid = foodCategory.main.uuid;
        console.log("fc", aux.system.foodCategory.main.uuid)
        //aux.system.bar.main.uuid =  this.metadata.bar.main.uuid
        //this.metadata.foodCategory.main.uuid = foodCategory.main.uuid;
        //aux.system.bar.main.uuid = foodCategory.baar.main.uuid;
        aux.system.bar.main.uuid = this.metadata.bar.main.uuid;
        console.log("barra1", aux.system.bar.main.uuid)
        //aux.system.bar.main.uuid = foodCategory.baar.main.uuid;
   /*      aux.system.bar.main.uuid = foodCategory.baar.main.uuid;
        console.log('barra', aux.system.bar.main.uuid) */

        // this.session.getRequest("foodSaucerBranch:update", aux).subscribe((data: any) => {
        //   // this.foodSaucerBranchList.push(aux);
        //   //foodCategory.system.selected = true;
        // }, error => {
        //   console.log(error);
        //   foodCategory.system.selected = false;
        //   this.snackBar.open("Solo se puede seleccionar una categoria por sucursal", 'Error', { duration: 5000 });
        // });
      }

    } else {
      //buscamos el objeto si existe foodSaucerBranchList para eliminarlo
      let aux = JSON.parse(JSON.stringify(this.foodSaucerBranchModel));
      aux.system.foodSaucer.main.uuid = this.object.main.uuid;
      aux.system.branch.main.uuid = foodCategory.system.branch.main.uuid;
      aux.system.foodCategory.main.uuid = foodCategory.main.uuid;
      aux.system.bar.main.uuid = foodCategory.baar.main.uuid;
      console.log('232', aux.system.bar.main.uuid)
      console.log('foodC***', foodCategory)
      //this.metadata.bar.main.uuid = foodCategory.system.bar.main.uuid;
      //eliminamos el objeto de la bd
      if(aux.system.foodSaucer.main.uuid != '' && aux.system.branch.main.uuid != '' && aux.system.foodCategory.main.uuid != '' &&  aux.system.bar.main.uuid != ''  )
      this.session.getRequest("foodSaucerBranch:delete", aux).subscribe((data) => {
      }, error => {
        foodCategory.system.selected = true;
        this.snackBar.open("Ocurrio un error, no se pudo desasociar la categoria", 'Error', { duration: 5000 });
      }); 

    }
  }

  barUpdate(bar,barList) {
    if (bar.system.selected) {
      let isSelected = false;
      for (let j = 0; j < barList.length; j++) {
        if(bar.main.uuid !=barList[j].main.uuid ){
          if(barList[j].system.selected ){
            isSelected = true;
           }
        }

       }
      if(isSelected){
        this.snackBar.open("Solo se puede seleccionar una barra por sucursal", 'Error', { duration: 5000 });
        setTimeout(()=>{
          bar.system.selected = false;
        },100)
      }else{
        let aux = JSON.parse(JSON.stringify(this.foodSaucerBranchModel));
        aux.system.foodSaucer.main.uuid = this.object.main.uuid;
        //console.log('*****', this.object)
        console.log("fs1", aux.system.foodSaucer.main.uuid )
        aux.system.branch.main.uuid = bar.system.branch.main.uuid;
        console.log("br1", aux.system.branch.main.uuid )
        aux.system.bar.main.uuid = bar.main.uuid;
        //this.metadata.bar.main.uuid = bar.main.uuid;//agregado
        console.log("ba1", aux.system.bar.main.uuid )
        //aux.system.foodCategory.main.uuid = bar.foodC.main.uuid;
        aux.system.foodCategory.main.uuid = this.metadata.foodCategory.main.uuid; 
        console.log("fc1", aux.system.foodCategory.main.uuid )
        if(aux.system.foodCategory.main.uuid != '' && aux.system.bar.main.uuid != '' && aux.system.branch.main.uuid != '' && aux.system.foodSaucer.main.uuid != '' ){
          this.session.getRequest("foodSaucerBranch:update", aux).subscribe((data: any) => {
            // this.foodSaucerBranchList.push(aux);
            //foodCategory.system.selected = true;
          }, error => {
            console.log(error);
            bar.system.selected = false;
            this.snackBar.open("Solo se puede seleccionar una barra por sucursal", 'Error', { duration: 5000 });
          });

        }
       /*  aux.system.foodCategory.main.uuid = aux.system.foodCategory.main.uuid;
        console.log("fc1", aux.system.foodCategory.main.uuid ) */
        //aqui agregar la validacion
        // aux.system.foodCategory.main.uuid = aux.system.foodCategory.main.uuid;
       
      }

    } else {
      //buscamos el objeto si existe foodSaucerBranchList para eliminarlo
      let aux = JSON.parse(JSON.stringify(this.foodSaucerBranchModel));
      aux.system.foodSaucer.main.uuid = this.object.main.uuid;
      console.log('foodSaucer1', aux.system.foodSaucer.main.uuid );
      aux.system.branch.main.uuid = bar.system.branch.main.uuid;
      console.log('guyg', bar );
      console.log('branch1',aux.system.branch.main.uuid);
      aux.system.bar.main.uuid = bar.main.uuid;
      console.log('barra1',aux.system.bar.main.uuid);
      aux.system.foodCategory.main.uuid = bar.foodC.main.uuid;
      
      if (aux.system.foodSaucer.main.uuid != '' && aux.system.branch.main.uuid != '' && aux.system.bar.main.uuid != '' && aux.system.foodCategory.main.uuid != ''  ){
        //eliminamos el objeto de la bd
        this.session.getRequest("foodSaucerBranch:delete", aux).subscribe((data) => {
        }, error => {
          bar.system.selected = true;
          this.snackBar.open("Ocurrio un error, no se pudo desasociar la barra", 'Error', { duration: 5000 });
        });
      }
    

    }
  }

  getConfiguration() {
    this.loadingService.wait();
    this.session.getRequest('configurationQr:load', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
      (data: any) => {
        this.metadata.configurationQr = data.object;
        this.loadingService.hide();
      }, error => {
        console.log("Error: configurationQr:load", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      });
  }

  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('branch:list', { max: 1000, offset: 0, system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: branch:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  getFoodCategoryList(branch) {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('foodCategory:findAllByBranch', { max: 1000, offset: 0, sort: "mName",
      order: "asc", system: { branch: branch } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: foodCategory:findAllByBranch", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        })
    });
  }

  /**
  * Barra de Restaurante
  */
  getBarList(branch) {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('bar:findAllByBranch', {system: { branch: branch } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: bar:findAllByBranch", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        })
    });
  }

  addFoodSaucerSize() {
    if (this.object.main.uuid != '') {
      this.foodSaucerSizeModel.system.foodSaucer.main.uuid = this.object.main.uuid;
    }
    this.foodSaucerSizeList.push(JSON.parse(JSON.stringify(this.foodSaucerSizeModel)));
  }

  addFoodSaucerComplement() {
    this.foodSaucerSizeModel.system.foodSaucer.main.uuid = this.object.main.uuid;
    this.foodSaucerComplementList.push(JSON.parse(JSON.stringify(this.foodSaucerComplementModel)));
  }

  slideToggle(value, toogle) {
    if (toogle == 'foodSaucerSize') {
      this.object.main.foodSaucerSize = !value;
    }
    if (toogle == 'tax') {
      this.object.main.tax = !value;
    }
    this.send();
  }

  complementUpdate(foodSaucerComplement) {
    console.log('FoodSaucerComplement', foodSaucerComplement);
    if (foodSaucerComplement.main.name != '' && foodSaucerComplement.main.uuid == '') {
      this.loadingService.show(true, "Guardando espere un momento");
      foodSaucerComplement.system.foodSaucer.main.uuid = this.object.main.uuid;
      this.session.getRequest("foodSaucerComplement:update", foodSaucerComplement).subscribe((data: any) => {
        for (let x=0; x<this.foodSaucerComplementList.length; x++) {
          if (this.foodSaucerComplementList[x].main.uuid == '' && this.foodSaucerComplementList[x].main.name == foodSaucerComplement.main.name) {
            this.foodSaucerComplementList[x] = data.object;
            let foodSaucerComplementDetail = this.foodSaucerComplementDetailModel;
            foodSaucerComplementDetail.system.foodSaucerComplement.main.uuid = data.object.main.uuid;
            this.foodSaucerComplementList[x].system.foodSaucerComplementDetails.push(JSON.parse(JSON.stringify(foodSaucerComplementDetail)));
          }
        }
        this.loadingService.hide();
      }, error => {
        console.log("Error: foodSaucerComplement:update");
        this.loadingService.hide();
      })
    } else if (foodSaucerComplement.main.name != '') {

    }
  }

  /**función para guardar un platillo
  */
  send() {
    //if (this.object.main.name != '' && this.object.main.price != '' && this.object.main.description != '') {
    if (this.object.main.name != '' && this.object.main.price != '') {
      this.loadingService.show(true, "Guardando espere un momento");
      this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.session.getRequest("foodSaucer:update", this.object).subscribe((data: any) => {
       console.log("aqui**", data);
        this.object.main.uuid = data.object.main.uuid;
        if (this.object.main.foodSaucerSize == true) {
          // Validamos si existen ya un foodSaucerSizeList
          if (this.foodSaucerSizeList.length == 0) {
            this.findAllByFoodSaucer().then((data:any) => {
              this.foodSaucerSizeList = data.object.instanceList;
            });
          } else {
            for (let x=0; x<this.foodSaucerSizeList.length; x++) {
              if (this.foodSaucerSizeList[x].main.price != '' && this.foodSaucerSizeList[x].main.size != '') {
                this.foodSaucerSizeList[x].system.foodSaucer.main.uuid = this.object.main.uuid;
                this.foodSaucerSizeList[x].main.uuid = this.foodSaucerSizeUpdate(this.foodSaucerSizeList[x]).then((data:any)=> {
                  console.log('data.object', data.object);
                  this.foodSaucerSizeList[x] = data.object;
                });
              }
            }
          }
        }
        this.loadingService.hide();
      }, error => {
        console.log("Error: foodSaucer:update");
        this.loadingService.hide();
      })
    }
  }

  findAllByFoodSaucer() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest("foodSaucerSize:findAllByFoodSaucer", {main: {uuid: ''}, system: {foodSaucer: this.object}}).subscribe((data: any) => {
        this.loadingService.hide();
        resolve({ transaction: 'ok', object: {instanceList: data.object.instanceList} });
      }, error => {
        console.log("Error: foodSaucerSize:findAllByFoodSaucer", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
    });
  }

  foodSaucerSizeUpdate(foodSaucerSize) {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest("foodSaucerSize:update", foodSaucerSize).subscribe((data: any) => {
        this.loadingService.hide();
        resolve({ transaction: 'ok', object: data.object });
      }, error => {
        console.log("Error: foodSaucerSize:update", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
    });
  }

  /**función para obetner los checks de foodCategory
  */
  findAllfoodSaucerBranch() {
    if (this.object.main.uuid != '') {
      this.session.getRequest("foodSaucerBranch:findAllByFoodSaucer", { system: { foodSaucer: this.object } }).subscribe((data: any) => {
        for (let item of data.object.instanceList) {
          for (let i = 0; i < this.branchList.length; i++) {
            console.log('77', this.branchList[i].system)
            for (let j = 0; j < this.branchList[i].system.categories.length; j++) {
              console.log('55',this.branchList[i].system.categories)
              if (
                this.branchList[i].system.categories[j].main.uuid == item.system.foodCategory.main.uuid &&
                this.branchList[i].main.uuid == item.system.branch.main.uuid
              ) {
                this.branchList[i].system.categories[j].system.selected = true;
                this.branchList[i].system.categories[j].baar= item.system.bar

              }
            }
             for (let x = 0; x < this.branchList[i].system.bars.length; x++) {
               console.log('44', this.branchList[i].system.bars)
              if (
                this.branchList[i].system.bars[x].main.uuid == item.system.bar.main.uuid &&
                this.branchList[i].main.uuid == item.system.branch.main.uuid
              ) {
                this.branchList[i].system.bars[x].system.selected = true;
                this.branchList[i].system.bars[x].foodC= item.system.foodCategory
              }
            } 
          }
        }
      }, error => {
        console.log(error);
      })

    }
  }

  setStep(index: number) {
    this.step = index;
  }
  /*
  * funcion para abrir o cerrar el sidenav
  */
  toogle(status) {
    this.data = "";
    this.object = JSON.parse(JSON.stringify(this.foodSaucerModel));
    for (let i = 0; i < this.branchList.length; i++) {
      for (let j = 0; j < this.branchList[i].system.categories.length; j++) {
        this.branchList[i].system.categories[j].system.selected = false;
      }
      for (let x = 0; x < this.branchList[i].system.bars.length; x++) {
        this.branchList[i].system.bars[x].system.selected = false;
      }
    }
    this.toggleSide.emit(status);
  }
}
