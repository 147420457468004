import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { CompanyClientPromotionUpdateComponent } from './company-client-promotion-update/company-client-promotion-update.component';

@Component({
  selector: 'app-company-client-promotion',
  templateUrl: './company-client-promotion.component.html',
  styleUrls: ['./company-client-promotion.component.css']
})
export class CompanyClientPromotionComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  promotionMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    },
    max:10,
    offset:0,
    filter:{
      name:"searchElement",
      value:""
    }
  }

  // MatPaginator Output
  pageEvent: PageEvent;
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  list = [];
  branchList = [];
  promotionList = [];
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.getBrachList().then((data:any)=>{
      for(let i = 0; i < this.branchList.length; i++){
        this.getPromotions(this.branchList,i);
      }
    }).catch(e=>{
      console.log(e);
    })
  }

  /**Buscamos el listado de sucursales
  */
  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('branch:list', {max: 1000, offset: 0, system: {company: {main: {uuid: this.session.getSessionObject().main.uuid}}}}).subscribe(
        (data: any) => {
          this.loadingService.hide();
          this.branchList = data.object.instanceList;
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: branch:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  /**función para listar las promociones por por sucursal
  */
  getPromotions(branchList,index){
    this.session.getRequest('promotionImage:findAllByBranch', {max: 1000, offset: 0, system: {branch: {main: {uuid: branchList[index].main.uuid}}}}).subscribe(
      (data: any) => {
        this.loadingService.hide();
        // branchList[index].system.promotions = [];
        // branchList[index].system.promotions = data.object.instanceList;
        for(let i = 0; i < data.object.instanceList.length;i++){
          data.object.instanceList[i].system.branch = branchList[index];
          this.promotionList.push(data.object.instanceList[i]);
        }
        // recorremos las promociones paar obtener su imagen
        if(branchList.length == index+1){
          for(let i=0; i < this.promotionList.length; i++){
            this.getImage(this.promotionList[i]);
          }
        }
      }, error => {
        console.log("Error: promotionImage:findAllByBranch", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      });
  }

  /**funcionalida dpara dar de alta una promoción
  */
  updatePromotion(uuid) {
    this.dialog.open(CompanyClientPromotionUpdateComponent, {
      width: '60%',
      height: '100%',
      data: {
        main: {
          uuid: uuid,
        }
      }
    }).afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.promotionList=[];
          for(let i = 0; i < this.branchList.length; i++){
            this.getPromotions(this.branchList,i);
          }
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  onPaginateChange(event){
    this.promotionMin.offset = (event.pageIndex * event.pageSize);
    this.promotionMin.max = event.pageSize;
    // this.foodListByCompany();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  /**función para obtener la imagen de una promoción
  */
  async getImage(object){
    let auxFile ={
      file:{
        uuid:object.main.uuid,
        name:object.main.name,
        file:'',
        index:0,
        total:0,
        chunk:10,
        chunkSize:0,
        position:0,
        type:''
      },
      main:{
        uuid:object.main.uuid//uuid del archivo
      }
    }
    let base64 = '';
    try{
      let chunkFirstTime = true;
      for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
        // console.log("index--->",auxFile.file.index);
        let resF = await this.getRequestBaseFile(auxFile);
        if(resF.transaction == 'ok'){
          base64 = base64.concat(resF.object.file.file);
          auxFile.file.name = resF.object.file.name;
          if(chunkFirstTime){
            auxFile.file.chunk = resF.object.file.chunk;
            chunkFirstTime = false;
          }
        }else{
          this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
        }
      }
      if( base64 != ''){
        // console.log(base64);
        base64 = base64.replace(/-/g, "+");
        object.image = base64;
      }

    }catch(e){
      console.log("error",e);
      object.mode = 'determinate';
      this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
    }
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequest('promotionImage:download',object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }

  /**función para eliminar una promoción
  */
  deleteAPromotion(object){
    this.loadingService.wait();
    this.session.getRequest("promotionImage:deleteImage",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
      for(let i=0; i < this.promotionList.length; i++){
        if(this.promotionList[i].main.uuid ==  object.main.uuid){
          this.promotionList.splice(i,1);
        }
      }
      this.loadingService.hide();
    },error=>{
      console.log("error:promotionImage:deleteImage",error);
      this.loadingService.hide();
    });

  }


}
