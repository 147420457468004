<div class="row justify-content-center title-modal" style="margin-left: -24px;margin-right: -24px;">
  <div class="col-4 text-uppercase text-center p-3">
    <h4>Nueva Área</h4>
  </div>
</div>
<hr />
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row">
      <mat-form-field class="col-8" appearance="outline">
        <mat-label>Nombre del área</mat-label>
        <input matInput placeholder="Primer piso, Penthouse, etc." name="mName" [(ngModel)]="object.main.name" [formControl]="complexForm.controls['name']" >
        <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <section class="col-4 text-center" style="display:initial">
        <mat-slide-toggle [(ngModel)]="object.system.status" name="sStatus">Activo</mat-slide-toggle>
      </section>
    </div>
    <div class="row" *ngIf="object.main.uuid == ''">
      <mat-form-field class="col-4" appearance="outline">
        <mat-label>Número de habitaciones</mat-label>
        <input matInput type="number" placeholder="" name="mNumber" [(ngModel)]="metadata.roomService.main.number" >
      </mat-form-field>
    </div>
    <div class="row" *ngIf="object.main.uuid != ''">
      <h3 class="col-12">Enumera o renombra tus habitaciones&nbsp;&nbsp;&nbsp;
      <button mat-mini-fab color="accent" (click)="createRoomService(object.roomServices.length + 1)" style="cursor: pointer" aria-label="Agregar habitación">
        <mat-icon >add</mat-icon>
      </button>
      </h3>
    </div>
    <div class="row" *ngIf="object.main.uuid != ''">
      <div class="col-3" *ngFor="let roomService of object.roomServices; let i=index;">
        <div class="row">
          <mat-checkbox class="col-1" [(ngModel)]="roomService.system.status" (ngModelChange)="roomServiceUpdate(roomService);" ></mat-checkbox>
          <mat-form-field class="col-10" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput disabled="{{roomService.system.status ? false : true}}" placeholder="" name="mNumber{{i}}" (change)="roomServiceUpdate(roomService);" [(ngModel)]="roomService.main.number" >
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button type="button" class="btn btn-danger btn-block" color="warn" [disabled]="complexForm.invalid"  (click)="send(object);">Aceptar</button>
      </div>
    </div>
  </div>
</div>
