<div class="row img-home-seafood font-kelson m-0">
  <div class="col-12 text-center">
    <img [src]="logo" alt="{{metadata.name}}" width="50%" *ngIf="logo">
    <div class="sk-circle" *ngIf="logo == ''">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-1">
        <h1 class="text-navy-blue name-restaurant font-weight-bold font-kelson">{{metadata.name}}</h1>
      </div>
      <div class="col-12 text-center mt-2">
        <h1 class="text-blue mt-2 font-billy" style="font-size:4.8rem;">Bienvenido</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-5 text-center mt-2" style="padding-top: 15%; padding-bottom:15%;">
        <button type="button" class="btn btn-orange btn-block font-kelson" routerLink="/restaurant-seafood/home" style="font-size:1.5rem;">
          &nbsp;Entrar&nbsp;
        </button>
      </div>
    </div>
  </div>
</div>
<footer class="text-center text-dark">
© 2019 Keysoft. Reservados todos los derechos.
</footer>
