import { Input, HostListener, OnInit, Component, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface images {
  uuid:string,
  name:string,
  file:string,
  index:number,
  total:number,
  chunk:number,
  chunkSize:number,
  position:number,
  type:string,
  upload:boolean,
  uploaded:boolean,
  mode:string,
  valueProcess:number,
  uuidFile:string
}
interface fileUpload {
  file:{
    uuid:string,
    name:string,
    file:string,
    index:number,
    total:number,
    chunk:number,
    chunkSize:number,
    position:number,
    type:string,
    upload:boolean,
    uploaded:boolean,
    mode:string,
    valueProcess:number,
    uuidFile:string
  }
}
@Component({
  selector: 'keysoft-upload',
  templateUrl: './keysoft-upload.html',
  styleUrls: ['./keysoft-upload.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KeysoftUploadComponent {
  @ViewChild("fileGet", {static: false}) fileGet: ElementRef;
  // @Input('stiFile') stiFile: images;
  @Input('multiple') multiple: boolean;
  @Input('uploadCode') uploadCode: string;
  @Input('downloadCode') downloadCode: string;
  @Input('objectUuid') objectUuid: string;
  @Input('listFilesCode') listFilesCode: string;
  @Input('deleteCode') deleteCode: string;


  files = [];

  metadata = {
    value:0,
    mode:'buffer'
  }
  fileUploadModel:fileUpload = {
    file:{
      uuid:'',
      name:'',
      file:'',
      index:0,
      total:0,
      chunk:0,
      chunkSize:0,
      position:0,
      type:'',
      upload:false,
      uploaded:false,
      mode:'buffer',
      valueProcess:0,
      uuidFile:''
    }
  };
  images:images = {
    uuid:'',
    name:'',
    file:'',
    index:0,
    total:0,
    chunk:0,
    chunkSize:0,
    position:0,
    type:'',
    upload:false,
    uploaded:false,
    mode:'buffer',
    valueProcess:0,
    uuidFile:''
  }


  stiFile:images = JSON.parse(JSON.stringify(this.images));

  constructor(protected session:SessionService, public snackBar: MatSnackBar) {
    // console.log("multiple",this.multiple);
    // console.log("uploadCode",this.uploadCode);
    // console.log("downloadCode",this.downloadCode);
    // console.log("objectUuid",this.objectUuid);
    // console.log("deleteCode",this.deleteCode);

  }
  ngOnInit(){

  }
  ngOnChanges(changes: ChangeDetectionStrategy) {
    // changes.prop contains the old and the new value...
    if(this.objectUuid != ''){
      this.getFilesFromDB();
    }
  }

  /**Método para obtener el archivo en base64
  */
  getFile(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
      // console.log("::::::",fileUpload.files);
      // console.log(fileUpload.files[0]);
     // this.uploadFile(this.fileModel);
     this.stiFile.name = fileUpload.files[0].name;
     this.stiFile.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     let reader = new FileReader();
     reader.readAsDataURL(file);
     reader.onload = (event:any) => {
       if(this.stiFile.type == 'image/png' || this.stiFile.type == 'image/jpeg'){
         this.resizeBase64Img(event.target.result,this.stiFile.type).then((compressed:any) => {
            // console.log(compressed);
            this.stiFile.file = compressed;
            //agregamos el file  a el arreglo de files
            let fileAux = JSON.parse(JSON.stringify(this.images));
            fileAux = JSON.parse(JSON.stringify(this.stiFile));
            this.files.push(fileAux);
            this.cleanButonStiFile();
          })
       }else{
         this.stiFile.file = event.target.result;
         //agregamos el file  a el arreglo de files
         let fileAux = JSON.parse(JSON.stringify(this.images));
         fileAux = JSON.parse(JSON.stringify(this.stiFile));
         this.files.push(fileAux);
         this.cleanButonStiFile();
       }
     };
    };
    fileUpload.click();
  }

  /**Método para subir el archivo a la Base de Datos
  */
  uploadFile(object){
    if(this.objectUuid && this.objectUuid != ''){
      if(this.uploadCode){
        object.upload = true;
        let auxFile = object.file.replace(/\+/g,"-");
        let chunksSize = 7000;
        let chunks = Math.ceil(object.file.length/chunksSize)-1;
        // console.log(this.stiFile);

        object.file = auxFile;
        object.chunk = chunks;
        object.chunkSize = chunksSize;
        object.total = object.file.length;
        object.mode = 'determinate';
        object.valueProcess = 0;
        object.index = 0;
        object.position = 0;
        object.uuid = this.objectUuid;
        this.uploadFileToBD(this.uploadCode,object).subscribe((data:any)=>{
          // console.log("DATOS",data);
          object.valueProcess = 100;
          object.uploaded = true;
          object.upload = false;
        },error=>{
          console.log("Error",error);
          this.cleandBuffer(object);
          this.snackBar.open("No se logro subir el archivo... intente nuevamente", 'Error', {duration: 5000});
        });
      }else{
        this.snackBar.open("Ingrese un endpoint para subir a la BD", 'Infomación', {duration: 5000});
      }
    }else{
      this.snackBar.open("Verifique que el padre del archivo ya este guardado antes de subir el archivo", 'Infomación', {duration: 5000});
    }
  }


  /*********** --- UTILIDADES --- ************/
  uploadFileToBD(code: string, object:any): Observable<Response> {

    return Observable.create(async(observer) => {

      try{
        let objectToSend:fileUpload = {
          file:{
            uuid:'',
            name:'',
            file:'',
            index:0,
            total:0,
            chunk:0,
            chunkSize:0,
            position:0,
            type:'',
            upload:false,
            uploaded:false,
            mode:'buffer',
            valueProcess:0,
            uuidFile:''
          }
        };
        objectToSend.file = JSON.parse(JSON.stringify(object));
        objectToSend.file.file = object.file;
        // console.log("object",object);
        // console.log("objectToSend",objectToSend);
        let resp = await this.uploadFileByParts(code,objectToSend,object);
        console.log(resp);
        if(resp.status){
          // console.log("Terminado");
          observer.next();
          observer.complete();
        }else{
          // console.log("Sin terminar");
        }
      }catch(e){
        observer.error(e);
      }

    });
  }

  /**función para subir base64 por partes
  */

  uploadFileByParts(code: string, object:fileUpload, images:images):Promise<{status:boolean,object:any}>{
    return new Promise(async(resolve,reject)=>{
      if(object.file.position < object.file.total){
        try{
          //partimos el base64 en varias partes de acuerdo el numero de trozos caracteres
          let lengthMin = object.file.position;
          let lengthMax = object.file.position + object.file.chunkSize > object.file.total ? object.file.total : object.file.position + object.file.chunkSize;

          // console.log("=== Trozos ===",lengthMin +'/'+lengthMax);
          //se envia el file en segmentos.
          let aux:fileUpload = object;
          aux.file.file = images.file.slice(lengthMin, lengthMax); // prestar atención aqui
          this.session.getRequest(code,aux).subscribe(async(data:any)=>{
            // console.log("HHHHHHHHHH",data);
            // images.uuidFile = data.object.main.uuid;
            object.file.position = object.file.position + object.file.chunkSize;
            object.file.index++;
            this.stiFile.index++;// para llevar el numero de petición actual
            this.changeLoadder(object,images);
            let resp = await this.uploadFileByParts(code,object,images);
            resp.object = data.object;
            resolve(resp);
          },error=>{
            reject(error);
          })
        }catch(e){
          reject(e);
        }
      }else{
        // como llegamos al limite de enviamos en el
        resolve({
          status:true,
          object:{}
        })
      }
    });
  }
    /*********** --- FIN --- ************/

    /**función para tenerminar el valor de la carga
    */
    changeLoadder(fileUpload:fileUpload,object:images){
      object.index = fileUpload.file.index;
      object.chunk = fileUpload.file.chunk;
      object.valueProcess = (object.index/object.chunk)*100;
    }

    /**clenad buffer to upload
    */
    cleandBuffer(object){
      object.mode = 'buffer';
      object.valueProcess = 0
      object.upload = false;
    }

    /** clean Data
    */
    cleanButonStiFile(){
      this.stiFile = JSON.parse(JSON.stringify(this.images));
    }


    /**método para obtener el listado de archivos
      *** Esta parte debe cambiarse para que este dinamico. y no fijo el object de la petición
    */
    getFilesFromDB(){
      this.files = [];
      this.session.getRequest(this.listFilesCode,{main:{uuid:this.objectUuid}}).subscribe((data:any)=>{
        // console.log('listFilesCode',data.object);
        if (this.multiple) {
          for(let item of data.object.instanceList){
            let aux:images = JSON.parse(JSON.stringify(this.images));
            aux.name = item.image.fileName;
            aux.type = item.image.fileType;
            aux.uuidFile = item.main.uuid;
            aux.uuid = this.objectUuid;
            aux.mode = 'determinate';
            aux.valueProcess = 100;
            aux.uploaded = true;
            this.files.push(aux);
          }
        } else {
          let aux:images = JSON.parse(JSON.stringify(this.images));
          aux.name = data.object.image.name;
          aux.type = data.object.image.type;
          aux.uuidFile = this.objectUuid;
          aux.uuid = this.objectUuid;
          aux.mode = 'determinate';
          aux.valueProcess = 100;
          aux.uploaded = true;
          if (data.object.image.name != null && data.object.image.name != '') {
            this.files.push(aux);
          }
        }
      },error=>{
        console.log(error);
      })
    }

    /*
    funcion para descargar un archivo.
    */
    async downloadAFile(object){
      let auxFile ={
        file:{
          uuid:object.uuidFile,
          name:object.name,
          file:'',
          index:0,
          total:0,
          chunk:10,
          chunkSize:0,
          position:0,
          type:''
        },
        main:{
          uuid:object.uuidFile//uuid del archivo
        }
      }
      let base64 = '';
      object.mode = 'query';
      try{
        let chunkFirstTime = true;
        for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
          // console.log("index--->",auxFile.file.index);
          let resF = await this.getRequestBaseFile(auxFile);
          if(resF.transaction == 'ok'){
            base64 = base64.concat(resF.object.file.file);
            auxFile.file.name = resF.object.file.name;
            if(chunkFirstTime){
              auxFile.file.chunk = resF.object.file.chunk;
              chunkFirstTime = false;
            }
          }else{
            object.mode = 'determinate';
            this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
          }
        }
        if( base64 != ''){
          // console.log(base64);
          base64 = base64.replace(/-/g, "+");
          let elem = document.createElement('a');
          elem.href = base64;
          elem.setAttribute('download', auxFile.file.name);
          document.body.appendChild(elem);
          elem.click();
          object.mode = 'determinate';
        }

      }catch(e){
        console.log("error",e);
        object.mode = 'determinate';
        this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
      }
    }

    /**funcionalidad para pedir a la bd el base
    */
    getRequestBaseFile(object):Promise<any>{
      return new Promise((resolve,reject)=>{
        this.session.getRequest(this.downloadCode,object).subscribe((data:any)=>{
          resolve(data);
        },error=>{
          reject(error);
        })
      })
    }

    /**Método para eliminar un file
    **/
    deleteAFile(object){
      if(object.uuidFile != ''){
        this.session.getRequest(this.deleteCode,{main:{uuid:object.uuid}}).subscribe((data:any)=>{
          for(let i=0; i < this.files.length; i++){
            if(this.files[i].name == object.name){
              this.files.splice(i,1);
            }
          }
        },error=>{
          console.log(error);
        })
      }else{
        for(let i=0; i < this.files.length; i++){
          if(this.files[i].name == object.name){
            this.files.splice(i,1);
          }
        }
      }
    }

    /**función para reducir y optimizar una imagen
    */
    resizeBase64Img(base64,type, width?, height?){
      return new Promise((res, rej) => {
        const img = new Image();
        img.src = base64;
        img.onload = () => {
          let quality = 0.9;
          let reduction = 1;
          let imgWidth = width? width : img.naturalWidth;
          let imgHeight = height? height : img.naturalHeight;
          //calculamos quality y reduccion de iamgen
          if(imgWidth < 2000){
            if(imgWidth < 1500){
              if(imgWidth < 1000){
                if(imgWidth < 500){
                  reduction = .80;
                }else{
                  reduction = .70;
                }
              }else{
                reduction = .50;
              }
            }else{
              reduction = .30;
            }
          }else{
            reduction = .20
          }
          // console.log('imgWidth: ', imgWidth*reduction);
          // console.log('imgHeight: ', imgHeight*reduction);
          const elem = document.createElement('canvas');
          elem.width = imgWidth*reduction;
          elem.height = imgHeight*reduction;
          const ctx = elem.getContext('2d');
          ctx.drawImage(img, 0, 0, imgWidth*reduction, imgHeight*reduction);
          const data = ctx.canvas.toDataURL(type,quality);
          res(data);
        }
        img.onerror = error => rej(error);
      })
    }

}
