<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0">
    <div class="col-10 text-right">
      <div class="row mt-2 mb-2 justify-content-end">
        <div class="col-3">
        </div>
      </div>
    </div>
    <div class="col-10">
      <div class="row justify-content-end">
        <mat-form-field class="col-3" color="accent">
          <mat-label>Periodo inicial</mat-label>
          <input matInput [matDatepicker]="picker1" placeholder="Periodo inicial" name="cStartDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-3" color="accent">
          <mat-label>Periodo final</mat-label>
          <input matInput [matDatepicker]="picker2" placeholder="Periodo final" name="cEndDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-12">
          <h3>Platillos Favoritos</h3>
        </div>
        <div class="col-3">
          <mat-card class="border border-info pt-5 text-center">
            <mat-card-content>
              <h1 class="text-info text-dashboard">20</h1>
              <small class="text-secondary">Pizza de peperoni</small>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-3">
          <mat-card class="border border-warning pt-5 text-center">
            <mat-card-content>
              <h1 class="text-warning text-dashboard">15</h1>
              <small class="text-secondary">Pizza 4 estaciones</small>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-3">
          <mat-card class="border border-danger pt-5 text-center">
            <mat-card-content>
              <h1 class="text-dashboard" style="color:#ee7b46;">12</h1>
              <small class="text-secondary">Pasta Alfredo</small>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-3">
          <mat-card class="border border-dark pt-5 text-center">
            <mat-card-content>
              <h1 class="text-dashboard" style="color:#3b6379;">10</h1>
              <small class="text-secondary">Lasagna</small>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 mb-2">
          Tipos de pedidos
        </div>
        <div class="col-9">
          <mat-card class="border border-info">
            <canvas baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType">
            </canvas>
          </mat-card>
        </div>
        <div class="col-3">
          <mat-card class="border border-warning pt-5 mb-2 text-center">
            <mat-card-content>
              <h1 class="text-dashboard text-warning" style="color:#ee7b46;">120</h1>
              <small class="text-secondary mt-5 mb-5">Total de pedidos enviados </small>
            </mat-card-content>
          </mat-card>
          <mat-card class="border border-dark mt-5 pt-5 text-center">
            <mat-card-content>
              <h1 class="text-dashboard" style="color:#3b6379;">139</h1>
              <small class="text-secondary mt-5 mb-5">Total de pedidos en sitio </small>
            </mat-card-content>
          </mat-card>
          <!-- <mat-card class="border border-danger mt-5 pt-5 text-center">
            <mat-card-content>
              <h1 class="text-dashboard" style="color:#ee7b46;">13</h1>
              <small class="text-secondary mt-5 mb-5">Pedidos Cancelados </small>
            </mat-card-content>
          </mat-card> -->
        </div>
      </div>
    </div>
  </div>

</mat-drawer-container>
