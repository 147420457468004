<div class="row img-home-retro font-Titilliumr" style="height: 100%;">
  <div class="col-12 text-center">
    <img [src]="logo" alt="{{metadata.name}}" width="50%" *ngIf="logo">
    <div class="sk-circle" *ngIf="logo == ''">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
    <div class="row" style="margin-top: 20vh;">
      <div class="col-12 text-center mt-1">
        <!-- <h1 class="text-navy-blue name-restaurant font-weight-bold font-Titilliumr" style="color: white;">{{metadata.name}}</h1> -->
      </div>
      <div class="col-12 text-center mt-5">
        <h1 class="text-blue mt-2 font-Titilliumb" style="font-size:2.8rem; color: #bb7d10;">Bienvenido</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-5 text-center mt-2" style="padding-top: 10%; padding-bottom:15%;">
        <button type="button" class="btn btn-amarillo btn-block font-Titilliumr" routerLink="/restaurant-retro/home" style="font-size:1rem;">
          &nbsp;INGRESAR&nbsp;
        </button>
      </div>
    </div>
  </div>
  <footer class="text-center text-dark" style="width: 100%;">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</div>
