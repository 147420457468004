<div class="row" style="height: 100%; background: radial-gradient(#535353, #1b1a18);">
  <div class="col-12 text-center img-home-hotel">
    <img [src]="logo" alt="{{metadata.name}}" width="50%" *ngIf="logo">
    <div class="sk-circle" *ngIf="logo == ''">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
    <div class="row" style="margin-top: 20vh;">
      <div class="col-12 text-center mt-1">
        <!-- <h1 class="text-navy-blue name-restaurant font-weight-bold font-Titilliumr" style="color: white;">{{metadata.name}}</h1> -->
      </div>
      <div class="col-12 text-center mt-5">
        <h1 class="text-blue mt-2 font-IBMPlexB" style="font-size:2.1rem; letter-spacing: 2px;color: #FFF;">BIENVENIDO</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-5 text-center mt-2" style="padding-top: 10%; padding-bottom:15%;">
        <button type="button" class=" btn-pasto btn-block font-IBMPlexR" routerLink="/restaurant-hotel/home" style="font-size:.8rem; letter-spacing: 3px;">
          &nbsp;INGRESAR&nbsp;
        </button>
      </div>
    </div>
  </div>
  <footer class="text-center" style="width: 100%; color: #7d7c7c;">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</div>
