<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0">
    <div class="col-10 text-right">
      <div class="row mt-2 mb-2 justify-content-end">
        <div class="col-3">
        </div>
      </div>
    </div>
    <div class="col-10">
      <mat-card>
        <div class="row">
          <div class="col-9">
            <h4 class="pl-2 pt-2"> CLIENTES DE KEYSOFT</h4>
          </div>
          <div class="col-3">
            <button style="width:100%" mat-flat-button color="accent"  matTooltip="Nueva compañia cliente" routerLink="/admin/companyClient/update/new"> <i class="material-icons">add_circle_outline</i>Cliente</button>
          </div>
        </div>
        <mat-card-actions>
          <div class="row justify-content-end">
            <!-- aqui va el buscado -->
            <div class="col-6">
              <mat-form-field style="text-aling: right; width: 100%;" class="m-0">
                <input matInput placeholder="Buscar" [(ngModel)]="companyMin.filter.value" (ngModelChange)="search()" >
              </mat-form-field>
            </div>
          </div>
          <!-- aqui el paginado o footer -->
        </mat-card-actions>
        <mat-card-content>
          <div class="row justify-content-center">
            <div class="col-12">
              <!-- Aqui va la tabla -->
              <table class="table table-general">
                <thead class="thead-dark">
                  <tr>
                    <th class="">Nombre</th>
                    <th class="">Tipo</th>
                    <th class="">Estatus</th>
                    <th class="">Regimen fiscal</th>
                    <th>Acciones</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let object of companyList">
                    <td>{{ object.main.tradename }}</td>
                    <td>{{ object.system.typePerson == 'PhysicalPerson' ? 'Persona Fisica' : 'Persona Moral'}}</td>
                    <td>{{ object.system.status == 'false'? 'Inactivo' : 'Activo'}}</td>
                    <td>{{ object.system.taxRegime.main.description }}</td>
                    <td>
                      <div class="table-actions">
                        <a mat-button color="primary" routerLink="/admin/companyClient/update/{{object.main.uuid}}">Editar</a> |
                        <a mat-button color="warn" (click)="delete(object);">Eliminar</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <!-- aqui el paginado o footer -->
          <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
            <div class="col-6 ">
              <mat-paginator [length]=paginatorModel.total [pageSize]="10" [pageSizeOptions]=paginatorModel.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)">
              </mat-paginator>
            </div>
          </div>
        </mat-card-actions>

      </mat-card>
    </div>
  </div>

</mat-drawer-container>
