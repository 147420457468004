<mat-tab-group (selectedTabChange)="tabActive($event)"  id="mat-tab-order">
    <mat-tab *ngFor="let bar of barList; let index = index" [label]="bar.main.uuid">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">brunch_dining</mat-icon>
        {{bar.main.name}}
      </ng-template>
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Preparado</th>
            <th scope="col">Platillo</th>
            <th scope="col">Anotación</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let foodOrder of foodOrderList" (click)="updateIsMake(foodOrder);">
            <th scope="row"><mat-checkbox class="example-margin" [(ngModel)]="foodOrder.complement.isMake"></mat-checkbox></th>
            <th>{{foodOrder.system.foodSaucer.main.name}}</th>
            <th>{{foodOrder.main.observation}}</th>
          </tr>
        </tbody>
      </table>
    </mat-tab>
  </mat-tab-group>

  <div class="row justify-content-end mt-2 mb-2">
    <div class="col-6">
      <button type="button" class="btn btn-danger btn-block"  (click)="close(false);">
        Cerrar
      </button>
    </div>
    <div class="col-6">
      <button type="button" class="btn btn-warn btn-block" color="warn" style="width:100%" (click)="commandReport();" [disabled]="foodOrderList.length == 0">
        Imprimir comanda
      </button>
    </div>
  </div>



