import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { PageEvent } from '@angular/material/paginator';


@Component({
  selector: 'app-company-client-update',
  templateUrl: './company-client-update.component.html',
  styleUrls: ['./company-client-update.component.css']
})
export class AdminCompanyClientUpdateComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  contacts = [];
  contact = {
    main: {
      uuid: '',
      data: '',
      type: ''
    },
    system: {
      status: true
    }
  };
  address = {
    main: {
      uuid: '',
      settingName: '',
      numberOutdoor: '',
      numberInterior: '',
      zipCode: '',
      fullAddress: ''
    },
    complement: {
      betweenRoads: '',
      locationDescription: ''
    },
    system: {
      addressType: 'Fiscal',
      setting: {
        main: {
          uuid: '',
          mName: ''
        },
        system: {
          locality: ''
        }
      },
      locality: {
        main: {
          uuid: '',
          code: '',
          name: '',
          typeLocality: ''
        },
        system: {
          town: {
            main: {
              uuid: '',
              name: ''
            },
            system: {
              state: {
                main: {
                  uuid: '',
                  name: ''
                },
              }
            }
          }
        }
      },
      state: {
        main: {
          uuid: ''
        }
      },
      status: true
    }
  };
  moralPerson = {
    main: {
      uuid: "",
      socialReason: "",
      legalRepresentativeName: ''
    },
    complement: {
      rfc: ""
    },
    system: {
      status: true,
      contacts: this.contacts
    }
  };
  physicalPerson = {
    main: {
      uuid: "",
      name: "",
      lastname: "",
      surname: ""
    },
    complement: {
      dateBirth: "",
      curp: "",
      rfc: "",
      gender: ""
    },
    system: {
      status: true,
      contacts: this.contact
    }

  };
  companyClient = {
    main: {
      uuid: '',
      tradename: '',
    },
    complement: {
      socialObjective: '',
      accountExecutive: {
        main: {
          uuid: '',
          username: '',
          fullName: ''
        }
      },
      customerPaysheet: {
        main: {
          uuid: '',
          username: '',
          fullName: ''
        }
      },
      customerSupport: {
        main: {
          uuid: '',
          username: '',
          fullName: ''
        }
      },
      primaryKey: '',
      legalRepresentative: '',
      socialObjetive: ''
    },
    system: {
      status: true,
      typePerson: '',
      company: {
        main: {
          uuid: ''
        }
      },
      taxRegime: {
        main: {
          uuid: '',
          code: '',
          description: ''
        },
        system: {
          status: true
        }
      },
      person: {
        main: {
          id: ''
        }
      },
      contractTypes: [],
      bank: {
        main: {
          uuid: '',
          code: '',
          tradename: ''
        }
      },
      stationNumber: '',
    },
    image: {
      fileCer: '',
      nameCer: '',
      fileKey: '',
      nameKey: '',
      logo: '',
      logoName: ''
    }
  };
  file = {
    image: {
      file: '',
      name: '',
      type: ''
    }
  };
  metadata = {
    disable: false,
    dateBirth: null,
    curp: '[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[A-Za-z0-9]{2}',
    reggexRfcMoral: '[^0-9]{3}[0-9]{6}[A-Za-z0-9]{3}',
    reggexRfcPhysical: '[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}',
  };
  typeStrategyList = ['Sindicato', 'Fondo de pensión', 'Excedente por efectivo'];
  cerFile = JSON.parse(JSON.stringify(this.file));
  keyFile = JSON.parse(JSON.stringify(this.file));
  logoFile = JSON.parse(JSON.stringify(this.file));
  bankList = [];

  taxRegimeList = [];

  taxRegimeMin = {
    max: 10,
    offset: 0,
    sort: "mCode",
    order: "asc",
  };

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
   }; 

  object = JSON.parse(JSON.stringify(this.companyClient));
  constructor(public fb: FormBuilder, protected sessionService: SessionService, private router: Router, private activatedRoute: ActivatedRoute, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    //this.object.system.typePerson = "MoralPerson";
    this.complexForm = fb.group({
      'tradename': [null, Validators.compose([Validators.required])],
      'socialReason': [null, Validators.compose([Validators.required])],
      'curp': [null, Validators.compose([Validators.required, Validators.pattern(this.metadata.curp)])],
      'rfcP': [null, Validators.compose([Validators.required, Validators.pattern(this.metadata.reggexRfcPhysical)])],
      'rfcM': [null, Validators.compose([Validators.required, Validators.pattern(this.metadata.reggexRfcMoral)])],
      'legalRepresentativeName': [null, Validators.compose([Validators.required])],
      'surname': [null, Validators.compose([Validators.required])],
      'lastname': [null, Validators.compose([Validators.required])],
      'primaryKey': [null, Validators.compose([Validators.required])],

    });
  }

  ngOnInit() {
    //this.getTaxRegime('MoralPerson');
    this.activatedRoute.params.subscribe((params: Params) => { 
      if (params['uuid'] == 'new') {
        // no hacemos nada por el momento
        this.metadata.disable = false;
      } else {
        this.object.main.uuid = params['uuid'];
        this.getCompany();
      }
    });
  }
  /*
  Funcion para obtener una una company
  */
  getCompany() {
    // cargamos los datos de la BD
    this.loadingService.wait();
    this.sessionService.getRequest('company:get', this.object).subscribe((data: any) => {
      this.object = JSON.parse(JSON.stringify(data.object));
      console.log("**getCompany**",this.object);
      this.metadata.disable = true;
      if (this.object.system.typePerson === 'PhysicalPerson') {
        this.metadata.dateBirth = moment(this.object.system.person.complement.dateBirth).add(6, 'hours').utc().toISOString();
      }
      if (this.object.system.person.system.address) {
        this.address = this.object.system.person.system.address;
      } else {
        this.address = JSON.parse(JSON.stringify(this.address));
      }
      //this.contacts = this.object.system.person.system.contacts;
      if (this.object.image.logo) {
        this.object.image.icon = this.object.image.icon.replace(/-/g, "+");
        this.logoFile.image.file = this.object.image.icon;
        this.logoFile.image.name = this.object.image.name;
      }
      if (this.object.image.nameCer) {
        this.cerFile.image.file = this.object.image.fileCer.replace(/-/g, "+");;
        this.cerFile.image.name = this.object.image.nameCer;
      }
      if (this.object.image.nameKey) {
        this.keyFile.image.file = this.object.image.fileKey.replace(/-/g, "+");;
        this.keyFile.image.name = this.object.image.nameKey;
      }
       //obtenemos el listado del regimen fiscal
      this.getTaxRegime2(this.object.system.typePerson);
      //console.log('*****',this.object.system.typePerson);
      //this.getTaxRegime();
      console.log('Company ', this.object);
      this.loadingService.hide();
    },
      (error) => {
        console.log('Error:company:get', error)
        this.loadingService.hide();
      })
  }


  /** función para obtener las compañias cliente por customer
  */
    getTaxRegime() {
      this.loadingService.wait();
      this.sessionService.getRequest('taxRegime:list', this.taxRegimeMin).subscribe(
        (data: any) => {
          this.taxRegimeList = data.object.instanceList;
          this.paginatorModel.total = data.object.total;
          this.loadingService.hide();
        },
        error => {
          console.log("taxRegime:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        })
    }

      // /*funcion para obtener tipos de regimenes fiscales.*/
      getTaxRegime2(use) {
        this.sessionService.getRequest('taxRegime:findAllByUse', { use: use, status:true }).subscribe(
          (data: any) => {
          this.taxRegimeList = data.object.instanceList;
        },
          (error) => {
            console.log('Error:taxRegime:findAllByUse', error)
          })
      }


  /**
   * función para enviar a crear o actualizar una compañia
   */
  send(object, status) {
    this.loadingService.show(true, 'Guardando compañia');
    if (this.cerFile.image.name != '') {
      object.image.fileCer = this.cerFile.image.file.replace(/\+/g, "-");;
      object.image.nameCer = this.cerFile.image.name;
    }
    if (this.keyFile.image.name != '') {
      object.image.fileKey = this.keyFile.image.file.replace(/\+/g, "-");;
      object.image.nameKey = this.keyFile.image.name
    }
    if (object.system.typePerson == 'PhysicalPerson') {
      this.object.system.person.complement.dateBirth = moment(this.metadata.dateBirth).format('DD-MM-YYYY');
    }
    this.object.system.person.system.address = this.address;
    this.object.system.person.system.contacts = this.contacts;
    if (this.logoFile.image.name != '') {
      object.image.icon = this.logoFile.image.file.replace(/\+/g, "-");
      object.image.name = this.logoFile.image.name;
    }
    this.sessionService.getRequest('company:update', object).subscribe((data: any) => {
      this.object = JSON.parse(JSON.stringify(data.object));
      this.metadata.disable = true;
      this.router.navigate(['/admin/companyClient']);
      this.loadingService.hide();
    },
      (error) => {
        console.log('Error:company:update', error)
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

  loadTypePerson() {
    if (this.object.system.typePerson == 'MoralPerson') {
      this.object.system.person = this.moralPerson;
      // this.getTaxRegime2(this.object.system.person);
    } else {
      this.object.system.person = this.physicalPerson;
     /*  this.getTaxRegime2(this.object.system.person); */
    }
    
  }

  changeCerFile(value) {
    if (value !== undefined && value !== null) {
      this.object.image.fileCer = value;
      this.object.image.nameCer = this.cerFile.image.name;
    }
  }

  changeKeyFile(value) {
    if (value !== undefined && value !== null) {
      this.object.image.fileKey = value;
      this.object.image.nameKey = this.keyFile.image.name;
    }
  }


  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
