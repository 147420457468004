<h1 mat-dialog-title class="mt-2">Restaurante</h1>
<hr />
<div mat-dialog-content class="row justify-content-center mt-2">
  <mat-form-field class="col-12" appearance="outline">
    <mat-label>Selecciona</mat-label>
    <mat-select [(ngModel)]="metadata.object.main.uuid">
      <mat-option *ngFor="let object of objects" [value]="object.system.objectId">
        {{object.system.object.main.tradename}} {{object.system.object.main.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="row justify-content-center">
  <button class="col-4" mat-raised-button color="accent" [disabled]="metadata.object.main.uuid  == ''" (click)="close(true)" >Seleccionar</button>
</div>
