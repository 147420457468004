<div class="logoGranvia">
  <img src="assets/img/logo_granvia.png" alt="Granvia" width="100%">
</div>
<div class="animatedSplash">

    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    	 width="496.475px" height="928.385px" viewBox="0 0 496.475 928.385" enable-background="new 0 0 496.475 928.385"
    	 xml:space="preserve" style="width:100%; height:100%;">
    <g id="colores">
    	<animate attributeType="XML"
                    attributeName="opacity"
                    begin="0s" dur="1s"
                    from= "0" to="1"
                    fill="freeze"
            />
             		<animate attributeType="XML"
                    attributeName="opacity"
                    begin="1.5s" dur="1s"
                    from= "1" to="0"
                    fill="freeze"
            />
                    <animate attributeType="XML"
                    attributeName="opacity"
                    begin="5s" dur=".5s"
                    from= "0" to="1"
                    fill="freeze"
            />
                    <animate attributeType="XML"
                    attributeName="opacity"
                    begin="6.5s" dur=".5s"
                    from= "1" to="0"
                    fill="freeze"
            />
    	<g>
    		<path fill="#4AA535" d="M344.37,355.113c0,0-76.692,38.355-84.761,72.659c0,0,64-3.695,93.012,51.278
    			c6.58-12.206,11.084-25.793,12.978-40.361C369.597,407.923,361.236,378.403,344.37,355.113z"/>
    		<path fill="#7FB558" d="M259.608,427.772c35.244,27.445,57.69,91.172,57.69,91.172c14.543-10.314,26.709-23.918,35.321-39.894
    			C323.608,424.077,259.608,427.772,259.608,427.772z"/>
    		<path fill="#0083C9" d="M220.691,313.084c-13.316,9.23-49.498,40.146-55.063,106.534c-4.752,56.718,17.964,93.913,26.445,105.639
    			c0.747,0.424,1.485,0.857,2.24,1.263C194.313,526.521,171.012,401.388,220.691,313.084z"/>
    		<path fill="#006BA8" d="M165.628,419.619c5.565-66.388,41.747-97.305,55.063-106.534c0.581-1.035,1.164-2.07,1.767-3.094
    			c-45.739,10.986-82.173,49.154-88.596,98.579c-6.307,48.523,18.134,93.933,58.211,116.689
    			C183.592,513.532,160.877,476.336,165.628,419.619z"/>
    		<path fill="#9E3F90" d="M213.357,444.187c4.343-24.058,24.385-97.662,108.249-112.665c-15.935-12.462-35.302-20.964-56.817-23.761
    			c-8.522-1.108-16.944-1.259-25.169-0.553C239.622,307.208,205.779,384.3,213.357,444.187z"/>
    		<path fill="#BA78AB" d="M213.357,444.187c0.155,1.229,0.321,2.451,0.512,3.665c29.763-63.828,119.779-105.523,119.779-105.523
    			c-3.746-3.869-7.779-7.471-12.042-10.807C237.743,346.525,217.7,420.129,213.357,444.187z"/>
    		<path fill="#E35F1D" d="M239.57,439.367c0,0-18.67,29.668-9.391,99.448c1.487,0.252,2.981,0.486,4.491,0.682
    			c24.396,3.172,48.007-1.433,68.351-11.883C303.021,527.614,271.818,457.796,239.57,439.367z"/>
    		<path fill="#F29B52" d="M239.57,439.367c0,0-14.569,8.225-23.871,26.89c-9.305,18.666-5.502,67.34-5.502,67.34
    			c6.393,2.292,13.073,4.048,19.982,5.218C220.9,469.035,239.57,439.367,239.57,439.367z"/>
    	</g>
    </g>
    <g id="texto">
    	<g>
    		<animate attributeType="XML"
                    attributeName="opacity"
                    begin="0s" dur="5s"
                    from= "0" to="0"
                    fill="freeze"
            />
            <animate attributeType="XML"
                    attributeName="opacity"
                    begin="5s" dur=".5s"
                    from= "0" to="1"
                    fill="freeze"
            />
    		<animateTransform attributeName="transform"
                type="translate"
                from="0 100"
                to="0 0"
                begin="5s"
                dur=".3s"
                repeatCount="1"
                fill="freeze"
          />
            <animate attributeType="XML"
                    attributeName="opacity"
                    begin="6.5s" dur=".5s"
                    from= "1" to="0"
                    fill="freeze"
            />
    		<path fill="#3D3D3F" d="M144.908,616.21c0.222,13.163,8.627,18.582,18.36,18.582c6.968,0,11.17-1.218,14.82-2.765l1.659,6.97
    			c-3.428,1.545-9.29,3.314-17.807,3.314c-16.479,0-26.322-10.837-26.322-26.987c0-16.146,9.512-28.864,25.106-28.864
    			c17.476,0,22.122,15.373,22.122,25.216c0,1.99-0.222,3.54-0.334,4.534H144.908z M173.444,609.245
    			c0.11-6.195-2.544-15.817-13.495-15.817c-9.843,0-14.157,9.068-14.932,15.817H173.444z"/>
    		<path fill="#3D3D3F" d="M198.441,587.678l11.724,31.629c1.216,3.54,2.544,7.743,3.426,10.951h0.224
    			c0.994-3.208,2.102-7.301,3.428-11.17l10.618-31.409h10.286l-14.601,38.153c-6.966,18.36-11.721,21.924-18.358,27.676
    			c-4.756,4.203-9.511,5.862-11.945,6.305l-2.434-8.184c2.434-0.777,5.642-2.324,8.517-4.76c2.653-2.1,5.971-5.86,8.184-10.837
    			c0.441-0.994,0.775-1.769,0.775-2.322s-0.222,4.513-0.665,3.295l-19.798-49.326H198.441z"/>
    		<path fill="#3D3D3F" d="M246,629.483c4.313,2.655,10.615,4.868,17.254,4.868c9.843,0,15.595-5.199,15.595-12.72
    			c0-6.968-3.985-10.949-14.048-14.822c-12.167-4.312-19.688-10.618-19.688-21.123c0-11.614,9.623-20.241,24.11-20.241
    			c7.633,0,13.163,1.769,16.481,3.65l-2.655,7.852c-2.432-1.325-7.411-3.54-14.157-3.54c-10.174,0-14.045,6.083-14.045,11.173
    			c0,6.968,4.536,10.398,14.82,14.377c12.61,4.868,19.023,10.951,19.023,21.9c0,11.502-8.515,21.454-26.101,21.454
    			c-7.19,0-15.042-2.1-19.023-4.754L246,629.483z"/>
    		<path fill="#3D3D3F" d="M350.628,613.998c0,19.798-13.714,28.424-26.656,28.424c-14.489,0-25.659-10.617-25.659-27.538
    			c0-17.919,11.724-28.424,26.544-28.424C340.23,586.46,350.628,597.629,350.628,613.998z M308.158,614.551
    			c0,11.724,6.746,20.572,16.258,20.572c9.29,0,16.258-8.737,16.258-20.792c0-9.07-4.534-20.572-16.036-20.572
    			C313.133,593.759,308.158,604.377,308.158,614.551z"/>
    		<path fill="#3D3D3F" d="M363.901,641.207v-46.12h-7.521v-7.409h7.521v0.979c0-7.521,1.659-14.377,6.193-18.689
    			c3.652-3.542,8.515-4.98,13.051-4.98c3.428,0,6.415,0.775,8.295,1.549l-1.328,7.519c-1.437-0.663-3.428-1.216-6.195-1.216
    			c-8.293,0-10.394,7.299-10.394,15.485v-0.648h12.939v7.409h-12.939v46.12H363.901z"/>
    		<path fill="#3D3D3F" d="M408.139,572.303v15.376h13.936v7.409h-13.936v28.863c0,6.639,1.881,10.4,7.299,10.4
    			c2.544,0,4.424-0.333,5.642-0.665l0.443,7.301c-1.881,0.773-4.868,1.323-8.629,1.323c-4.534,0-8.184-1.435-10.506-4.089
    			c-2.767-2.877-3.761-7.633-3.761-13.938v-29.197h-8.296v-7.409h8.296v-12.834L408.139,572.303z"/>
    		<path fill="#3D3D3F" d="M131.383,642.548l-12.324-0.009l-24.198-26.387h-0.215l-0.019,26.363l-8.786-0.006l0.062-81.23
    			l8.786,0.006l-0.041,51.493h0.217l23.06-25.111l11.521,0.011l-25.474,26.447L131.383,642.548z"/>
    	</g>
    </g>
    <g id="lineas">
    	<animate attributeType="XML"
                    attributeName="opacity"
                    begin="0s" dur="2.5s"
                    from= "0" to="0"
                    fill=""
        />
        <animate attributeType="XML"
                    attributeName="opacity"
                    begin="2s" dur=".5s"
                    from= "0" to="1"
                    fill=""
        />
        <animate attributeType="XML"
                    attributeName="opacity"
                    begin="5s" dur=".5s"
                    from= "1" to="0"
                    fill="freeze"
        />

    	<path fill="none" stroke="#9E3F90" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="340" stroke-dashoffset="100;100" x="0px" y="0px" d="M463.897-104.615
    		c-93.747,125.881-209.94,371-224.858,413.185c-3.82,10.802-32.67,80.401-25.682,135.617"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="340;0"
    	                repeatCount="1"
    	                fill="freeze"
    	    />

    	<path fill="none" stroke="#9E3F90" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="400" stroke-dashoffset="0;100" x="0px" y="0px"  d="M366.771,423.591
    		c0-64.547-52.326-116.873-116.873-116.873c-39.038,0-73.606,19.14-94.831,48.547"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	    />

    	<path fill="none" stroke="#BA78AB" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="500" stroke-dashoffset="0;100" x="0px" y="0px"  d="M213.357,444.187c0,0,9.551-51.19,45.2-83.305
    		c27.007-24.33,87.84-63.497,482.007-38.164"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#BA78AB" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="300" stroke-dashoffset="0;100" x="0px" y="0px"  d="M213.87,447.852c0,0,22.531-56.398,119.779-105.766
    		s342.248-136.701,342.248-136.701"/>
    			<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />
    	<path fill="none" stroke="#4AA535" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="400" stroke-dashoffset="0;100" x="0px" y="0px" d="M259.608,427.772c0,0,1.55-12.188,16.013-25.17
    		c14.463-12.982,41.25-38.225,101.276-62.217s316.333-93.667,316.333-93.667"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#4AA535" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="300" stroke-dashoffset="0;100" x="0px" y="0px"  d="M259.608,427.772c0,0,33.176-0.292,56.649,13.91
    		s43.473,36.202,60.973,102.702c11.484,43.638,44.844,156,13.102,410"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#E35F1D" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="400" stroke-dashoffset="0;100" x="0px" y="0px"  d="M358.318,467.086
    		c-17.253,43.019-59.342,73.399-108.527,73.399c-16.784,0-32.742-3.538-47.169-9.908"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#006BA8" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="300" stroke-dashoffset="0;100" x="0px" y="0px"  d="M217.93,536.27
    		c-49.048-13.894-84.981-58.999-84.981-112.497c0-64.568,52.343-116.911,116.911-116.911c11.039,0,21.72,1.53,31.843,4.389"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#7FB558" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="400" stroke-dashoffset="0;100" x="0px" y="0px"  d="M259.608,427.772c0,0,32.425,23.065,57.69,91.172
    		c25.265,68.107,16.35,259.44-63.341,409.44"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#E35F1D" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="300" stroke-dashoffset="0;100" x="0px" y="0px"  d="M239.57,439.367
    		c32.077,19.518,60.494,80.161,70.661,106.851c20.118,52.815,47.477,191.107,2.866,375.167"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#F29B52" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="450" stroke-dashoffset="0;100" x="0px" y="0px"  d="M239.57,439.367c0,0-18.093,25.362-9.391,99.448
    		s61.728,477.57,61.728,477.57"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#F29B52" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="300" stroke-dashoffset="0;100" x="0px" y="0px"  d="M239.57,439.367c0,0-22.048,15.393-26.47,33.966
    		c-6.775,28.454-1.97,80.21,0.769,121.802c5.711,86.699,42.506,286.917,66.6,366.917"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#0083C9" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="500" stroke-dashoffset="0;100" x="0px" y="0px"  d="M433.897-78.615
    		c-114,131-211.439,388.605-211.439,388.605s-26.216,36.789-31.721,107.092s3.577,109.439,3.577,109.439
    		s26.378,286.864,79.19,444.864"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    	<path fill="none" stroke="#006BA8" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="600" stroke-dashoffset="0;100" x="0px" y="0px"  d="M520.564,270.051
    		c-140-17.333-277.244,21.78-299.873,43.033s-53.461,48.634-55.71,110.05c-1.609,43.944,8.141,74.7,27.091,102.123
    		c18.95,27.423,114.041,106.794,277.158,150.127"/>
    		<animate attributeType="XML"
    	                attributeName="stroke-dashoffset"
    	                begin="1.5s" dur="3.8s"
    	                values="1000;0"
    	                repeatCount="1"
    	                fill="freeze"
    	        />

    </g>
    </svg>
</div>
