/*
USO ejemplo
<app-sti-contact [contacts] = "contacts"></app-sti-contact>
*/
import { Input, HostListener, Component } from '@angular/core';

@Component({
  selector: 'app-sti-contact',
  templateUrl: './sti-contact.component.html',
  styleUrls: ['./sti-contact.component.css']
})
export class StiContactComponent {

  contactModel = {
    main: {
      uuid: '',
      data: '',
      type: ''
    },
    system: {
      status: true
    }
  }
  contactsType = [];
  contactList = [];
  @Input('contacts') contacts:Array<{}> ;
  @Input('stiDisable') stiDisable: boolean;

  constructor() {
    // inicializamos los tipos
    this.contactsType = [{name:'Telefono de trabajo', icon:"contact_phone"},
                        {name:'Telefono de casa', icon:"phone"},
                        {name:'Celular', icon:"phone_iphone"},
                        {name:'Fax', icon:"insert_drive_file"},
                        {name:'Email', icon:"email"},
                        {name:'URL', icon:"web_asset"},
                        {name:'Twitter', icon:"fab fa-twitter"},
                        {name:'Facebook', icon:"fab fa-facebook"},
                        {name:'Skype', icon:"fab fa-skype"},
                        {name:'Otro', icon:"whatshot"},
                      ];

  }

  ngOnChanges() {
  }

  /*
  funcion para seleccionar un tipo
  */
  select(type){
    let contactModelAux = Object.assign({},{
      main: {
      uuid: '',
      data: '',
      type: ''
    },
    system: {
      status: true
    }});
    // console.log("directive contacts: ", contactModelAux);
    contactModelAux.main.type = type;
    this.contacts.push(contactModelAux);
    // console.log("directive contacts: ", this.contacts);
  }
  /*
  funcion para remover un contacto
  */
  removeContact(index, enable){
    var contactList = this.contacts;
    if(contactList.length>0 && !enable){
      contactList.splice(index,1);
      this.contacts = contactList;
    }
  }
}
