<mat-drawer-container class="drawerConteiner font-kelson img-home-seafood" autosize>
  <mat-drawer #drawer class="mainDrawwerSeafood" mode="push" position="end">
    <app-restaurant-seafood-menu-collapsed></app-restaurant-seafood-menu-collapsed>
  </mat-drawer>
  <div class="row img-home-seafood m-0">
    <div class="col-12">
      <div class="row justify-content-center font-kelson ">
        <div class="col-3" routerLink="/restaurant-seafood">
          <button mat-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
            <mat-icon class="pt-2 text-orange" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center" id="ribbon">
          <h2 class="text-white align-middle mb-0" id="content-ribbon" style="line-height: 1.4rem;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-seafood/order">
            <mat-icon class="text-orange">receipt</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-orange" style="font-size: 2rem;">dehaze</mat-icon>
          </button>
        </div>
      </div>
      <div class="row home-restaurant font-kelson mt-1 m-0">
        <div class="col-12">
          <ngb-carousel *ngIf="object.main.promotionImage">
            <ng-template ngbSlide *ngFor="let object of promotionList">
              <div class="picsum-img-wrapper">
                <img [src]="object.image" alt="promo" width="100%" *ngIf="object.image">
                <div class="sk-circle" *ngIf="object.image == undefined">
                  <div class="sk-circle1 sk-child"></div>
                  <div class="sk-circle2 sk-child"></div>
                  <div class="sk-circle3 sk-child"></div>
                  <div class="sk-circle4 sk-child"></div>
                  <div class="sk-circle5 sk-child"></div>
                  <div class="sk-circle6 sk-child"></div>
                  <div class="sk-circle7 sk-child"></div>
                  <div class="sk-circle8 sk-child"></div>
                  <div class="sk-circle9 sk-child"></div>
                  <div class="sk-circle10 sk-child"></div>
                  <div class="sk-circle11 sk-child"></div>
                  <div class="sk-circle12 sk-child"></div>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
      <div class="row home-restaurant pt-2 pb-5 font-kelson">
        <div class="col-6 text-center" *ngFor="let foodCat of foodCategoryList" routerLink="/restaurant-seafood/menu/{{foodCat.main.uuid}}">
          <img src="/assets/img/{{object.main.theme}}/{{foodCat.complement.icon}}" class="background-menu" style="width:9.4rem;">
          <h2 class="text-dark">{{foodCat.main.name}}</h2>
        </div>
      </div>
      <footer class="text-center text-secondary p-1">
      © 2019 Keysoft. Reservados todos los derechos.
      </footer>
    </div>
  </div>
</mat-drawer-container>
