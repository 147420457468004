import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-restaurant-menu',
  templateUrl: './restaurant-menu.component.html',
  styleUrls: ['./restaurant-menu.component.css']
})
export class RestaurantMenuComponent implements OnInit {

  constructor(private session: SessionService, private router: Router, private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar) { }
  metadata = {
    branch:{
      main:{
        uuid:''
      }
    },
    foodCategory:{
      main:{
        uuid:'',
        name:''
      }
    }
  }
  foodSaucerBranchMin ={
    system:{
      branch:{
        main:{
          uuid:''
        }
      },
      foodCategory:{
        main:{
          uuid:''
        }
      }
    },
    "max":10,
    "offset":0,
    "filter":{
      "name":"searchElement",
      "value":""
    }
  }
  foodSaucerBranchList = [];
  foodSaucerList = [];
  foodSaucerListSecond = [];
  qrConfiguration = {
    main:{
      carryOut: true,
      hotel: true,
      mexPago: false,
      payPal: false,
      promotionImage: true,
      restaurant: true,
      theme: "",
      uuid: "",
      whitImage: false
    }
  };
  ngOnInit(): void {
    this.qrConfiguration = this.session.getConfigurationQr();
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] != '') {
        //bscamos los platillos de una categoria
        this.metadata.branch = this.session.getAnonymousSessionBranch();
        this.foodSaucerList = [];
        this.foodSaucerListSecond = [];
        this.getFoodSaucer(this.metadata.branch.main.uuid,params['uuid']);
      } else {
        this.router.navigate(['/restaurant/home']);
      }
    });
  }

  /**función para obtener el listado de platillo por categoria y branch
  */
  getFoodSaucer(branchUuui,foodCategoryUuid){
    this.session.getRequestAnonymous("foodSaucerBranch:findAllByBranchAndFoodCategory",{system:{branch:{main:{uuid:branchUuui}}, foodCategory:{main:{uuid:foodCategoryUuid}}}}).subscribe(async(data:any)=>{
      this.foodSaucerBranchList = data.object.instanceList;
      for(let i=0; i< this.foodSaucerBranchList.length; i++){
        if(this.foodSaucerBranchList[i].system.foodSaucer.main.foodSaucerSize){
          this.foodSaucerBranchList[i].system.foodSaucer.main.price = await this.getMinPriceFromFoodSaucerSize(this.foodSaucerBranchList[i].system.foodSaucer);
        }
        if(i%2 == 0){
          this.foodSaucerList.push(this.foodSaucerBranchList[i].system.foodSaucer);
        }else{
          this.foodSaucerListSecond.push(this.foodSaucerBranchList[i].system.foodSaucer);
        }
        this.metadata.foodCategory = this.foodSaucerBranchList[i].system.foodCategory;
      }
      // buscamos la imagenes
      for(let i=0; i < this.foodSaucerList.length; i++){
        if(this.foodSaucerList[i].main.image  && this.qrConfiguration.main.whitImage){
          this.getImage(this.foodSaucerList[i],i);
        }
      }
      for(let i=0; i < this.foodSaucerListSecond.length; i++){
        if(this.foodSaucerListSecond[i].main.image && this.qrConfiguration.main.whitImage){
          this.getImage(this.foodSaucerListSecond[i],i);
        }
      }
      // console.log("foodSaucerList::::::::::",this.foodSaucerList);
    },error=>{
      console.log("error:foodSaucerBranch:findAllByBranchAndFoodCategory",error);
    })
  }

  /**función para obtener el menor precio del catalog de tamaños de alimentos
  */
  getMinPriceFromFoodSaucerSize(object){
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous("foodSaucerSize:findAllByFoodSaucerAndStatus", {main: {uuid: ''}, system: {foodSaucer: object,status:true}}).subscribe((data: any) => {
        let price = data.object.instanceList[0].main.price;
        // resolve({ transaction: 'ok', object: {instanceList: data.object.instanceList} });
        resolve(price);
      }, error => {
        console.log("Error: foodSaucerSize:findAllByFoodSaucer", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        reject(error);
      })
    });
  }


  /**función para obtener la imagen de un producto
  */
  async getImage(object,indexX){
    let auxFile ={
      file:{
        uuid:object.main.uuid,
        name:object.main.name,
        file:'',
        index:0,
        total:0,
        chunk:10,
        chunkSize:0,
        position:0,
        type:''
      },
      main:{
        uuid:object.main.uuid//uuid del archivo
      }
    }
    let base64 = '';
    try{
      let chunkFirstTime = true;
      for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
        // console.log("index--->",auxFile.file.index);
        let resF = await this.getRequestBaseFile(auxFile);
        if(resF.transaction == 'ok'){
          base64 = base64.concat(resF.object.file.file);
          auxFile.file.name = resF.object.file.name;
          if(chunkFirstTime){
            auxFile.file.chunk = resF.object.file.chunk;
            chunkFirstTime = false;
          }
        }else{
          // this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
        }
      }
      if( base64 != ''){
        // console.log(base64);
        base64 = base64.replace(/-/g, "+");
        object.image = base64;
      }

    }catch(e){
      console.log("error",e);
      object.mode = 'determinate';
      // this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
    }
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous('foodSaucer:download',object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }

}
