import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-company-client-qr-view',
  templateUrl: './company-client-qr-view.component.html',
  styleUrls: ['./company-client-qr-view.component.css']
})
export class CompanyClientQrViewComponent implements OnInit {

  branchModel = {
    main: {
      uuid: '',
      name: ''
    },
    image: {
      file: ''
    }
  }

  object = JSON.parse(JSON.stringify(this.branchModel));
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyClientQrViewComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.loadingService.wait();
    this.object.main.uuid = this.data.main.uuid;
    // cargamos los datos de la BD
    this.session.getRequest('foodSaucerBranch:qrByBranch', {system: {branch: {main: {uuid: this.data.main.uuid} } } }).subscribe((data:any)=>{
      this.object.image = data.object.image;
      this.object.main = data.object.main;
      this.object.system = data.object.system;
      this.loadingService.hide();
    }, (error)=>{
      this.loadingService.hide();
      console.log('Error: foodSaucerBranch:qrByBranch',error)
    });
  }

  download() {
    let elem = document.createElement('a');
    elem.href = this.object.image.file;
    elem.setAttribute('download', this.object.image.name);
    document.body.appendChild(elem);
    elem.click();
  }

}
