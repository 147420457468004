import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { CompanyClientBarUpdateComponent } from './company-client-bar-update/company-client-bar-update.component';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-company-client-bar',
  templateUrl: './company-client-bar.component.html',
  styleUrls: ['./company-client-bar.component.css']
})
export class CompanyClientBarComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  branchList = [];

  barList = [];
  list= [];

  barModel = {
    main: {
      uuid: '',
      name: '',
    },
    system: {
      branch: {
        main: {
          uuid: ''
        }
      },
    }
  };

  metadata = {
    branch: {
      main: {
        uuid: '',
        name: '',
      }
    }
  }
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.getBrachList().then((data: any) => {
      this.getBarList(this.branchList[0]).then((data: any) => {
        this.barList = [];
        for (let x=0; x<data.instanceList.length; x++) {
          let bar = JSON.parse(JSON.stringify(this.barModel));
          //console.log(bar, "2")
          bar.main = data.instanceList[x].main;
          bar.system.branch = data.instanceList[x].system.branch;
          this.barList.push(bar);
        }
      });
    });
  }


    /**
   * Barra de Restaurante
   */
    getBarList(branch) {
      return new Promise((resolve) => {
        this.loadingService.wait();
        this.session.getRequest('bar:findAllByBranch', {system: { branch: branch } }).subscribe(
          (data: any) => {
            this.loadingService.hide();
            resolve({ transaction: 'ok', instanceList: data.object.instanceList });
          }, error => {
            console.log("Error: bar:findAllByBranch", error);
            this.snackBar.open(error.message, 'Error', { duration: 5000 });
            this.loadingService.hide();
          })
      });
    }
  
    /**
     * Inicializa los datos de la sucursal seleccionada
     */
    setBranch(event) {
      // Cargas las barras del lugar de acuerdo a sucursal seleccionada
      this.getBarList(this.branchList[event.index]).then((data: any) => {
        this.metadata.branch = this.branchList[event.index];
        this.barList = [];
        for (let x=0; x<data.instanceList.length; x++) {
          //console.log("23",data.instanceList)
          let bar = JSON.parse(JSON.stringify(this.barModel));
          bar.main = data.instanceList[x].main;
          //console.log("55",bar.main)
          bar.system.branch = data.instanceList[x].system.branch;
          this.barList.push(bar);
        }
      });
    }
  
  /**
  * Obtiene la lista de sucursales
  */
  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('branch:findAllByCompanyAndStatus', { system: {status: true,  company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
        (data: any) => {
          this.branchList = data.object.instanceList;
          // Obtenemos las áreas del lugar de acuerdo a la sucursal
          this.metadata.branch = this.branchList[0];
          this.loadingService.hide();
        }, error => {
          console.log("Error: branch:findAllByCompanyAndStatus", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
      });
  }

  /**
  * funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }


  /**
  * Funcionalidad para dar de alta una barra del restaurante
  */
  updateDialog(uuid) {
    this.dialog.open(CompanyClientBarUpdateComponent, {
      width: '60%',
      data: {
        main: {
          uuid: uuid
        },
        system: {
          branch: this.metadata.branch
        }
      }
    }).afterClosed().subscribe(result => {
      //console.log('***',result)
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          console.log('qqq',result.object)
            this.getBarList(result.object.system.branch).then((data: any) => {
              this.barList = [];
              //console.log('**+++',data.instanceList)
              for (let x=0; x<data.instanceList.length; x++) {
                let bar = JSON.parse(JSON.stringify(this.barModel));
                bar.main = data.instanceList[x].main;
                bar.system.branch = data.instanceList[x].system.branch;
                this.barList.push(bar);
              }
            });
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

}
