import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-client',
  templateUrl: './menu-client.component.html',
  styleUrls: ['./menu-client.component.css']
})
export class MenuClientComponent implements OnInit {
  metadata = {
    branch:{
      main:{
        uuid:''
      }
    }
  }
  configurationQr = {
    main:{
      uuid:'',
      theme:''
    }
  }
  foodCategoryList = [];
  constructor(private session: SessionService, public router:Router) { }

  ngOnInit(): void {
    this.configurationQr = this.session.getConfigurationQr();
    this.metadata.branch = this.session.getAnonymousSessionBranch();
    this.getCategories();
  }

  /**función para obtener el listado de categorias de una sucursal
  */
  getCategories(){
    this.session.getRequestAnonymous("foodCategory:findAllByBranch",{system:{branch:{main:this.metadata.branch.main}}}).subscribe((data:any)=>{
      this.foodCategoryList = data.object.instanceList;
    },error=>{
      console.log(error,"error:foodCategory:findAllByBranch");
    })
  }

}
