<mat-drawer-container class="drawerConteiner" autosize style="background: #fff;">
  <mat-drawer #drawer class="mainDrawwerHotel" mode="push" position="end">
    <app-restaurant-hotel-menu-collapsed></app-restaurant-hotel-menu-collapsed>
  </mat-drawer>

<!-- MENU HORIZONTAL CON REGRESAR, MENU Y NOTA -->
  <div class="row img-home-hotel mr-0">
    <div class="col-12">

      <div class="row justify-content-center" style="background: #f1f1f1;">
        <div class="col-3 pr-0" (click)="back();">
          <button mat-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
            <mat-icon class="pt-2 text-gris" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center p-0" >
          <h2 class="text-gris align-middle mb-0 font-IBMPlexR" style="line-height: 1.2rem; font-size: 1rem; padding-top: 4px;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2 pl-0 pr-0">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-hotel/order">
            <mat-icon class="text-gris">receipt_long</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-gris" style="font-size: 2rem; margin-right: 5px;">dehaze</mat-icon>
          </button>
        </div>

      </div>

    </div>
  </div>


        <div class="row justify-content-center mt-3">
          <div class="">
            <h1 class="font-IBMPlexB text-gris" style="line-height: 0.9rem; letter-spacing: 3px;">{{object.main.name}}</h1>
          </div>
        </div>


        <div class="row justify-content-center" >
          <div class=" col-8 box_hotel-product">
            <ngb-carousel style="box-shadow: #661414 0px 22px 0px 0px;">
              <ng-template ngbSlide>
                <div *ngIf="!object.main.image" style="margin-top: 100%;"></div>
                <div  *ngIf="object.main.image">
                  <img [src]="object.image" alt="promo" width="100%" *ngIf="object.image">
                  <div class="sk-circle" *ngIf="object.image == undefined">
                    <div class="sk-circle1 sk-child"></div>
                    <div class="sk-circle2 sk-child"></div>
                    <div class="sk-circle3 sk-child"></div>
                    <div class="sk-circle4 sk-child"></div>
                    <div class="sk-circle5 sk-child"></div>
                    <div class="sk-circle6 sk-child"></div>
                    <div class="sk-circle7 sk-child"></div>
                    <div class="sk-circle8 sk-child"></div>
                    <div class="sk-circle9 sk-child"></div>
                    <div class="sk-circle10 sk-child"></div>
                    <div class="sk-circle11 sk-child"></div>
                    <div class="sk-circle12 sk-child"></div>
                  </div>
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
        </div>


        <div class="row justify-content-center mt-4">
          <div class="col-7 p-0">
            <p class=" text-justify box_description text-gris">
              {{object.main.description}}
            </p>
          </div>
          <div class="col-3 p-0" style="padding-right: 0px;" *ngIf="!object.main.foodSaucerSize">
            <span class="font-IBMPlexB pb-1 price" >{{object.main.price | currency:'CAD':'symbol-narrow'}}</span>
            <button aria-label="Regresar" matTooltip="Agregar" class="pb-2 pt-2 btn-agregar"  (click)="openModal();">
              AGREGAR
            </button>
          </div>
          <div class="col-12" *ngIf="object.main.foodSaucerSize">
            <label id="example-radio-group-label">Escoge tu tamaño favorito</label>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="metadata.foodSaucerSize.main.uuid" (change)="selectedFoodSaucerSize($event);">
                <mat-radio-button class="example-radio-button" *ngFor="let object of foodSaucerSizeList" [value]="object.main.uuid">
                  <span>{{object.main.size}} </span>
                  <span style="float:right;">{{object.main.price | currency:'CAD':'symbol-narrow'}}</span>
                </mat-radio-button>
              </mat-radio-group>
          </div>
        </div>

        <footer class="text-center text-secondary p-1 mt-5">
        © 2019 Keysoft. Reservados todos los derechos.
        </footer>

</mat-drawer-container>




<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{display:metadata.displayModal ? 'block':'none'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background:#e6e6e6;">
      <div class="modal-header font-IBMPlexR">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body font-IBMPlexR" style="color: #424242;">
        <div class="row">
          <p class="col-12">Agregar la cantidad de platillos/bebidas.</p>
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-2">
                <button mat-icon-button (click)="minQuantity();"> <mat-icon>remove_circle_outline</mat-icon> </button>
              </div>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>Cantidad</mat-label>
                <input matInput name="quantity" [(ngModel)]="metadata.quantity">
              </mat-form-field>
              <div class="col-2">
                <button mat-icon-button (click)="addQuantity();"> <mat-icon>add_circle_outline</mat-icon> </button>
              </div>
            </div>
          </div>
          <p class="col-12">Si quieres agregar complementos ú observaciones agregalas aqui.</p>
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Complementos</mat-label>
            <textarea matInput name="description" [(ngModel)]="metadata.description"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width:100%;">
          <div class="col-6">
            <button mat-flat-button  color="#b0352d" style="width:100%; IBMPlex-regular !important;" (click)="closeModal();">Cerrar</button>
          </div>
          <div class="col-6">
            <button mat-flat-button color="accent"  style="width:100%; IBMPlex-regular !important;" (click)="addProduct();">Agregar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
