import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-company-client-hotel-update',
  templateUrl: './company-client-hotel-update.component.html',
  styleUrls: ['./company-client-hotel-update.component.css']
})
export class CompanyClientHotelUpdateComponent implements OnInit {
  complexForm: FormGroup;

  restaurantAreaModel = {
    main: {
      uuid: '',
      name: '',
      typeArea: 'Hotel',
    },
    system: {
      branch: {
        main: {
          uuid: ''
        }
      },
      foodTables: [],
      status: true
    }
  };

  metadata = {
    roomService: {
      main: {
        number: 0
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.restaurantAreaModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyClientHotelUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    });
  }

  ngOnInit(): void {
    this.object.system.branch.main.uuid = this.data.system.branch.main.uuid;
    // Obtenemos las sucursales del restaurante
    if (this.data.main.uuid != 'new') {
      this.loadingService.wait();
      this.object.main.uuid = this.data.main.uuid;
      // cargamos los datos de la BD
      this.session.getRequest('restaurantArea:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        // Validamos si el usuario, indico número de mesas
        this.metadata.roomService.main.number = 0;
        this.loadingService.hide();
      }, (error) => {
          this.loadingService.hide();
          console.log('Error: restaurantArea:get', error)
      });
    }
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.code = 'restaurantArea:success:001';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'restaurantArea:error:001';
    }
    this.dialogRef.close(object);
  }

  createRoomService(number) {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('roomService:update', {main: {uuid: '', number: number, status: 'Disponible'}, system: {status: true, branch: this.object.system.branch, restaurantArea: {main: this.object.main}}}).subscribe(
        (data: any) => {
          this.loadingService.hide();
          if(this.metadata.roomService.main.number == 0) {
            this.object.roomService.push(data.object);
          }
          resolve({ transaction: 'ok', object: data.object});
        }, error => {
          console.log("Error: roomService:update", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  roomServiceUpdate(roomService) {
    this.loadingService.show(true, 'Guardando registro');
    this.session.getRequest("roomService:update", roomService).subscribe(
      (data: any) => {
        this.snackBar.open('Se actualiza '+ roomService.main.number + ' correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
      }, error => {
        console.log("Error: roomService:update", error);
        this.loadingService.hide();
      });
  }

  /**
   * funcion para guardar una sucursale
   */
  send(object) {
    this.loadingService.show(true, 'Guardando registro');
    this.session.getRequest("restaurantArea:update", object).subscribe(
      (data: any) => {
        this.object.main = data.object.main
        this.snackBar.open('El área del restaurante se guardo correctamente', '', {
          duration: 8000
        });
        // Validamos si el usuario, indico número de mesas
        if (this.metadata.roomService.main.number > 0) {
          for (let x=0; x<this.metadata.roomService.main.number; x++) {
            this.createRoomService(x+1).then((data: any) => {
              this.object.roomService.push(data.object);
            });
          }
        }
        this.snackBar.open('Las mesas del área del restaurante se crearon correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.close(true);
      }, error => {
        console.log("Error: restaurantArea:update", error);
        this.loadingService.hide();
      });
  }
}
