<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <mat-sidenav #sidenav mode="side" class="sideNav" position="end" mode="push">
    <app-company-client-product-update [data]="metadata.object.main.uuid" (toggleSide)="closeSide($event);"></app-company-client-product-update>
  </mat-sidenav>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0">
    <div class="col-10">
      <div class="row mt-2 mb-2">
        <div class="col-9">
          <h4 class="text-uppercase pt-2 pb-2">Configuración de Productos para Menú</h4>
        </div>
        <!-- <button style="width:100%" mat-flat-button color="accent"  matTooltip="Crear nuevo producto" (click)="edit('81327129879213')"> <i class="material-icons">add_circle_outline</i>Cro</button> -->

        <div class="col-3">
          <button style="width:100%" mat-flat-button color="accent"  matTooltip="Crear nuevo producto" (click)="edit('new')"> <i class="material-icons">add_circle_outline</i>Crear nuevo producto</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-10">
      <mat-card>
        <mat-card-content>
          <div class="row">
            <div class="col-12">
              <table class="table table-general">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">IMAGEN</th>
                    <th scope="col">NOMBRE</th>
                    <th scope="col">DESCRIPCIÓN</th>
                    <th scope="col">PRECIO</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let object of list">
                    <td>
                      <img *ngIf="!object.main.image" alt="Sin imagen" src="assets/img/not-image.jpg" class="img-product" />
                      <img *ngIf="object.main.image" [src]="object.image" class="img-product" alt="promo">
                    </td>
                    <td >
                      {{object.main.name}}
                    </td>
                    <td>
                      {{object.main.description}}
                    </td>
                    <td>
                      {{object.main.price | currency:'CAD':'symbol-narrow'}}
                    </td>
                    <td>
                      <button mat-icon-button color="accent" (click)="edit(object.main.uuid);">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <!-- aqui el paginado o footer -->
          <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
            <div class="col-6 ">
              <mat-paginator [length]=paginatorModel.total [pageSize]="10" [pageSizeOptions]=paginatorModel.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)">
              </mat-paginator>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

</mat-drawer-container>
