<div class="row home-restaurant" style="z-index:9;">
  <div class="col-2 pt-2">
    <button mat-icon-button aria-label="Regresar" matTooltip="Regresar" (click)="back();" color="warn">
      <mat-icon style="font-size:2.5rem;">chevron_left</mat-icon>
    </button>
  </div>
  <div class="col-6"></div>
  <div class="col-2 pt-2">
    <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  color="warn" routerLink="/restaurant/order">
      <mat-icon >receipt</mat-icon>
    </button>
  </div>
</div>
<div class="row home-restaurant">
  <div class="col-12">
    <ngb-carousel >
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper" *ngIf="object.main.image">
          <img [src]="object.image" alt="promo" width="100%" *ngIf="object.image">
          <div class="sk-circle" *ngIf="object.image == undefined">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<div class="row home-restaurant">
  <div class="col-12">
    <div class="row justify-content-center">
      <div class="col-6">
        <h2 class="text-light pt-2">{{object.main.name}}</h2>
      </div>
      <div class="col-5">
        <h2 class="text-center text-light font-weight-bold pt-1" id="price-details" *ngIf="!object.main.foodSaucerSize">
          {{object.main.price | currency:'CAD':'symbol-narrow'}}
        </h2>
      </div>
      <div class="col-12" *ngIf="object.main.foodSaucerSize">
        <label id="example-radio-group-label">Escoge tu tamaño favorito</label>
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [(ngModel)]="metadata.foodSaucerSize.main.uuid" (change)="selectedFoodSaucerSize($event);">
            <mat-radio-button class="example-radio-button" *ngFor="let object of foodSaucerSizeList" [value]="object.main.uuid">
              <span>{{object.main.size}} </span>
              <span style="float:right;">{{object.main.price | currency:'CAD':'symbol-narrow'}}</span>
            </mat-radio-button>
          </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="col-12 pb-2">
    <div class="row justify-content-center">
      <div class="col-11">
        <p class="text-light text-justify font-weight-light">
          {{object.main.description}}
        </p>
      </div>
      <div class="col-12 text-right float-right" style="z-index:9; top: 72%;">
        <button mat-button aria-label="Agregar" matTooltip="Agregar a pedido" color="accent" (click)="openModal();">
          <mat-icon style="font-size:2.5rem;">add_circle</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<footer class="text-center text-secondary p-1">
© 2019 Keysoft. Reservados todos los derechos.
</footer>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{display:metadata.displayModal ? 'block':'none'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <p class="col-12">Agregar la cantidad de platillos/bebidas.</p>
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-2">
                <button mat-icon-button (click)="minQuantity();"> <mat-icon>remove_circle_outline</mat-icon> </button>
              </div>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>Cantidad</mat-label>
                <input matInput name="quantity" [(ngModel)]="metadata.quantity" >
              </mat-form-field>
              <div class="col-2">
                <button mat-icon-button (click)="addQuantity();"> <mat-icon>add_circle_outline</mat-icon> </button>
              </div>
            </div>
          </div>
          <p class="col-12">Si quieres agregar complementos ó observaciones agregalas aqui.</p>
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Complementos</mat-label>
            <textarea matInput name="description" [(ngModel)]="metadata.description"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width:100%;">
          <div class="col-6">
            <button mat-flat-button  color="warn" style="width:100%;" (click)="closeModal();">Cerrar</button>
          </div>
          <div class="col-6">
            <button mat-flat-button color="accent"  style="width:100%;" (click)="addProduct();">Agregar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
