import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { SessionService } from '../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { interval, Subject } from 'rxjs';


@Component({
  selector: 'app-cashier-dashboard',
  templateUrl: './cashier-dashboard.component.html',
  styleUrls: ['./cashier-dashboard.component.css']
})
export class CashierDashboardComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  branchList = [];

  qrConfiguration = {
    main: {
      uuid: '',
      theme: '',
      restaurant: true,
      hotel: false,
      carryOut: false,
      mexPago: false,
      payPal: false,
      promotionImage:false,
    },
    complement: {
      SbClientId: ''
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      },
      tax:{
        main:{
          uuid:''
        }
      }
    }
  }

  branchModel={
    main:{
      uuid:'',
      name:'',
      code:'',
    },
    system:{
      company:{
        main:{
          uuid:'',
          tradename:'',
        }
      }
    }
  }

  branchMin={
    main:{
      uuid:'',
      name:'',
      code:'',
    },
  }

  metadata = {
    dateFoodOrder: moment().format('DD-MM-YYYY'),
    hourFoodOrder: moment().format('HH:mm:ss'),
    status: 0,
    branch:{
      main:{
        uuid:'',
        name:''
      }
    },
    bar:{
      main:{
        uuid:'',
        name:'',
      }
    }
  }

  
  foodSaucerBranchMin={
    system:{
      foodSaucer:{
        main:{
          uuid:'',
        }
      },
      bar:{
        main:{
          uuid:'',
        }
      }
    }
  }

  foodOrderList = [];
  foodOrderList2 = [];
  foodOrderDetail = [];
  foodOrderDetailList = [];


  foodOrderMin={
    main:{
      carryOut: false,
      status:'Por pagar',
      dateFoodOrderStart: moment().format('DD/MM/YYYY HH:mm:ss'),
      dateFoodOrderEnd: moment().format('DD/MM/YYYY HH:mm:ss')
    },
    system:{
      branch:this.branchModel
    }
  }


  constructor(public dialog: MatDialog, private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    this.branchMin.main.uuid = this.session.getSessionObject().main.uuid;
   }

  ngOnInit(): void {
    this.getBranch();
  }

    /**función para obtener los datos de configuración de qr
  */
    getConfigurationQr(){
      this.session.getRequest("configurationQr:get",{system:{company:{main:{uuid: this.branchModel.system.company.main.uuid}}}}).subscribe(
        (data:any) => {
          console.log(data);
          this.qrConfiguration = data.object;
        }, error => {
          console.log("Error: configurationQr:get",error);
        });
    }
  
      /**función para obtener los datos de configuración de qr
    */
     getBranch(){
      this.session.getRequest("branch:get", {main:{uuid: this.session.getSessionObject().main.uuid}} ).subscribe(
        (data:any) => {
          console.log(data);
          this.branchModel = data.object;
          console.log(this.branchModel)
          this.getConfigurationQr();
          this.tabActive({index:0})
        }, error => {
          console.log("Error: configurationQr:get",error);
        });
    }

    getFoodOrder() {
      this.session.getRequest('foodOrder:findAllByBranchAndCarryOutAndStatusAndDateFoodOrderBetween', this.foodOrderMin).subscribe(
        (data:any) => {
         this.foodOrderList2 = data.object.instanceList;
         for(let x = 0; x < this.foodOrderList2.length; x++){
          this.foodOrderDetail = this.foodOrderList2[x].system.foodOrderDetails;
          console.log('36', this.foodOrderDetail)
            for(let y= 0; y < this.foodOrderDetail.length; y++){
              this.foodSaucerBranchMin.system.foodSaucer.main.uuid = this.foodOrderDetail[y].system.foodSaucer.main.uuid;
            }
           }
        
        }, error => {
          console.log("Error: foodOrder:findAllByBranchAndCarryOutAndStatusAndDateFoodOrderBetween", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
        });
    };
  
  

      // // /*
      // // Funcion a ejecutar cuando cambien el tab
      // // */
      // eventTab(event){
      //   // this.documentTypeList.max = 10;
      //   // this.documentTypeList.offset = 0;
      //   switch(event.index){
      //     case 0:{
      //       this.foodOrderList2;
      //       break;
      //     }
      //     case 1:{
      //       this.documentTypeList.system.objectAplication = 'Employee';
      //       break;
      //     }
      //   }
      //   this.listDocumentType();
      // }


      tabActive(event) {
        // obtenemos el index del tab
      //  console.log(event.index);
        if (event.index == 0) {
          this.foodOrderMin.main.status = 'Por pagar'
          this.foodOrderMin.system.branch = this.branchModel
          this.getFoodOrder();
        }
        if (event.index == 1) {
          this.foodOrderMin.main.status = 'Pagado'
          this.foodOrderMin.system.branch = this.branchModel
          this.getFoodOrder();
        }
      }


  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
