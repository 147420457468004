import { environment } from '../../../environments/environment';
/**url / endpoint para la conexión al backend
* *Si environment.production es true devuelve url de "url_de_producción"
* *Si environment.production es false devuelve url de "url_de_desarrollo"
*
*/
 const server_url:string = environment.production ? 'http://quickly.keysoft.mx/WEB-QR-ORDEN-0720/':'http://localhost:8090/';
 //const server_url:string = environment.production ? 'http://localhost:8090/':'http://localhost:8090/';

export default server_url;
