import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-company-client-configuration',
  templateUrl: './company-client-configuration.component.html',
  styleUrls: ['./company-client-configuration.component.css']
})
export class CompanyClientConfigurationComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  themeList = [
    {name: 'El gusto por las Tlayudas', step: 0, value:'restaurant-home', visible:true, checked:false},
    {name: 'El gusto por el mar', step: 1, value:'restaurant-seafood', visible:true, checked:false},
    {name: 'El gusto por la comida corrida', step: 2, value:'restaurant-country', visible:true, checked:false},
    {name: 'El gusto por los snacks', step: 3, value:'restaurant-retro', visible:true, checked:false},
    {name: 'Clásico', step: 4, value:'restaurant-hotel', visible:true, checked:false},

    // {name: 'Desahogo', value:'restaurant-home', visible:false}
  ]
  panelOpenState = false;

  configurationQrModel = {
    main: {
      uuid: '',
      theme: '',
      restaurant: true,
      hotel: false,
      carryOut: false,
      mexPago: false,
      payPal: false,
      promotionImage:false,
      whitImage:false
    },
    complement: {
      SbClientId: ''
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      },
      tax:{
        main:{
          uuid:''
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.configurationQrModel));
  metadata = {
    company:{
      main:{
        uuid:''
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.loadingService.show(true,'Obteniendo la configuración');
    this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("configurationQr:get",this.object).subscribe(
      (data:any) => {
        if(data.object.main.uuid != null){
          this.object.main = data.object.main;
        }
        this.metadata.company.main.uuid = this.session.getSessionObject().main.uuid;
        this.themeChange(this.object.main.theme)
        console.log(this.object);
        this.snackBar.open('La configuración se obtuvo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
      }, error => {
        console.log("Error: configurationQr:get",error);
        this.loadingService.hide();
      });
  }

  chargeRestaurant(event) {
    this.object.main.restaurant = event.checked;
  }

  chargeHotel(event) {
    this.object.main.hotel = event.checked;
  }

  chargeCarryOut(event) {
    this.object.main.carryOut = event.checked;
  }

  chargeMexPago(event) {
    this.object.main.mexPago = event.checked;
  }

  chargePayPal(event) {
    this.object.main.payPal = event.checked;
  }

  themeChange(theme) {
    for (let x=0; x<this.themeList.length; x++) {
      if (this.themeList[x].value == theme) {
        this.themeList[x].checked = true;
        this.object.main.theme = theme;
      } else {
        this.themeList[x].checked = false;
      }
    }
  }

  setStep(index: number) {
    this.themeList[index].step = index;
  }

  send(object) {
    this.loadingService.show(true,'Guardando configuración');
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("configurationQr:update", object).subscribe(
      (data:any) => {
        this.snackBar.open('La configuración se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
      }, error => {
        console.log("Error: configurationQr:update",error);
        this.loadingService.hide();
      });
  }

  /**
   * funcionalidad para cambiar el menú
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  /**funcionalidad para eliminar el logo
  */
  deleteAlog(){
    this.loadingService.wait();
    let companyUuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("configurationQr:deleteLogo",{system:{company:{main:{uuid:companyUuid}}}}).subscribe((data)=>{
      this.metadata.company.main.uuid = '';
      setTimeout(()=>{
        this.loadingService.hide();
        this.metadata.company.main.uuid = this.session.getSessionObject().main.uuid;
      },3000);
    },error=>{
      console.log("error:configurationQr:deleteLogo",error);
      this.loadingService.hide();
    })
  }

}
