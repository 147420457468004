import { Component, OnInit } from '@angular/core';
import { SessionService } from '../service/session/session.module';
import { LoadingService } from '../directive/loading/loading.module';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-loading-ksf',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  metadata = {
    animatedSplash:true,
    configurationQr:{
      main:{
        uuid:'',
        theme:''
      }
    }
  }

  parametersToSendModel = {
  system: {
    company: {
      main: {
        uuid: ''
      }
    },
    // walking: a entregar en sitio o lugar
    // foodTable: mesa del lugar
    // roomOccupation: habitación de un hotel
    object:'walking',
    objectUuid: ''
  }
}

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      // console.log('companyUuid',params['companyUuid']);
      // console.log('type',params['type']);
      // console.log('objectUuid',params['objectUuid']);
      /*guradamos los datos del qr para consultarlos en un futuro
      */
      let parameter ={
        company:{
          main:{
            uuid:params['companyUuid']
          }
        },
        object:params['type'],
        objectUuid:params['objectUuid']
      }
      this.session.setAnonymousSessionSystem(parameter)
      this.getSetting(params['companyUuid'],params['type'],params['objectUuid'])
    });
  }

  /** función para obtener la configuration de la BD
  */
  getSetting(companyUuid:string,type:string,objectUuid:string){
    this.loadingService.wait();
    localStorage.setItem("foodOrder", '');
    let aux = JSON.parse(JSON.stringify(this.parametersToSendModel));
    aux.system.company.main.uuid = companyUuid;
    aux.system.object = type;
    aux.system.objectUuid = objectUuid;
    this.session.getRequestAnonymous("configurationQr:load",aux).subscribe((data:any)=>{
      // console.log("configurationQr:load",data);
      this.metadata.configurationQr = data.object;
      this.session.setAnonymousSessionSettings(data.object);
      //buscamos  los datos de object y object uuid
      // console.log(type);
      switch(type){
        case 'branch':
          this.getBranch(objectUuid).then((data:any)=>{
            this.session.setAnonymousSessionObject(data.object);
            this.session.setAnonymousSessionBranch(data.object);
            this.getUrlByTheme(this.metadata.configurationQr.main.theme);
          }).catch(e=>{
            console.log(e);
            this.loadingService.hide();
          })
        break;
        case 'foodTable':
          this.getFoodTable(objectUuid).then((data:any)=>{
            this.session.setAnonymousSessionObject(data.object);
            this.getBranch(data.object.system.branch.main.uuid).then((data:any)=>{
              this.session.setAnonymousSessionBranch(data.object);
              this.getUrlByTheme(this.metadata.configurationQr.main.theme);
            }).catch(e=>{
              console.log(e);
              this.loadingService.hide();
            })
          }).catch(e=>{
            console.log(e);
            this.loadingService.hide();
          })
        break;
        case 'roomService':
        this.getRoomService(objectUuid).then((data:any)=>{
          this.session.setAnonymousSessionObject(data.object);
          this.getBranch(data.object.system.branch.main.uuid).then((data:any)=>{
            this.session.setAnonymousSessionBranch(data.object);
            this.getUrlByTheme(this.metadata.configurationQr.main.theme);
          }).catch(e=>{
            console.log(e);
            this.loadingService.hide();
          })
        }).catch(e=>{
          console.log(e);
          this.loadingService.hide();
        })
        break;
      }

      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();

    })
  }

  /**función para obtener los datos de la mesa
  */
  getFoodTable(objectUuid){
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous("foodTable:get",{main:{uuid:objectUuid}}).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        console.log("error:foodTable:get");
        reject(error);
      })
    })
  }

  getBranch(objectUuid){
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous("branch:get",{main:{uuid:objectUuid}}).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        console.log("error:foodTable:get");
        reject(error);
      })
    })
  }

/**función para seleccionar un thema deacuerdo a la configuración
*/
  getUrlByTheme(theme:string){
    switch(theme){
      case 'restaurant-home':
        this.router.navigate(['/restaurant']);
      break;
      case 'restaurant-seafood':
        this.router.navigate(['/restaurant-seafood']);
      break;
      case 'restaurant-country':
        this.router.navigate(['/restaurant-country']);
      break;
      case 'restaurant-retro':
        this.router.navigate(['/restaurant-retro']);
      break;
      case 'restaurant-hotel':
        this.router.navigate(['/restaurant-hotel']);
      break;
      default:
        console.log("El administardor no ha seleccionado un tema");
      break;
    }
  }

  /**función para obtener los datos de la habitación
  */
  getRoomService(objectUuid){
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous("roomService:get",{main:{uuid:objectUuid}}).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        console.log("error:roomService:get");
        reject(error);
      })
    })
  }


}
