import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import { CompanyClientWaiterUpdateComponent } from './company-client-waiter-update/company-client-waiter-update.component';
import { CompanyClientWaiterAccessComponent } from './company-client-waiter-access/company-client-waiter-access.component';

@Component({
  selector: 'app-company-client-waiter',
  templateUrl: './company-client-waiter.component.html',
  styleUrls: ['./company-client-waiter.component.css']
})
export class CompanyClientWaiterComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  waiterMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      status: true,
    },
    max: 10,
    offset: 0,
    sort: "mCode",
    order: "asc",
  };

  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;
  waiterList = [];


  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    this.waiterMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
   }
 

  ngOnInit() {
    this.getWaiter();
  }

   /** función para obtener las meseros
  */
   getWaiter() {
    this.loadingService.wait();
    this.session.getRequest('waiter:list', this.waiterMin).subscribe(
      (data: any) => {
        this.waiterList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("waiter:list", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

    // funcion para obtener los datos del paginado.
    onPaginateChange(event) {
      this.waiterMin.offset = (event.pageIndex * event.pageSize);
      this.waiterMin.max = event.pageSize;
      this.getWaiter();
    }

    /**
   * funcionalidad para cambiar el menú
   */
    toogleMenu(ev) {
      this.drawerCtrl.opened = ev;
    }


  /**funcionalidad para crea o actualizar un mesero
  */
  update(uuid){
    const dialogRef = this.dialog.open(CompanyClientWaiterUpdateComponent, {
      width: '98%',
      height:'80%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getWaiter();
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

    /**funcionalidad para asignar un mesero a una sucursal
  */
    access(uuid){
      const dialogRef = this.dialog.open(CompanyClientWaiterAccessComponent, {
        width: '98%',
        height:'80%',
        data: uuid,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            // El modal se cerro con objeto
            this.getWaiter();
          }else{
            // El modal se cerro sin objeto
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

}
