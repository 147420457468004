import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';
import { Router } from '@angular/router';

@Injectable()
export class ReloadAnomynus implements CanActivate {

  constructor(private session: SessionService, private router: Router) {
    //verificamos si existen variables de session para permitir el reload
    this.session.realodSessionAnonymus().then(data =>{
      if(data){
        // no hacemos nada y permitimos el reload
      }else{
      }
    })
   }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return true;
  }
}
