<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer2" mode="push" position="end">
    <app-menu-client></app-menu-client>
  </mat-drawer>
  <div class="row justify-content-center home-restaurant">
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="Regresar" matTooltip="Regresar" routerLink="/restaurant" color="accent">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>
    <div class="col-8 text-center">
      <!-- <img class="mt-1 mb-2 img-toolbar" [src]="metadata.logo" width="30%"> -->
    </div>
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" color="accent">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
  <div class="row home-restaurant">
    <div class="col-12">
      <ngb-carousel *ngIf="object.main.promotionImage">
        <ng-template ngbSlide *ngFor="let object of promotionList">
          <div class="picsum-img-wrapper">
            <img [src]="object.image" alt="promo" width="100%" *ngIf="object.image">
            <div class="sk-circle" *ngIf="object.image == undefined">
              <div class="sk-circle1 sk-child"></div>
              <div class="sk-circle2 sk-child"></div>
              <div class="sk-circle3 sk-child"></div>
              <div class="sk-circle4 sk-child"></div>
              <div class="sk-circle5 sk-child"></div>
              <div class="sk-circle6 sk-child"></div>
              <div class="sk-circle7 sk-child"></div>
              <div class="sk-circle8 sk-child"></div>
              <div class="sk-circle9 sk-child"></div>
              <div class="sk-circle10 sk-child"></div>
              <div class="sk-circle11 sk-child"></div>
              <div class="sk-circle12 sk-child"></div>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
  <div class="row home-restaurant pt-2 pb-5">
    <div class="col-6 text-center" *ngFor="let foodCat of foodCategoryList" routerLink="/restaurant/menu/{{foodCat.main.uuid}}">
      <img src="/assets/img/{{object.main.theme}}/{{foodCat.complement.icon}}" style="width:9rem;">
      <h3 class="text-warning">{{foodCat.main.name}}</h3>
    </div>
  </div>
  <footer class="text-center text-secondary p-1">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</mat-drawer-container>
