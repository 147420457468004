import { Component, Input, Output, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-waiter-order-product',
  templateUrl: './waiter-order-product.component.html',
  styleUrls: ['./waiter-order-product.component.css']
})
export class WaiterOrderProductComponent {

  // complexForm: FormGroup;

  foodOrderDetailModel ={
    main:{
      uuid: '',
      quantity: 1,
      observation:'',
      priceUnitary:'',
      subTotal:0,
      tax:0,
    },
    complement:{
      peopleNumber:'',
    },
    system:{
      foodSaucer:{
        main:{
          uuid:''
        }
      },
      foodSaucerSize:{
        main:{
          uuid:'',
          order:0,
          price:0.0,
          size:'',    
        }
      }
    }
  }

  metadata={
    //totalFoodSaucers:'', //total de platillos de la comanda
    clientUuid:'',//uuid del cliente(cliente al publico)
  }

  // foodSaucerSizeMin={
  //   system:{
  //     foodSaucer:{
  //       main:{
  //         uuid:''
  //       }
  //     }
  //   }
  // } 

  // foodSaucerSizeList  = [];
  foodOrderDetails=[];

  saleModel={
    main:{
      uuid:'',
      saleNumber:'',
      total: 0,
      subTotal: 0,
      taxTotal: 0,
      tripAmount:0,
      status:'Por cobrar',
      tripType: false,
    },
    system:{
      typeSale:'',
      saleUuid:'',
      client:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      waiter:{
        main:{
          uuid:''
        }
      },

    },
  }

  clientMin={
    max: 10,
    offset: 0,
    sort: "mCode",
    order: "asc",
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  }

  foodTableModel={
    main:{
      uuid:'',
      number:0,
      status:'',
    },
    system:{
      restaurantArea:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      }
    }
  }

  clientList= [];


  object = JSON.parse(JSON.stringify(this.foodOrderDetailModel));
  sale = JSON.parse(JSON.stringify(this.saleModel));
  foodTable = JSON.parse(JSON.stringify(this.foodTableModel));

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<WaiterOrderProductComponent >, @Inject(MAT_DIALOG_DATA) public data: any) {
    // this.complexForm = fb.group({
    //   // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
    //   'quantity': [null, Validators.required],
    // })

   }

  ngOnInit() {
    if(this.data != 'new') {
     // this.loadingService.wait();
       this.object = this.data;
      this.foodOrderDetails = this.data.system.foodOrderDetails;
      this.foodTable = this.data.system.foodTable;

/* 
        let totalFoodSaucers = localStorage.getItem('totalFoodSaucers');
        this.metadata.totalFoodSaucers = totalFoodSaucers; */
       console.log('**',this.foodOrderDetails)
       console.log('%%%',this.object)
       this.findAllByCompanyAndStatus();
       // cargamos los datos de la BD
   /*     this.session.getRequest('waiter:get',this.object).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));

         this.session.getRequest('user:findByPhysicalPerson',{system:{ physicalPerson:{main:{uuid: this.object.system.physicalPerson.main.uuid}}}}).subscribe((data:any)=>{
          this.user = JSON.parse(JSON.stringify(data.object));
          this.loadingService.hide();
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error: waiter:get',error)
        })

         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: waiter:get',error)
       })
    } */
    //console.log(this.data.system.foodSaucer.main.uuid, "uuid de foodSaucer");
    //this.object.system.foodSaucer = this.data.system.foodSaucer;
    //this.getFoodSaucerSizeList();
  }
}

//funcion para obtener el uuid del cliente
findAllByCompanyAndStatus(){
  this.loadingService.wait();
  this.clientMin.system.company.main.uuid = this.session.getSessionObject().system.company.main.uuid;
  this.session.getRequest('client:findAllByCompanyAndStatus', this.clientMin).subscribe(
    (data: any) => {
      this.clientList = data.object.instanceList;
       for(let c=0; c<this.clientList.length; c++){
     this.metadata.clientUuid = this.clientList[c].main.uuid;
   }
      this.loadingService.hide();
    }, error => {
      console.log("Error: client:findAllByCompanyAndStatus", error);
      this.snackBar.open(error.message, 'Error', { duration: 5000 });
      this.loadingService.hide();
    })
}

 


  // /**
  //  * Tamaño del platillo
  //  */
  //   getFoodSaucerSizeList() {
  //     this.loadingService.wait();
  //     this.foodSaucerSizeMin.system.foodSaucer.main.uuid =this.data.system.foodSaucer.main.uuid;
  //     this.session.getRequest('foodSaucerSize:findAllByFoodSaucer', this.foodSaucerSizeMin ).subscribe(
  //     (data: any) => {
  //       this.foodSaucerSizeList = data.object.instanceList;
  //       this.loadingService.hide();
  //       }, error => {
  //       console.log("Error: restaurantArea:findAllByBranch", error);
  //       this.snackBar.open(error.message, 'Error', { duration: 5000 });
  //       this.loadingService.hide();
  //       })
  //   }


  // send(foodSaucerDetail){
  //   foodSaucerDetail.system.foodSaucer = this.data.system.foodSaucer;
  //   foodSaucerDetail.system.foodSaucer.main.uuid = this.data.system.foodSaucer.main.uuid;
  //   for(let x=0; x<this.foodSaucerSizeList.length; x++){
  //     if(this.foodSaucerSizeList[x].main.uuid == foodSaucerDetail.system.foodSaucerSize.main.uuid){
  //       foodSaucerDetail.system.foodSaucerSize = this.foodSaucerSizeList[x];
  //     }
  //   }
  //   let peopleNumber = localStorage.getItem('peopleNumber');
  //   foodSaucerDetail.complement.peopleNumber = peopleNumber;
  //   let object = {
  //     transaction:'',
  //     code:'001',
  //     object:{}
  //   };
    
  //     object.transaction = 'ok';
  //     object.object = foodSaucerDetail;

  //   this.dialogRef.close(object);
  // } 

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'waiter:001';
    }
    this.dialogRef.close(object);
  }

  charge(object){
        this.loadingService.show(true, 'Generando su ticket espere por favor ....');
/*          object.main.dateFoodOrder = moment().format('DD-MM-YYYY hh:mm:ss');
         object.main.status = 'Por pagar'
         this.session.getRequest('foodOrder:update', object).subscribe((data: any) => {
          this.foodTable.main.status = 'Disponible';
         this.session.getRequest("foodTable:update", this.foodTable).subscribe(
          (data: any) => {
            this.snackBar.open('Se actualiza '+ this.foodTable.main.status + ' correctamente', '', {
              duration: 1000
            });
            this.loadingService.hide();
          }, error => {
            console.log("Error: foodTable:update", error);
            this.loadingService.hide();
          });
        this.object = JSON.parse(JSON.stringify(data.object));
       // console.log('38',this.object)
         this.sale.system.client.main.uuid = this.metadata.clientUuid
         this.sale.system.typeSale = 'foodOrder';
         this.sale.system.saleUuid = this.object.main.uuid;
         this.sale.system.branch.main.uuid = this.session.getSessionObject().main.uuid;
         this.sale.system.waiter.main.uuid = this.object.system.waiter.main.uuid;
         //console.log('34', this.object.main.uuid)
         this.session.getRequest("sale:update", this.sale).subscribe(
           (data: any) => {
             this.sale = data.object;
             console.log('45',this.sale)
             this.snackBar.open('Se actualiza '+ this.sale.main.status + ' correctamente', '', {
               duration: 1000
             }); */

            //  para actualizar la mesa--- ponerlo despues del ticket --- tambien falta pasar la foodOrden a "Entregada"
        /*      this.foodTable.main.status = 'Disponible';
             this.session.getRequest("foodTable:update", this.foodTable).subscribe(
              (data: any) => {
                this.snackBar.open('Se actualiza '+ this.foodTable.main.status + ' correctamente', '', {
                  duration: 1000
                });
                this.loadingService.hide();
              }, error => {
                console.log("Error: foodTable:update", error);
                this.loadingService.hide();
              }); */

             //console.log('aquiui', object)

             this.sale.system.client.main.uuid = this.metadata.clientUuid
             this.sale.system.typeSale = 'foodOrder';
             this.sale.system.saleUuid = this.object.main.uuid;
             this.sale.system.branch.main.uuid = this.session.getSessionObject().main.uuid;
             this.sale.system.waiter.main.uuid = this.object.system.waiter.main.uuid;
             this.sale.main.total= object.main.total;
             this.sale.main.subTotal = object.main.subTotal;
             //console.log('34', this.object.main.uuid)
             this.session.getRequest("sale:update", this.sale).subscribe(
               (data: any) => {
                 this.sale = data.object;
                 console.log('45',this.sale)
                 this.snackBar.open('Se actualiza '+ this.sale.main.status + ' correctamente', '', {
                   duration: 1000
                 });
    // --
             this.loadingService.show(true, 'Descargando ticket, espere un momento...');
              this.session.getRequest('sale:ticket', this.sale).subscribe(
               (data: any) => {
                 var elem = document.createElement('a');
                  elem.href = "data:application/pdf;base64,"+ data.object;
                 elem.setAttribute('download', this.sale.main.saleNumber +'.pdf');
                 document.body.appendChild(elem);
                 elem.click();
                 this.loadingService.hide();
               },
               error => {
                 console.log("sale:ticket", error);
                 this.loadingService.hide();
                 this.snackBar.open('Error en la descarga', 'Error', { duration: 5000 });
               })

               this.loadingService.hide();
              }, error => {
                console.log("Error: sale:update", error);
                this.loadingService.hide();
              });
    // --
/*              this.loadingService.hide();
           }, error => {
             console.log("Error: sale:update", error);
             this.loadingService.hide();
           });
    
        this.snackBar.open('La orden esta lista para cobrar', 'Información', {
          duration: 8000
        });
       //  this.router.navigate(['/waiter/dashboard']);
          this.loadingService.hide();
        },
         (error) => {
            console.log('Error:foodOrder:update', error)
            this.snackBar.open(error.message, 'Error', { duration: 5000 });
            this.loadingService.hide();
          });
     */
      }

      closingBill(){
        console.log('foodOrder?',this.object)
        this.object.main.status = 'Entregada'
        this.object.main.dateFoodOrder = moment().format('DD-MM-YYYY hh:mm:ss');
        this.session.getRequest('foodOrder:update', this.object).subscribe(
          (data: any) => {
          //se actualiza el estatus de la mesa a "ocupada"
          this.foodTable.main.status = 'Disponible';
        this.session.getRequest("foodTable:update", this.foodTable).subscribe(
          (data: any) => {
            this.snackBar.open('Se actualiza '+ this.foodTable.main.status + ' correctamente', '', {
              duration: 1000
            });
    
            this.loadingService.hide();
          }, error => {
            console.log("Error: foodTable:update", error);
            this.loadingService.hide();
          });
    
        this.object = JSON.parse(JSON.stringify(data.object));
        this.snackBar.open('Se agrega orden correctamente', 'Información', {
          duration: 8000
        });
        this.close(true);
         this.router.navigate(['/waiter/dashboard']);
          this.loadingService.hide();
        },
          (error) => {
            console.log('Error:foodOrder:update', error)
            this.snackBar.open(error.message, 'Error', { duration: 5000 });
            this.loadingService.hide();
          });
    
      }
    
  
}
