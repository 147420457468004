import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';

@Component({
  selector: 'app-restaurant-seafood-menu-collapsed',
  templateUrl: './restaurant-seafood-menu-collapsed.component.html',
  styleUrls: ['./restaurant-seafood-menu-collapsed.component.css']
})
export class RestaurantSeafoodMenuCollapsedComponent implements OnInit {

  metadata = {
    branch:{
      main:{
        uuid:''
      }
    }
  }
  configurationQr = {
    main:{
      uuid:'',
      theme:''
    }
  }
  foodCategoryList = [];
  constructor(private session: SessionService) { }

  ngOnInit(): void {
    this.configurationQr = this.session.getConfigurationQr();
    this.metadata.branch = this.session.getAnonymousSessionBranch();
    this.getCategories();
  }

  /**función para obtener el listado de categorias de una sucursal
  */
  getCategories(){
    this.session.getRequestAnonymous("foodCategory:findAllByBranch",{system:{branch:{main:this.metadata.branch.main}}}).subscribe((data:any)=>{
      this.foodCategoryList = data.object.instanceList;
    },error=>{
      console.log(error,"error:foodCategory:findAllByBranch");
    })
  }
}
