import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/session/session.module';
import { ReloadAnomynus } from './service/session/reloadAnonymus';

import { RestaurantWelcomeComponent } from './restaurant/restaurant-welcome/restaurant-welcome.component';
import { RestaurantHomeComponent } from './restaurant/restaurant-home/restaurant-home.component';
import { RestaurantProductDetailComponent } from './restaurant/restaurant-product-detail/restaurant-product-detail.component';
import { RestaurantMenuComponent } from './restaurant/restaurant-menu/restaurant-menu.component';
import { RestaurantOrderComponent } from './restaurant/restaurant-order/restaurant-order.component';
import { RestaurantPaymentComponent } from './restaurant/restaurant-payment/restaurant-payment.component';

import { LoginComponent } from './login/login.component';
import { LoadingComponent } from './loading/loading.component';

import { AdminCompanyClientUpdateComponent } from './admin/admin-company-client/admin-company-client-update/company-client-update.component';
import { AdminCompanyClientComponent } from './admin/admin-company-client/admin-company-client.component';
import { AdminUserComponent }  from './admin/admin-user/admin-user.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminRoleComponent } from './admin/admin-role/admin-role.component';

import { CompanyClientDashboardComponent } from './companyClient/company-client-dashboard/company-client-dashboard.component';
import { CompanyClientBranchComponent } from './companyClient/company-client-branch/company-client-branch.component';
import { CompanyClientRestaurantAreaComponent } from './companyClient/company-client-restaurant-area/company-client-restaurant-area.component';
import { CompanyClientProductComponent } from './companyClient/company-client-product/company-client-product.component';
import { CompanyClientProductUpdateComponent } from './companyClient/company-client-product/company-client-product-update/company-client-product-update.component';
import { CompanyClientConfigurationComponent } from './companyClient/company-client-configuration/company-client-configuration.component';
import { CompanyClientHotelComponent } from './companyClient/company-client-hotel/company-client-hotel.component';
import { CompanyClientHotelUpdateComponent } from './companyClient/company-client-hotel/company-client-hotel-update/company-client-hotel-update.component';
import { CompanyClientWaiterComponent } from './companyClient/company-client-waiter/company-client-waiter.component';
import { CompanyClientBarComponent } from './companyClient/company-client-bar/company-client-bar.component';
import { CompanyClientChefComponent } from './companyClient/company-client-chef/company-client-chef.component';

import { RestaurantCountryHomeComponent } from './restaurant-country/restaurant-country-home/restaurant-country-home.component';
import { RestaurantCountryWelcomeComponent } from './restaurant-country/restaurant-country-welcome/restaurant-country-welcome.component';
import { RestaurantCountryMenuComponent } from './restaurant-country/restaurant-country-menu/restaurant-country-menu.component';
import { RestaurantCountryProductDetailComponent } from './restaurant-country/restaurant-country-product-detail/restaurant-country-product-detail.component';
import { RestaurantCountryOrderComponent } from './restaurant-country/restaurant-country-order/restaurant-country-order.component';
import { RestaurantCountryPaymentComponent } from './restaurant-country/restaurant-country-payment/restaurant-country-payment.component';
import { RestaurantSeafoodHomeComponent } from './restaurant-seafood/restaurant-seafood-home/restaurant-seafood-home.component';
import { RestaurantSeafoodWelcomeComponent } from './restaurant-seafood/restaurant-seafood-welcome/restaurant-seafood-welcome.component';
import { RestaurantSeafoodProductDetailComponent } from './restaurant-seafood/restaurant-seafood-product-detail/restaurant-seafood-product-detail.component';
import { RestaurantSeafoodMenuComponent } from './restaurant-seafood/restaurant-seafood-menu/restaurant-seafood-menu.component';
import { RestaurantSeafoodOrderComponent } from './restaurant-seafood/restaurant-seafood-order/restaurant-seafood-order.component';
import { RestaurantSeafoodPaymentComponent } from './restaurant-seafood/restaurant-seafood-payment/restaurant-seafood-payment.component';

import { RestaurantRetroHomeComponent } from './restaurant-retro/restaurant-retro-home/restaurant-retro-home.component';
import { RestaurantRetroWelcomeComponent } from './restaurant-retro/restaurant-retro-welcome/restaurant-retro-welcome.component';
import { RestaurantRetroProductDetailComponent } from './restaurant-retro/restaurant-retro-product-detail/restaurant-retro-product-detail.component';
import { RestaurantRetroMenuComponent } from './restaurant-retro/restaurant-retro-menu/restaurant-retro-menu.component';
import { RestaurantRetroOrderComponent } from './restaurant-retro/restaurant-retro-order/restaurant-retro-order.component';
import { RestaurantRetroPaymentComponent } from './restaurant-retro/restaurant-retro-payment/restaurant-retro-payment.component';
import { RestaurantRetroMenuTableComponent } from './restaurant-retro/restaurant-retro-menu-table/restaurant-retro-menu-table.component';

import { RestaurantHotelHomeComponent } from './restaurant-hotel/restaurant-hotel-home/restaurant-hotel-home.component';
import { RestaurantHotelWelcomeComponent } from './restaurant-hotel/restaurant-hotel-welcome/restaurant-hotel-welcome.component';
import { RestaurantHotelProductDetailComponent } from './restaurant-hotel/restaurant-hotel-product-detail/restaurant-hotel-product-detail.component';
import { RestaurantHotelMenuComponent } from './restaurant-hotel/restaurant-hotel-menu/restaurant-hotel-menu.component';
import { RestaurantHotelOrderComponent } from './restaurant-hotel/restaurant-hotel-order/restaurant-hotel-order.component';
import { RestaurantHotelPaymentComponent } from './restaurant-hotel/restaurant-hotel-payment/restaurant-hotel-payment.component';
import { RestaurantHotelMenuSnComponent } from './restaurant-hotel/restaurant-hotel-menu-sn/restaurant-hotel-menu-sn.component';


import { CompanyClientPromotionComponent } from './companyClient/company-client-promotion/company-client-promotion.component';
import { BranchKitchenComponent } from './branch/branch-kitchen/branch-kitchen.component';

import { WaiterDashboardComponent } from './waiter/waiter-dashboard/waiter-dashboard.component';
import { WaiterOrderComponent } from './waiter/waiter-order/waiter-order.component';

import { KitchenDashboardComponent } from './kitchen/kitchen-dashboard/kitchen-dashboard.component';
import { KitchenBarComponent } from './kitchen/kitchen-bar/kitchen-bar.component';

import { CashierDashboardComponent } from './cashier/cashier-dashboard/cashier-dashboard.component';
import { CashierSaleComponent } from './cashier/cashier-sale/cashier-sale.component';

const routes: Routes = [
  { path :'' ,redirectTo:'login/admin' , pathMatch:'full' },
  { path: 'login/admin', component: LoginComponent },
  { path: 'loading/:companyUuid/:type/:objectUuid',component:LoadingComponent },
  { path: 'restaurant',component:RestaurantWelcomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant/home',component:RestaurantHomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant/menu/:uuid',component:RestaurantMenuComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant/details/:uuid',component:RestaurantProductDetailComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant/order',component:RestaurantOrderComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant/order/payment',component:RestaurantPaymentComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-country',component:RestaurantCountryWelcomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-country/home',component:RestaurantCountryHomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-country/menu/:uuid',component:RestaurantCountryMenuComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-country/product-detail/:uuid',component:RestaurantCountryProductDetailComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-country/order',component:RestaurantCountryOrderComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-country/order/payment',component:RestaurantCountryPaymentComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-seafood',component:RestaurantSeafoodWelcomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-seafood/home',component:RestaurantSeafoodHomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-seafood/product-detail/:uuid',component:RestaurantSeafoodProductDetailComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-seafood/menu/:uuid',component:RestaurantSeafoodMenuComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-seafood/order',component:RestaurantSeafoodOrderComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-seafood/order/payment',component:RestaurantSeafoodPaymentComponent, canActivate:[ReloadAnomynus] },
  { path: 'admin/dashboard',component:AdminDashboardComponent, canActivate:[AuthenticationGuard] },
  { path: 'admin/users', component: AdminUserComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'admin/roles', component: AdminRoleComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'admin/companyClient', component: AdminCompanyClientComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'admin/companyClient/update/:uuid', component:AdminCompanyClientUpdateComponent, canActivate:[AuthenticationGuard] },
  { path: 'companyClient/dashboard', component: CompanyClientDashboardComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/branch', component: CompanyClientBranchComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/restaurantArea', component: CompanyClientRestaurantAreaComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/hotel', component: CompanyClientHotelComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/products', component: CompanyClientProductComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/products/update', component: CompanyClientProductUpdateComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/configuration', component: CompanyClientConfigurationComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/promotions', component: CompanyClientPromotionComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'branch/kitchen', component: BranchKitchenComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/waiter', component: CompanyClientWaiterComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/bar', component: CompanyClientBarComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'companyClient/chef', component: CompanyClientChefComponent, canActivate:[ AuthenticationGuard ] },
  

  { path: 'restaurant-retro',component:RestaurantRetroWelcomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-retro/home',component:RestaurantRetroHomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-retro/product-detail/:uuid',component:RestaurantRetroProductDetailComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-retro/menu/:uuid',component:RestaurantRetroMenuComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-retro/menu-table/:uuid',component:RestaurantRetroMenuTableComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-retro/order',component:RestaurantRetroOrderComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-retro/order/payment',component:RestaurantRetroPaymentComponent, canActivate:[ReloadAnomynus] },

  { path: 'restaurant-hotel',component:RestaurantHotelWelcomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-hotel/home',component:RestaurantHotelHomeComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-hotel/product-detail/:uuid',component:RestaurantHotelProductDetailComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-hotel/menu/:uuid',component:RestaurantHotelMenuComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-hotel/order',component:RestaurantHotelOrderComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-hotel/order/payment',component:RestaurantHotelPaymentComponent, canActivate:[ReloadAnomynus] },
  { path: 'restaurant-hotel/menu/sn/:uuid',component:RestaurantHotelMenuSnComponent, canActivate:[ReloadAnomynus] },

  { path: 'waiter/dashboard', component: WaiterDashboardComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'waiter/order/:uuid', component: WaiterOrderComponent, canActivate:[ AuthenticationGuard ] },

  { path: 'kitchen/dashboard', component: KitchenDashboardComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'kitchen/bar', component: KitchenBarComponent, canActivate:[ AuthenticationGuard ] },

  { path: 'cashier/dashboard', component: CashierDashboardComponent, canActivate:[ AuthenticationGuard ] },
  { path: 'cashier/sale/:uuid', component: CashierSaleComponent, canActivate:[ AuthenticationGuard ] },

  

  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true,onSameUrlNavigation:'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
