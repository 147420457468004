<div class="row bg-dark">
  <div class="col-12 text-center pt-3 pb-3">
    <h3 class="text-white text-uppercase">Detalle de Producto</h3>
  </div>
</div>
<div class="row justify-content-center mt-2">
  <mat-accordion class="col-11">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>Datos Generales</mat-panel-title>
        <mat-icon >room_service</mat-icon>
      </mat-expansion-panel-header>
      <div class="row">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>&nbsp;Nombre de platillo</mat-label>
          <input matInput placeholder="Nombre de platillo" name="name" [(ngModel)]="object.main.name" (change)="send();">
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Descripción de platillo</mat-label>
          <textarea matInput placeholder="Descripción de platillo" name="description" [(ngModel)]="object.main.description" (change)="send();"></textarea>
          <!-- <mat-error *ngIf="complexForm.controls['description'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error> -->
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-8">
          <mat-slide-toggle (ngModelChange)="slideToggle(object.main.foodSaucerSize, 'foodSaucerSize');" [(ngModel)]="object.main.foodSaucerSize" [labelPosition]="'before'">Precio por tamaño</mat-slide-toggle>
        </div>
        <div class="col-4 text-right mb-1">
          <!-- <button style="width:100%" mat-flat-button color="accent"  matTooltip="Crear nuevo producto" (click)="edit('new')"> <i class="material-icons">add_circle_outline</i>Crear nuevo producto</button> -->
          <button style="width:100%; color: #ffffff;" mat-flat-button [ngClass]="object.main.foodSaucerSize ? 'btn-danger' : ''" [disabled]="!object.main.foodSaucerSize" matTooltip="Agregar tamaño de porción" (click)="addFoodSaucerSize()"><i class="material-icons">add_circle_outline</i>Nuevo tamaño</button>
        </div>
      </div>
      <div *ngIf="object.main.foodSaucerSize" class="row mb-2">
        <div class="col-12" *ngFor="let foodSaucerSize of foodSaucerSizeList">
          <mat-checkbox class="example-margin" [(ngModel)]="foodSaucerSize.system.status" (change)="send();" >
          </mat-checkbox>
          <mat-form-field class="col-4" appearance="outline">
            <mat-label>Tamaño</mat-label>
            <input matInput placeholder="Grande, Familiar, Jumbo, etc." name="{{foodSaucerSize.main.uuid}}_name" (change)="send();" [(ngModel)]="foodSaucerSize.main.size" >
          </mat-form-field>
          <mat-form-field class="col-3" appearance="outline">
            <mat-label>Precio</mat-label>
            <input matInput type="number" placeholder="$ 000.00" name="{{foodSaucerSize.main.uuid}}_price" (change)="send();" [(ngModel)]="foodSaucerSize.main.price" >
          </mat-form-field>
          <mat-form-field class="col-4" appearance="outline">
            <mat-label>Orden de presentación</mat-label>
            <input matInput type="number" placeholder="Orden en el que se mostraran" name="{{foodSaucerSize.main.order}}_price" (change)="send();" [(ngModel)]="foodSaucerSize.main.order" >
          </mat-form-field>
        </div>
      </div>
      <br />
      <div class="row">
        <mat-form-field class="col-4" appearance="outline">
          <mat-label>Precio de platillo</mat-label>
          <input [disabled]="object.main.foodSaucerSize" matInput placeholder="000.00" name="price" [(ngModel)]="object.main.price" (change)="send();">
          <mat-error *ngIf="complexForm.controls['price'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <div class="col-8 text-right">
          <mat-slide-toggle (ngModelChange)="slideToggle(object.main.tax, 'tax');" [(ngModel)]="object.main.tax" [labelPosition]="'before'">Incluye impuestos</mat-slide-toggle>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle [disabled]="object.main.uuid == ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Complementos
        </mat-panel-title>
        <mat-icon>fastfood</mat-icon>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-7"></div>
        <div class="col-5 text-right mt-1 mb-1">
          <button style="width:100%; color: #ffffff;" mat-flat-button class="btn-danger" matTooltip="Agregar complemento del platillo" (click)="addFoodSaucerComplement()"><i class="material-icons">add_circle_outline</i>Nuevo complemento</button>
        </div>
      </div>
      <div class="row content-justify-center" *ngFor="let foodSaucerComplement of foodSaucerComplementList">
        <div class="col-12">
          <div class="row content-justify-center">
            <mat-form-field class="col-10" appearance="outline">
              <mat-label>Nombre del complemento</mat-label>
              <input matInput placeholder="Ingrediente extra, etc." name="{{foodSaucerComplement.main.uuid}}_name" [(ngModel)]="foodSaucerComplement.main.name" (change)="complementUpdate(foodSaucerComplement);">
            </mat-form-field>
            <div class="col-5 text-right">
              <mat-slide-toggle class="" (ngModelChange)="complementUpdate(foodSaucerComplement);" [(ngModel)]="foodSaucerComplement.main.complement" [labelPosition]="'before'">{{ foodSaucerComplement.main.complement ? 'Precio extra': 'Sin costo'}}</mat-slide-toggle>
            </div>
            <div class="col-5 text-right">
              <mat-slide-toggle class="" (ngModelChange)="complementUpdate(foodSaucerComplement);" [(ngModel)]="foodSaucerComplement.main.anyFood" [labelPosition]="'before'">{{foodSaucerComplement.main.anyFood ? 'Seleccionar varios' : 'Selecciona solo uno' }}</mat-slide-toggle>
            </div>
          </div>
          <div class="row content-justify-center">
            <div class="col-12" *ngFor="let foodSaucerComplementDetail of foodSaucerComplement.system.foodSaucerComplementDetails">
              <mat-checkbox class="example-margin" [(ngModel)]="foodSaucerComplementDetail.system.status" (change)="send();" >
              </mat-checkbox>
              <mat-form-field class="col-7" appearance="outline">
                <mat-label>Descripción</mat-label>
                <input matInput placeholder="Rigatonni, Proteina etc." name="{{foodSaucerComplementDetail.main.uuid}}_name" (change)="send();" [(ngModel)]="foodSaucerComplementDetail.main.description" >
              </mat-form-field>
              <mat-form-field *ngIf="foodSaucerComplement.main.complement" class="col-4" appearance="outline">
                <mat-label>Precio</mat-label>
                <input matInput placeholder="$ 000.00" name="{{foodSaucerComplementDetail.main.uuid}}_price" (change)="send();" [(ngModel)]="foodSaucerComplementDetail.main.price" >
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle [disabled]="object.main.uuid == ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Sucursal , categoria y barra del platillo 
        </mat-panel-title>
        <mat-icon>pin_drop</mat-icon>
      </mat-expansion-panel-header>
      <div class="row" *ngFor="let branch of branchList">
        <div class="col-12">
          <h4>{{branch.main.name}}</h4>
          <h4>Categoria</h4>
          <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="foodCategory.system.selected" (change)="foodCategoryUpdate(foodCategory, branch.system.categories);" *ngFor="let foodCategory of branch.system.categories">
                <img style="background: #000000;width: 3rem;" src="/assets/img/{{metadata.configurationQr.main.theme}}/{{foodCategory.complement.icon}}"><br/>
                {{foodCategory.main.name}}
            </mat-checkbox>&nbsp;&nbsp;
          </section>
          <h4>Barra</h4>
          <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="bar.system.selected" (change)="barUpdate(bar, branch.system.bars);" *ngFor="let bar of branch.system.bars">
                {{bar.main.name}}&nbsp;&nbsp;
            </mat-checkbox>&nbsp;&nbsp;
          </section>
          <hr>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle [disabled]="object.main.uuid == ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Imagenes del platillo
        </mat-panel-title>
        <mat-icon>photo</mat-icon>
      </mat-expansion-panel-header>
      <div class="row">
        <!-- <mat-form-field class="col-12" appearance="outline">
          <mat-label>Imagen</mat-label>
          <input matInput placeholder="Imagen" name="mName">
        </mat-form-field> -->
        <div class="col-12">
          <keysoft-upload [uploadCode]="'foodSaucer:upload'" [downloadCode]="'foodSaucer:download'" [listFilesCode]="'foodSaucer:getImage'" [deleteCode]="'foodSaucer:deleteImage'" [objectUuid]="object.main.uuid" [multiple]="false"></keysoft-upload>
          <!-- <keysoft-upload [uploadCode]="'foodSaucer:upload'" [downloadCode]="'foodSaucer:download'" [listFilesCode]="'foodSaucer:getImage'" [deleteCode]="'foodSaucer:deleteImage'" [objectUuid]="object.main.uuid" [multiple]="false"></keysoft-upload> -->
    <!-- <keysoft-upload [uploadCode]="'configurationQr:updateLogo'" [downloadCode]="'configurationQr:downloadLogo'" [listFilesCode]="'configurationQr:getNameLogo'" [objectUuid]="metadata.company.main.uuid" [multiple]="false"></keysoft-upload> -->
        </div>
      </div>

    </mat-expansion-panel>

<!--     <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle [disabled]="object.main.uuid == ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Sucursal y barra del platillo
        </mat-panel-title>
        <mat-icon>dinner_dining</mat-icon>
      </mat-expansion-panel-header>

      <div class="row" *ngFor="let branch of branchList">
        <div class="col-12">
          <h4>{{branch.main.name}}</h4>
          <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="bar.system.selected" (change)="barUpdate(bar, branch.system.bars);" *ngFor="let bar of branch.system.bars">
                {{bar.main.name}}&nbsp;&nbsp;
            </mat-checkbox>&nbsp;&nbsp;
          </section>
        </div>
      </div>
    </mat-expansion-panel> -->
  </mat-accordion>
  <div class="col-8 row justify-content-end mt-2 mb-2">
    <div class="col-6">
      <button type="button" class="btn btn-block" style="width:100%" (click)="toogle(false);">Cerrar</button>
    </div>
    <div class="col-6">
      <button type="button" class="btn btn-danger btn-block" color="warn" style="width:100%" (click)="toogle(true);">Guardar</button>
    </div>
  </div>
</div>
