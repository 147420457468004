import { Component, Input, Output, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-company-client-chef-access',
  templateUrl: './company-client-chef-access.component.html',
  styleUrls: ['./company-client-chef-access.component.css']
})
export class CompanyClientChefAccessComponent {

  complexForm: FormGroup;

  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status: true,
      //address: this.address,
      //contacts: []
    }
  };

  chefModel = {
    main: {
      uuid: '',
      code: '',
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      },
      physicalPerson: this.physicalPerson
    }
  };


  userModel = {
    main: {
      uuid: "",
      username: "",
      password: "",
      passwordConfirm: "",
    },
    complement: {
      enabled: "true",
      accountExpired: "false",
      accountLocked: "false",
      passwordExpired: "false"
    },
    system: {
      status: true,
      type: "Company",
      objectUuid: "",
      physicalPerson: this.physicalPerson
    }
  }

  branchList = [];
  objectsList = [];

  userObjectModel = {
    main: {
      uuid: ''
    },
    system: {
      selected: false,
      user: {
        main: {
          uuid: '',
          username: ''
        }
      },
      object: 'Branch',
      objectUuid: '',
      objectType: 'Branch',
    },
  };


  metadata = {
    dateBirth: null,
    reggexCurp: '^[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[A-Za-z0-9]{2}',
    reggexRfc: '[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}',
  };

  object = JSON.parse(JSON.stringify(this.chefModel));
  user = JSON.parse(JSON.stringify(this.userModel));

  constructor(fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyClientChefAccessComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data != 'new') {
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      console.log('**', this.data)
      // cargamos los datos de la BD
      this.session.getRequest('chef:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));

        this.session.getRequest('user:findByPhysicalPerson', { system: { physicalPerson: { main: { uuid: this.object.system.physicalPerson.main.uuid } } } }).subscribe((data: any) => {
          this.user = JSON.parse(JSON.stringify(data.object));
          this.loadingService.hide();
          this.getBrachList().then((data: any) => {
            this.branchList = data.instanceList;
            //  console.log('22',this.branchList);
          });
        },
          (error) => {
            this.loadingService.hide();
            console.log('Error: user:findByPhysicalPerson', error)
          })

        this.loadingService.hide();
      },
        (error) => {
          this.loadingService.hide();
          console.log('Error: chef:get', error)
        })
    }
 

    

  }

  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      let instanceList = []
      this.session.getRequest('branch:list', { max: 1000, offset: 0, system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
        (data: any) => {

          //this.branchList = data.object.instanceList;
          for (let x = 0; x < data.object.instanceList.length; x++) {
            let userObject = JSON.parse(JSON.stringify(this.userObjectModel));
            userObject.system.objectType = 'Branch';
            userObject.system.object = data.object.instanceList[x];
            userObject.system.objectUuid = data.object.instanceList[x].main.uuid;
            this.session.getRequest('user:findByObjectTypeAndObjectUuidAndUser', { system: { objectType: 'Branch', objectUuid: data.object.instanceList[x].main.uuid, user:{main:{uuid:this.user.main.uuid}} } }).subscribe(
              (data: any) => {
                //console.log('78',data)
                if (data.object.system != undefined) {
                  console.log('***--',data.object.system)
                  // console.log('98', data.object.system)             
                  userObject.system.selected = true;

                }
                instanceList.push(userObject)
              }, error => {
                console.log("Error: user:findByObjectTypeAndObjectUuidAndUser", error);
                this.snackBar.open(error.message, 'Error', { duration: 5000 });
                this.loadingService.hide();
              });
          }
          //console.log('22', this.branchList)
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: instanceList });
        }, error => {
          console.log("Error: branch:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }


  // Agrega a la lista las branch de acceso
  addBranch(object) {
    var exist = 'false'
    console.log('**', object);
    this.loadingService.show(true, 'Guardando accesos de usuario');
    // this.objectsList = [];
    if (object.system.selected) {
      object.system.user.main.uuid = this.user.main.uuid;
      object.system.user.main.username = this.user.main.username;
      object.system.selected = true;
      this.session.getRequest('user:updateUserObject', object).subscribe(
        (data: any) => {
          this.userObjectModel = data.object;
          console.log("¡Guardado Exitoso!");
          this.snackBar.open('Registro guardado exitosamente', 'Informacion', { duration: 5000 });
          //this.close(true);
          this.loadingService.hide();
        },
        error => {
          console.log("user:updateUserObject", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        })
    } else {
      this.loadingService.hide();
      //buscamos el objeto si existe userObject para eliminarlo
 /*      let aux = JSON.parse(JSON.stringify(this.userObjectModel));
      aux.main.uuid = this.object.main.uuid;
      aux.system.user.main.uuid = this.user.main.uuid;
      aux.system.user.main.username = this.user.main.username;
      console.log('23', object)
      aux.system.objectUuid = this.object.main.uuid;
      aux.system.objectType = 'Branch'; */
      
      //eliminamos el objeto de la bd
      this.session.getRequest("user:deleteByUserObjectAndUserUuidAndUser", {system:{objectType:'Branch', objectUuid: object.system.objectUuid , user:{main:{uuid:this.user.main.uuid}} }}).subscribe((data) => {
      }, error => {
        this.loadingService.hide();
        object.system.selected = true;
        this.snackBar.open("Ocurrio un error, no se pudo desasociar la categoria", 'Error', { duration: 5000 });
      });

    }

    //  this.object.userObject.system.listObjects = this.object.objectsList;
  }



  //Funcion para guardar accesos
  //  send(userObject) {
  //   this.loadingService.show(true,'Guardando accesos de usuario');
  //   this.objectsList = [];
  //   this.userObject.system.user.main.uuid = this.user.main.uuid;
  //   this.userObject.system.user.main.username = this.user.main.username;
  //   this.session.getRequest('user:updateUserObject', this.userObject).subscribe(
  //     (data:any)=>{
  //       console.log("¡Guardado Exitoso!");
  //       this.snackBar.open('Registro guardado exitosamente', 'Error', {duration: 5000});
  //       this.close(true);
  //       this.loadingService.hide();
  //     },
  //     error => {
  //       console.log("user:updateUserObject",error);
  //       this.snackBar.open(error.message, 'Error', {duration: 5000});
  //       this.loadingService.hide();
  //     })
  // }

  bar(){
    this.session.getRequest('branch:list', { max: 1000, offset: 0, system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
      (data: any) => {
        for (let x = 0; x < data.object.instanceList.length; x++) {
          let branch = data.object.instanceList[x];

          this.session.getRequest('bar:list', { max: 1000, offset: 0, system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
            (data: any) => {
              for (let x = 0; x < data.object.instanceList.length; x++) {
                let branch = data.object.instanceList[x];
      
                
      
      
              }
              this.loadingService.hide();
            }, error => {
              console.log("Error: branch:list", error);
              this.snackBar.open(error.message, 'Error', { duration: 5000 });
              this.loadingService.hide();
            });




        }
        this.loadingService.hide();
      }, error => {
        console.log("Error: branch:list", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      });
  }

  getBrachList2() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('branch:list', { max: 1000, offset: 0, system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: branch:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  /**
  * Barra de Restaurante
  */
    getBarList(branch) {
      return new Promise((resolve) => {
        this.loadingService.wait();
        this.session.getRequest('bar:findAllByBranch', {system: { branch: branch } }).subscribe(
          (data: any) => {
            this.loadingService.hide();
            resolve({ transaction: 'ok', instanceList: data.object.instanceList });
          }, error => {
            console.log("Error: bar:findAllByBranch", error);
            this.snackBar.open(error.message, 'Error', { duration: 5000 });
            this.loadingService.hide();
          })
      });
    }





  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'chef:001';
    }
    this.dialogRef.close(object);
  }

}
