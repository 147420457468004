<div class="row fondo-login">
   <div class="col-12">
     <div class="row justify-content-center mt-5">
       <mat-card class="col-lg-4 col-md-6 col-12 mt-5 text-center">
          <img src="assets/img/logo.png" alt="Logo" class="pt-3 pb-3" width="200">
          <mat-card-content>
            <form class="col login-form mt-4">
               <mat-form-field class="col-10" appearance="outline">
                 <mat-label>&nbsp;Usuario</mat-label>
                 <input matInput placeholder="Usuario" name="mName"  [formControl]="complexForm.controls['username']" [(ngModel)]="object.main.username">
                 <span matPrefix class="material-icons">face</span>
                 <mat-error *ngIf="complexForm.controls['username'].hasError('required')">Campo requerido</mat-error>
               </mat-form-field>
               <mat-form-field class="col-10" appearance="outline">
                 <mat-label>&nbsp;Contraseña</mat-label>
                 <input matInput type="password" placeholder="Contraseña" name="mPassword" [(ngModel)]="object.main.password" [formControl]="complexForm.controls['password']">
                 <span matPrefix class="material-icons">lock</span>
                 <mat-error *ngIf="complexForm.controls['password'].hasError('required')">Campo requerido</mat-error>
               </mat-form-field>
               <br />
           </form>
          </mat-card-content>
          <mat-card-actions class="mt-4 mb-4">
            <button mat-raised-button color="accent" class="w-50" (click)="send(object);" [disabled]="complexForm.invalid">Iniciar Sesión</button>
          </mat-card-actions>
       </mat-card>
     </div>
   </div>
</div>
