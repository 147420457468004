import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rpayment-dialog',
  templateUrl: './rpayment-dialog.component.html',
  styleUrls: ['./rpayment-dialog.component.css']
})
export class RpaymentDialogComponent implements OnInit {

    object = {
      main:{
        numberOrder:''
      }
    };
    constructor(public dialogRef: MatDialogRef<RpaymentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.object = this.data;
     }

    ngOnInit(): void {
    }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      this.dialogRef.close(object);
    }

}
