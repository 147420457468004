import { Component, OnInit, Output, EventEmitter, ViewChild, ViewEncapsulation  } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-restaurant-product-detail',
  templateUrl: './restaurant-product-detail.component.html',
  styleUrls: ['./restaurant-product-detail.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class RestaurantProductDetailComponent implements OnInit {
  foodSaucerModel = {
    main: {
      uuid: '',
      name: '',
      price: 0,
      tax: true,
      isCombo: false,
      description: '',
    },
    imagen: {
      image: '',
      imageName: '',
      imageType: '',
    },
    system: {
      status: true,
      foodCategory: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      }
    }
  }
  foodOrderModel = {
    main:{
      uuid: '',
      carryOut:  false, // true para llevar, false para en sitio
      dateFoodOrder: '', // Fecha y hora del medido
      status: 'Abierta', // Abierta, Preparacion, En entrega, Concluida
      subTotal: 0.0, // Subtotal a pagar
      tax: 0.0, // Impuestos a pagar
      total: 0.0, // //Total a pagar
      numberOrder:'',
      paymentMethod:'Pago en Efectivo'//Cargo a la habitació, Pago en Efectivo, Debito, Credito
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      objectType:'',
      objectUuid:''
    }
  };
  foodOrderDetailModel = {
    main:{
      uuid:'',
      priceUnitary: 0.0,
      quantity: 0.0,
      observation:''
    },
    system:{
      foodSaucer:{
        main:{
          uuid:''
        }
      },
      foodOrder:{
        main:{
          uuid:''
        }
      }
    }
  }
  foodOrderCarryOut = {
    main:{
      uuid: '',
      fullname: '',
      paymentMethod:''// Método de pago: contra entrega, tarjeta de crédito, tarjeta de debito, Paypal
    },
    system:{
      address:{
        main:{
          uuid:'',
          street:''
        }
      },
      company:{
        main:{
          uuid:''
        }
      },
      foodOrder:{
        main:{
          uuid:''
        }
      },
      contacts:[]
    }
  }
  foodOrderDetailList = [];
  object = JSON.parse(JSON.stringify(this.foodSaucerModel));
  foodOrder = JSON.parse(JSON.stringify(this.foodOrderModel));
  metadata = {
    name:'',
    description:'',
    quantity:1,
    displayModal:false,
    foodSaucerSize:{
      main:{
        uuid:'',
        price:'',
        size:''
      }
    }
  };
  qrConfiguration = {
    main:{
      carryOut: true,
      hotel: true,
      mexPago: false,
      payPal: false,
      promotionImage: true,
      restaurant: true,
      theme: "",
      uuid: "",
      whitImage: false
    }
  };
  foodSaucerSizeList = [];


  constructor(private session: SessionService, private router: Router, private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private _location: Location, public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.qrConfiguration = this.session.getConfigurationQr();
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] != '') {
        //bscamos los platillos de una categoria
        this.object.main.uuid= params['uuid'];
        this.session.getRequestAnonymous("foodSaucer:get",this.object).subscribe((data:any)=>{
          this.object = data.object;
          if(this.object.main.image){
            this.getImage();
          }
        },error=>{
          console.log("error:",error);
        })
      } else {
        this.router.navigate(['/restaurant/home']);
      }
    });
  }

  /**función para obtener la imagen de un producto
  */
  async getImage(){
    let auxFile ={
      file:{
        uuid:this.object.main.uuid,
        name:this.object.main.name,
        file:'',
        index:0,
        total:0,
        chunk:10,
        chunkSize:0,
        position:0,
        type:''
      },
      main:{
        uuid:this.object.main.uuid//uuid del archivo
      }
    }
    let base64 = '';
    try{
      let chunkFirstTime = true;
      for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
        // console.log("index--->",auxFile.file.index);
        let resF = await this.getRequestBaseFile(auxFile);
        if(resF.transaction == 'ok'){
          base64 = base64.concat(resF.object.file.file);
          auxFile.file.name = resF.object.file.name;
          if(chunkFirstTime){
            auxFile.file.chunk = resF.object.file.chunk;
            chunkFirstTime = false;
          }
        }else{
          // this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
        }
      }
      if( base64 != ''){
        // console.log(base64);
        base64 = base64.replace(/-/g, "+");
        this.object.image = base64;
      }

    }catch(e){
      console.log("error",e);
      // this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
    }
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous('foodSaucer:download',object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }

  /**función para regrsar a la pantalla anterior
  */
  back(){
    this._location.back();
  }

  /**función para obtener el foodOrder
  */
  getFoodOrder(){
    return new Promise((resolve,reject)=>{
      //verificamos si existe foodOrder si no lo pedimos de localstorage
      let aux =  localStorage.getItem("foodOrder");
      // console.log(aux);
      if(aux){
        // console.log("Encontramos un foodOrder");
        let aux2 = JSON.parse(localStorage.getItem("foodOrder"));
        if(aux2.main.status == 'Concluida'){
          aux2.main.status = 'Abierta';
          aux2.main.uuid = '';
          let objectAndObjectUuid = this.session.getAnonymusObjectAndObjectUuid();
          this.foodOrder.system.objectType = objectAndObjectUuid.object;
          this.foodOrder.system.objectUuid = objectAndObjectUuid.objectUuid;
          if(objectAndObjectUuid.object == "foodTable" || objectAndObjectUuid.object == "roomService"){
            this.foodOrder.main.carryOut = false;
          }else{
            this.foodOrder.main.carryOut = true;
          }
          this.foodOrder.main.dateFoodOrder = moment().format('DD/MM/YYYY HH:mm:ss');
          this.session.getRequestAnonymous("foodOrder:update",this.foodOrder).subscribe((data:any)=>{
            this.foodOrder.main.uuid = data.object.main.uuid;
            localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
            resolve(this.foodOrder);
          },error=>{
            reject(error);
          })
        }else{
          resolve(aux2);
        }
      }else{
        let branch:any = this.session.getAnonymousSessionBranch();
        this.foodOrder.system.company.main = branch.system.company.main;
        this.foodOrder.system.branch.main = branch.main;
        let objectAndObjectUuid = this.session.getAnonymusObjectAndObjectUuid();
        // console.log("getAnonymusObjectAndObjectUuid",objectAndObjectUuid);
        this.foodOrder.system.objectType = objectAndObjectUuid.object;
        this.foodOrder.system.objectUuid = objectAndObjectUuid.objectUuid;
        if(objectAndObjectUuid.object == "foodTable" || objectAndObjectUuid.object == "roomService"){
          this.foodOrder.main.carryOut = false;
        }else{
          this.foodOrder.main.carryOut = true;
        }
        this.foodOrder.main.dateFoodOrder = moment().format('DD/MM/YYYY HH:mm:ss');
        // console.log("No hay un foodOrder, lo creamos",this.foodOrder);
        this.session.getRequestAnonymous("foodOrder:update",this.foodOrder).subscribe((data:any)=>{
          this.foodOrder.main.uuid = data.object.main.uuid;
          localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
          resolve(this.foodOrder);
        },error=>{
          reject(error);
        })
      }
    });
  }

  /**función par agregar un producto
  */
  addProduct(){
    this.loadingService.wait();
    this.getFoodOrder().then((data)=>{
      this.foodOrder = data;
      let foodOrderDetailAux = JSON.parse(JSON.stringify(this.foodOrderDetailModel));
      if(this.object.main.foodSaucerSize){
        foodOrderDetailAux.main.priceUnitary = this.metadata.foodSaucerSize.main.price;
        foodOrderDetailAux.main.foodSaucerSize = this.metadata.foodSaucerSize.main.size;
      }else{
        foodOrderDetailAux.main.priceUnitary = this.object.main.price;
      }
      foodOrderDetailAux.main.observation = this.metadata.description;
      foodOrderDetailAux.main.quantity = this.metadata.quantity;
      foodOrderDetailAux.system.foodSaucer.main = this.object.main;
      foodOrderDetailAux.system.foodOrder.main = this.foodOrder.main;
      this.foodOrder.main.dateFoodOrder = moment().format('DD-MM-YYYY HH:mm');
      this.foodOrder.main.quantity = this.foodOrder.main.quantity + foodOrderDetailAux.main.quantity;
      this.foodOrder.main.subTotal = this.foodOrder.main.subTotal + (foodOrderDetailAux.main.quantity*foodOrderDetailAux.main.priceUnitary);
      this.updateFoodOrderDetails(foodOrderDetailAux).then((data)=>{
        if(localStorage.getItem("foodOrderDetailList")){
          this.foodOrderDetailList = JSON.parse(localStorage.getItem("foodOrderDetailList"));
        }
        this.foodOrderDetailList.push(data);
        localStorage.setItem("foodOrderDetailList", JSON.stringify(this.foodOrderDetailList));
        // console.log("producto agregado");
        this.getFoodOrderUpdate();
        this.closeModal();
        this.loadingService.hide()
        this.snackBar.open("Gracias tu platillo fue agregado", 'Aceptar', {duration: 5000});
      }).catch((e)=>{
        this.loadingService.hide()
        console.log("e:updateFoodOrderDetails",e);
      });

    }).catch((e)=>{
      this.loadingService.hide()
      console.log("error:getFoodOrder",e);
    })
  }

  /**funcionalidad para actualziar un foodOrder
  */
  getFoodOrderUpdate(){
    this.session.getRequestAnonymous("foodOrder:get",this.foodOrder).subscribe((data:any)=>{
      this.foodOrder = data.object;
      localStorage.setItem("foodOrder", JSON.stringify(this.foodOrder));
    },error=>{
    })
  }

  /**funcionalidad para actualizar foodOrderDetail
  */
  updateFoodOrderDetails(object){
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous("foodOrdenDetail:update",object).subscribe((data:any)=>{
        object.main.uuid = data.object.main.uuid;
        //actualizamos los valores de la orden
        resolve(object);
      },error=>{
        reject(error);
      })
    });
  }

  /**función para obtener el menor precio del catalog de tamaños de alimentos
  */
  getMinPriceFromFoodSaucerSize(object){
    return new Promise((resolve,reject)=>{
      this.session.getRequestAnonymous("foodSaucerSize:findAllByFoodSaucerAndStatus", {main: {uuid: ''}, system: {foodSaucer: object,status:true}}).subscribe((data: any) => {
        this.foodSaucerSizeList = data.object.instanceList;
        this.metadata.foodSaucerSize.main = JSON.parse(JSON.stringify(this.foodSaucerSizeList[0].main));
      }, error => {
        console.log("Error: foodSaucerSize:findAllByFoodSaucer", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        reject(error);
      })
    });
  }

  /**Método para seleccionar un foodSaucer
  */
  selectedFoodSaucerSize(ev){
    for(let item of this.foodSaucerSizeList){
      if(ev.value ==  item.main.uuid){
        this.metadata.foodSaucerSize.main.size = item.main.size;
        this.metadata.foodSaucerSize.main.price = item.main.price;
      }
    }
  }

  addQuantity(){
    this.metadata.quantity+=1;
  }

  minQuantity(){
    this.metadata.quantity-=1;
    if(this.metadata.quantity < 1){
      this.metadata.quantity = 1;
    }
  }

  /**función par abrir el modal */
  openModal(){
    this.metadata.displayModal = true;
  }

  /**función para cerrar el modal */
  closeModal(){
    this.metadata.displayModal = false;
  }


}
