import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from '../../service/session/session.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-restaurant-seafood-home',
  templateUrl: './restaurant-seafood-home.component.html',
  styleUrls: ['./restaurant-seafood-home.component.css']
})
export class RestaurantSeafoodHomeComponent implements OnInit {
  @Output() menuToogleRestaurant: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  configurationQRModel = {
    main:{
      uuid:'',
      theme: '',
      restaurant: false,
      hotel: false,
      mexpago: false,
      PayPal: false
    },
    system:{
      status:true
    }
  }
  object = JSON.parse(JSON.stringify(this.configurationQRModel));
  metadata = {
    name:'',
    toogleMenuRestaurant: false,
    branch:{
      main:{
        uuid:''
      }
    },
  }
  logoBlob:any = '';
  foodCategoryList = [];
  promotionList = [];
  constructor(private session: SessionService, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    //obtenemos la configuración de la company
    this.object = this.session.getConfigurationQr();
    this.metadata.branch = this.session.getAnonymousSessionBranch();
    let branch = this.session.getAnonymousSessionBranch();
    this.metadata.name = branch.system.company.main.tradename;
    //cargamos el logo de la compañia
    // console.log("MEGA WTF!!!!!!!",this.object);
    // this.getLogo();
    this.getCategories();
    this.getPromotions();
  }
  /**función para obtener el listado de categorias de una sucursal
  */
  getCategories(){
    this.session.getRequestAnonymous("foodCategory:findAllByBranch",{system:{branch:{main:this.metadata.branch.main}}}).subscribe((data:any)=>{
      this.foodCategoryList = data.object.instanceList;
      // console.log("foodCategoryList",this.foodCategoryList);
    },error=>{
      console.log(error,"error:foodCategory:findAllByBranch");
    })
  }
  /*
  * funcion para abrir o cerrar le menu
  */
  toogleMenuRestaurant(ev){
    this.drawerCtrl.opened = ev;
  }

  /**función para listar las promociones por por sucursal
  */
  getPromotions(){
    this.session.getRequestAnonymous('promotionImage:findAllByBranch', {max: 1000, offset: 0, system: {branch: {main: {uuid: this.metadata.branch.main.uuid}}}}).subscribe(
      (data: any) => {
        this.promotionList = data.object.instanceList;
        for(let i = 0; i < this.promotionList.length;i++){
          this.getImage(this.promotionList[i]);
        }
      }, error => {
        console.log("Error: promotionImage:findAllByBranch", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
  }

  /**función para obtener el logo de la compañia
  */
  /**función para obtener la imagen de una promoción
  */
  async getImage(object){
    let auxFile ={
      file:{
        uuid:object.main.uuid,
        name:object.main.name,
        file:'',
        index:0,
        total:0,
        chunk:10,
        chunkSize:0,
        position:0,
        type:''
      },
      main:{
        uuid:object.main.uuid//uuid del archivo
      }
    }
    let base64 = '';
    try{
      let chunkFirstTime = true;
      for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
        // console.log("index--->",auxFile.file.index);
        let resF = await this.getRequestBaseFile(auxFile);
        if(resF.transaction == 'ok'){
          base64 = base64.concat(resF.object.file.file);
          auxFile.file.name = resF.object.file.name;
          if(chunkFirstTime){
            auxFile.file.chunk = resF.object.file.chunk;
            chunkFirstTime = false;
          }
        }else{
          this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
        }
      }
      if( base64 != ''){
        // console.log(base64);
        base64 = base64.replace(/-/g, "+");
        object.image = base64;
      }

    }catch(e){
      console.log("error",e);
      object.mode = 'determinate';
      this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
    }
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequest('promotionImage:download',object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }
}
