<div class="row justify-content-center title-modal" >
  <div class="col-12 text-uppercase text-center">
    <h4>QR de Alimentos para llevar</h4>
  </div>
</div>
<hr/>
<div class="row justify-content-center">
  <div class="col-12 text-center">
    <img src="{{object.image.file}}" alt="QR para redes sociales">
  </div>
  <div class="col-8 text-center">
    <label><strong>{{object.main.name}}</strong></label>
  </div>
  <div class="col-12 text-center">
    <button mat-raised-button color="warn" class="col-8 text-center" (click)="download()">Descargar</button>
  </div>
  <br />

</div>
