
<div class="" *ngIf="stiFile">
  <div class="" *ngIf="stiFile.name">
    <div class="">
      <mat-progress-bar [mode]="metadata.mode" [value]="metadata.value" ></mat-progress-bar>
    </div>
    <div class="row">
      <div class="col-1 pt-1">
        <mat-icon aria-hidden="false" aria-label="Example home icon" color="accent" *ngIf="metadata.value >= 100">check_circle_outline</mat-icon>
      </div>
      <div class="col-9 pt-1">
        {{stiFile.name}}
      </div>
      <div class="col-1">
        <button mat-icon-button aria-label="Example icon-button with a heart icon" color="accent" [disabled]="stiFile.uuid == '' && stiFile.name == '' " (click)="downloadAFile();">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </div>
    <div class="p-0">
      <!-- <button mat-icon-button aria-label="Example icon-button with a heart icon" color="accent" [disabled]="stiFile.uuid == ''" (click)="uploadAFile();">
        <mat-icon>cloud_upload</mat-icon>
      </button> -->
    </div>
  </div>
</div>
