<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <!-- aqui va el contenido -->
  <div class="row justify-content-center">
    <div class="col-11">
      <mat-card class="card-box mb-2">
        <div>
          <div class="row">
            <div class="col-9">
              <h4 class="pl-2 pt-2">Nuevo Cliente</h4>
            </div>
            <div class="col-3">
              <button style="width:100%" mat-flat-button color="accent"  matTooltip="Nueva compañia" routerLink="/admin/companyClient"> <i class="material-icons">undo</i> Regresar</button>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="card-box">

        <mat-card-content>
          <div class="row justify-content-center">
            <div class="col-10">
              <mat-horizontal-stepper #stepper>
                <mat-step>
                  <ng-template matStepLabel ></ng-template>
                  <div class="row justify-content-center ">
                    <form class="col-12 login-form">
                      <mat-list-item>Datos Generales</mat-list-item>
                      <div class="row mt-3">
                        <mat-form-field class="col-6" appearance="outline">
                          <mat-label>Nombre Comercial</mat-label>
                          <input matInput placeholder="Nombre Comercial" name="mTradename" [(ngModel)]="object.main.tradename" [formControl]="complexForm.controls['tradename']" >
                          <mat-error *ngIf="complexForm.controls['tradename'].hasError('required')">
                            Campo <strong>requerido</strong>.
                          </mat-error>
                        </mat-form-field>
                        <div class="col-6 text-center">
                          <mat-radio-group name="sPhysicalPerson"  [(ngModel)]="object.system.typePerson" (ngModelChange)="loadTypePerson()"  >
                            <mat-radio-button class="mt-2" value="MoralPerson" (click)="getTaxRegime2(object.system.typePerson = 'MoralPerson');">Persona Moral &nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button>
                            <mat-radio-button class="mt-2" value="PhysicalPerson" (click)="getTaxRegime2(object.system.typePerson = 'PhysicalPerson');">Persona Fisica</mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <mat-form-field class="col-6" appearance="outline">
                          <mat-label>Régimen Fiscal</mat-label>
                          <mat-select placeholder="Régimen Fiscal" name="mTaxRegime" [(ngModel)]="object.system.taxRegime.main.uuid">
                            <mat-option *ngFor="let object of taxRegimeList" [value]="object.main.uuid">
                              {{object.main.code}}-{{ object.main.description }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </form>
                    <!-- <div class="col-12">
                      <label>Logotipo</label>
                      <sti-file-directive [stiFile]="logoFile.image"></sti-file-directive>
                    </div> -->
                  </div>

                  <div class="row justify-content-end mt-2">
                    <div class="col-2">
                      <button mat-raised-button color="primary" matStepperNext [disabled]="object.main.tradename == '' || object.system.typePerson == '' || object.system.taxRegime == '' "   >Siguiente</button>
                    </div>
                  </div>
                </mat-step>
                <mat-step>
                  <form class="col-12 login-form">
                    <mat-list-item>Datos Fiscales</mat-list-item>
                    <div class="row mt-3">
                      <div *ngIf="object.system.typePerson == 'MoralPerson'">
                        <mat-form-field class="col-8" appearance="outline">
                          <mat-label>Razón social</mat-label>
                          <input matInput placeholder="Razón social" name="mSocialReason" [(ngModel)]="object.system.person.main.socialReason" [formControl]="complexForm.controls['socialReason']" >
                          <mat-error *ngIf="complexForm.controls['socialReason'].hasError('required')">
                            Campo <strong>requerido</strong>.
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-4" appearance="outline">
                          <mat-label>RFC</mat-label>
                          <input matInput placeholder="RFC" name="mTradename" [(ngModel)]="object.system.person.complement.rfc" [formControl]="complexForm.controls['rfcM']" >
                          <mat-error *ngIf="complexForm.controls['rfcM'].hasError('required')">
                            Campo <strong>requerido</strong>.
                          </mat-error>
                          <mat-error *ngIf="complexForm.controls['rfcM'].hasError('pattern')">
                            Algo anda mal verifique su <strong>RFC</strong>.
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-12" appearance="outline">
                          <mat-label>Nombre de la persona de contacto</mat-label>
                          <input matInput placeholder="Persona quien solicito el servicio" name="mLegalRepresentativeName" [(ngModel)]="object.system.person.main.legalRepresentativeName" [formControl]="complexForm.controls['legalRepresentativeName']" >
                          <mat-error *ngIf="complexForm.controls['legalRepresentativeName'].hasError('required')">
                            Campo <strong>requerido</strong>.
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div *ngIf="object.system.typePerson == 'PhysicalPerson'">
                        <mat-form-field class="col-12" appearance="outline">
                          <mat-label>Nombre</mat-label>
                          <input matInput placeholder="Nombre" name="namePerson" [(ngModel)]="object.system.person.main.name" >
                        </mat-form-field>
                        <mat-form-field class="col-6" appearance="outline">
                          <mat-label>Apellido paterno</mat-label>
                          <input matInput placeholder="Apellido Paterno" name="mlastname" [(ngModel)]="object.system.person.main.lastname" [formControl]="complexForm.controls['lastname']" >
                          <mat-error *ngIf="complexForm.controls['lastname'].hasError('required')">
                            Campo <strong>requerido</strong>.
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-6" appearance="outline">
                          <mat-label>Apellido materno</mat-label>
                          <input matInput placeholder="Apellido Materno" name="lastnamePerson" [(ngModel)]="object.system.person.main.surname" [formControl]="complexForm.controls['surname']" >
                          <mat-error *ngIf="complexForm.controls['surname'].hasError('required')">
                            Campo <strong>requerido</strong>.
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-4" appearance="outline">
                          <mat-label>RFC</mat-label>
                          <input matInput placeholder="RFC" name="mRfcP" [(ngModel)]="object.system.person.complement.rfc" [formControl]="complexForm.controls['rfcP']" >
                          <mat-error *ngIf="complexForm.controls['rfcP'].hasError('required')">
                            Campo <strong>requerido</strong>.
                          </mat-error>
                          <mat-error *ngIf="complexForm.controls['rfcP'].hasError('pattern')">
                            Algo anda mal verifique su <strong>RFC</strong>.
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-4" appearance="outline">
                          <mat-label>CURP</mat-label>
                          <input matInput placeholder="CURP" name="mcurp" [(ngModel)]="object.system.person.complement.curp" [formControl]="complexForm.controls['curp']" >
                          <mat-error *ngIf="complexForm.controls['curp'].hasError('required')">
                            Campo <strong>requerido</strong>.
                          </mat-error>
                          <mat-error *ngIf="complexForm.controls['curp'].hasError('pattern')">
                            Algo anda mal verifique su <strong>RFC</strong>.
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-4" appearance="outline">
                          <mat-label>Fecha de nacimiento</mat-label>
                          <input matInput [matDatepicker]="picker" placeholder="Fecha de Nacimiento" name="dateBirth" [(ngModel)]="metadata.dateBirth">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-radio-group class="col-4 text-center" name="sGender" [(ngModel)]="object.system.person.complement.gender">
                          <mat-radio-button value="Femenino">Femenino</mat-radio-button>&nbsp;&nbsp;
                          <mat-radio-button value="Masculino">Masculino</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="row justify-content-end mt-3">
                      <div class="col-2">
                        <button mat-raised-button color="warn" matStepperPrevious>Anterior</button>
                      </div>
                      <div class="col-2">
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                        <!-- <button mat-raised-button color="primary" matStepperNext [disabled]="!(object.system.typePerson !== '' && object.main.code !=='' && object.main.name !== '' && object.main.contactName !== '' && (object.system.person.main.name !== '' || object.system.person.main.socialReason !== ''))" >Siguiente</button> -->
                      </div>
                    </div>
                  </form>
                </mat-step>
                <!-- //se agrega certificados-->
                <mat-step>
                  <ng-template matStepLabel ></ng-template>
                  <div class="row justify-content-center ">
                    <form class="col-12 login-form">
                      <mat-list-item>Certificados</mat-list-item>
                      <br>
                        <div class="row">
                          <div class="col-6">
                              <label>Cer File</label>
                              <sti-file-directive [stiFile]="cerFile.image"></sti-file-directive>
                          </div>
                          <div class="col-6">
                              <label>Key File</label>
                              <sti-file-directive [stiFile]="keyFile.image"></sti-file-directive>
                          </div>
                          <mat-form-field class="col-6" appearance="outline">
                              <mat-label>Clave</mat-label>
                              <input matInput type="password" placeholder="Clave" name="socialObjective" [(ngModel)]="object.complement.primaryKey" [formControl]="complexForm.controls['primaryKey']" autocomplete="off">
                              <mat-error *ngIf="complexForm.controls['primaryKey'].hasError('required')">
                                Campo <strong>requerido</strong>.
                              </mat-error>
                          </mat-form-field>
                      </div>
                    </form>
                    <!-- <div class="col-12">
                      <label>Logotipo</label>
                      <sti-file-directive [stiFile]="logoFile.image"></sti-file-directive>
                    </div> -->
                  </div>

                  <div class="row justify-content-end mt-2">
                    <div class="col-2">
                      <!-- <button mat-raised-button color="primary" matStepperNext [disabled]="object.main.tradename == '' || object.system.typePerson == ''">Siguiente</button> -->
                      <button mat-raised-button color="primary" (click)="send(object,true);" >Guardar</button>
                    </div>
                  </div>
                </mat-step>

              </mat-horizontal-stepper>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <div class="row">
            <hr class="col-11 devider">
          </div>
          <!-- aqui el paginado o footer -->
          <!-- <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
            <div class="col-6 ">
              <button mat-raised-button color="accent" style="width:100%">Guardar</button>

            </div>
          </div> -->
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

</mat-drawer-container>
