<mat-drawer-container class="drawerConteiner" autosize style="background: white;">
  <mat-drawer #drawer class="mainDrawwerHotel" mode="push" position="end">
    <app-restaurant-hotel-menu-collapsed></app-restaurant-hotel-menu-collapsed>
  </mat-drawer>
  <div class="row img-home-hotel mr-0">
    <div class="col-12">

      <div class="row justify-content-center" style="background: #f1f1f1;">
        <div class="col-3 pr-0" routerLink="/restaurant-hotel/home">
          <button mat-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
            <mat-icon class="pt-2 text-gris" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center p-0" >
          <h2 class="text-gris align-middle mb-0 font-IBMPlexR" style="line-height: 1.2rem; font-size: 1rem; padding-top: 4px;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2 pl-0 pr-0">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-hotel/order">
            <mat-icon class="text-gris">receipt_long</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-gris" style="font-size: 2rem; margin-right: 5px;">dehaze</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="row justify-content-center mt-2">
    <!-- <mat-form-field appearance="legacy" class="col-11 font-kelson">
      <mat-label>Buscar</mat-label>
      <input matInput placeholder="Buscar">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field> -->
    <div class="col-11 text-center mt-2">
      <h1 class="font-IBMPlexB text-gris" style="font-size: 2rem; letter-spacing: 4px;">{{metadata.foodCategory.main.name}}</h1>
    </div>
  </div>


  <div class="row justify-content-center m-0">
    <div class="col-11">
      <div class="row  text-star mt-2 p-0" *ngFor="let foodSaucer of foodSaucerList" style="border-bottom: 1px #c9c9c9; border-bottom-style: solid;">
          <div class="col-8 m-0 p-0">
            <h3 class="font-IBMPlexB text-gris " style="margin-bottom: 3px; line-height: 1rem; letter-spacing: 2px; text-align:left; font-size:.9rem;">{{foodSaucer.main.name}}</h3>
            <p class="text-justify-left  text-gris" style="font-size: .7rem; line-height: .8rem;"> {{foodSaucer.main.description}} </p>
          </div>
          <div class="col-2 m-0 p-0">
            <span class="font-IBMPlexB pb-1 price">{{foodSaucer.main.price | currency:'CAD':'symbol-narrow'}}</span>
          </div>
          <div class="col-1">
              <span matTooltip="Agregar" class="material-icons" style="color: rgb(195 195 195);" (click)="openModal(foodSaucer);">add_circle</span>
          </div>
        </div>
    </div>
  </div>

  <footer class="text-center text-secondary p-1 mt-5" >
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>


</mat-drawer-container>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{display:metadata.displayModal ? 'block':'none'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background:#e6e6e6;">
      <div class="modal-header font-IBMPlexR">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body font-IBMPlexR" style="color: #424242;">
        <div class="row">
          <p class="col-12">Agregar la cantidad a <strong>{{objectToOrder.main.name}}</strong> .</p>
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-2">
                <button mat-icon-button (click)="minQuantity();"> <mat-icon>remove_circle_outline</mat-icon> </button>
              </div>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>Cantidad</mat-label>
                <input matInput name="quantity" [(ngModel)]="metadata.quantity">
              </mat-form-field>
              <div class="col-2">
                <button mat-icon-button (click)="addQuantity();"> <mat-icon>add_circle_outline</mat-icon> </button>
              </div>
            </div>
          </div>
          <p class="col-12">Si quieres agregar complementos ú observaciones agregalas aqui.</p>
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Complementos</mat-label>
            <textarea matInput name="description" [(ngModel)]="metadata.description"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width:100%;">
          <div class="col-6">
            <button mat-flat-button  color="#b0352d" style="width:100%; IBMPlex-regular !important;" (click)="closeModal();">Cerrar</button>
          </div>
          <div class="col-6">
            <button mat-flat-button color="accent"  style="width:100%; IBMPlex-regular !important;" (click)="addProduct();">Agregar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
