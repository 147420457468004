import { Component, Input, Output, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-company-client-waiter-update',
  templateUrl: './company-client-waiter-update.component.html',
  styleUrls: ['./company-client-waiter-update.component.css']
})
export class CompanyClientWaiterUpdateComponent {
  complexForm: FormGroup;
  complexForm2: FormGroup;

  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status: true,
      //address: this.address,
      //contacts: []
    }
  };

  waiterModel = {
    main: {
      uuid: '',
      code: '',
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      },
      physicalPerson: this.physicalPerson
    }
  };

  userModel = {
    main:{
      uuid:"",
      username:"",
      password:"",
      passwordConfirm:"",
    },
    complement:{
      enabled:"true",
      accountExpired:"false",
      accountLocked:"false",
      passwordExpired:"false"
    },
    system:{
      status:true,
      type: "Company",
      objectUuid: "",
      physicalPerson: this.physicalPerson
    }
  }

  metadata = {
    dateBirth: null,
    reggexCurp: '^[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[A-Za-z0-9]{2}',
    reggexRfc: '[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}',
  };

  roleModel={
    main:{
      uuid:'',
      authority:''
    }
  }

  userRoleModel={
    system:{
      user:{
        main:{
          uuid:'',
        }
      },
      role:{
        main:{
          uuid:'',
        }
      }
    }
  }

  rolesList = [];

  object = JSON.parse(JSON.stringify(this.waiterModel));
  user = JSON.parse(JSON.stringify(this.userModel));

  userRole=  JSON.parse(JSON.stringify(this.userRoleModel));

  constructor(fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyClientWaiterUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.compose([Validators.required])],
      'name': [null, Validators.compose([Validators.required])],
      'surname': [null, Validators.compose([Validators.required])],
      'lastname': [null, Validators.compose([Validators.required])],
      'curp': [null, Validators.compose([Validators.required, Validators.pattern(this.metadata.reggexCurp)])],
      'rfc': [null, Validators.compose([Validators.required, Validators.pattern(this.metadata.reggexRfc)])],
    }),
    this.complexForm2 = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'username': [null, Validators.compose([Validators.required])],
      'password': [null, Validators.compose([Validators.required])],
      'passwordConfirm': [null, Validators.compose([Validators.required])],
    })
  }


  ngOnInit(){
    if(this.data != 'new') {
      this.loadingService.wait();
       this.object.main.uuid = this.data;
       console.log('**',this.data)
       // cargamos los datos de la BD
       this.session.getRequest('waiter:get',this.object).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));

         this.session.getRequest('user:findByPhysicalPerson',{system:{ physicalPerson:{main:{uuid: this.object.system.physicalPerson.main.uuid}}}}).subscribe((data:any)=>{
          this.user = JSON.parse(JSON.stringify(data.object));
          this.loadingService.hide();
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error: waiter:get',error)
        })

         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: waiter:get',error)
       })
    }
    this.getRoles();
  }

 //obtenemos los roles registrados en el sistema
 getRoles(){
  return new Promise(resolve=>{
   //this.session.getRequest('user:findAllRolByStatus', {system: {status: true}}).subscribe(
   this.session.getRequest('user:findAll',{}).subscribe(
     (data:any)=>{
       if(data.object.instanceList.length > 0){
         this.rolesList = data.object.instanceList;
        // console.log('---roles----',this.rolesList)
         for(let x=0; x<this.rolesList.length; x++){
          //console.log('23', this.rolesList[x].main.authority);
          if(this.rolesList[x].main.authority == 'ROLE_WAITER'){
            //console.log('25',this.rolesList[x].main.uuid)
            this.userRole.system.role.main.uuid = this.rolesList[x].main.uuid
          }
         }
         resolve(this.rolesList);
       }
       else{
         console.log("No hay roles registrados");
       }
     },
     error => {
       console.log("user:findAll",error);
     })
   })
 }

    /**
     * funcion para guardar un mesero
     */
    send(object, user){
      this.loadingService.show(true,'Guardando registro');
      object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.object.system.physicalPerson.complement.dateBirth = moment().format('DD-MM-YYYY');
      this.session.getRequest("waiter:update",object).subscribe(
        (data:any) => {
          this.object.main.uuid = data.object.main.uuid;
          this.user.system.physicalPerson = data.object.system.physicalPerson
          this.user.system.objectUuid  = this.session.getSessionObject().main.uuid
          this.session.getRequest('user:update', user).subscribe((data:any)=>{
            this.user = JSON.parse(JSON.stringify(data.object));
            console.log('23',this.userRole.system.role.main.uuid)
            this.userRole.system.user.main.uuid = this.user.main.uuid;
            console.log('24',this.userRole.system.user.main.uuid)
            this.session.getRequest('user:updateUserRole', this.userRole).subscribe((data:any)=>{
              console.log('aqui')
              this.userRole = JSON.parse(JSON.stringify(data.object));
  /*             this.snackBar.open('Registro guardado exitosamente', 'Información', {duration: 5000});
              this.close(true); */
              this.loadingService.hide();
            },
            (error)=>{
              console.log('Error:user:update',error)
              this.snackBar.open(error.message, 'Error', {duration: 5000});
              this.loadingService.hide();
            })
            
            this.loadingService.hide();
          },
          (error)=>{
            console.log('Error:user:update',error)
            this.snackBar.open(error.message, 'Error', {duration: 5000});
            this.loadingService.hide();
          })
          this.snackBar.open('Se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.close(true);
        },
        error => {
          console.log("waiter:update",error);
          this.loadingService.hide();
        }
      );
    }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = this.object;
      }else{
        object.transaction = 'bad';
        object.code = 'waiter:001';
      }
      this.dialogRef.close(object);
    }

}
