<mat-drawer-container class="drawerConteiner" autosize style="background: #fff;">
  <mat-drawer #drawer class="mainDrawwerHotel" mode="push" position="end">
    <app-restaurant-hotel-menu-collapsed></app-restaurant-hotel-menu-collapsed>
  </mat-drawer>
  <div class="row img-home-hotel mr-0">
    <div class="col-12">



      <div class="row justify-content-center" style="background: #f1f1f1;">
        <div class="col-3 pr-0" routerLink="/restaurant-hotel/home">
          <button mat-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;">
            <mat-icon class="pt-2 text-gris" style="font-size: 2rem;">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="col-6 text-center p-0" >
          <h2 class="text-gris align-middle mb-0 font-IBMPlexR" style="line-height: 1.2rem; font-size: 1rem; padding-top: 4px;">{{metadata.name}}</h2>
        </div>
        <div class="col-3 text-right pt-2 pl-0 pr-0">
          <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-hotel/order">
            <mat-icon class="text-gris">receipt_long</mat-icon>
          </button>
          <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
            <mat-icon class="text-gris" style="font-size: 2rem; margin-right: 5px;">dehaze</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="row justify-content-center mt-2">
    <!-- <mat-form-field appearance="legacy" class="col-11 font-kelson">
      <mat-label>Buscar</mat-label>
      <input matInput placeholder="Buscar">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field> -->
    <div class="col-11 text-center mt-2">
      <h1 class="font-IBMPlexB text-gris" style="font-size: 2rem; letter-spacing: 4px;">{{metadata.foodCategory.main.name}}</h1>
    </div>
  </div>


  <div class="row justify-content-center m-0">
    <div class="col-11">
      <div class="row">
        <div class="col-6 text-star mt-2" *ngFor="let foodSaucer of foodSaucerList" routerLink="/restaurant-hotel/product-detail/{{foodSaucer.main.uuid}}">
          <div *ngIf="foodSaucer.main.image" class="box_hotel">
            <img mat-card-image [src]="foodSaucer.image" class="img-menu rounded" *ngIf="foodSaucer.image">
            <div class="sk-circle" *ngIf="foodSaucer.image == undefined">
              <div class="sk-circle1 sk-child"></div>
              <div class="sk-circle2 sk-child"></div>
              <div class="sk-circle3 sk-child"></div>
              <div class="sk-circle4 sk-child"></div>
              <div class="sk-circle5 sk-child"></div>
              <div class="sk-circle6 sk-child"></div>
              <div class="sk-circle7 sk-child"></div>
              <div class="sk-circle8 sk-child"></div>
              <div class="sk-circle9 sk-child"></div>
              <div class="sk-circle10 sk-child"></div>
              <div class="sk-circle11 sk-child"></div>
              <div class="sk-circle12 sk-child"></div>
            </div>
          </div>
          <div class="box_hotel" *ngIf="!foodSaucer.main.image">
          </div>
          <div class="col-12" style="padding: 0px; background: #661414; width: 100%; height: 13%; text-align: center;">
            <span class="font-IBMPlexR pb-1 price">{{foodSaucer.main.price | currency:'CAD':'symbol-narrow'}}</span>
          </div>
          <div class=" p-1">
            <p class="font-IBMPlexR text-gris " style="line-height: 0.9rem; letter-spacing: 2px; text-align: center; font-size:.8rem;">{{foodSaucer.main.name}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
  <footer class="text-center text-secondary p-1 mt-5" >
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>


</mat-drawer-container>
