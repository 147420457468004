/*
USO ejemplo
<sti-auto-upload [stiFile] = "file" [uploadMethod]="code:code" [downloadMethod]="code:code"></sti-auto-upload>
modelo de file
{
  uuid:'uuid_de_objeto',
  name:'nombre_del_archivo',
  file:'',
  index:0,
  total:0,
  chunk:0,
  chunkSize:0,
  position:0,
  type:'',
  upload:false
}
*/
import { Input, HostListener, OnInit, Component, ChangeDetectionStrategy } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface images {
  uuid:string,
  name:string,
  file:string,
  index:number,
  total:number,
  chunk:number,
  chunkSize:number,
  position:number,
  type:string,
  upload:boolean
}
interface fileUpload {
  file:{
    uuid:string,
    name:string,
    file:string,
    index:number,
    total:number,
    chunk:number,
    chunkSize:number,
    position:number,
    type:string
  }
}
@Component({
  selector: 'sti-auto-upload',
  templateUrl: './sti-file.component.html',
  styleUrls: ['./sti-file.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StiFileToBlobComponent {
  @Input('stiFile') stiFile: images;
  @Input('uploadMethod') uploadMethod:string;
  @Input('downloadMethod') downloadMethod:string;

  metadata = {
    value:0,
    mode:'buffer',
    base64Aux:''
  }

  constructor(protected session:SessionService, public snackBar: MatSnackBar) {
    // console.log("FILE",this.stiFile);
  }

  ngOnChanges(){
    // console.log("HABER QUE HAY",this.stiFile);
    if(this.stiFile){
      //si la bandera esta activada subimod el archivo
      if(this.stiFile.upload){
        // console.log("Hay que subir dale ! To the moon ¡");
        this.stiFile.upload = false;
        this.uploadAFile();
      }else{
        // console.log("No hacemos nada");
        this.metadata.mode = 'determinate';
        this.metadata.value = 100;

      }
    }
  }

  /*
  * funcion para convertir un archivo en base 64
  */

  onFileChange(event) {
  let reader = new FileReader();
  if(event.target.files && event.target.files.length > 0) {
    // console.log(event);
    this.stiFile.name = event.target.files[0].name;
    this.stiFile.type = event.target.files[0].type;
    let file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = (event:any) => {
      this.stiFile.file = event.target.result;
      this.metadata.mode = 'buffer';
      this.metadata.value = 0;
      // console.log("event.target.result");
    };
  }
}
/**
función para subir un archivo
*/
uploadAFile(){
  // verificamos que alla un uuid
  if(this.stiFile.uuid != ''){
    if(this.stiFile.file != ''){
      let auxFile = this.stiFile.file.replace(/\+/g,"-");
      let chunksSize = 7000;
      let chunks = Math.ceil(this.stiFile.file.length/chunksSize)-1;
      // console.log(this.stiFile);

      this.stiFile.file = auxFile;
      this.stiFile.chunk = chunks;
      this.stiFile.chunkSize = chunksSize;
      this.stiFile.total = this.stiFile.file.length;
      this.metadata.mode = 'determinate';
      this.metadata.value = 0;
      this.stiFile.index = 0;
      this.stiFile.position = 0;
      this.uploadFileToBD(this.uploadMethod,this.stiFile).subscribe((data:any)=>{
        // console.log("DATOS",data);
        this.metadata.value = 100;
      },error=>{
        console.log("Error",error);
        this.cleandBuffer();
        this.snackBar.open("No se logro subir el archivo... intente nuevamente", 'Error', {duration: 5000});
      });
    }else{
      this.snackBar.open("Selecciona un archivo", 'Infomación', {duration: 5000});
    }
  }else{
    this.snackBar.open("No se ha realizado el guardado del objeto principal", 'Infomación', {duration: 5000});
  }
}

/*
funcion para descargar un archivo.
*/
async downloadAFile(){
  let auxFile ={
    file:{
      uuid:this.stiFile.uuid,
      name:this.stiFile.name,
      file:'',
      index:0,
      total:0,
      chunk:10,
      chunkSize:0,
      position:0,
      type:''
    }
  }
  let base64 = '';
  this.metadata.mode = 'query';
  try{
    let chunkFirstTime = true;
    for(;auxFile.file.index <= auxFile.file.chunk; auxFile.file.index++){
      // console.log("index--->",auxFile.file.index);
      let resF = await this.getRequestBaseFile(auxFile);
      if(resF.transaction == 'ok'){
        base64 = base64.concat(resF.object.file.file);
        auxFile.file.name = resF.object.file.name;
        if(chunkFirstTime){
          auxFile.file.chunk = resF.object.file.chunk;
          chunkFirstTime = false;
        }
      }else{
        this.metadata.mode = 'determinate';
        this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});
      }
    }
    if( base64 != ''){
      // console.log(base64);
      base64 = base64.replace(/-/g, "+");
      let elem = document.createElement('a');
      elem.href = base64;
      elem.setAttribute('download', auxFile.file.name);
      document.body.appendChild(elem);
      elem.click();
      this.metadata.mode = 'determinate';
    }

  }catch(e){
    console.log("error",e);
    this.metadata.mode = 'determinate';
    this.snackBar.open("No se logro descargar el archivo... intente nuevamente", 'Error', {duration: 5000});

  }



  // let elem = document.createElement('a');
  // elem.href = this.stiFile.file;
  // elem.setAttribute('download', this.stiFile.name);
  // document.body.appendChild(elem);
  // elem.click();
}

/**funcionalidad para pedir a la bd el base
*/
getRequestBaseFile(object):Promise<any>{
  return new Promise((resolve,reject)=>{
    this.session.getRequest(this.downloadMethod,object).subscribe((data:any)=>{
      resolve(data);
    },error=>{
      reject(error);
    })
  })
}

/**  ========== utilidades =========*/
/**Metodo para subir un file
*/
uploadFileToBD(code: string, object:any): Observable<Response> {

  return Observable.create(async(observer) => {

    try{
      let objectToSend:fileUpload = {
        file:{
          uuid:'',
          name:'',
          file:'',
          index:0,
          total:0,
          chunk:0,
          chunkSize:0,
          position:0,
          type:''
        }
      };
      objectToSend.file = JSON.parse(JSON.stringify(object));
      let resp = await this.uploadFileByParts(code,objectToSend);
      // console.log(resp);
      if(resp.status){
        // console.log("Terminado");
        observer.next();
        observer.complete();
      }else{
        // console.log("Sin terminar");
      }
    }catch(e){
      observer.error(e);
    }

  });
}

/**función para subir base64 por partes
*/

uploadFileByParts(code: string, object:fileUpload):Promise<{status:boolean,object:any}>{
  return new Promise(async(resolve,reject)=>{
    if(object.file.position < object.file.total){
      try{
        //partimos el base64 en varias partes de acuerdo el numero de trozos caracteres
        let lengthMin = object.file.position;
        let lengthMax = object.file.position + object.file.chunkSize > object.file.total ? object.file.total : object.file.position + object.file.chunkSize;

        // console.log("=== Trozos ===",lengthMin +'/'+lengthMax);
        //se envia el file en segmentos.
        let aux:fileUpload = object;
        aux.file.file = this.stiFile.file.slice(lengthMin, lengthMax);
        this.session.getRequest(code,aux).subscribe(async(data:any)=>{
          // console.log("HHHHHHHHHH",data);
          object.file.position = object.file.position + object.file.chunkSize;
          object.file.index++;
          this.stiFile.index++;// para llevar el numero de petición actual
          this.changeLoadder();
          let resp = await this.uploadFileByParts(code,object);
          resp.object = data.object;
          resolve(resp);
        },error=>{
          reject(error);
        })
      }catch(e){
        reject(e);
      }
    }else{
      // como llegamos al limite de enviamos en el
      resolve({
        status:true,
        object:{}
      })
    }
  });
}

/**función para tenerminar el valor de la carga
*/
changeLoadder(){
  this.metadata.value = (this.stiFile.index/this.stiFile.chunk)*100;
}

/**clenad buffer to upload
*/
cleandBuffer(){
  this.metadata.mode = 'buffer';
  this.metadata.value = 0
}


}
