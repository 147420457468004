<div class="row title-modal" style="margin-left: -24px;margin-right: -24px;">
  <div class="col-4 text-uppercase p-3">
    <h4>Accesos</h4>
  </div>
</div>
<div class="row justify-content-start">
  <div class="row justify-content-start" *ngIf="object.userObject != undefined">
  <div class="col-9">
    <label> USUARIO:  <strong>{{object.userObject.user.main.username}}</strong></label>
  </div>
  <div class="col-3">
    <button *ngIf="object.userObject.role.main.uuid != ''" mat-raised-button color="primary" mat-button (click)="changeRole(object.userObject.user.main.uuid)">CAMBIAR ROL</button>
  </div>
  <div class="col-11">
    <form class="login-form">
      <mat-form-field class="col-9" appearance="outline">
        <mat-label>ROL</mat-label>
        <mat-select placeholder="ROL" [disabled]="object.userObject.role.main.uuid != ''"  [(ngModel)]="object.userObject.role.main.uuid" name="sRole" (ngModelChange)="addRol();">
          <mat-option *ngFor="let object of object.rolesList" [value]="object.main.uuid">
            {{ object.main.authority }}
          </mat-option>
        </mat-select>
      </mat-form-field>

<!--       <mat-form-field class="col-9" appearance="outline">
        <mat-label>CUSTOMER</mat-label>
        <mat-select placeholder="CUSTOMER" [(ngModel)]="object.userObject.system.objectIdTemp" name="sCustomer" (ngModelChange)="changueClient(object.userObject.system.objectIdTemp);">
          <mat-option *ngFor="let object of object.customerList" [value]="object.main.uuid">
            {{ object.main.tradename }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <div *ngIf="object.userObject.system.objectType == 'CUSTOMER'">
        <mat-form-field class="col-9" appearance="outline">
          <mat-label>Compañia</mat-label>
          <mat-select placeholder="Compañia" [(ngModel)]="object.userObject.system.objectId" name="sCompany" (ngModelChange)="addCompany();">
            <mat-option *ngFor="let object of companyList" [value]="object.main.uuid">
              {{ object.main.tradename }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-list class="col-9">
        <div mat-subheader>Nuevo Acceso</div>
        <mat-list-item *ngFor="let company of object.objectsList; let i=index">
          <button mat-icon-button color="primary" matTooltip="Eliminar" (click)="removeObject(i)">
            <i class="material-icons">delete_forever</i>
          </button>
          <div mat-line>{{ company.main.tradename }}</div>
        </mat-list-item>
      </mat-list>

      <mat-list class="col-9">
        <div mat-subheader>Accesos Actuales</div>
        <mat-list-item *ngFor="let objectAssing of object.objectsAssingList; let i=index;">
          <button mat-icon-button color="primary" matTooltip="Eliminar" (click)="deleteObject(objectAssing.id, i)">
            <i class="material-icons">delete_forever</i>
          </button>
          <div mat-line>{{ objectAssing.object.main.tradename }}</div>
        </mat-list-item>
      </mat-list>

    </form>
  </div>
  <div class="col-11 row justify-content-end mt-4">
    <div class="col-4">
      <button type="button" style="width:100%" class="btn btn-block" (click)="close(false);">Cerrar</button>
    </div>
    <div class="col-4">
      <button type="button" style="width:100%" class="btn btn-danger btn-block" [disabled]="object.userObject.role.main.uuid ==='' || object.userObject.system.objectIdTemp === ''" (click)="send();">Aceptar</button>
    </div>
  </div>
</div>
