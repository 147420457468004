import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-selected-object',
  templateUrl: './selected-object.component.html',
  styleUrls: ['./selected-object.component.css']
})
export class SelectedObjectComponent implements OnInit {
  result = {
    transaction:'ok',
    object:{},
    code:'selectedObjectComponent:001'
  }
  objects = [];
  metadata = {
    object:{
      main:{
        uuid:''
      }
    }
  }
  constructor(public dialogRef: MatDialogRef<SelectedObjectComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    this.objects = this.data;
  }

  /** funcionalidad para cerrar sesión
  */
  close(status){
    for(let item of this.objects){
      if(item.system.object.main.uuid ==  this.metadata.object.main.uuid){
        this.metadata.object = item.system.object;
      }
    }
    if(status){
      this.result.transaction = 'ok';
      this.result.object = this.metadata.object;
    }else{
      this.result.transaction = 'bad';
      this.result.code = 'selectedObjectComponent:002';
    }
    this.dialogRef.close(this.result);
  }

}
