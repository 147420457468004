import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatSelectionList } from '@angular/material/list';
@Component({
  selector: 'app-company-client-promotion-update',
  templateUrl: './company-client-promotion-update.component.html',
  styleUrls: ['./company-client-promotion-update.component.css']
})
export class CompanyClientPromotionUpdateComponent implements OnInit {
  @ViewChild('matselectionlist', { static: false }) matselectionlist: MatSelectionList ;

  branchList = [];

  promotionImageModel = {
    main:{
      uuid:''
    },
    image:{
      file:'',
      fileName:'',
      fileType:''
    },
    system:{
      foodCategory:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      }
    }
  }
  branchSelectedList = [];
  fileModel = {
    uuid:'uuid_de_objeto',
    name:'nombre_del_archivo',
    file:'',
    index:0,
    total:0,
    chunk:0,
    chunkSize:0,
    position:0,
    type:'',
    upload:true,
    main:{
      uuid:'uuid_de_objeto'
    }
  }

  promotionList = [];
  metadata = {
    image:{
      file:'',
      name:'',
      type:''
    }
  }

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyClientPromotionUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {
    console.log("DATA::::::", this.data);
    this.getBrachList();
  }

  getBrachList() {
    return new Promise((resolve) => {
      this.loadingService.wait();
      this.session.getRequest('branch:list', {max: 1000, offset: 0, system: {company: {main: {uuid: this.session.getSessionObject().main.uuid}}}}).subscribe(
        (data: any) => {
          this.loadingService.hide();
          this.branchList = data.object.instanceList;
          resolve({ transaction: 'ok', instanceList: data.object.instanceList });
        }, error => {
          console.log("Error: branch:list", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          this.loadingService.hide();
        });
    });
  }

  /**función para seleccionar un branch
  */
    selectedBranch(ev){
      if(ev.option._selected){
        console.log("Seleccionado, buscamos el objeto para agregarlo ");
        for( let item of this.branchList){
          if(item.main.uuid == ev.option._value){
            this.branchSelectedList.push(item);
          }
        }
      }else{
        console.log("Deseleccionado");
        for(let i=0; i < this.branchSelectedList.length; i++){
          if(this.branchSelectedList[i].main.uuid == ev.option._value){
            this.branchSelectedList.splice(i,1);
            break;
          }
        }
      }
      // console.log(ev);
    }

    /**funcionalidad para guardar las promociones sin imagen
    */
    savePromotiosWitoutImage(){
      let aux = JSON.parse(JSON.stringify(this.promotionImageModel));
      let index = 0;
      for(let item of  this.branchSelectedList){
        aux.system.branch.main = item.main;
        // let fileAux = JSON.parse(JSON.stringify(this.fileModel));
        // fileAux.uuid = aux.main.uuid;
        // fileAux.main.uuid = aux.main.uuid;
        // fileAux.name = this.metadata.image.name;
        // fileAux.type = this.metadata.image.type;
        // fileAux.file = this.metadata.image.file;
        // aux.file = fileAux;
        // this.promotionList.push(aux);
        // index++;
        // if(index == this.branchSelectedList.length){
        //   //aqui limpiamos todas las variables para una nueva carga
        //   //limpiar metadata, branchSelectedList
        //   this.branchSelectedList = [];
        //   this.matselectionlist.deselectAll();
        //   this.metadata.image.file = '';
        //   this.metadata.image.type = '';
        //   this.metadata.image.name = '';
        // }
        this.session.getRequest("promotionImage:update",aux).subscribe((data:any)=>{
          let fileAux = JSON.parse(JSON.stringify(this.fileModel));
          fileAux.uuid = data.object.main.uuid;
          fileAux.main.uuid = data.object.main.uuid;
          fileAux.name = this.metadata.image.name;
          fileAux.type = this.metadata.image.type;
          fileAux.file = this.metadata.image.file;
          data.object.file = fileAux;
          this.promotionList.push(data.object);
          index++;
          if(index == this.branchSelectedList.length){
            //aqui limpiamos todas las variables para una nueva carga
            //limpiar metadata, branchSelectedList
            this.branchSelectedList = [];
            this.matselectionlist.deselectAll();
            this.metadata.image.file = '';
            this.metadata.image.type = '';
            this.metadata.image.name = '';
          }
        },error=>{
          console.log("promotionImage:update",error);
        });
      }
    }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = {};
      }else{
        object.transaction = 'bad';
        object.code = 'promotions:001';
      }
      this.dialogRef.close(object);
    }

}
