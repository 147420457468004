import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AdminUserUpdateComponent } from './admin-user-update/admin-user-update.component';
import { AdminUserAddAccessComponent } from './admin-user-add-access/admin-user-add-access.component';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.css']
})
export class AdminUserComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  pageEvent: PageEvent;

  list = [];

  userMin = {
    // type:"Clientes",
    // objectUuid:"",
    max: 10,
    offset: 0,
    sort: "username",
    order: "asc",
    filter:{
      name:"",
      value:""
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
  }
  companyList=[];
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService:LoadingService) {
   //  this.userMin.objectUuid = this.session.getSessionObject().main.uuid;
    // console.log('--hjh---',this.userMin.objectUuid)
   //  this.userMin.type = "Customer";
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      if(val != ''){
        this.list = [];
        this.userMin.filter.name = 'searchElement';
      }else{
        this.list = [];
        this.userMin.filter.name = '';
        this.userMin.filter.value = '';
        val = 'false'
      }
      this.userList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
      }, error => {
      console.log("error:::",error);
    });
   }

  ngOnInit(): void {
    this.userList();
  }
  /** Funcion para obtener la lista de usuarios **/
  userList(){
    this.loadingService.wait();
    this.session.getRequest('user:list', this.userMin).subscribe(
      (data:any)=>{
        this.list = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        // for (var x = 0; x < data.object.instanceList.length; x++) {
        //   this.list.push(data.object.instanceList[x])
        //   // Se llama a la funcion para obtener los objetos a los que tiene acceso cada usuario
        //   this.getObjectsUser(data.object.instanceList[x].main.uuid, x);
        // }
        this.loadingService.hide();
        //this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("user:list",error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

  /**funcionalidad para dar de alta un usuario
  */
  updateDialog(uuid){
    this.dialog.open(AdminUserUpdateComponent, {
      width: '60%',
      height:'90%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.userList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**funcionalidad para ver los acceso de un usuario*/
  addAccess(object){
    console.log("addAccessTO:::::",object);
    this.dialog.open(AdminUserAddAccessComponent, {
      width: '60%',
      height:'90%',
      data: object
    }).afterClosed().subscribe(result => {
      this.userList();
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.snackBar.open('Acceso actualizado exitosamente', '', {
            duration: 1000
          });
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /** Función para eliminar un usuario.
  */
  delete(uuid){
  this.loadingService.wait();
   this.session.getRequest('dependentMethods:deleteUser', {main:{uuid:uuid}}).subscribe(
    (data:any)=>{
      for(let i=0; i < this.list.length; i++){
        if(this.list[i].main.uuid == uuid){
          this.list.splice(i,1);
        }
      }
      this.loadingService.hide();
      this.snackBar.open('Se eliminó usuario exitosamente', 'Información', {duration: 5000});
    },
    error => {
      console.log("dependentMethods:deleteUser", error);
      this.loadingService.hide();
      this.snackBar.open(error.message, 'Error', {duration: 5000});
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.userMin.offset = (event.pageIndex * event.pageSize);
    this.userMin.max = event.pageSize;
    this.userList();
  }

  tabChange(ev){
    // console.log(ev);
    this.list = [];
    if(ev.tab.textLabel == 'Clientes'){
      // this.userMin.objectUuid = '';
      // this.userMin.type = "Company";
      let customerUuid = this.session.getSessionObject().main.uuid
      this.changueClient(customerUuid);
    }else{
      // this.userMin.objectUuid = this.session.getSessionObject().main.uuid;
      //  this.userMin.type = "Customer";
      this.userList();
    }
  }

  changueClient(objectIdTemp){
    this.session.getRequest('company:findAllByStatus', {system:{company:{main:{uuid: objectIdTemp}}, status:true}}).subscribe(
     (data:any)=>{
       this.companyList = data.object.instanceList;
     },
     error => {
       console.log("company:findAllByStatus",error);
     })
  }


  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
