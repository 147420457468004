import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingService } from './loading.service';
import {
  MatProgressBarModule,
} from '@angular/material/progress-bar';
import {
  MatProgressSpinnerModule
} from '@angular/material/progress-spinner';
export * from './loading.service';

@NgModule({
  providers:[
    LoadingService
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    LoadingComponent
  ],
  entryComponents: [],
  exports: [
    LoadingComponent,
  ]
})
export class LoadingModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: LoadingModule,
    };
  }
 }
