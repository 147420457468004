<mat-drawer-container class="drawerConteiner" autosize>
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-menu></app-menu>
  </mat-drawer>
  <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
  <div class="row justify-content-center pl-0 pr-0">
    <div class="col-10">
      <div class="row mt-2 mb-2">
        <div class="col-9">
          <h4 class="text-uppercase pt-2 pb-2">Configuración de Sucursales</h4>
        </div>
        <div class="col-3">
          <button style="width:100%" mat-flat-button color="accent"  matTooltip="Crear nueva sucursal" (click)="updateDialog('new');"> <i class="material-icons">add_circle_outline</i>Crear nueva sucursal</button>
        </div>
      </div>
    </div>
    <mat-tab-group class="col-10" (selectedTabChange)="setBranch($event)">
      <mat-tab *ngFor="let branch of branchList">
        <ng-template mat-tab-label>
          {{ branch.main.name }}
          <button mat-icon-button color="accent" matTooltip="Editar" (click)="updateDialog(branch.main.uuid);">
            <i class="material-icons">create</i>
          </button>
          <button mat-icon-button color="primary" matTooltip="QR" (click)="generateQrDialog(branch.main.uuid);">
            <i class="material-icons">qr_code</i>
          </button>
        </ng-template>
          <div class="row ">
            <div class="col-2 col-md-3 mt-2"  *ngFor="let foodCategory of foodCategoryList">
              <mat-card class=" text-center ml-2">
                <img src="/assets/img/{{metadata.configurationQr.main.theme}}/{{foodCategory.complement.icon}}" style="width:4rem; background: #3c94ac">
                <h3 class="mb-0">{{foodCategory.main.name}}</h3>
                <div class="row justify-content-end">
                  <div class="col-5 pr-0">
                    <mat-button-toggle-group #group="matButtonToggleGroup" (click)="updateDialogFoodCategory(foodCategory.main.uuid);" >
                      <mat-button-toggle value="left" aria-label="Text align left">
                        <mat-icon color="warn">create</mat-icon>
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                  <div class="col-3"></div>
                </div>
              </mat-card>
            </div>
            <div class="col-2 col-md-3 text-center mt-3" (click)="updateDialogFoodCategory('new');">
              <button mat-mini-fab color="accent" aria-label="Nueva clasificación de menú">
                <mat-icon>add_box</mat-icon>
              </button>
              <h3 class="mt-2">Nueva clasificación de menú</h3>
            </div>
          </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-drawer-container>
