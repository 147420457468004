import { Component, Input, Output, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-waiter-order-bar',
  templateUrl: './waiter-order-bar.component.html',
  styleUrls: ['./waiter-order-bar.component.css']
})
export class WaiterOrderBarComponent{
  foodOrderDetailModel ={
    main:{
      uuid: '',
      quantity: 1,
      observation:'',
      priceUnitary:'',
      subTotal:0,
      tax:0,
    },
    complement:{
      peopleNumber:'',
    },
    system:{
      foodSaucer:{
        main:{
          uuid:''
        }
      },
      foodSaucerSize:{
        main:{
          uuid:'',
          order:0,
          price:0.0,
          size:'',    
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.foodOrderDetailModel));

  foodSaucerBranchMin={
    system:{
      foodSaucer:{
        main:{
          uuid:'',
        }
      },
      bar:{
        main:{
          uuid:'',
        }
      }
    }
  }


  barList =[];
  foodOrderDetailList =[];
  foodOrderList=[];

  metadata={
    bar:{
      main:{
        uuid:''
      }
    },
  }

  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<WaiterOrderBarComponent >, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.getBarList();
    if(this.data != 'new') {
        this.object = this.data;
   }

  }

  //función para obtener las barras por sucursal
  getBarList() {
    this.loadingService.wait();
    this.session.getRequest('bar:findAllByBranch', {system:{branch:{main:{uuid: this.session.getSessionObject().main.uuid }}}}).subscribe(
      (data: any) => {
        this.barList = data.object.instanceList;      
        this.loadingService.hide();
      }, error => {
        console.log("Error: bar:findAllByBranch", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

  


  //  /**función para obtener los platillos por barra y si no estan preparados 
  // */
  //  findAllByBarAndIsMake(){
  //   this.session.getRequest("foodSaucerBranch:findAllByBarAndIsMake", {system:{bar:{main:{uuid: this.metadata.bar.main.uuid}}}, complement:{isMake: false}} ).subscribe(
  //   (data:any) => {
  //     console.log(data);
  //     this.foodOrderDetailList = data.object.instanceList;     
  //   }, error => {
  //     console.log("Error: configurationQr:get",error);
  //   });
  // }


    /**función para obtener los platillos por barra y por orden 
  */
    findAllByFoodOrderAndBar(){
      this.session.getRequest("foodSaucerBranch:findAllByFoodOrderAndBar", {system:{bar:{main:{uuid: this.metadata.bar.main.uuid}}, foodOrder:{main:{uuid: this.object.main.uuid}}}}).subscribe(
      (data:any) => {
        this.foodOrderList = data.object.instanceList;     
      }, error => {
        console.log("Error: configurationQr:get",error);
      });
    }

  tabActive(event) {
    // obtenemos el id de la barra
    this.metadata.bar.main.uuid = event.tab.textLabel
    //this.findAllByBarAndIsMake();
    this.findAllByFoodOrderAndBar();
  }

  updateIsMake(foodOrder){
    this.loadingService.show(true,'Guardando registro...');
    foodOrder.complement.isMake = true;
    this.session.getRequest("foodOrderDetail:updateIsMake",foodOrder).subscribe(
      (data:any) => { 
        this.findAllByFoodOrderAndBar();
        this.loadingService.hide();
      },
      error => {
        console.log("Error: foodOrderDetail:updateIsMake",error);
        this.loadingService.hide();
      }
    );
  
    

  }

  commandReport(){
    this.loadingService.show(true, "Obteniendo comanda de la barra " + this.metadata.bar.main.uuid);
    this.session.getRequest('sale:comanda', {system:{bar:{main:{uuid:this.metadata.bar.main.uuid}}, foodOrder:{main:{uuid:this.object.main.uuid}}}}).subscribe(
    (data: any) => {
      if(data.transaction=='ok'){
        var elem = document.createElement('a');
        elem.href = "data:application/pdf;base64,"+ data.object;
        elem.setAttribute('download', this.metadata.bar.main.uuid+'.pdf');
        document.body.appendChild(elem);
        elem.click();
      }
      this.loadingService.hide();
    }, error => {
      console.log("sale:comanda", error);
      this.snackBar.open('Error en la descarga', 'Error', { duration: 5000 });
      this.loadingService.hide();
    })
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'sale:001';
    }
    this.dialogRef.close(object);
  }

}
