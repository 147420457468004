import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import { CompanyClientChefUpdateComponent } from './company-client-chef-update/company-client-chef-update.component';
import { CompanyClientChefAccessComponent } from './company-client-chef-access/company-client-chef-access.component';

@Component({
  selector: 'app-company-client-chef',
  templateUrl: './company-client-chef.component.html',
  styleUrls: ['./company-client-chef.component.css']
})
export class CompanyClientChefComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  chefMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      status: true,
    },
    max: 10,
    offset: 0,
    sort: "mCode",
    order: "asc",
  };

  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;
  chefList = [];


  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    this.chefMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
   }

  ngOnInit(): void {
    this.getChef();
  }

  /** función para obtener las meseros
  */
  getChef() {
    this.loadingService.wait();
    this.session.getRequest('chef:list', this.chefMin).subscribe(
      (data: any) => {
        this.chefList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("chef:list", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

    // funcion para obtener los datos del paginado.
    onPaginateChange(event) {
      this.chefMin.offset = (event.pageIndex * event.pageSize);
      this.chefMin.max = event.pageSize;
      this.getChef();
    }


    /**
   * funcionalidad para cambiar el menú
   */
      toogleMenu(ev) {
        this.drawerCtrl.opened = ev;
      }

  /**funcionalidad para crea o actualizar un cocinero
  */
  update(uuid){
    const dialogRef = this.dialog.open(CompanyClientChefUpdateComponent, {
      width: '98%',
      height:'80%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getChef();
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

  /**funcionalidad para asignar un chef a una sucursal
*/
  access(uuid) {
    const dialogRef = this.dialog.open(CompanyClientChefAccessComponent, {
      width: '98%',
      height: '80%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.getChef();
        } else {
          // El modal se cerro sin objeto
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

}
