import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { SessionService } from '../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.module';
import { debounceTime, switchMap } from 'rxjs/operators';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-admin-company-client',
  templateUrl: './admin-company-client.component.html',
  styleUrls: ['./admin-company-client.component.css']
})
export class AdminCompanyClientComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  pageEvent: PageEvent;

  companyMin = {
   // system: {
      company: {
        main: {
          uuid: ''
        }
      },
   // },
    max: 10,
    offset: 0,
    sort: "mTradename",
    order: "asc",
    filter: {
      name: "",
      value: ""
    }
  };

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>()
  }
  companyList = [];

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    //this.companyMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.companyMin.company.main.uuid = this.session.getSessionObject().main.uuid;
    console.log('hola',this.companyMin.company.main.uuid);
    this.metadata.searchBoxInput.pipe(debounceTime(700),
        switchMap(val => {
          // console.log('called once',val)
          if (val != '') {
            this.companyList= [];
            this.companyMin.filter.name = 'findAllByCompanyAndLikeName';
          } else {
            this.companyList  = [];
            this.companyMin.filter.name = '';
            this.companyMin.filter.value = '';
            val = 'false'
          }
            this.getCompany();
          return val;
        })
        ).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
          }, error => {
          console.log('error logged:');
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          console.log(error);
        });
/*     this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.companyList = [];
          this.companyMin.filter.name = 'searchElement';
        } else {
          this.companyList = [];
          this.companyMin.filter.name = '';
          this.companyMin.filter.value = '';
          val = 'false'
        }
        this.getCompany();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      }); */
  }

  ngOnInit(): void {
    this.getCompany();
  }

  /** función para obtener las compañias cliente por customer
  */
  getCompany() {
    this.loadingService.wait();
    this.session.getRequest('company:list', this.companyMin).subscribe(
      (data: any) => {
        this.companyList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("company:list", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

  /*
  * funcion para eliminar una compañia
  */
  delete(object) {
    this.session.getRequest('dependentMethods:deleteCompany', { main: { uuid: object.main.uuid } }).subscribe(
    // this.session.getRequest('dependentMethods:deleteCompany', { main: { uuid: object.main.uuid } }).subscribe(
      (data: any) => {
        console.log("Se elimino compañia exitosamente");
        this.snackBar.open('Empresa cliente eliminada correctamente', 'Información', { duration: 5000 });
        for (let i = 0; i < this.companyList.length; i++) {
          if (this.companyList[i].main.uuid == object.main.uuid) {
            this.companyList.splice(i, 1);
          }
        }
      },
      error => {
        console.log("dependentMethods:deleteCompany", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      })
  }
  /*
    *Funcion tipo evento para buscar una compañia cliente
  */
  search() {
    this.metadata.searchBoxInput.next(this.companyMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.companyMin.offset = (event.pageIndex * event.pageSize);
    this.companyMin.max = event.pageSize;
    this.getCompany();
  }

  handleUpdated(event) {
    this.companyMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize : this.companyMin.offset);
    this.getCompany();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  } 

}
