import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import { AdminRoleUpdateComponent } from './admin-role-update/admin-role-update.component';

@Component({
  selector: 'app-admin-role',
  templateUrl: './admin-role.component.html',
  styleUrls: ['./admin-role.component.css']
})
export class AdminRoleComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  metadata = {
    saving:false,
    objectType:"Company",
    company:{
      main:{
        uuid:''
      }
    },
    searchBoxInput: new Rx.Subject<string>(),
  };
  roleList = [];
  actionMin = {
    main:{
      uuid:''
    },
    system: {
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };
  roleActionMin = {
    main:{
      uuid:''
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };
  actionModel = {
    main:{
      uuid:'',
      description:'',
      httpMethod:'',
      role:'',
      url:'',
    },
    system:{
      status:true
    }
  };
  actionList = [];
  roleActionList = [];
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };
  object:any = {};

  paginatorAction = JSON.parse(JSON.stringify(this.paginatorModel));
  paginatorRoleAction = JSON.parse(JSON.stringify(this.paginatorModel));

  // MatPaginator Output
  pageEventActions: PageEvent;
  pageEventRoleActions: PageEvent;

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.actionList = [];
        this.actionMin.filter.name = 'searchElement';
      }else{
        this.actionList = [];
        this.actionMin.filter.name = '';
        this.actionMin.filter.value = '';
        val = 'false'
      }
      this.getActions();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
    console.log('error logged:');
    this.snackBar.open(error.message, 'Error', {duration: 5000});
    console.log(error);
  });
  }
  ngOnInit() {
    this.metadata.company = this.session.getSessionObject();
    this.findAllRoleByObject(this.metadata.company.main.uuid,'Company');
    this.getActions();
  }

  /**funcion para obtener el listado de roles
  */
  findAllRoleByObject(uuid,type){
    switch(type){
      case 'Company':{
        this.metadata.objectType = "Company";
        break;
      }
      case 'Branch':{
        this.metadata.objectType = "Branch";
        break;
      }
    }
    // this.metadata.objectType = "Branch";
    this.metadata.saving = true;
    this.session.getRequest("role:findAllByObjectTypeAndObjectId",{system:{objectId:uuid,objectType:this.metadata.objectType}}).subscribe((data:any)=>{
      // console.log("OKO",data);
      this.roleList = data.object.instanceList;
      this.metadata.saving = false;
    },error=>{
      this.metadata.saving = false;
      console.log("Error:role:findAllByObjectTypeAndObjectId",error);
    })
  }

/** Funcionalidad para
*/
  openDialogToCreateRoles(): void {
  const dialogRef = this.dialog.open(AdminRoleUpdateComponent, {
    width:'50vw',
    data: {}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed',result);
    if(result){
      // recargamos los roles
      this.findAllRoleByObject(this.metadata.company.main.uuid,'Company');
    }else{
      // no hacemos nada ya que no se agregaron roles
    }
  });
}
/** Función para obtener las acciones que hay diponible en el sistema
*/
getActions(){
  this.loadingService.wait();
  this.session.getRequest("action:list",this.actionMin).subscribe((data:any)=>{
    // console.log("OKO",data);
    this.loadingService.hide();
    this.actionList = data.object.instanceList;
    this.paginatorAction.total = data.object.total;
    // recorremos la acciones para quitar esa accion
    for(let j=0; j < this.roleActionList.length; j++){
      for(let i=0; i < this.actionList.length; i++){
        if(this.roleActionList[j].main.uuid == this.actionList[i].main.uuid){
          this.actionList.splice(i,1);
        }
      }
    }

  },error=>{
    console.log("Error:action:list",error);
    this.loadingService.hide();
  });
}

/**Funcion para obtener las acciones de un rol
*/
getActionByRole(){
  this.session.getRequest("role:findAllByAction",this.roleActionMin).subscribe((data:any)=>{
    this.roleActionList = data.object.instanceList;
    this.paginatorRoleAction.total = data.object.total;
  },error=>{
    console.log("Error:role:findAllByAction",error);
  })
}

/*
  *Funcion para buscar
*/
search(){
  this.metadata.searchBoxInput.next(this.actionMin.filter.value);
}

// funcion para obtener los datos del paginado de role Action.
onPaginateChange(event){
  this.roleActionMin.offset = (event.pageIndex * event.pageSize);
  this.roleActionMin.max = event.pageSize;
  this.getActionByRole();
}

handleUpdated(event) {
  this.roleActionMin.offset = (this.pageEventRoleActions ? this.pageEventRoleActions.pageIndex * this.pageEventRoleActions.pageSize  : this.roleActionMin.offset);
  this.getActionByRole();
}
// funcion para obtener los datos del paginado de acciones
onPaginateChangeAction(event){
  this.actionMin.offset = (event.pageIndex * event.pageSize);
  this.actionMin.max = event.pageSize;
  this.getActions();
}

handleUpdatedAction(event) {
  this.actionMin.offset = (this.pageEventActions ? this.pageEventActions.pageIndex * this.pageEventActions.pageSize  : this.actionMin.offset);
  this.getActions();
}

deleteAnAction(object){
  this.loadingService.wait();
  this.session.getRequest("role:deleteRoleAction",{role:this.object.main.uuid,action:object.main.uuid}).subscribe((data)=>{
    //removemos el rol de la accion
    this.explodeActionRole(object.main.role,[]).then((data:any)=>{
      let rolesAux = data;
      let rolessrt = '';
      for(let i =0; i < rolesAux.length; i++){
        if(rolesAux[i] == this.object.main.authority){
          rolesAux.splice(i,1);
          break;
        }
      }
      for(let i =0; i < rolesAux.length; i++){
        if(i==0){
          rolessrt = rolessrt+rolesAux[i];
        }else{
          rolessrt = rolessrt+','+rolesAux[i];
        }
      }
      object.main.role = rolessrt;
      console.log("action:",object);
      this.session.getRequest('action:update', object).subscribe((data: any) => {
        for(let i=0; i < this.roleActionList.length; i++){
          if(this.roleActionList[i].main.uuid == object.main.uuid){
            this.roleActionList.splice(i,1);
          }
        }
        this.session.getRequest('role:refreshRoles', {}).subscribe((data: any) => {
          this.snackBar.open('El registro se almaceno exitosamente', '', {duration: 8000});
          this.loadingService.hide();
          //recargamos acciones
          this.getActions();
        },(error) => {
            this.loadingService.hide();
            console.log('Error: role:refreshRoles', error)
          });
      },
        (error) => {
          this.loadingService.hide();
          console.log('Error: action:update', error)
        });
    })

    this.loadingService.hide();
  },error=>{
    this.loadingService.hide();
    console.log("error",error);
  })
}

/** Método para agregra un accion a un rol
*/
actionSeleted(object){
  let actionRole = [];
  this.explodeActionRole(object.main.role,actionRole).then((data:any)=>{
    console.log("OKOKOKOKOKOKO")
    actionRole = data;
    let found = false;
    for(let item of actionRole){
      if(item == this.object.main.authority){
        found = true;
      }
    }
    if(!found){
      this.loadingService.wait();
      this.session.getRequest("role:createRoleAction",{role:this.object.main.uuid,action:object.main.uuid}).subscribe((data:any)=>{
        this.roleActionList.push(object);
        // this.actionList = [];
        //editamos la accion para editarla
        let action = data.object.action;
        action.main.role = action.main.role + ','+this.object.main.authority;
        this.session.getRequest('action:update', action).subscribe((data: any) => {
          this.session.getRequest('role:refreshRoles', {}).subscribe((data: any) => {
            this.snackBar.open('El registro se almaceno exitosamente', '', {duration: 8000});
            this.loadingService.hide();
            //recargamos acciones
            this.getActions();
          },(error) => {
              this.loadingService.hide();
              console.log('Error: role:refreshRoles', error)
            });
        },
          (error) => {
            this.loadingService.hide();
            console.log('Error: action:update', error)
          });
      },error=>{
        this.loadingService.hide();
        console.log("error",error);
      })
    }
  })

}
/** function to explode a role from action
*/
explodeActionRole(str,roles){
  return new Promise((resolve,reject)=>{
    // console.log(str);
    // console.log(roles);
    if(str.search(',') == -1){
      // console.log("Fin");
      roles.push(str);
      resolve(roles);
    }else{
      roles.push(str.slice(0,str.search(',')));
      this.explodeActionRole(str.slice(str.search(',')+1,str.length),roles).then((data)=>{
        resolve(data);
      })
    }
  })
}

/**función para seleccionar un role
*/
selectedRole(object){
  console.log("object",object.tab.textLabel);
  for(let item of this.roleList){
    if(item.main.authority == object.tab.textLabel){
      this.object = JSON.parse(JSON.stringify(item));
    }
  }
  this.roleActionMin.main.uuid = this.object.main.uuid;
  this.getActionByRole();
}

/*
 * funcion para controlar si se abre o cierra el menu
 */
toogleMenu(ev) {
  this.drawerCtrl.opened = ev;
}

}
