<mat-drawer-container class="drawerConteiner" autosize>
    <mat-drawer #drawer class="mainDrawer" mode="side">
      <app-menu></app-menu>
    </mat-drawer>
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    
    <div class="row justify-content-center pl-0 pr-0 mt-3">
      <div class="row col-12">
        <div class="col-7">
            <mat-card>
                <h3>Folio:</h3>
                
            </mat-card>
        </div>
        <div class="col-5">
            <mat-card >
                <div class="row">
                    <div class="col-6">
                        <h3>Impuestos $:</h3>
                        <h3>Subtotal $:</h3>
                    </div>
                    <div class="col-6">
                        <h3>Abono $:</h3>
                        <h3>Fecha:</h3>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-12 mt-4">
            <mat-card>
                <h3>Platillos:</h3>
                
            </mat-card>
        </div>
     

      </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-11">

        </div>
    </div>

</mat-drawer-container>


