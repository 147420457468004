<mat-drawer-container class="drawerConteiner font-kelson" autosize>
  <mat-drawer #drawer class="mainDrawwerCountry" mode="push" position="end">
    <app-restaurant-seafood-menu-collapsed></app-restaurant-seafood-menu-collapsed>
  </mat-drawer>
  <div class="row justify-content-center font-kelson m-0" style="background:#010c42;">
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="Menu" matTooltip="Regresar" style="padding-left:0px;" (click)="back();">
        <mat-icon class="text-white">keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div class="col-6 text-center pt-2">
      <h2 class="text-white" style="line-height: 1.4rem;">{{metadata.name}}</h2>
    </div>
    <div class="col-2 pt-2">
      <button mat-icon-button aria-label="pedidos" matTooltip="Pedidos"  routerLink="/restaurant-seafood/order">
        <mat-icon class="text-white">receipt</mat-icon>
      </button>
    </div>
    <div class="col-2 pt-2 text-center">
      <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="drawer.toggle();" style="padding-left:0px;">
        <mat-icon class="text-white">dehaze</mat-icon>
      </button>
    </div>
  </div>
  <div class="row justify-content-center m-0">
    <div class="col-11 pt-3">
      <p class="pb-4" style=" font-size: 15pt;"> <strong>Formulario de pedido</strong> </p>
      <!-- <p class="pb-1" style=" font-size: 12pt;"> <strong>Método de pago: Efectivo</strong> </p> -->
      <!-- <p class="pb-1" style=" font-size: 12pt;"> <strong>Forma de pago: {{object.main.paymentMethod}}</strong> </p> -->

      <div class="row">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.main.fullname" [formControl]="complexForm.controls['name']" >
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Teléfono</mat-label>
          <input matInput placeholder="Teléfono" name="phone" [(ngModel)]="contacts[0].main.data" [formControl]="complexForm.controls['phone']" >
          <mat-error *ngIf="complexForm.controls['phone'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <!-- <app-sti-contact [contacts] = "contacts"></app-sti-contact> -->
        <div class="col-12 mb-2" *ngIf="metadata.map.enabled">
          <p>Agrega tu ubicación.</p>
          <agm-map [latitude]="metadata.map.lat" [longitude]="metadata.map.log" [zoom]="18" style="width: 100%; height: 40vh;">
           <agm-marker [latitude]="metadata.map.lat" [longitude]="metadata.map.log" [label]="'Tu ubicación'" [markerDraggable]="true" (dragEnd)="changeLocation($event);">
           </agm-marker>
          </agm-map>
        </div>
        <div *ngIf="!metadata.map.enabled" class="col-12">
          <p>Tu localziación no esta activa. Agrega los datos de tu dirección.</p>
        </div>
        <mat-label class="col-12 mb-2"> <strong>Ingresa tu dirección</strong> </mat-label>
        <sti-address-anonymus [stiAddress]="object.system.address" class="col-12"></sti-address-anonymus>
      </div>

    </div>
    <div class="col-10">
      <button mat-flat-button  [disabled]="complexForm.invalid || contacts.length == 0" style="width:100%; background:#95c751;" (click)="send();">Pedir</button>
    </div>
  </div>
  <footer class="text-center text-secondary p-1">
  © 2019 Keysoft. Reservados todos los derechos.
  </footer>
</mat-drawer-container>
