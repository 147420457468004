<mat-toolbar>
  <mat-toolbar-row class="toolbar">
    <div class="row" style="width:100%;">
      <div class="col-2">
        <button mat-mini-fab aria-label="Menu" matTooltip="Menu" (click)="toogleMenu();" color="accent">
          <mat-icon *ngIf="!metadata.toggleMenu">menu</mat-icon>
          <mat-icon *ngIf="metadata.toggleMenu">menu_open</mat-icon>
        </button>
        <span *ngIf="!metadata.toggleMenu" class="p-3">
          <img src="assets/img/logo.png" alt="Logo" width="100">
        </span>
      </div>
      <div class="col-8 text-center" >
        <div *ngIf="metadata.enabledSettingProduct">
          <button mat-button [routerLink]="object.route" (click)="object.selected = true; selected(object,'productSettings');" class="item-menu2" [ngClass]="object.selected ? 'item-menu-active2' : ''" *ngFor="let object of productSettings">
            <span>{{object.title}}</span>
          </button>
        </div>
        <div *ngIf="metadata.enabledIventory">
          <!-- <button mat-button [routerLink]="object.route" class="item-menu2" *ngFor="let object of inventoryRouters" (click)="object.selected = true; selected(object,'inventoryRouters');" [ngClass]="object.selected ? 'item-menu-active2' : ''">
            <span>{{object.title}}</span>
          </button> -->
        </div>
      </div>
      <div class="col-2 p-0 text-right">
        <button mat-button [matMenuTriggerFor]="menu" class="userToolbar">
          <span class="text-uppercase">Administrador &nbsp;|&nbsp; </span>
          <span class="material-icons">account_circle</span>
          <!-- <span>{{metadata.object.main.tradename}}</span> -->
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logout();">
            <span class="material-icons">exit_to_app</span>
            <span>Salir</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
