<div class="row img-home" [ngStyle]="{'background-image': 'url(' + metadata.background + ')'}">
  <div class="col-12 text-center mt-5">
    <h1 class="text-white mt-5" style="font-size:3.8rem;" [ngStyle]="{'font-family': metadata.fontFamily}">Bienvenido</h1>
  </div>
  <div class="col-12 text-center">
    <button mat-button color="accent" routerLink="/restaurant/home" style="padding-top: 15%; padding-bottom:10%; font-size:2rem;">Entrar</button>
  </div>
  <div class="col-12 text-center mt-4 mb-2" routerLink="/restaurant/home">
    <img [src]="logo" width="100%" style="padding-top: 10%;">
    <div class="sk-circle" *ngIf="logo == ''">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
  </div>
</div>
<footer class="text-center text-secondary p-1">
© 2019 Keysoft. Reservados todos los derechos.
</footer>
