<mat-drawer-container class="drawerConteiner" autosize>
    <mat-drawer #drawer class="mainDrawer" mode="side">
      <app-menu></app-menu>
    </mat-drawer>
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center pl-0 pr-0">
      <div class="col-10">
        <div class="row">
          <h4 class="text-uppercase pt-2 pb-2 col-6">Gestion de barras</h4>
        </div>
        <mat-tab-group (selectedTabChange)="setBranch($event)">
          <mat-tab *ngFor="let branch of branchList">
            <ng-template mat-tab-label>{{branch.main.name}}</ng-template>
            <div class="row">
              <mat-card class="col-3 mt-1 ml-2" *ngFor="let bar of barList">
                <!-- <button mat-icon-button class="float-right" style="cursor: pointer" (click)="updateDialog(restaurantArea.main.uuid);" color="primary" aria-label="Editar área"> -->
                <button mat-icon-button class="float-right" style="cursor: pointer" (click)="updateDialog(bar.main.uuid);" color="primary" aria-label="Editar barra">
                  <mat-icon>edit</mat-icon>
                </button><br />
                <mat-card-header>
                  <mat-card-title >
                    <mat-icon style="font-size:22px;" color="warn">dinner_dining</mat-icon>&nbsp;{{bar.main.name}}
                  </mat-card-title>
                </mat-card-header>
              </mat-card>
              <div class="col-2 text-center pt-4" (click)="updateDialog('new');">
                <button mat-mini-fab color="accent" aria-label="Nueva barra">
                  <mat-icon>dinner_dining</mat-icon>
                </button>
                <h3 class="mt-2">Nueva Barra</h3>
              </div> 
            </div>
          </mat-tab>
        </mat-tab-group> 
      </div>
    </div>
  
  </mat-drawer-container>
