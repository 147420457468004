import { Input, HostListener, OnInit, Component, ChangeDetectionStrategy } from '@angular/core';
export interface images {
  file: string,
  type: string,
  name: string,
}
@Component({
  selector: 'sti-file-directive',
  templateUrl: './sti-file.component.html',
  styleUrls: ['./sti-file.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StiFileComponent {
  @Input('stiFile') stiFile: images;

  constructor() {
    console.log("FILE",this.stiFile);
  }

  /*
  * funcion para convertir un archivo en base 64
  */

  onFileChange(event) {
  let reader = new FileReader();
  if(event.target.files && event.target.files.length > 0) {
    console.log(event);
    this.stiFile.name = event.target.files[0].name;
    this.stiFile.type = event.target.files[0].type;
    let file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = (event:any) => {
      if(this.stiFile.type == 'image/png' || this.stiFile.type == 'image/jpeg'){
        this.resizeBase64Img(event.target.result,this.stiFile.type).then((compressed:any) => {
           // console.log(compressed);
           this.stiFile.file = compressed;
         })
      }else{
        this.stiFile.file = event.target.result
      }
      console.log("event.target.result");
    };
  }
}
/*
funcion para eliminar el objeto.
*/
deleteAFile(){
  this.stiFile.file = '';
  this.stiFile.name = '';
  this.stiFile.type = '';
}
/*
funcion para descargar un archivo.
*/
downloadAFile(){
  let elem = document.createElement('a');
  elem.href = this.stiFile.file;
  elem.setAttribute('download', this.stiFile.name);
  document.body.appendChild(elem);
  elem.click();
}

/**función para reducir y optimizar una imagen
*/
resizeBase64Img(base64,type, width?, height?){
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      let quality = 0.9;
      let reduction = 1;
      let imgWidth = width? width : img.naturalWidth;
      let imgHeight = height? height : img.naturalHeight;
      //calculamos quality y reduccion de iamgen
      if(imgWidth < 2000){
        if(imgWidth < 1500){
          if(imgWidth < 1000){
            if(imgWidth < 500){
              reduction = .80;
            }else{
              reduction = .70;
            }
          }else{
            reduction = .50;
          }
        }else{
          reduction = .30;
        }
      }else{
        reduction = .20
      }
      // console.log('imgWidth: ', imgWidth*reduction);
      // console.log('imgHeight: ', imgHeight*reduction);
      const elem = document.createElement('canvas');
      elem.width = imgWidth*reduction;
      elem.height = imgHeight*reduction;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, imgWidth*reduction, imgHeight*reduction);
      const data = ctx.canvas.toDataURL(type,quality);
      res(data);
    }
    img.onerror = error => rej(error);
  })
}


}
