<div class="row img-home font-kelson">
  <div class="col-12 background-home">
    <div class="row">
      <div class="col-12 text-center mt-5">
        <!-- <p class="text-white name-restaurant">{{metadata.name}}</p> -->
        <img [src]="logo" alt="{{metadata.name}}" width="50%" *ngIf="logo">
        <div class="sk-circle" *ngIf="logo == ''">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
      </div>
      <div class="col-12 text-center" style="padding-top: 15%; padding-bottom:15%;">
        <button type="button" class="btn btn-outline-light" routerLink="/restaurant-country/home" style="font-size:2rem;">
          Entrar
        </button>
      </div>
      <div class="col-12 text-center mt-1">
        <h1 class="text-white mt-5 font-beatiful font-weight-lighter" style="font-size:4.8rem;">Bienvenido</h1>
      </div>
    </div>
  </div>
</div>
<footer class="text-center text-secondary p-1">
© 2019 Keysoft. Reservados todos los derechos.
</footer>
