import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';

import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

import { StiContactComponent } from './directive/sti-contact/sti-contact.component';
import { StiAddressComponent } from './directive/sti-address/sti-address.directive';
import { StiAddressAnonymusComponent } from './directive/sti-address-anonymus/sti-address.directive';
import { StiFileComponent } from './directive/sti-file/sti-file.directive';
import { KeysoftUploadComponent } from './directive/keysoft-upload/keysoft-upload';
import { MenuComponent } from './menu/menu.component';
import { MenuService } from './menu/menu.service';
import { StiAutoUploadModule } from './directive/sti-auto-upload/sti-upload-file.module';
import { ToolBarComponent } from './tool-bar/tool-bar.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';

import { AgmCoreModule } from '@agm/core';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './pushNotificationService/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';

import { MatGridListModule } from '@angular/material/grid-list';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { RestaurantHomeComponent } from './restaurant/restaurant-home/restaurant-home.component';
import { LoginComponent } from './login/login.component';
import { SelectedObjectComponent } from './login/selected-object/selected-object.component';
import { AdminCompanyClientUpdateComponent } from './admin/admin-company-client/admin-company-client-update/company-client-update.component';
import { AdminUserComponent } from './admin/admin-user/admin-user.component';
import { AdminUserUpdateComponent } from './admin/admin-user/admin-user-update/admin-user-update.component';
import { AdminUserAddAccessComponent } from './admin/admin-user/admin-user-add-access/admin-user-add-access.component';
import { AdminRoleComponent } from './admin/admin-role/admin-role.component';
import { AdminRoleUpdateComponent } from './admin/admin-role/admin-role-update/admin-role-update.component';
import { RestaurantWelcomeComponent } from './restaurant/restaurant-welcome/restaurant-welcome.component';
import { RestaurantMenuComponent } from './restaurant/restaurant-menu/restaurant-menu.component';
import { CompanyClientDashboardComponent } from './companyClient/company-client-dashboard/company-client-dashboard.component';
import { CompanyClientBranchComponent } from './companyClient/company-client-branch/company-client-branch.component';
import { CompanyClientBranchUpdateComponent } from './companyClient/company-client-branch/company-client-branch-update/company-client-branch-update.component';
import { MenuClientComponent } from './restaurant/menu-client/menu-client.component';
import { CompanyClientRestaurantAreaComponent } from './companyClient/company-client-restaurant-area/company-client-restaurant-area.component';
import { CompanyClientRestaurantAreaUpdateComponent } from './companyClient/company-client-restaurant-area/company-client-restaurant-area-update/company-client-restaurant-area-update.component';
import { RestaurantProductDetailComponent } from './restaurant/restaurant-product-detail/restaurant-product-detail.component';
import { CompanyClientFoodCategoryUpdateComponent } from './companyClient/company-client-branch/company-client-food-category-update/company-client-food-category-update.component';
import { RestaurantCountryMenuCollapsedComponent } from './restaurant-country/restaurant-country-menu-collapsed/restaurant-country-menu-collapsed.component';
import { RestaurantCountryHomeComponent } from './restaurant-country/restaurant-country-home/restaurant-country-home.component';
import { RestaurantCountryWelcomeComponent } from './restaurant-country/restaurant-country-welcome/restaurant-country-welcome.component';
import { RestaurantCountryProductDetailComponent } from './restaurant-country/restaurant-country-product-detail/restaurant-country-product-detail.component';
import { BranchKitchenComponent } from './branch/branch-kitchen/branch-kitchen.component';
import { BranchOrderDetailsComponent } from './branch/branch-kitchen/branch-order-details/branch-order-details.component';
import { CompanyClientProductComponent } from './companyClient/company-client-product/company-client-product.component';
import { CompanyClientProductUpdateComponent } from './companyClient/company-client-product/company-client-product-update/company-client-product-update.component';
import { CompanyClientConfigurationComponent } from './companyClient/company-client-configuration/company-client-configuration.component';
import { LoadingComponent } from './loading/loading.component';
import { CompanyClientQrViewComponent } from './companyClient/company-client-branch/company-client-qr-view/company-client-qr-view.component';
import { RestaurantCountryMenuComponent } from './restaurant-country/restaurant-country-menu/restaurant-country-menu.component';
import { RestaurantSeafoodHomeComponent } from './restaurant-seafood/restaurant-seafood-home/restaurant-seafood-home.component';
import { RestaurantSeafoodWelcomeComponent } from './restaurant-seafood/restaurant-seafood-welcome/restaurant-seafood-welcome.component';
import { RestaurantSeafoodProductDetailComponent } from './restaurant-seafood/restaurant-seafood-product-detail/restaurant-seafood-product-detail.component';
import { RestaurantSeafoodMenuCollapsedComponent } from './restaurant-seafood/restaurant-seafood-menu-collapsed/restaurant-seafood-menu-collapsed.component';
import { RestaurantSeafoodMenuComponent } from './restaurant-seafood/restaurant-seafood-menu/restaurant-seafood-menu.component';
import { CompanyClientPromotionComponent } from './companyClient/company-client-promotion/company-client-promotion.component';
import { CompanyClientPromotionUpdateComponent } from './companyClient/company-client-promotion/company-client-promotion-update/company-client-promotion-update.component';
import { RestaurantOrderComponent } from './restaurant/restaurant-order/restaurant-order.component';
import { RestaurantCountryOrderComponent } from './restaurant-country/restaurant-country-order/restaurant-country-order.component';
import { RestaurantCountryPaymentComponent } from './restaurant-country/restaurant-country-payment/restaurant-country-payment.component';
import { RcpaymentDialogComponent } from './restaurant-country/restaurant-country-payment/rcpayment-dialog/rcpayment-dialog.component';
import { RestaurantPaymentComponent } from './restaurant/restaurant-payment/restaurant-payment.component';
import { RpaymentDialogComponent } from './restaurant/restaurant-payment/rpayment-dialog/rpayment-dialog.component';
import { AdminCompanyClientComponent } from './admin/admin-company-client/admin-company-client.component';
import { RestaurantSeafoodOrderComponent } from './restaurant-seafood/restaurant-seafood-order/restaurant-seafood-order.component';
import { RestaurantSeafoodPaymentComponent } from './restaurant-seafood/restaurant-seafood-payment/restaurant-seafood-payment.component';
import { RspaymentDialogComponent } from './restaurant-seafood/restaurant-seafood-payment/rspayment-dialog/rspayment-dialog.component';
import { CompanyClientHotelComponent } from './companyClient/company-client-hotel/company-client-hotel.component';
import { CompanyClientHotelUpdateComponent } from './companyClient/company-client-hotel/company-client-hotel-update/company-client-hotel-update.component';

import { RestaurantRetroHomeComponent } from './restaurant-retro/restaurant-retro-home/restaurant-retro-home.component';
import { RestaurantRetroWelcomeComponent } from './restaurant-retro/restaurant-retro-welcome/restaurant-retro-welcome.component';
import { RestaurantRetroProductDetailComponent } from './restaurant-retro/restaurant-retro-product-detail/restaurant-retro-product-detail.component';
import { RestaurantRetroMenuCollapsedComponent } from './restaurant-retro/restaurant-retro-menu-collapsed/restaurant-retro-menu-collapsed.component';
import { RestaurantRetroMenuComponent } from './restaurant-retro/restaurant-retro-menu/restaurant-retro-menu.component';
import { RestaurantRetroOrderComponent } from './restaurant-retro/restaurant-retro-order/restaurant-retro-order.component';
import { RestaurantRetroPaymentComponent } from './restaurant-retro/restaurant-retro-payment/restaurant-retro-payment.component';
import { RrpaymentDialogComponent } from './restaurant-retro/restaurant-retro-payment/rrpayment-dialog/rrpayment-dialog.component';

import { RestaurantHotelHomeComponent } from './restaurant-hotel/restaurant-hotel-home/restaurant-hotel-home.component';
import { RestaurantHotelWelcomeComponent } from './restaurant-hotel/restaurant-hotel-welcome/restaurant-hotel-welcome.component';
import { RestaurantHotelProductDetailComponent } from './restaurant-hotel/restaurant-hotel-product-detail/restaurant-hotel-product-detail.component';
import { RestaurantHotelMenuCollapsedComponent } from './restaurant-hotel/restaurant-hotel-menu-collapsed/restaurant-hotel-menu-collapsed.component';
import { RestaurantHotelMenuComponent } from './restaurant-hotel/restaurant-hotel-menu/restaurant-hotel-menu.component';
import { RestaurantHotelOrderComponent } from './restaurant-hotel/restaurant-hotel-order/restaurant-hotel-order.component';
import { RestaurantHotelPaymentComponent } from './restaurant-hotel/restaurant-hotel-payment/restaurant-hotel-payment.component';
import { RhpaymentDialogComponent } from './restaurant-hotel/restaurant-hotel-payment/rrpayment-dialog/rrpayment-dialog.component';
import { RestaurantHotelMenuSnComponent } from './restaurant-hotel/restaurant-hotel-menu-sn/restaurant-hotel-menu-sn.component';
import { RestaurantRetroMenuTableComponent } from './restaurant-retro/restaurant-retro-menu-table/restaurant-retro-menu-table.component';
import { CompanyClientWaiterComponent } from './companyClient/company-client-waiter/company-client-waiter.component';
import { CompanyClientWaiterUpdateComponent } from './companyClient/company-client-waiter/company-client-waiter-update/company-client-waiter-update.component';
import { WaiterDashboardComponent } from './waiter/waiter-dashboard/waiter-dashboard.component';
import { WaiterOrderComponent } from './waiter/waiter-order/waiter-order.component';
import { WaiterOrderProductComponent } from './waiter/waiter-order/waiter-order-product/waiter-order-product.component';
import { KitchenDashboardComponent } from './kitchen/kitchen-dashboard/kitchen-dashboard.component';
import { CompanyClientBarComponent } from './companyClient/company-client-bar/company-client-bar.component';
import { CompanyClientBarUpdateComponent } from './companyClient/company-client-bar/company-client-bar-update/company-client-bar-update.component';
import { KitchenBarComponent } from './kitchen/kitchen-bar/kitchen-bar.component';
import { CashierDashboardComponent } from './cashier/cashier-dashboard/cashier-dashboard.component';
import { CashierSaleComponent } from './cashier/cashier-sale/cashier-sale.component';
import { CompanyClientChefComponent } from './companyClient/company-client-chef/company-client-chef.component';
import { CompanyClientChefUpdateComponent } from './companyClient/company-client-chef/company-client-chef-update/company-client-chef-update.component';
import { CompanyClientWaiterAccessComponent } from './companyClient/company-client-waiter/company-client-waiter-access/company-client-waiter-access.component';
import { CompanyClientChefAccessComponent } from './companyClient/company-client-chef/company-client-chef-access/company-client-chef-access.component';
import { WaiterOrderBarComponent } from './waiter/waiter-order/waiter-order-bar/waiter-order-bar.component';

@NgModule({
  declarations: [
    AppComponent,
    StiContactComponent,
    StiAddressComponent,
    StiAddressAnonymusComponent,
    StiFileComponent,
    KeysoftUploadComponent,
    MenuComponent,
    ToolBarComponent,
    AdminDashboardComponent,
    RestaurantHomeComponent,
    LoginComponent,
    SelectedObjectComponent,
    AdminCompanyClientUpdateComponent,
    AdminUserComponent,
    AdminUserUpdateComponent,
    AdminUserAddAccessComponent,
    AdminRoleComponent,
    AdminRoleUpdateComponent,
    RestaurantWelcomeComponent,
    RestaurantMenuComponent,
    CompanyClientDashboardComponent,
    CompanyClientBranchComponent,
    CompanyClientBranchUpdateComponent,
    MenuClientComponent,
    CompanyClientRestaurantAreaComponent,
    CompanyClientRestaurantAreaUpdateComponent,
    RestaurantProductDetailComponent,
    CompanyClientFoodCategoryUpdateComponent,
    RestaurantCountryMenuCollapsedComponent,
    RestaurantCountryHomeComponent,
    RestaurantCountryWelcomeComponent,
    RestaurantCountryProductDetailComponent,
    BranchKitchenComponent,
    BranchOrderDetailsComponent,
    CompanyClientProductComponent,
    CompanyClientProductUpdateComponent,
    CompanyClientConfigurationComponent,
    LoadingComponent,
    CompanyClientQrViewComponent,
    RestaurantCountryMenuComponent,
    RestaurantSeafoodHomeComponent,
    RestaurantSeafoodWelcomeComponent,
    RestaurantSeafoodProductDetailComponent,
    RestaurantSeafoodMenuCollapsedComponent,
    RestaurantSeafoodMenuComponent,
    CompanyClientPromotionComponent,
    CompanyClientPromotionUpdateComponent,
    RestaurantOrderComponent,
    RestaurantCountryOrderComponent,
    RestaurantCountryPaymentComponent,
    RcpaymentDialogComponent,
    RestaurantPaymentComponent,
    RpaymentDialogComponent,
    AdminCompanyClientUpdateComponent,
    AdminCompanyClientComponent,
    RestaurantSeafoodOrderComponent,
    RestaurantSeafoodPaymentComponent,
    RspaymentDialogComponent,
    CompanyClientHotelComponent,
    CompanyClientHotelUpdateComponent,
    RestaurantRetroHomeComponent,
    RestaurantRetroWelcomeComponent,
    RestaurantRetroProductDetailComponent,
    RestaurantRetroMenuCollapsedComponent,
    RestaurantRetroMenuComponent,
    RestaurantRetroOrderComponent,
    RestaurantRetroPaymentComponent,
    RrpaymentDialogComponent,
    RestaurantHotelHomeComponent,
    RestaurantHotelWelcomeComponent,
    RestaurantHotelProductDetailComponent,
    RestaurantHotelMenuCollapsedComponent,
    RestaurantHotelMenuComponent,
    RestaurantHotelOrderComponent,
    RestaurantHotelPaymentComponent,
    RhpaymentDialogComponent,
    RestaurantHotelMenuSnComponent,
    RestaurantRetroMenuTableComponent,
    CompanyClientWaiterComponent,
    CompanyClientWaiterUpdateComponent,
    WaiterDashboardComponent,
    WaiterOrderComponent,
    WaiterOrderProductComponent,
    KitchenDashboardComponent,
    CompanyClientBarComponent,
    CompanyClientBarUpdateComponent,
    KitchenBarComponent,
    CashierDashboardComponent,
    CashierSaleComponent,
    CompanyClientChefComponent,
    CompanyClientChefUpdateComponent,
    CompanyClientWaiterAccessComponent,
    CompanyClientChefAccessComponent,
    WaiterOrderBarComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    SessionModule.forRoot(),
    LoadingModule.forRoot(),
    StiAutoUploadModule.forRoot(),
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatRadioModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatNativeDateModule,
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBYO4QR3MizJVXeg1AP4guJ19tvXMOo38Y'
    }),
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyBYO4QR3MizJVXeg1AP4guJ19tvXMOo38Y",
      authDomain: "quickly-286316.firebaseapp.com",
      databaseURL: "https://quickly-286316.firebaseio.com",
      projectId: "quickly-286316",
      storageBucket: "quickly-286316.appspot.com",
      messagingSenderId: "331572397080",
      appId: "1:331572397080:web:d446d0dcc8fa1e46912f6d",
      measurementId: "G-NPG045CWMD"
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    MatGridListModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  providers: [
    MenuService,
    MessagingService,
    AsyncPipe
  ],
  entryComponents:[
    StiContactComponent,
    StiAddressComponent,
    StiAddressAnonymusComponent,
    StiFileComponent,
    KeysoftUploadComponent,
    SelectedObjectComponent,
    AdminUserUpdateComponent,
    AdminUserAddAccessComponent,
    CompanyClientBranchUpdateComponent,
    CompanyClientRestaurantAreaUpdateComponent,
    CompanyClientFoodCategoryUpdateComponent,
    CompanyClientHotelUpdateComponent,
    CompanyClientQrViewComponent,
    CompanyClientPromotionUpdateComponent,
    RcpaymentDialogComponent,
    RpaymentDialogComponent,
    RspaymentDialogComponent,
    RrpaymentDialogComponent,
    RhpaymentDialogComponent,
    AdminRoleUpdateComponent,
    CompanyClientWaiterUpdateComponent,
    // WaiterOrderComponent,
    WaiterOrderProductComponent,
    CompanyClientBarUpdateComponent,
    CompanyClientChefUpdateComponent,
    CompanyClientWaiterAccessComponent,
    CompanyClientChefAccessComponent,
    WaiterOrderBarComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
