<!---<div class="row bg-dark">
    <div class="col-12 text-center pt-1 pb-1">
     <h3 class="text-white text-uppercase">{{object.main.uuid == '' ? 'Agregar' : 'Editar'}} comanda</h3>
      <h3 class="text-white text-uppercase">Detalle de producto</h3>
    </div>
</div>

<div class="row mt-3">
  <mat-form-field class="col-4" appearance="outline">
    <mat-label>&nbsp;Cantidad</mat-label>
    <input type="number" matInput placeholder="Cantidad" name="mQuantity" [(ngModel)]="object.main.quantity">
    <mat-error *ngIf="complexForm.controls['quantity'].hasError('required')">
      Campo <strong>requerido</strong>.
    </mat-error>
  </mat-form-field>

    <mat-form-field class="col-6" appearance="outline" *ngIf="this.object.system.foodSaucer.main.foodSaucerSize == true">
      <mat-label>Tamaño</mat-label>
      <mat-select placeholder="Tamaños del platillo" name="sFoodSaucerSize" [(ngModel)]="object.system.foodSaucerSize.main.uuid">
        <mat-option *ngFor="let object of foodSaucerSizeList" [value]="object.main.uuid">
          {{object.main.size}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  <mat-form-field class="col-12" appearance="outline">
    <mat-label>&nbsp;Anotación</mat-label>
    <input matInput placeholder="Anotación" name="mObservation" [(ngModel)]="object.main.observation">
  </mat-form-field>
</div>

<div class="row justify-content-end mt-2 mb-2">
  <div class="col-6">
    <button type="button" class="btn btn-danger btn-block" color="warn" style="width:100%" [disabled]="object.main.quantity == '' || object.main.quantity == 0 " (click)="send(object);">Guardar</button>
  </div>
</div> --->

<!-- <div class="row bg-dark">
    <div class="col-12 text-center pt-1 pb-1">
      <h3 class="text-white text-uppercase">Ticket</h3>
    </div>
</div> -->

<div>
<table class="table table-general mt-3">
  <thead class="thead-dark">
  <tr>
    <th scope="col">Platillo</th>
    <th scope="col">Cant.</th>
    <th scope="col">Precio</th>
    <th scope="col">Subtotal</th>
  </tr>
  </thead>
  <tbody>
  <tr  *ngFor="let foodOrderDetail of foodOrderDetails; let x = index;">
    <td>{{foodOrderDetail.system.foodSaucer.main.name}}</td>
    <td>{{foodOrderDetail.main.quantity}}</td>
    <td>{{foodOrderDetail.main.priceUnitary | currency:'CAD':'symbol-narrow'}}</td>
    <td>{{foodOrderDetail.main.subTotal | currency:'CAD':'symbol-narrow'}}</td>
  </tr>
  </tbody>
  <tfoot style="background-color: #ecd044;">
  <tr>
    <td>Total de platillos:</td>
    <td>{{foodOrderDetails.length}}</td>
    <td>Total de cuenta:</td>
    <td>{{this.object.main.total | currency:'CAD':'symbol-narrow'}}</td>
  </tr>
  </tfoot>
</table>
</div>


<div class="row justify-content-end mt-2 mb-2">
  <div class="col-4">
    <button type="button" class="btn btn-danger btn-block"  (click)="close(false);">Cerrar</button>
  </div>
  <div class="col-4">
    <button type="button" class="btn btn-warn btn-block" color="warn" style="width:100%" (click)="charge(object);">
      Imprimir ticket
    </button>
  </div>
  <div class="col-4">
    <button type="button" class="btn btn-ll btn-block" color="yellow" style="width:100%" (click)="closingBill();" >
      Cerrar cuenta
    </button>
  </div>
</div>

<!-- <mat-card-footer> -->
<!--   <div class="row justify-content-end mt-2 mb-2">
    <div class="col-6">
      <button type="button" class="btn btn-block" style="width:100%" (click)="close(false);">
        Cancelar
      </button>
    </div>
    <div class="col-6">
      <button type="button" class="btn btn-warn btn-block" color="warn" style="width:100%">
        Imprimir ticket
      </button>
    </div>
  </div> -->
<!-- </mat-card-footer> -->



